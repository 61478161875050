import { Info } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React, { useState } from 'react'

export default function StatusCell({ data, config, withInfo, info }) {
  var type = config.filter((item) => item.status === data)
  type = type[0]

  useState(() => {}, [data])

  const setStatus = () => {
    if (!type) {
      return (
        <span className="py-1 px-3 rounded bg-secondary-gray text-primary-gray relative">
          <span>{'missing'}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    } else if (type.state === 'success') {
      return (
        <span className="py-1 px-3 rounded bg-secondary-green text-primary-green relative">
          <span>{type.text}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    } else if (type.state === 'warning') {
      return (
        <span className="py-1 px-3 rounded bg-secondary-yellow text-primary-yellow relative">
          <span>{type.text}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    } else if (type.state === 'warning-urgent') {
      return (
        <span className="py-1 px-3 rounded bg-secondary-orange text-primary-orange relative">
          <span>{type.text}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    } else if (type.state === 'danger') {
      return (
        <span className="py-1 px-3 rounded bg-secondary-red text-primary-red relative">
          <span>{type.text}</span>
          {withInfo ? (
            <Tooltip title={info} placement="top-start">
              <span className="relative -top-[1px]">
                <Info sx={{ fontSize: 14 }} />{' '}
              </span>
            </Tooltip>
          ) : null}
        </span>
      )
    }
    return (
      <span className="py-1 px-3 rounded bg-secondary-blue text-primary-blue relative">
        <span>{type.text}</span>
        {withInfo ? (
          <Tooltip title={info} placement="top-start">
            <span className="relative -top-[1px]">
              <Info sx={{ fontSize: 14 }} />{' '}
            </span>
          </Tooltip>
        ) : null}
      </span>
    )
  }

  return <div className="w-full">{setStatus()}</div>
}
