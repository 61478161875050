import React from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import ChangePassword from './ChangePassword'
import { AccountCircle, Lock } from '@mui/icons-material'
import UpdateProfile from './UpdateProfile'

export default function Profile() {
  return (
    <div className="flex mt-12 min-h-[calc(100vh-56px-48px-48px)] w-[1200px] mx-auto border rounded-lg">
      <div className="w-[350px] min-h-full border-r rounded-l-lg overflow-hidden">
        <NavLink to="" end>
          {({ isActive }) => {
            return (
              <span
                className={`px-8 py-6 text-big flex w-full items-center gap-2 transition-all ${
                  isActive ? 'bg-main-500 text-white' : 'text-slate-600 hover:text-main-500 hover:bg-main-100'
                }`}
              >
                <span className="w-4 h-4 flex items-center justify-center">
                  <AccountCircle sx={{ fontsize: 24 }} />
                </span>
                <span className="ml-2 font-semibold">Profile</span>
              </span>
            )
          }}
        </NavLink>
        <NavLink to="change-password" end>
          {({ isActive }) => {
            return (
              <span
                className={`px-8 py-6 text-big flex w-full items-center gap-2 transition-all ${
                  isActive ? 'bg-main-500 text-white' : 'text-slate-400 hover:text-main-500 hover:bg-sky-50'
                }`}
              >
                <span className="w-4 h-4 flex items-center justify-center">
                  <Lock sx={{ fontsize: 24 }} />
                </span>
                <span className="ml-2 font-semibold">Change Password</span>
              </span>
            )
          }}
        </NavLink>
      </div>
      <div className="flex-1 gap-4 p-6 min-h-full overflow-y-scroll scrollbar-thin scrollbar-thumb-main-500">
        <Routes>
          <Route path="/" element={<UpdateProfile />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Routes>
      </div>
    </div>
  )
}
