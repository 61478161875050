import React, { forwardRef, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import { format } from 'date-fns'
import { DayPicker } from 'react-day-picker'
import withClickOutside from '../withClickOutside'
import moment from 'moment/moment'
import { getWeekNumber, getWeekOfMonth } from '../helper'

const DropdownRangeDate = forwardRef(function DropdownRangeDate({ open, setOpen, props }, ref) {
  const { data, handleSelectedOption, noPic, type, value, mode } = { ...props }
  const [range, setRange] = useState()

  useEffect(() => {
    handleSelectedOption(range)
  }, [range])

  useEffect(() => {
    // setSelected(value)
  }, [value])

  const css = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    background-color: #024267;
    color: white;
  }`

  return (
    <div className="relative inline-block text-left" ref={ref}>
      <div className="min-w-[250px]">
        <button
          type="button"
          className="w-full rounded-md bg-white px-4 py-2 font-medium text-gray-700 hover:bg-sky-50 focus:outline-none focus:ring-0"
          onClick={() => setOpen(!open)}
        >
          {range ? (
            <div className="flex items-center gap-2">
              <span className="w-10 h-10 rounded-full bg-blue-100 overflow-hidden flex items-center justify-center text-main-500">
                <BusinessCenterIcon sx={{ fontSize: 22 }} />
              </span>
              <div className="flex-1 text-start pr-4">
                <p className="font-bold text-main-500">Created Date</p>
                <div className="flex items-center justify-between">
                  <span className="flex-1 text-normal">
                    {moment(range.from).format('D MMM YYYY')} - {moment(range.to).format('D MMM YYYY')}
                  </span>
                  <span className="flex items-center justify-center w-2 h-2">
                    <KeyboardArrowDownIcon sx={{ fontSize: 20 }} className="ml-4" />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <span className="w-10 h-10 rounded-full bg-blue-100 overflow-hidden flex items-center justify-center text-main-500">
                <BusinessCenterIcon sx={{ fontSize: 22 }} />
              </span>
              <div className="flex-1 text-start pr-4">
                <p className="font-bold text-main-500">Created Date</p>
                <div className="flex items-center justify-between">
                  <span className="flex-1 text-normal">This Year</span>
                  <span className="flex items-center justify-center w-2 h-2">
                    <KeyboardArrowDownIcon sx={{ fontSize: 20 }} className="ml-4" />
                  </span>
                </div>
              </div>
            </div>
          )}
        </button>
      </div>
      {open && (
        <div className="absolute left-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1" role="none">
            {/* {data.map((option) => {
              return (
                <button
                  key={option.id}
                  className="text-gray-700 w-full px-4 py-2 text-sm flex items-center gap-2 hover:bg-main-50"
                  onClick={() => handleOptionClicked(option)}
                >
                  <>
                    {noPic ? null : (
                      <span className="rounded-full bg-main-100 overflow-hidden">
                        <img className="w-8 h-8" src={option.image} alt="" />
                      </span>
                    )}
                    <>{option.firstName}</>
                  </>
                </button>
              )
            })} */}
            <style>{css}</style>
            <DayPicker
              defaultMonth={new Date()}
              mode="range"
              selected={range}
              modifiersClassNames={{
                selected: 'my-selected',
              }}
              // onSelect={(date) => {
              //   setRange()
              //   // setSelected(date)
              //   // setOpen(!open)
              // }}
              onSelect={setRange}
            />
          </div>
        </div>
      )}
    </div>
  )
})

export default withClickOutside(DropdownRangeDate)
