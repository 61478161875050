import { Link, useNavigate } from 'react-router-dom'
import Basic from './cell/Basic'
import React, { useEffect, useState } from 'react'
import { Delete, Edit, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import Button from '../Button'
import Pagination from '../Pagination'

const Td = ({ className, children, ...props }) => {
  return (
    <td className={`px-4 py-4 ${className && className}`} {...props}>
      {children}
    </td>
  )
}

const Tr = ({ className, children, ...props }) => {
  return (
    <tr className="border-b border-gray-border" {...props}>
      {children}
    </tr>
  )
}

function Table({
  cols,
  data,
  handleDelete,
  withActions,
  customAction,
  editLink,
  viewLink,
  tableType = 'full',
  footer,
  noIndex,
  withPaging,
  pageIndex,
  withoutDelete,
}) {
  const navigate = useNavigate()

  const [totalPage, setTotalPage] = useState(Math.ceil(data.length / 10))
  const [currentPage, setCurrentPage] = useState(1)
  const [displayedData, setDisplayedData] = useState([])

  useEffect(() => {
    // console.log(Math.ceil(data.length / 10))
    if (withPaging) {
      setDisplayedData(data.slice((currentPage - 1) * 10, currentPage * 10))
    } else {
      setDisplayedData(data)
    }
  }, [currentPage, data])

  useEffect(() => {
    // console.log((pageIndex - 1) * 10 + 0 + 1)
    console.log(data)
  }, [pageIndex])

  const handlePageChange = (page) => {
    setCurrentPage(page)
    // console.log(page)
  }

  return (
    <>
      <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-main-500 scrollbar-track-sky-50">
        <table className={tableType == 'inline' ? 'table-auto ' : 'table-auto min-w-full'}>
          <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
            <tr>
              {!noIndex && <th className="px-4 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>}
              {withActions ? (
                <>
                  {cols.map((col, index) => {
                    return (
                      <th key={index} className={`px-4 py-4 ${col.classes}`}>
                        {col.name}
                      </th>
                    )
                  })}
                  <th className="action-column sticky top-auto right-0 px-6 z-10 bg-main-500 text-center rounded-r">
                    Action
                  </th>
                </>
              ) : (
                <>
                  {customAction ? (
                    <>
                      {cols.map((col, index) => {
                        if (index === cols.length - 1) {
                          return (
                            <th key={index} className={`px-4 py-4 ${col.classes}`}>
                              {col.name}
                            </th>
                          )
                        } else if (noIndex && index === 0) {
                          return (
                            <th key={index} className={`px-4 py-4 rounded-l-[5px] ${col.classes}`}>
                              {col.name}
                            </th>
                          )
                        }
                        return (
                          <th key={index} className={`px-4 py-4 ${col.classes}`}>
                            {col.name}
                          </th>
                        )
                      })}
                      <th className="action-column w-[175px] sticky top-auto right-0 px-6 z-10 bg-main-500 text-center rounded-r">
                        Action
                      </th>
                    </>
                  ) : (
                    cols.map((col, index) => {
                      if (index === cols.length - 1) {
                        return (
                          <th key={index} className={`px-4 py-4 rounded-r-[5px] ${col.classes}`}>
                            {col.name}
                          </th>
                        )
                      } else if (noIndex && index === 0) {
                        return (
                          <th key={index} className={`px-4 py-4 rounded-l-[5px] ${col.classes}`}>
                            {col.name}
                          </th>
                        )
                      }
                      return (
                        <th key={index} className={`px-4 py-4 ${col.classes}`}>
                          {col.name}
                        </th>
                      )
                    })
                  )}
                </>
              )}
            </tr>
          </thead>
          <tbody className="font-semibold">
            {data.length === 0 ? (
              <tr className="text-center italic">
                <td colSpan={cols.length} className="text-gray-text font-semibold py-4">
                  No data found
                </td>
              </tr>
            ) : (
              displayedData.map((row, index) => {
                if (viewLink) {
                  return (
                    <tr key={index} className="border-b border-gray-border ">
                      {!noIndex && (
                        <td className="px-4 py-4 w-8 hover:cursor-pointer" onClick={() => navigate(viewLink(row))}>
                          {withPaging
                            ? (currentPage - 1) * 10 + index + 1
                            : pageIndex
                            ? (pageIndex - 1) * 10 + index + 1
                            : index + 1}
                          .
                        </td>
                      )}
                      {cols.map((col) => {
                        return (
                          <td
                            className={`px-4 py-4 hover:cursor-pointer`}
                            key={col.id}
                            onClick={() => navigate(viewLink(row))}
                          >
                            {col.customCell ? col.customCell(row) : col.selector(row)}
                          </td>
                        )
                      })}
                      {withActions && customAction === undefined && (
                        <td className="action-column bg-white sticky top-auto right-0 px-4 py-4 w-8">
                          <div className="h-full flex items-center">
                            <Link
                              to={editLink(row)}
                              className="inline-flex w-8 h-8 items-center justify-center rounded text-main-100 hover:bg-sky-200 hover:text-main-500 transition-all"
                            >
                              <Edit sx={{ fontSize: 20 }} />
                            </Link>
                            {!withoutDelete && (
                              <button
                                onClick={() => handleDelete(row.id)}
                                className="inline-flex w-8 h-8 items-center justify-center rounded text-main-100 hover:bg-red-200 hover:text-red-500 transition-all"
                              >
                                <Delete sx={{ fontSize: 20 }} />
                              </button>
                            )}
                          </div>
                        </td>
                      )}
                      {customAction !== undefined && (
                        <td className="action-column bg-white sticky top-auto right-0 px-4 py-4 w-8">
                          <div className="h-full flex items-center">{customAction(row)}</div>
                        </td>
                      )}
                    </tr>
                  )
                }
                return (
                  <tr key={index} className="border-b border-gray-border">
                    {!noIndex && (
                      <td className="px-4 py-4 w-8">
                        {withPaging
                          ? (currentPage - 1) * 10 + index + 1
                          : pageIndex
                          ? (pageIndex - 1) * 10 + index + 1
                          : index + 1}
                        .
                      </td>
                    )}
                    {cols.map((col) => {
                      return (
                        <td className={col.tdClassName ? col.tdClassName : `px-4 py-4`} key={col.id}>
                          {col.customCell ? col.customCell(row) : col.selector(row)}
                        </td>
                      )
                    })}
                    {withActions && customAction === undefined && (
                      <td className="action-column bg-white sticky top-auto right-0 px-4 py-4 w-8">
                        <div className="h-full flex items-center">
                          <Link
                            to={editLink(row)}
                            className="inline-flex w-8 h-8 items-center justify-center rounded text-main-100 hover:bg-sky-200 hover:text-main-500 transition-all"
                          >
                            <Edit sx={{ fontSize: 20 }} />
                          </Link>

                          {!withoutDelete && (
                            <button
                              onClick={() => handleDelete(row.id)}
                              className="inline-flex w-8 h-8 items-center justify-center rounded text-main-100 hover:bg-red-200 hover:text-red-500 transition-all"
                            >
                              <Delete sx={{ fontSize: 20 }} />
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                    {customAction !== undefined && (
                      <td className="action-column bg-white sticky top-auto right-0 px-4 py-4 w-8">
                        <div className="h-full flex items-center">{customAction(row)}</div>
                      </td>
                    )}
                  </tr>
                )
              })
            )}
          </tbody>
          {/* {withPaging && (
        <tfoot>
          <tr>
            <td colSpan={cols.length + 1}>
            </td>
          </tr>
        </tfoot>
      )} */}
          {footer && <tfoot>{footer}</tfoot>}
        </table>
        {withPaging && (
          <div className="mt-4 flex justify-end">
            <Pagination totalPage={totalPage} currentPage={currentPage} onChange={handlePageChange} />
          </div>
        )}
      </div>
    </>
  )
}

Table.Tr = Tr
Table.Td = Td

export default Table
