import React from 'react'

export default function Input({ label, onChange, className, value, name, error, type, containerClasses, ...props }) {
  return (
    <div className={`${containerClasses}`}>
      {label && (
        <p className="text-slate-800 font-semibold mb-1">
          {label}
          {props.required && <strong className="text-red-500">*</strong>}
        </p>
      )}
      <input
        type={type}
        value={value}
        name={name}
        className={`px-3 py-3 rounded-[5px] border ${
          error ? 'border-red-500' : 'border-primary-gray'
        } w-full block transition-all
                    focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none
                    focus:border-main-500
                     ${className}`}
        onChange={(e) => onChange(e)}
        {...props}
      />
    </div>
  )
}
