import React, { useEffect, useState } from 'react'
import Input from '../../components/Input'
import InputDate from '../../components/InputDate'
import DragAndDropFile from '../../components/DragAndDropFile'
import Button from '../../components/Button'
import BreadCrumbs from '../../components/BreadCrumbs'
import { useParams, useNavigate } from 'react-router-dom'
import { industry } from '../../services/industry'
import DropdownInput from '../../components/DropdownInput'
import ClientAPI from '../../apis/ClientAPI'
import Loading from '../../components/Loading'
import StorageAPI from '../../apis/storageAPI'
import moment from 'moment'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import usePermission from '../../hooks/usePermission'
import ErrorPage from '../../ErrorPage'
import Alert from '../../components/Alert'
import { WarningAmber } from '@mui/icons-material'

export default function EditClient() {
  let { id } = useParams()
  const [client, setClient] = useState({})
  const [title, setTitle] = useState('Client')
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(true)
  const [selectedIndustry, setSelectedIndustry] = useState()
  const [newLogo, setNewLogo] = useState(null)
  const [user, setUser] = useLocalStorage('user', {})
  const [isLoadingSave, setLoadingSave] = useState(false)
  const [fieldsError, setFieldsError] = useState({
    company_name: false,
    company_code: false,
    company_address: false,
    industry: false,
    pic_name: false,
    pic_title: false,
    pic_email: false,
    pic_phone_number: false,
  })
  const [errorSave, setErrorSave] = useState()
  const { isAdmin } = usePermission()
  const [firstSave, setFirstSave] = useState(true)
  const [listAgency, setListAgency] = useState([])

  useEffect(() => {}, [])

  useEffect(() => {
    var promises = [
      // new Promise((resolve, reject) => {
      //   ClientAPI.getListAgency()
      //     .then((res) => {
      //       setListAgency(res.data)
      //       resolve()
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //       reject()
      //     })
      // }),
      id != undefined &&
        new Promise((resolve, reject) => {
          ClientAPI.getClient(id).then((data) => {
            setClient(data.data)
            setSelectedIndustry(data.data.industry)
            resolve()
          })
        }),
    ]

    if (id != undefined) {
      setTitle('Edit ' + title)
    } else {
      setTitle('Add New ' + title)
      setLoading(false)
    }

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    console.log(listAgency)
  }, [listAgency])

  const handleChange = (input) => {
    setClient({ ...client, [input.name]: input.name === 'company_code' ? input.value.toUpperCase() : input.value })
  }

  function handleSelectedOption(selected) {
    setClient({ ...client, industry: selected.id })
  }

  function handleSelectedAnniversaryDate(selected) {
    setClient({ ...client, company_anniversary_date: selected })
  }

  function handleSelectedBirthDate(selected) {
    setClient({ ...client, pic_birth_date: selected })
  }

  function handleFileUpload(input) {
    const objectUrl = URL.createObjectURL(input)
    setNewLogo({ file: input, blob: objectUrl })
    setClient({
      ...client,
      logo: objectUrl,
    })
  }

  function handleSave() {
    setLoadingSave(true)
    setFirstSave(false)
    var finalData = {
      ...client,
      company_anniversary_date:
        client.company_anniversary_date == null ? null : moment(client.company_anniversary_date).format('YYYY-MM-DD'),
      pic_birth_date: client.pic_birth_date == null ? null : moment(client.pic_birth_date).format('YYYY-MM-DD'),
    }

    var allowSave = true
    var requiredKey = ['company_name', 'company_code', 'industry']

    requiredKey.forEach((key) => {
      if (!client[key]) {
        allowSave = false
        setFieldsError((fieldsError) => ({ ...fieldsError, [key]: true }))
      }
    })

    if (client.company_code?.length !== 4) {
      allowSave = false
      setFieldsError({ ...fieldsError, company_code: true })
    }

    if (allowSave) {
      if (id != undefined) {
        if (newLogo != null) {
          var fileExtension = newLogo.file.name.split('.').pop()

          StorageAPI.uploadFile(`client-logo/${id + '.' + fileExtension}`, newLogo.file)
            .then((res) => {
              finalData = { ...finalData, logo: res }
              ClientAPI.update(finalData)
                .then((res) => {
                  if (res.success) {
                    navigate(-1)
                  } else throw res
                })
                .catch((err) => {
                  setLoadingSave(false)
                  if (err.response) {
                    setErrorSave(err.response.data.message)
                  } else {
                    setErrorSave(err.statusText)
                  }
                })
            })
            .catch((err) => {
              setLoadingSave(false)
              if (err.response) {
                setErrorSave(err.response.data.message)
              } else {
                setErrorSave(err.statusText)
              }
            })
        } else {
          ClientAPI.update(finalData)
            .then((res) => {
              navigate('/data/clients')
            })
            .catch((err) => {
              if (err.response) {
                setErrorSave(err.response.data.message)
              } else {
                setErrorSave(err.statusText)
              }
              setLoadingSave(false)
            })
        }
      } else {
        if (newLogo != null) {
          var fileExtension = newLogo.file.name.split('.').pop()
          StorageAPI.uploadFile(`client-logo/${id + '.' + fileExtension}`, newLogo.file)
            .then((res) => {
              finalData = { ...finalData, logo: res }
              ClientAPI.create(finalData)
                .then((res) => {
                  navigate(-1)
                })
                .catch((err) => {
                  if (err.response) {
                    setErrorSave(err.response.data.message)
                  } else {
                    setErrorSave(err.statusText)
                  }
                  setLoadingSave(false)
                })
            })
            .catch((err) => {
              if (err.response) {
                setErrorSave(err.response.data.message)
              } else {
                setErrorSave(err.statusText)
              }
              setLoadingSave(false)
            })
        } else {
          ClientAPI.create(finalData)
            .then((res) => {
              navigate(-1)
            })
            .catch((err) => {
              if (err.response) {
                setErrorSave(err.response.data.message)
              } else {
                setErrorSave(err.statusText)
              }
              setLoadingSave(false)
            })
        }
      }
    } else {
      setErrorSave('Please fill the required fields')
      setLoadingSave(false)
    }
  }

  return (
    <div className="py-4">
      <BreadCrumbs className="mb-4">
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item href={'/data/clients'}>Clients</BreadCrumbs.Item>
        <BreadCrumbs.Item current>{title}</BreadCrumbs.Item>
      </BreadCrumbs>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <h1 className="text-big font-semibold mb-2">{title}</h1>
          <div className="rounded-lg border border-gray-border p-4 flex gap-16 mb-4">
            <div className="w-[270px] ">
              <div className="comp flex flex-col gap-4 mb-8">
                <Input
                  value={client.company_name}
                  label={'Company Name'}
                  name="company_name"
                  placeholder="Company name"
                  error={fieldsError.company_name}
                  onChange={(e) => handleChange(e.target)}
                  required
                />
                <Input
                  value={client.company_code}
                  label={'Company Code'}
                  name="company_code"
                  placeholder="Company code"
                  error={fieldsError.company_code}
                  onChange={(e) => handleChange(e.target)}
                  required
                />
                {!firstSave && fieldsError.company_code && (
                  <p className="text-small italic text -mt-2.5 text-red-500 font-medium">
                    <WarningAmber sx={{ fontSize: 14 }} />
                    Company codes need to have four characters.
                  </p>
                )}
                {/* <div>
                  <p className="text-slate-800 font-semibold mb-1">Agency</p>
                  <DropdownInput
                    data={listAgency}
                    handleSelectedOption={(selected) => setClient({ ...client, agency_id: selected.id })}
                    attributeShown="name"
                    placeholder="Choose Agency"
                    value={listAgency.find((agency) => agency.id == client.agency_id)}
                  />
                </div> */}
                <Input
                  value={client.company_address}
                  label={'Company Address'}
                  name="company_address"
                  placeholder="Company address"
                  error={fieldsError.company_address}
                  onChange={(e) => handleChange(e.target)}
                />
                <div>
                  <p className="text-slate-800 font-semibold mb-1">
                    Industry<strong className="text-red-500">*</strong>
                  </p>

                  <DropdownInput
                    defaultValue={selectedIndustry}
                    data={industry}
                    handleSelectedOption={handleSelectedOption}
                    placeholder="Choose industry"
                    value={industry.find((ind) => ind.id == client.industry)}
                    error={fieldsError.industry}
                    attributeShown="industry"
                    required
                  />
                </div>
                <InputDate
                  label={'Company Anniversary Date'}
                  name="company_anniversary_date"
                  value={client.company_anniversary_date}
                  placeholder="Company Anniversary Date"
                  handleSelectedOption={handleSelectedAnniversaryDate}
                />
              </div>
              <div className="pic flex flex-col gap-4">
                <Input
                  label={'PIC Name'}
                  name="pic_name"
                  value={client.pic_name}
                  error={fieldsError.pic_name}
                  placeholder="PIC name"
                  onChange={(e) => handleChange(e.target)}
                />
                <Input
                  label={'PIC Title'}
                  name="pic_title"
                  value={client.pic_title}
                  error={fieldsError.pic_title}
                  placeholder="PIC title"
                  onChange={(e) => handleChange(e.target)}
                />
                <Input
                  label={'PIC Email'}
                  name="pic_email"
                  value={client.pic_email}
                  placeholder="PIC email"
                  error={fieldsError.pic_email}
                  type="email"
                  onChange={(e) => handleChange(e.target)}
                />
                <Input
                  label={'PIC Phone Number'}
                  name="pic_phone_number"
                  value={client.pic_phone_number}
                  placeholder="PIC phone number"
                  error={fieldsError.pic_phone_number}
                  onChange={(e) => handleChange(e.target)}
                />
                <InputDate
                  label={'PIC Birth Date'}
                  name="pic_birth_date"
                  value={client.pic_birth_date}
                  placeholder="PIC Birth Date"
                  handleSelectedOption={handleSelectedBirthDate}
                />
              </div>
            </div>
            <div className="w-[270px]">
              <DragAndDropFile
                className="h-[100px] mb-4"
                label="Logo"
                onChange={(input) => {
                  handleFileUpload(input)
                }}
              />
              {client.logo ? (
                <div
                  className="w-24 h-24 border border-gray-border 
                                  flex items-center justify-center
                                  p-2 rounded-lg"
                >
                  <img src={client.logo} className="w-full" />
                </div>
              ) : null}
            </div>
            <div className="flex-1"></div>
          </div>
          <div className="flex gap-4">
            <Button onClick={handleSave} isLoading={isLoadingSave}>
              Save
            </Button>
            {errorSave && <Alert type={'error'} message={errorSave} />}
          </div>
        </div>
      )}
    </div>
  )
}
