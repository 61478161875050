import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import DropdownInput from '../../components/DropdownInput'
import Button from '../../components/Button'
import Alert from '../../components/Alert'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import { ProductsAPI } from '../../apis/ProductsAPI'
import Basic from '../../components/table/cell/Basic'
import { formatThousands } from '../../helper'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import usePermission from '../../hooks/usePermission'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'

export default function FlagshipDetail() {
  const { id } = useParams()

  const [project, setProject] = useState()
  const [updatedProject, setUpdatedProject] = useState()
  const [isLoading, setLoading] = useState(true)
  const [isLoadingSaveProject, setLoadingSaveProject] = useState(false)
  const [errorUpdateProject, setErrorUpdateProject] = useState()
  const navigate = useNavigate()
  const { isAdmin } = usePermission()
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])

  useEffect(() => {
    console.log(updatedProject)
  }, [updatedProject])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ProjectAPI.getFlagshipProjectById(id).then((res) => {
          setProject({ ...res.data, type: 'flagship' })
          setUpdatedProject({ ...res.data, type: 'flagship' })
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [id])

  const handleStatusChange = (selected) => {
    var temp = { ...updatedProject, status: selected.value }
    setUpdatedProject(temp)
  }

  const handleChange = (input) => {
    setUpdatedProject({ ...updatedProject, [input.name]: input.value })
  }

  const saveUpdatedProject = () => {
    setLoadingSaveProject(true)
    console.log(updatedProject.revenue_items.length > 0)
    if (updatedProject.revenue_items.length > 0) {
      var tempObject = {
        name: updatedProject.name,
        description: updatedProject.description,
        status: updatedProject.status,
        revenue_items: updatedProject.revenue_items.map((item) => {
          return {
            id: item.id,
            product_id: item.product_id,
            sub_product_id: item.sub_product_id,
            product_variant_id: item.product_variant_id,
            price: item.price,
            quantity: item.quantity,
            discount_type: item.discount_type,
            discount_percentage: item.discount_percentage,
            discount_absolute: item.discount_absolute,
          }
        }),
        cogs_items: updatedProject.cogs_items.map((item) => {
          // console.log(item.revenue_items_id)
          return {
            revenue_items_id: item.project_revenue_item_id,
            cogs_id: item.cogs_id,
            cost: item.cost,
            quantity: item.quantity,
            custom_name: '',
          }
        }),
      }
      // console.log(tempObject)
      ProjectAPI.updateFlagshipProject(id, tempObject)
        .then((res) => {
          if (res.success) {
            // console.log(res)
            navigate('/projects/flagship')
          }
        })
        .catch((err) => {
          if (err.response) {
            setErrorUpdateProject(err.response.data.message)
          } else {
            setErrorUpdateProject(err.message)
          }
        })
    } else {
      setErrorUpdateProject("Can't save flagship project with no revenue item.")
      setLoadingSaveProject(false)
    }
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>
          <div className="mb-12">
            <h1 className="font-semibold text-giant mb-4">Project Information</h1>
            <div className="border border-gray-border rounded-[5px] p-4">
              <div className="max-w-[500px]">
                <Input
                  label={'Project Name'}
                  onChange={(e) => handleChange(e.target)}
                  name="name"
                  className={'mb-4'}
                  placeholder="Project Name"
                  value={updatedProject.name}
                />
                <Textarea
                  label={'Description'}
                  onChange={(e) => handleChange(e.target)}
                  name="description"
                  className={'mb-4'}
                  rows={5}
                  value={updatedProject.description}
                />

                <p className="mb-1 font-medium">Status</p>
                <DropdownInput
                  data={[
                    { id: 'active', text: 'Active', value: 'active' },
                    { id: 'inactive', text: 'Inactive', value: 'inactive' },
                  ]}
                  attributeShown="text"
                  defaultValue={updatedProject.status}
                  handleSelectedOption={(selected) => {
                    handleStatusChange(selected)
                  }}
                  placeholder={'Choose status'}
                />
              </div>
            </div>
          </div>
          <RevenueItemsTable
            project={updatedProject}
            setProject={setUpdatedProject}
            isFlagshipPages={isAdmin}
            projectTypeTemp={'custom'}
          />
          <COGSItemsTable
            project={updatedProject}
            setProject={setUpdatedProject}
            isEditable={isAdmin}
            projectTypeTemp={'custom'}
          />
          <div className="mb-12">
            <h1 className="text-giant mb-4 font-semibold">Margin</h1>
            <div className="border border-gray-border rounded-[5px] p-4">
              {/* <div className="w-[360px] ">
                <div className="px-2.5 py-4 flex gap-8 border-b">
                  <p className="flex-1 font-semibold">Total Revenue</p>
                  <p className="flex-1 font-semibold text-right">Rp{formatThousands(updatedProject.total_revenue)}</p>
                </div>
                <div className="px-2.5 py-4 flex gap-8 border-b">
                  <p className="flex-1 font-semibold">Total COGS</p>
                  <p className="flex-1 font-semibold text-right">Rp{formatThousands(updatedProject.total_cogs)}</p>
                </div>
                <div className="px-2.5 py-4 flex gap-8 rounded-[5px] bg-main-500 text-white">
                  <p className="flex-1 font-semibold">Margin</p>
                  <p className="flex-1 font-semibold text-right">{countMargin()}</p>
                </div>
              </div> */}
              <Table
                tableType="inline"
                noIndex={true}
                cols={marginCols}
                data={[
                  {
                    id: 0,
                    row: 'Revenue',
                    totalAmount: updatedProject.total_revenue,
                    percentage:
                      updatedProject.total_revenue !== 0
                        ? 1 - updatedProject.total_cogs / updatedProject.total_revenue
                        : 0,
                  },
                  {
                    id: 1,
                    row: 'COGS',
                    totalAmount: updatedProject.total_cogs,
                    percentage:
                      updatedProject.total_revenue !== 0 ? updatedProject.total_cogs / updatedProject.total_revenue : 1,
                  },
                ]}
              />
            </div>
          </div>
          <div className="flex justify-end gap-4 mb-12">
            {errorUpdateProject && <Alert type={'error'} message={errorUpdateProject} />}
            <Button onClick={saveUpdatedProject} isLoading={isLoadingSaveProject}>
              Save Project
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
