import React, { useEffect, useState } from 'react'
import Button from './components/Button'
import { useNavigate, useParams } from 'react-router-dom'

export default function ErrorPage({ type }) {
  // const { code } = useParams()
  const [message, setMessage] = useState()
  const navigate = useNavigate()

  const messageText = () => {
    if (parseInt(type) === 400) {
      return 'The request cannot be fulfilled due to bad syntax'
    } else if (parseInt(type) === 401) {
      return 'The request was a legal request, but the server is refusing to respond to it. For use when authentication is possible but has failed or not yet been provided'
    } else if (parseInt(type) === 403) {
      return 'Unauthorized request!'
    } else if (parseInt(type) === 404) {
      return "Sorry we couldn't find this page. "
    } else if (parseInt(type) === 500) {
      return 'Server Error. Please try again later or contact you IT department.1'
    } else {
      return 'Something error. Please contact your IT department'
    }
  }

  return (
    <div className="h-[calc(100vh-56px)] w-full  flex items-center">
      <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
        <div className="max-w-md">
          <div className="text-5xl font-dark font-bold ">{type}</div>
          <p className="text-2xl md:text-3xl font-light leading-normal mb-4">{messageText(type)}</p>
          {/* <p className="mb-8">But dont worry, you can find plenty of other things on our homepage.</p> */}

          <Button href={'/'} className={'mr-2'}>
            back to homepage
          </Button>
          <Button
            onClick={() => {
              navigate(-1)
            }}
          >
            back to previous page
          </Button>
        </div>
        <div className="max-w-lg"></div>
      </div>
    </div>
  )
}
