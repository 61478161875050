import React from 'react'
import { Oval } from 'react-loader-spinner'

export default function LoadingSpinner(props) {
  if (props.button) {
    return (
      <div className="min-w-[15px] h-[15px] flex items-center justify-center w-full">
        <div>
          <Oval
            height={16}
            width={16}
            color="#034A73"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#e5ecf1"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </div>
      </div>
    )
  }
  return (
    <div className="min-w-[20px] h-[20px] flex items-center justify-center w-full">
      <div>
        <Oval
          height={22}
          width={2}
          color="#034A73"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#e5ecf1"
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
      </div>
    </div>
  )
}
