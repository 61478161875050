import { devAxios } from './axiosConfig'

const StatisticsAPI = {
  getSalesDealStatistics: async (
    client_id = undefined,
    sbu_id = undefined,
    value_range_max = undefined,
    value_range_min = undefined,
    flagship_project_id = undefined,
    date_start = undefined,
    date_end = undefined,
    industry = undefined,
    topic = undefined,
    account_id = undefined
  ) => {
    var query = {
      client_id: client_id,
      sbu_id: sbu_id,
      value_range_max: value_range_max,
      value_range_min: value_range_min,
      flagship_project_id: flagship_project_id,
      date_start: date_start,
      date_end: date_end,
      industry: industry,
      topic: topic,
      account_id: account_id,
    }
    var res = await devAxios.get('statistics/sales-deal', { params: query })
    return res.data
  },

  getMonthlyPerformance: async (sbu_id = undefined, sm_id = undefined, ae_id = undefined, year = undefined) => {
    var res = await devAxios.get('statistics/revenue/monthly-performance', {
      params: {
        sbu_id: sbu_id || undefined,
        sm_id: sm_id || undefined,
        ae_id: ae_id || undefined,
        year: year || undefined,
      },
    })
    return res.data
  },

  getYTDPerformance: async (sbu_id = undefined, sm_id = undefined, ae_id = undefined, year = undefined) => {
    var res = await devAxios.get('statistics/revenue/ytd-performance', {
      params: {
        sbu_id: sbu_id || undefined,
        sm_id: sm_id || undefined,
        ae_id: ae_id || undefined,
        year: year || undefined,
      },
    })
    return res.data
  },

  getSalesDealPerformace: async (sbu_id = undefined, sm_id = undefined, ae_id = undefined, year = undefined) => {
    var res = await devAxios.get('statistics/revenue/sales-deal-performance', {
      params: {
        sbu_id: sbu_id || undefined,
        sm_id: sm_id || undefined,
        ae_id: ae_id || undefined,
        year: year || undefined,
      },
    })
    return res.data
  },

  getProductSold: async (sbu_id = undefined, sm_id = undefined, ae_id = undefined, year = undefined) => {
    var res = await devAxios.get('statistics/product/product-sold', {
      params: {
        sbu_id: sbu_id || undefined,
        sm_id: sm_id || undefined,
        ae_id: ae_id || undefined,
        year: year || undefined,
      },
    })
    return res.data
  },

  getCOGSPerformance: async (sbu_id = undefined, sm_id = undefined, ae_id = undefined, year = undefined) => {
    var res = await devAxios.get('statistics/product/cogs-performance', {
      params: {
        sbu_id: sbu_id || undefined,
        sm_id: sm_id || undefined,
        ae_id: ae_id || undefined,
        year: year || undefined,
      },
    })
    return res.data
  },

  getSalesARDelay: async (sbu_id = undefined, sm_id = undefined, ae_id = undefined, year = undefined) => {
    var res = await devAxios.get('statistics/sales/ar-delay', {
      params: {
        sbu_id: sbu_id || undefined,
        sm_id: sm_id || undefined,
        ae_id: ae_id || undefined,
        year: year || undefined,
      },
    })
    return res.data
  },
  getAERanking: async (sbu_id = undefined, sm_id = undefined, ae_id = undefined, year = undefined) => {
    var res = await devAxios.get('statistics/sales/ae-ranking', {
      params: {
        sbu_id: sbu_id || undefined,
        sm_id: sm_id || undefined,
        ae_id: ae_id || undefined,
        year: year || undefined,
      },
    })
    return res.data
  },

  getClientMothlyPerformance: async (sbu_id = undefined, sm_id = undefined, ae_id = undefined, year = undefined) => {
    var res = await devAxios.get('statistics/client/monthly-performance', {
      params: {
        sbu_id: sbu_id || undefined,
        sm_id: sm_id || undefined,
        ae_id: ae_id || undefined,
        year: year || undefined,
      },
    })
    return res.data
  },

  getClientYTDPerformance: async (sbu_id = undefined, sm_id = undefined, ae_id = undefined, year = undefined) => {
    var res = await devAxios.get('statistics/client/ytd-performance', {
      params: {
        sbu_id: sbu_id || undefined,
        sm_id: sm_id || undefined,
        ae_id: ae_id || undefined,
        year: year || undefined,
      },
    })
    return res.data
  },
}

export default StatisticsAPI
