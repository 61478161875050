import { devAxios } from './axiosConfig'

const ProjectAPI = {
  getAllProjects: async (
    sbu_id = undefined,
    sm_id = undefined,
    ae_id = undefined,
    status = undefined,
    client_id = undefined,
    page = 1,
    name = undefined
  ) => {
    var temp = {}
    if (sbu_id) temp.sbu_id = sbu_id
    if (sm_id) temp.sm_id = sm_id
    if (ae_id) temp.ae_id = ae_id
    if (status) temp.status = status
    if (client_id) temp.client_id = client_id
    if (page) temp.page = page
    if (name) temp.name = name
    var res = await devAxios.get('projects', { params: { ...temp } })
    return res.data
  },
  getProjectSimple: async (client_id = undefined) => {
    var res = await devAxios.get('projects/simple', {
      params: {
        client_id: client_id,
      },
    })
    return res.data
  },

  getAllFlagshipProject: async (status = undefined) => {
    var res = await devAxios.get('flagship-projects', { params: { status: status } })
    return res.data
  },

  getProjectById: async (id) => {
    var res = await devAxios(`projects/${id}`)
    return res.data
  },

  getListFlagshipProjects: async (id) => {
    var res = await devAxios.get('/flagship-projects')
    return res.data
  },

  getFlagshipProjectById: async (id) => {
    var res = await devAxios.get(`flagship-projects/${id}`)
    return res.data
  },

  createNewFlagshipProject: async (data) => {
    var res = await devAxios.post('flagship-projects', data)
    return res.data
  },

  updateFlagshipProject: async (id, data) => {
    var res = await devAxios.put(`flagship-projects/${id}`, data)
    return res.data
  },

  updateProject: async (id, data) => {
    var res = await devAxios.put(`projects/${id}`, data)
    return res.data
  },

  saveReviewProjectById: async (id, data) => {
    var res = await devAxios.post(`projects/review/${id}`, data)
    return res.data
  },

  deleteFlagshipProject: async (id) => {
    var res = await devAxios.delete(`flagship-projects/${id}`)
    return res.data
  },
}

export default ProjectAPI
