import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import { useNavigate, useParams } from 'react-router-dom'
import SBUAPI from '../../apis/SBUAPI'
import toast from 'react-hot-toast'
import Loading from '../../components/Loading'
import ErrorPage from '../../ErrorPage'
import Input from '../../components/Input'
import DragAndDropFile from '../../components/DragAndDropFile'
import Button from '../../components/Button'
import Alert from '../../components/Alert'

const nameShorten = (name) => {
  var temp = name.split(' ')
  var short = ''
  temp.forEach((element) => {
    short += element[0]
  })
  return short
}

export default function EditOtherSBU() {
  const [sbu, setSbu] = useState({})
  const { id } = useParams()
  const [isLoading, setLoading] = useState(true)
  const [newLogo, setNewLogo] = useState()
  const [isLoadingSave, setLoadingSave] = useState(false)
  const [errorSave, setErrorSave] = useState()
  const [errorFields, setErrorFields] = useState({
    sbu_name: false,
    cogs_guideline: false,
  })
  const navigate = useNavigate()

  useEffect(() => {
    SBUAPI.getSingleSBU(id)
      .then((res) => {
        setSbu({
          ...res.data[0],
          sbu_image: res.data[0]?.sbu_image
            ? res.data[0].sbu_image
            : `https://ui-avatars.com/api/?name=${nameShorten(res.data[0].sbu_name)}&background=888888&color=fff`,
        })
        setLoading(false)
      })
      .catch((err) => {
        if (err.response) toast.error(err.response.data.message)
        else toast.error(err.statusText)
        setLoading(false)
      })
  }, [id])

  function handleFileUpload(input) {
    const objectUrl = URL.createObjectURL(input)
    setNewLogo({ file: input, blob: objectUrl })
    setSbu({
      ...sbu,
      sbu_image: objectUrl,
    })
  }

  const handleSave = () => {
    console.log(sbu)
    setLoadingSave(true)
    setErrorSave()
    setErrorFields({
      sbu_name: false,
      cogs_guideline: false,
    })
    var requiredKey = ['sbu_name']
    var allowSave = true

    requiredKey.forEach((key) => {
      if (!sbu[key]) {
        allowSave = false
        setErrorFields((errorFields) => ({ ...errorFields, [key]: true }))
      }
    })

    if (!allowSave) {
      setErrorSave('Please complete all required input')
      setLoadingSave(false)
      return
    }

    if (allowSave) {
      SBUAPI.updateSBU(id, sbu.sbu_name, sbu.cogs_guideline, sbu.sbu_image, newLogo.blob)
        .then((res) => {
          navigate('/data/other-data/sbu')
        })
        .catch((err) => {
          if (err.response) setErrorSave(err.response.data.message)
          else setErrorSave(err.response)
          setLoadingSave(false)
        })
    }
  }

  return (
    <div className="py-4">
      <BreadCrumbs className={'mb-4'}>
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item href={'/data/other-data'}>Other Data</BreadCrumbs.Item>
        <BreadCrumbs.Item href={'/data/other-data/sbu'}>SBU</BreadCrumbs.Item>
        <BreadCrumbs.Item current>Edit SBU</BreadCrumbs.Item>
      </BreadCrumbs>
      {isLoading ? (
        <Loading />
      ) : sbu.sbu_id ? (
        <>
          <h1 className="text-giant mb-4 font-bold">Edit SBU</h1>
          <div className="border-2 rounded-[5px] p-4 mb-4">
            <div className="max-w-[400px] grid gap-4">
              <Input
                label={'SBU Name'}
                required
                value={sbu?.sbu_name}
                onChange={(e) => setSbu({ ...sbu, sbu_name: e.target.value })}
                error={errorFields.sbu_name}
              />
              <Input
                label={'COGS Guideline'}
                required
                value={`${(sbu?.cogs_guideline * 100).toFixed(0)}`}
                type={'number'}
                onChange={(e) =>
                  setSbu({ ...sbu, cogs_guideline: e.target.value ? (e.target.value / 100).toFixed(3) : 0 })
                }
                error={errorFields.cogs_guideline}
              />
              <DragAndDropFile
                className="h-[100px] mb-4"
                label="Logo"
                onChange={(input) => {
                  handleFileUpload(input)
                }}
              />
              {sbu.sbu_image ? (
                <div
                  className="w-24 h-24 border border-gray-border 
                                  flex items-center justify-center
                                  p-2 rounded-lg"
                >
                  <img src={sbu.sbu_image} className="w-full" />
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex gap-2.5 items-center">
            <Button onClick={handleSave} isLoading={isLoadingSave}>
              Save
            </Button>
            {errorSave && <Alert type={'error'} message={errorSave} />}
          </div>
        </>
      ) : (
        <ErrorPage type={404} />
      )}
    </div>
  )
}
