import { devAxios } from './axiosConfig'
export const COGSitemAPI = {
  getCOGSitems: async (search = '', page = 1) => {
    var query = ''
    if (search != '') {
      query = `?search=${search}&page=${page}`
    } else {
      query = `?page=${page}`
    }

    var res = await devAxios.get(`cogs-items${query}`)
    return res.data
  },

  getCOGSItemsSimple: async () => {
    var res = await devAxios.get('cogs-items/simple')
    return res.data
  },

  getSingleCOGSitem: async (id) => {
    var res = await devAxios.get(`cogs-items/${id}`)
    return res.data
  },

  update: async (cogs) => {
    var res = await devAxios.put(`cogs-items`, cogs)
    return res.data
  },

  delete: async (id) => {
    var res = await devAxios.delete(`cogs-items`, { data: { id: id } })
    return res.data
  },

  create: async (cogs) => {
    var res = await devAxios.post(`cogs-items`, cogs)
    return res.data
  },
}
