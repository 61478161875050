import React, { useEffect, useState } from 'react'
import Basic from '../../components/table/cell/Basic'
import Button from '../../components/Button'
import Table from '../../components/table/Table'
import { Dataset } from '@mui/icons-material'
import StatisticsAPI from '../../apis/Statistics'
import Loading from '../../components/Loading'
import { formatThousands } from '../../helper'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import exportFromJSON from 'export-from-json'
import moment from 'moment'

export default function Products({}) {
  const [isLoading, setLoading] = useState(true)
  const [productsStats, setProductsStats] = useState([])
  const [cogsStats, setCogsStats] = useState([])
  const productsCols = [
    {
      id: 0,
      name: 'Products',
      classes: 'w-[170px]',
      selector: (row) => <Basic data={row.product_name} />,
    },
    {
      id: 1,
      name: 'Sub Product',

      classes: 'w-[220px]',
      selector: (row) => <Basic data={row.sub_product_name} />,
    },
    {
      id: 2,
      name: 'Variant',
      classes: 'w-[250px]',
      selector: (row) => <Basic data={row.variant_name} />,
    },
    {
      id: 3,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
      selector: (row) => <Basic data={row.quantity} alignment={'right'} />,
    },
    {
      id: 4,
      name: 'Total Revenue',
      classes: 'text-right w-[270px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.total_revenue)}`} alignment={'right'} />,
    },
  ]
  const cogsStatsCols = [
    {
      id: 0,
      name: 'SBU',
      classes: 'w-[240px]',
      selector: (row) => <Basic data={row.sbu_name} />,
    },
    {
      id: 1,
      name: 'Number Of Projects',
      classes: 'w-[160px]',
      selector: (row) => <Basic data={row.number_of_projects} />,
    },
    {
      id: 3,
      name: 'Average COGS',
      classes: 'w-[160px] text-right',
      selector: (row) => (
        <Basic
          data={`${parseFloat(row.average_cogs * 100).toFixed(2)}%`}
          alignment={'right'}
          state={row.average_cogs > row.cogs_guideline ? 'negative' : 'positive'}
        />
      ),
    },
    {
      id: 4,
      name: 'COGS Guideline',
      classes: 'w-[160px] text-right',
      selector: (row) => <Basic data={`${row.cogs_guideline * 100}%`} alignment={'right'} />,
    },
  ]
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [productSoldFormated, setProductSoldFormated] = useState()
  const [cogsPerformanceFormated, setCogsPerformanceFormated] = useState()

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        StatisticsAPI.getProductSold(searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.year).then(
          (res) => {
            setProductsStats(res.data)
            var temp = res.data.map((row) => ({
              Products: row.product_name,
              'Sub Product': row.sub_product_name,
              Variant: row.variant_name,
              Quantity: row.quantity,
              'Total Revenue': row.total_revenue,
            }))
            setProductSoldFormated(temp)
            resolve()
          }
        )
      }),
      new Promise((resolve, reject) => {
        StatisticsAPI.getCOGSPerformance(searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.year).then(
          (res) => {
            setCogsStats(res.data)
            var temp = res.data.map((row) => ({
              SBU: row.sbu_name,
              'Number Of Projects': row.number_of_projects,
              'Average COGS': row.average_cogs,
              'COGS Guideline': row.cogs_guideline,
            }))
            setCogsPerformanceFormated(temp)
            resolve()
          }
        )
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.year])

  // useEffect(() => {
  //   console.log(productsStats)
  //   console.log(cogsStats)
  // }, [productsStats, cogsStats])
  const exportXls = (data, fileName) => {
    exportFromJSON({ data, fileName, exportType: 'xls' })
  }

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="product-sold mb-8">
            <h1 className="font-semibold text-giant mb-4">Product Sold</h1>
            <div className="mb-4 w-fit max-w-full">
              <Table data={productsStats ? productsStats : []} cols={productsCols} tableType="inline" withPaging />
              {/* <Table data={[]} cols={productsCols} tableType="inline" /> */}
            </div>
            <div>
              <Button
                variant={'outlined'}
                onClick={() => exportXls(productSoldFormated, `product_sold_${moment().format('DDMMMYYYY')}`)}
                icon={<Dataset sx={{ fontSize: 18 }} />}
              >
                .xls
              </Button>
            </div>
          </div>
          <div className="cogs-performance mb-8">
            <h1 className="font-semibold text-giant mb-4">COGS Performance</h1>
            <div className="mb-4">
              <Table data={cogsStats} cols={cogsStatsCols} tableType="inline" />
              {/* <Table data={[]} cols={cogsStatsCols} tableType="inline" /> */}
            </div>
            <div>
              <Button
                variant={'outlined'}
                icon={<Dataset sx={{ fontSize: 18 }} />}
                onClick={() => exportXls(cogsPerformanceFormated, `cogs_performance_${moment().format('DDMMMYYYY')}`)}
              >
                .xls
              </Button>
            </div>
          </div>
        </>
      )}{' '}
    </div>
  )
}
