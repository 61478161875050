import React from 'react'

export default function Status({ type, size }) {
  if (type === 'visit') {
    if (size === 'small') {
      return (
        <span className="flex gap-2 items-center">
          <span className="w-[20px] h-[20px] rounded-full border-[3px] border-secondary-blue bg-primary-blue inline-block"></span>
          <p className="font-semibold text-gray-text text-normal">Visit</p>
        </span>
      )
    } else {
      return (
        <span className="flex gap-2 ">
          <span className="w-[28px] h-[28px] rounded-full border-[3px] border-secondary-blue bg-primary-blue inline-block"></span>
          <p className="font-semibold text-giant">Visit</p>
        </span>
      )
    }
  } else if (type === 'leads') {
    if (size === 'small') {
      return (
        <span className="flex gap-2 items-center">
          <span className="w-[20px] h-[20px] rounded-full border-[3px] border-secondary-yellow bg-primary-yellow inline-block"></span>
          <p className="font-semibold text-gray-text text-normal">Leads</p>
        </span>
      )
    } else {
      return (
        <span className="flex gap-2 ">
          <span className="w-[28px] h-[28px] rounded-full border-[3px] border-secondary-yellow bg-primary-yellow inline-block"></span>
          <p className="font-semibold text-giant">Leads</p>
        </span>
      )
    }
  } else if (type === 'hot_prospect') {
    if (size === 'small') {
      return (
        <span className="flex gap-2 items-center">
          <span className="w-[20px] h-[20px] rounded-full border-[3px] border-secondary-orange bg-primary-orange inline-block"></span>
          <p className="font-semibold text-gray-text text-normal">Hot Prospect</p>
        </span>
      )
    } else {
      return (
        <span className="flex gap-2 ">
          <span className="w-[28px] h-[28px] rounded-full border-[3px] border-secondary-orange bg-primary-orange inline-block"></span>
          <p className="font-semibold text-giant">Hot Prospect</p>
        </span>
      )
    }
  } else if (type === 'on_hands') {
    if (size === 'small') {
      return (
        <span className="flex gap-2 items-center">
          <span className="w-[20px] h-[20px] rounded-full border-[3px] border-secondary-green bg-primary-green inline-block"></span>
          <p className="font-semibold text-gray-text text-normal">On Hands</p>
        </span>
      )
    } else {
      return (
        <span className="flex gap-2 ">
          <span className="w-[28px] h-[28px] rounded-full border-[3px] border-secondary-green bg-primary-green inline-block"></span>
          <p className="font-semibold text-giant">On Hands</p>
        </span>
      )
    }
  } else if (type === 'revenue') {
    if (size === 'small') {
      return (
        <span className="flex gap-2 items-center">
          <span className="w-[20px] h-[20px] rounded-full border-[3px] border-secondary-pink bg-primary-pink inline-block"></span>
          <p className="font-semibold text-gray-text text-normal">Revenue</p>
        </span>
      )
    } else {
      return (
        <span className="flex gap-2 ">
          <span className="w-[28px] h-[28px] rounded-full border-[3px] border-secondary-pink bg-primary-pink inline-block"></span>
          <p className="font-semibold text-giant">Revenue</p>
        </span>
      )
    }
  } else {
    if (size === 'small') {
      return (
        <span className="flex gap-2 items-center">
          <span className="w-[20px] h-[20px] rounded-full border-[3px] border-secondary-gray bg-primary-gray inline-block"></span>
          <p className="font-semibold text-gray-text text-normal">Loss</p>
        </span>
      )
    } else {
      return (
        <span className="flex gap-2 ">
          <span className="w-[28px] h-[28px] rounded-full border-[3px] border-secondary-gray bg-primary-gray inline-block grow-0 shrink-0"></span>
          <p className="font-semibold text-giant">Loss</p>
        </span>
      )
    }
  }
}
