import { KeyboardArrowRight } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

const BreadCrumbs = ({ children, className }) => {
  return (
    <div className={`py-1.5 text-gray-text flex gap-2 items-center ${className && className}`}>
      {children.map((item, index) => {
        if (index === children.length - 1) return <span key={index}>{item}</span>
        else
          return (
            <React.Fragment key={index}>
              {item}
              <KeyboardArrowRight sx={{ fontSize: 16 }} className="top-[1px] relative" />
            </React.Fragment>
          )
      })}
    </div>
  )
}
const Item = ({ children, current, href }) => {
  if (current) {
    return (
      <span className={`${current ? 'text-main-500 font-bold' : 'hover:underline'} text-small transition-all`}>
        {children}
      </span>
    )
  }
  return (
    <Link to={href}>
      <span className={`${current ? 'text-main-500 font-bold' : 'hover:underline'} text-small transition-all`}>
        {children}
      </span>
    </Link>
  )
}

BreadCrumbs.Item = Item

export default BreadCrumbs
