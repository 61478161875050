import React, { useEffect, useState } from 'react'
import Item from './Item'
import { Droppable } from 'react-beautiful-dnd'
import { AddCircle, CalendarMonthRounded, Close } from '@mui/icons-material'
import { ExitToApp } from '@mui/icons-material'
import GroupButtons from '../GroupButtons'
import DropdownInput from '../DropdownInput'
import Loading from '../Loading'
import Input from '../Input'
import Button from '../Button'
import ClientAPI from '../../apis/ClientAPI'
import ProjectAPI from '../../apis/ProjectAPI'
import moment from 'moment'
import SalesPlanAPI from '../../apis/SalesPlanAPI'
import { useNavigate } from 'react-router-dom'
import usePermission from '../../hooks/usePermission'
import Alert from '../Alert'
import TagsDropdown from '../TagsDropdown'

const CreatePlanModal = ({ setIsOpen, meeting_date, setRefreshDOM }) => {
  const [clients, setClients] = useState([])
  const [flagshipProjects, setFlagshipProject] = useState([])
  const [listAgency, setListAgency] = useState([])
  const [dealType, setDealType] = useState('new')
  const [selectedClient, setSelectedClient] = useState()
  const [projectType, setProjectType] = useState('standard')
  const [projectId, setProjectId] = useState()
  const [selectedFlagship, setSelectedFlagship] = useState()
  const [projectName, setProjectName] = useState('')
  const [projectByClient, setProjectByClient] = useState([])
  const [fieldsError, setFieldsError] = useState({
    projectName: false,
    selectedClient: false,
  })
  const [isLoading, setLoading] = useState(true)
  const [isLoadingSavePlan, setLoadingSavePlan] = useState(false)
  const [errorSave, setErrorSave] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedAgency, setSelectedAgency] = useState()
  const [existingProjectAgency, setExistingProjectAgency] = useState('')
  const { isAe, isAdmin } = usePermission()
  const navigate = useNavigate()

  useEffect(() => {
    if (dealType === 'existing') {
      if (selectedClient) {
        ProjectAPI.getProjectSimple(selectedClient.id).then((res) => {
          setProjectByClient(res.data)
        })
      }
    }
  }, [selectedClient, dealType])

  useEffect(() => {
    const promises = [
      new Promise((resolve, reject) => {
        ClientAPI.getClientSimple()
          .then((res) => {
            setClients(res.data)
            resolve()
          })
          .catch((err) => console.log(err))
      }),
      new Promise((resolve, reject) => {
        ProjectAPI.getAllFlagshipProject('active')
          .then((res) => {
            setFlagshipProject(res.data)
            resolve()
          })
          .catch((err) => console.log(err))
      }),
      new Promise((resolve, reject) => {
        ClientAPI.getListAgency().then((res) => {
          console.log(res.data)
          setListAgency(res.data)
          resolve()
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  const handleSavePlan = () => {
    setLoadingSavePlan(true)
    setErrorSave(false)

    var allowSave = true
    if (dealType === 'new' && !projectName) {
      allowSave = false
      setFieldsError((fieldsError) => ({ ...fieldsError, projectName: true }))
    }

    if (!selectedClient) {
      allowSave = false
      setFieldsError((fieldsError) => ({ ...fieldsError, selectedClient: true }))
    }

    if (allowSave) {
      var temp = {
        type: dealType,
        client_id: selectedClient.id,
        agency_id: selectedAgency?.id,
        project_type: projectType,
        project_name: projectName,
        project_id: projectId ? projectId : null,
        flagship_project_id: selectedFlagship ? selectedFlagship : null,
        meeting_date: moment(meeting_date).format('YYYY-MM-DD'),
        tags: selectedTags,
      }
      // console.log(temp)
      SalesPlanAPI.createNewSalesPlan(temp)
        .then((res) => {
          // console.log(res.message)
          if (res.success) {
            setIsOpen(false)
            setLoadingSavePlan(false)
            setRefreshDOM(Math.random())
          }
        })
        .catch((err) => {
          // console.log(err)
          if (err.response) setErrorSave(err.response.data.message)
          else setErrorSave(err.statusText)
          setLoadingSavePlan(false)
        })
    } else {
      setLoadingSavePlan(false)
      setErrorSave('Please fill the required fields')
    }
  }

  return (
    <>
      <div
        className="bg-black bg-opacity-20 z-[100] w-screen h-screen top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute"
        onClick={() => setIsOpen(false)}
      />
      <div className="fixed z-[110] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-screen flex items-center">
        <div className="w-full max-w-[600px] max-h-[80%] rounded bg-white shadow-lg z-[110] md:w-[600px]">
          {isLoading ? (
            <div className="h-[300px] p-8 text-center">
              <Loading />
            </div>
          ) : (
            <>
              <div className="p-4 overflow-hidden rounded-t border-b flex justify-between items-center mb-2.5">
                <h5 className="text-big">Add New Sales Plan</h5>
                <button
                  className="w-6 h-6 flex items-center justify-center rounded text-gray-400 hover:bg-red-200 hover:text-red-500"
                  onClick={() => setIsOpen(false)}
                >
                  <Close sx={{ fontSize: 16 }} />
                </button>
              </div>
              <div className="mr-1.5 ">
                <div className="h-fit">
                  <div className="p-4 flex items-center gap-2 mb-2.5">
                    <span>
                      <CalendarMonthRounded sx={{ fontSize: 18 }} />
                    </span>
                    <p className="text-big">{moment(meeting_date).format('dddd, D MMMM YYYY')}</p>
                  </div>
                  <div className="px-4">
                    <div className="mb-4">
                      <p className="mb-1">Deal Type</p>
                      <GroupButtons
                        className="mr-4"
                        data={[
                          { id: 0, text: 'New', onClick: () => setDealType('new') },
                          { id: 1, text: 'Existing', onClick: () => setDealType('existing') },
                        ]}
                      />
                    </div>
                    {dealType === 'new' ? (
                      <>
                        <div className="mb-4 w-[300px]">
                          <p className="mb-1">Client</p>
                          <DropdownInput
                            className="mr-4"
                            data={clients}
                            error={fieldsError.selectedClient}
                            handleSelectedOption={(selected) => {
                              setSelectedClient(selected)
                            }}
                            placeholder="Search Client"
                            attributeShown="company_name"
                            withSearch={true}
                          />
                        </div>
                        <div className="mb-4 w-[300px]">
                          <p className="mb-1">Agency</p>
                          <DropdownInput
                            data={listAgency}
                            position="top"
                            value={listAgency.find((agency) => agency.id === selectedAgency?.id)}
                            handleSelectedOption={(selected) => {
                              setSelectedAgency(selected)
                            }}
                            placeholder="Select Agency(optional)"
                            attributeShown="name"
                          />
                        </div>
                        <div className="mb-4">
                          <p className="mb-1">Project Type</p>
                          <GroupButtons
                            className="mr-4"
                            data={[
                              { id: 0, text: 'Standard', onClick: () => setProjectType('standard') },
                              { id: 1, text: 'Custom', onClick: () => setProjectType('custom') },
                              { id: 2, text: 'Flagship', onClick: () => setProjectType('flagship') },
                            ]}
                          />
                        </div>
                        {projectType === 'flagship' ? (
                          <>
                            <div className="mb-4 w-[300px]">
                              <p className="mb-1">Project Flagship</p>
                              <DropdownInput
                                className="mr-4"
                                data={flagshipProjects}
                                position="top"
                                handleSelectedOption={(selected) => setSelectedFlagship(selected.id)}
                                placeholder="Search Project Flagship"
                                attributeShown="name"
                              />
                            </div>
                            <div className="w-[300px]">
                              <Input
                                label="Project Name"
                                onChange={(e) => setProjectName(e.target.value)}
                                placeholder="Project Name"
                                error={fieldsError.projectName}
                                value={projectName}
                              />
                            </div>
                            {/* <div className="mt-4 w-[300px]">
                              <TagsDropdown setSelectedTags={setSelectedTags} selectedTags={selectedTags} />
                            </div> */}
                          </>
                        ) : (
                          <>
                            <div className="w-[300px]">
                              <Input
                                label="Project Name"
                                onChange={(e) => setProjectName(e.target.value)}
                                placeholder="Project Name"
                                error={fieldsError.projectName}
                                value={projectName}
                              />
                              {/* <div className="mt-4 w-[300px]">
                                <TagsDropdown setSelectedTags={setSelectedTags} selectedTags={selectedTags} />
                              </div> */}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="mb-4 w-[300px]">
                          <p className="mb-1">Client</p>
                          <DropdownInput
                            className="mr-4"
                            data={clients}
                            handleSelectedOption={(selected) => {
                              setSelectedClient(selected)
                            }}
                            placeholder="Search Client"
                            attributeShown="company_name"
                            withSearch={true}
                          />
                        </div>
                        {existingProjectAgency && (
                          <div className="mb-4 w-[300px]">
                            <p className="mb-1">Agency</p>
                            <Input value={existingProjectAgency} disabled />
                          </div>
                        )}
                        <div className="mb-4 w-[300px]">
                          <p className="mb-1">Project</p>
                          <DropdownInput
                            className="mr-4"
                            data={projectByClient}
                            attributeShown="project_name"
                            placeholder="Search Project"
                            handleSelectedOption={(selected) => {
                              setProjectId(selected.id)
                              setSelectedTags(selected.tags)
                              console.log(selected.agency_name)
                              if (selected.agency_name) {
                                setExistingProjectAgency(selected.agency_name)
                              } else {
                                setExistingProjectAgency('')
                              }
                            }}
                            withSearch={true}
                          />
                        </div>
                        <div className="mt-4 w-[300px]">
                          {/* <TagsDropdown setSelectedTags={setSelectedTags} selectedTags={selectedTags} /> */}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex justify-end gap-2.5 p-4 pt-0 mt-4">
                    {errorSave && <Alert message={errorSave} type={'error'} />}
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setIsOpen(false)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button onClick={() => handleSavePlan()} isLoading={isLoadingSavePlan}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
              {/* <div className="absolute bottom-1">
            <div className="flex justify-around items-center">
              <button className="px-4 py-2 bg-red-200 text-white" onClick={() => setIsOpen(false)}>
                Delete
              </button>
              <button className="px-4 py-2 bg-red-200 text-white" onClick={() => setIsOpen(false)}>
                Cancel
              </button>
            </div>
          </div> */}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default function Column({ col, setRefreshDOM }) {
  const [isOpen, setIsOpen] = useState(false)
  const { isAE, isAdmin } = usePermission()

  return (
    <div className="h-full flex-1 flex flex-col min-w-[200px]">
      <div className="mb-4">
        <div className="py-2 px-4 mb-1 bg-sky-50 rounded border-b-[5px] border-main-500">
          <div className="flex gap-2 items-center">
            <h2 className="text-big capitalize">{moment(col.id).format('dddd')}</h2>
            <span className="p-1 px-2.5 rounded-xl text-center bg-main-500 text-white font-semibold">
              {col.list.length}
            </span>
          </div>
          <p className="text-small">{col.id}</p>
        </div>
        {(isAE || isAdmin) && (
          <button
            onClick={() => {
              setIsOpen(true)
            }}
            className="bg-sky-50 rounded text-gray-400 w-full py-2 relative hover:bg-sky-100 transition-all"
          >
            <div className="flex items-center justify-center gap-2 relative -left-1">
              <span className="flex items-center justify-center w-4 h-4">
                <AddCircle sx={{ fontSize: 14 }} />
              </span>
              <p>Add New Plan</p>
            </div>
          </button>
        )}
        {isOpen && <CreatePlanModal setIsOpen={setIsOpen} meeting_date={col.id} setRefreshDOM={setRefreshDOM} />}
      </div>
      <div className="flex-1 flex flex-col gap-4 overflow-y-auto pr-1 scrollbar-thin scrollbar-track-sky-50 scrollbar-thumb-main-500 scrollbar-thumb-rounded scrollbar-track-rounded">
        <Droppable droppableId={col.id}>
          {(provided) => (
            <div className="flex flex-col gap-4" {...provided.droppableProps} ref={provided.innerRef}>
              {col.list.map((plan, index) => (
                <Item key={plan.id} plan={plan} index={index} setRefreshDOM={setRefreshDOM} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}
