import React, { useEffect, useState } from 'react'
import Basic from '../../components/table/cell/Basic'
import Table from '../../components/table/Table'
import Button from '../../components/Button'
import { Dataset } from '@mui/icons-material'
import { formatThousands } from '../../helper'
import StatisticsAPI from '../../apis/Statistics'
import Loading from '../../components/Loading'
import moment from 'moment/moment'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import exportFromJSON from 'export-from-json'

export default function Sales({}) {
  const [isLoading, setLoading] = useState(false)
  const [accountReceivableDelay, setAccountReceivableDelay] = useState([])
  const [aeRanking, setAeRanking] = useState([])
  const accountReceivableDelayCols = [
    {
      id: 0,
      name: 'Client',
      classes: 'w-[175px]',
      selector: (row) => <Basic data={row.client} />,
    },
    {
      id: 1,
      name: 'Sales Deal',
      classes: 'w-[280px]',
      selector: (row) => <Basic data={row.project_name} />,
    },
    {
      id: 2,
      name: 'Value',
      classes: 'w-[175px] text-right',
      selector: (row) => <Basic data={`Rp${formatThousands(row.value)}`} alignment={'right'} />,
    },
    {
      id: 3,
      name: 'Due Date',
      classes: 'w-[200px]',
      selector: (row) => <Basic data={moment(row.due_date).format('DD MMMM YYYY')} />,
    },
    {
      id: 4,
      name: 'Overdue Days',
      classes: 'w-[150px]',
      selector: (row) => <Basic data={`${row.overdue_days}`} />,
    },
  ]
  const cogsStatsCols = [
    {
      id: 0,
      name: 'Name',
      classes: 'w-[210px]',
      selector: (row) => <Basic data={row.name} />,
    },
    {
      id: 1,
      name: 'Number Of Projects',
      classes: 'w-[160px]',
      selector: (row) => <Basic data={row.number_of_projects} />,
    },
    {
      id: 3,
      name: 'Number of Clients',
      classes: 'w-[160px]',
      selector: (row) => <Basic data={row.number_of_clients} />,
    },
    {
      id: 4,
      name: 'Total Revenue',
      classes: 'w-[180px] text-right',
      selector: (row) => <Basic data={`Rp${formatThousands(row.total_revenue)}`} alignment={'right'} />,
    },
  ]
  const [searchParams, setSearchParams] = useCustomSearchParams()

  const [accountReceivableDelayFormated, setAccountReceivableDelayFormated] = useState()
  const [aeRankingFormated, setAeRankingFormated] = useState()

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        StatisticsAPI.getSalesARDelay(searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.year).then(
          (res) => {
            setAccountReceivableDelay(res.data)
            var temp = res.data.map((row) => ({
              Client: row.client,
              'Sales Deal': row.project_name,
              Value: row.value,
              'Due Date': moment(row.due_date).format('DD MMMM YYYY'),
              'Overdue Days': row.overdue_days,
            }))
            setAccountReceivableDelayFormated(temp)
            resolve()
          }
        )
      }),
      new Promise((resolve, reject) => {
        StatisticsAPI.getAERanking(searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.year).then(
          (res) => {
            setAeRanking(res.data)
            var temp = res.data.map((row) => ({
              Name: row.name,
              'Number of projects': row.number_of_projects,
              'Number of clients': row.number_of_clients,
              'Total Revenue': row.total_revenue,
            }))
            setAeRankingFormated(temp)
            resolve()
          }
        )
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.year])

  const exportXls = (data, fileName) => {
    exportFromJSON({ data, fileName, exportType: 'xls' })
  }

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="product-sold mb-8">
            <h1 className="font-semibold text-giant mb-4">Account Receivable Delay</h1>
            <div className="mb-4">
              <Table data={accountReceivableDelay} cols={accountReceivableDelayCols} tableType="inline" />
              {/* <Table data={accountReceivableDelay} cols={accountReceivableDelayCols} tableType="inline" /> */}
            </div>
            <div>
              <Button
                variant={'outlined'}
                icon={<Dataset sx={{ fontSize: 18 }} />}
                onClick={() =>
                  exportXls(accountReceivableDelayFormated, `account_receivable_delay_${moment().format('DDMMMYYYY')}`)
                }
              >
                .xls
              </Button>
            </div>
          </div>
          <div className="cogs-performance mb-8">
            <h1 className="font-semibold text-giant mb-4">Account Executive Ranking</h1>
            <div className="mb-4">
              <Table data={aeRanking} cols={cogsStatsCols} tableType="inline" />
              {/* <Table data={[]} cols={cogsStatsCols} tableType="inline" /> */}
            </div>
            <div>
              <Button
                variant={'outlined'}
                icon={<Dataset sx={{ fontSize: 18 }} />}
                onClick={() => exportXls(aeRankingFormated, `ae_ranking_${moment().format('DDMMMYYYY')}`)}
              >
                .xls
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
