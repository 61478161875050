import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from './firebaseConfig'
import { UserAuth } from '../context/AuthContext'
import { devAxios } from './axiosConfig'
import axios from 'axios'
import { store } from '../redux/store'
import { setRefrehingToken } from '../redux/authSlice'

const AuthAPI = {
  login: async (email, password) => {
    const { login } = UserAuth()
    login(auth, email, password)
      .then((userCredential) => {
        return userCredential
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
      })
  },

  refreshToken: async () => {
    store.dispatch(setRefrehingToken())
    var res = await axios.post(
      'https://securetoken.googleapis.com/v1/token?key=AIzaSyDJ-ZMLad5-JtLIcVxFIeylRrQIBtVGLWc',
      { grant_type: 'refresh_token', refresh_token: store.getState().auth.firebaseUser.refreshToken }
    )
    return res.data
  },
}

export default AuthAPI
