import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Tabs({ data, size, className }) {
  return (
    <div
      className={`flex items-end ${className ? className : 'mb-4'} ${
        size === 'full' ? 'max-w-full overflow-x-auto scrollbar-thin scrollbar-thumb-main-500' : null
      }`}
    >
      {data.map((tab) => {
        return (
          <NavLink key={tab.id} className="shrink-0" to={tab.link} end>
            {({ isActive }) => (
              <div
                className={
                  isActive
                    ? 'rounded-t text-main-500 text-center font-semibold border-b-2 py-3 px-6 border-main-500 bg-[#E0F2FE] transition-all'
                    : 'rounded-t text-main-500 text-center font-semibold border-b-2 py-3 px-6 border-primary-gray transition-all'
                }
              >
                {tab.name}
              </div>
            )}
          </NavLink>
        )
      })}
      <div className="border-b-2 border-primary-gray flex-1"></div>
    </div>
  )
}
