import React, { forwardRef, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import withClickOutside from '../withClickOutside'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { DateCalendar } from '@mui/x-date-pickers'
import moment from 'moment'
import Button from './Button'

const DropdownDatePicker = forwardRef(function DropdownDatePicker({ open, setOpen, props }, ref) {
  const [selected, setSelected] = useState(moment(new Date()))
  const [isOpen, setIsOpen] = useState(false)
  const { children, handleDatePicked, defaultValue } = { ...props }

  useEffect(() => {
    // console.log(moment(defaultValue).format('dddd, DD MMMM YYYY'))
    // if (defaultValue) setSelected(moment(defaultValue).format('YYYY-MM-DD'))
  }, [defaultValue])
  // useEffect(() => {
  //   if (defaultValue) {
  //     var temp = data.filter((item) => item.id === defaultValue)
  //     setSelected(temp[0])
  //   }
  // }, [defaultValue])

  const handleButtonSave = () => {
    handleDatePicked(selected.format('YYYY-MM-DD'))
    setOpen(!open)
  }

  return (
    <div className="relative text-left" ref={ref}>
      <div className="">
        <div onClick={() => setOpen(!open)}>
          {children}
          {/* {selected ? <p>{selected[attributeShown]}</p> : <p className="text-gray-400">{placeholder}</p>}
          <span className="w-[12px] h-[12px] flex items-center justify-center ml-4">
            <KeyboardArrowDownIcon sx={{ fontSize: 16 }} className="" />
          </span> */}
        </div>
      </div>
      {open && (
        <div className="absolute left-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ">
          <div className="py-1" role="none">
            <DateCalendar
              // defaultValue={defaultValue}
              value={moment(defaultValue) ? moment(defaultValue) : selected}
              onChange={(newValue) => {
                setSelected(newValue)
              }}
              slotProps={{
                actionBar: {
                  actions: ['accept'],
                },
              }}
            />
            <div className="px-6 py-4 flex justify-end gap-2">
              <Button variant={'outlined'} onClick={() => setOpen(!open)}>
                Cancel
              </Button>
              <Button onClick={handleButtonSave}>Save</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})

export default withClickOutside(DropdownDatePicker)
