import { Check, Close } from '@mui/icons-material'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import withClickOutside from '../withClickOutside'
import SalesPlanAPI from '../apis/SalesPlanAPI'
import toast, { Toaster } from 'react-hot-toast'

const TagsDropdown = forwardRef(function TagsDropdown({ open, setOpen, props }, ref) {
  const { allowCreate, className, selectedTags, setSelectedTags } = { ...props }
  const [listTags, setListTags] = useState([])
  const [displayedData, setDisplayedData] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [keyChange, setKeyChange] = useState(1)
  const [isOpenList, setIsOpenList] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    SalesPlanAPI.getAllTags()
      .then((res) => {
        setListTags(res.data.map((item) => item.value))
      })
      .catch((err) => {
        if (err.response) toast.error(err.response.data.message)
        else toast.error(err.statusText)
      })
  }, [])

  useEffect(() => {
    setDisplayedData(listTags)
  }, [listTags])

  useEffect(() => {
    // console.log(inputValue)
    var temp = listTags.filter((tag) => tag.toLowerCase().includes(inputValue.toLocaleLowerCase()))
    setDisplayedData(temp)
  }, [inputValue])

  const handleSelect = (tag) => {
    setKeyChange((keyChange) => keyChange + 1)
    var index = selectedTags.findIndex((item) => item === tag)
    if (index === -1) {
      setSelectedTags((selectedTags) => [...selectedTags, tag])
    } else {
      var temp = selectedTags
      temp.splice(index, 1)
      console.log(temp)
      setSelectedTags((selectedTags) => temp)
    }
  }

  const handleRemoveTag = (tag) => {
    var temp = selectedTags.filter((existingTag) => existingTag !== tag)
    setSelectedTags(temp)
  }

  const handleEnterOnInput = (e) => {
    e.preventDefault()

    var index = selectedTags.findIndex((item) => item.toLowerCase() === inputValue.toLowerCase())
    if (index === -1) {
      setSelectedTags((selectedTags) => [...selectedTags, inputValue])
    } else {
      var temp = selectedTags
      temp.splice(index, 1)
      console.log(temp)
      setSelectedTags((selectedTags) => temp)
    }
    setInputValue('')
  }
  return (
    <>
      <Toaster position="top-right" />
      <p className="text-slate-800 font-semibold mb-1 ">Tags</p>
      <div className="relative" ref={ref}>
        <div
          key={keyChange}
          onClick={() => {
            inputRef.current.focus()
            setOpen(true)
            // setIsOpenList((isOpenList) => !isOpenList)
          }}
          className={`px-3 py-1.5 rounded-[5px] border border-primary-gray w-full transition-all focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none focus:border-main-500 flex gap-2 flex-wrap relative
               ${className}`}
        >
          {selectedTags.map((tag, index) => {
            return (
              <span
                key={index}
                className="flex items-center gap-1 p-1.5 rounded border-2 border-main-500 text-main-500"
              >
                <p className="text-small">{tag}</p>
                <i
                  className="w-4 h-4 flex items-center justify-center font-bold rounded cursor-pointer transition-all hover:bg-red-100 hover:text-red-500"
                  onClick={() => {
                    handleRemoveTag(tag)
                  }}
                >
                  <Close sx={{ fontSize: 10 }} />
                </i>
              </span>
            )
          })}
          <form className="w-fit" onSubmit={handleEnterOnInput}>
            <input
              className="py-2 min-w-0 outline-none"
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </form>
        </div>
        {open && displayedData.length > 0 && (
          <div className="absolute -top-4 -translate-y-[100%] z-[120] mt-2 w-full max-h-[300px] origin-bottom-right overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-main-500 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1" role="none">
              {displayedData.map((tag, index) => {
                return (
                  <div
                    key={index}
                    className="text-gray-700 w-full px-4 py-2 text-sm flex items-center gap-2 hover:bg-main-50"
                    onClick={() => handleSelect(tag)}
                  >
                    {tag}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </>
  )
})

export default withClickOutside(TagsDropdown)
