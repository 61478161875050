import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import GroupButtons from '../../components/GroupButtons'
import DropdownInput from '../../components/DropdownInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import RadioButton from '../../components/RadioButton'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import { ProductsAPI } from '../../apis/ProductsAPI'
import LogoCell from '../../components/table/cell/LogoCell'
import Basic from '../../components/table/cell/Basic'
import ActionCell from '../../components/table/cell/ActionCell'
import { formatThousands } from '../../helper'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import { ArrowForward, ArrowRight, ArrowRightAlt, Delete, KeyboardArrowRight } from '@mui/icons-material'
import CustomTextDropdownInput from '../../components/CustomTextDropdownInput'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import Status from '../../components/Status'
import moment from 'moment'
import StatusCell from '../../components/table/cell/StatusCell'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'

export default function ViewProject() {
  const { id } = useParams()
  const [user, setUser] = useLocalStorage('user', {})
  const [project, setProject] = useState()
  const [updatedProject, setUpdatedProject] = useState()
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'Pending',
      status: 'pending',
      state: 'warning',
    },
    {
      text: 'Need Revision',
      status: 'need_revision',
      state: 'warning-urgent',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'In Review - VP',
      state: 'default',
      status: 'in_review_vp',
    },

    {
      text: 'In Review - SM',
      state: 'default',
      status: 'in_review_sm',
    },
  ])
  const [isLoading, setLoading] = useState(true)
  const [review, setReview] = useState({ notes: '', results: null })
  const [revenueCols, setRevenueCols] = useState([
    {
      id: 0,
      name: 'Product',
      classes: 'min-w-[200px]',
    },
    {
      id: 1,
      name: 'Variant',
      classes: 'w-[320px]',
    },
    {
      id: 2,
      name: 'Price',
      classes: 'text-right min-w-[200px]',
    },
    {
      id: 3,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
    },
    {
      id: 4,
      name: 'Discount',
      classes: 'text-right',
    },
    {
      id: 5,
      name: 'Total Price',
      classes: 'text-right min-w-[200px]',
    },
  ])
  const [cogsCols, setCOGSCols] = useState([
    {
      id: 0,
      name: 'Item',
      classes: 'min-w-[250px]',
      selector: (row) => <Basic data={row.cogs_item_name} />,
    },
    {
      id: 1,
      name: 'Cost',
      classes: 'min-w-[250px]',
      classes: 'text-right min-w-[200px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.cost)}`} alignment={'right'} />,
    },
    {
      id: 2,
      name: 'Quantity',
      classes: 'text-right',
      selector: (row) => <Basic alignment={'right'} data={row.quantity} />,
    },
    {
      id: 3,
      name: 'COGS',
      classes: 'text-right min-w-[200px]',
      selector: (row) => <Basic data={row.current_cost} alignment={'right'} />,
    },
    // {
    //   id: 4,
    //   name: 'COGS%',
    //   classes: 'text-right min-w-[100px]',
    //   selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    // },
  ])
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])
  const [listTopic, setListTopic] = useState([
    { id: 'green', topic: 'Green' },
    { id: 'non_green', topic: 'Non-green' },
  ])
  const navigate = useNavigate()

  useEffect(() => {
    console.log(updatedProject)
  }, [updatedProject])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ProjectAPI.getProjectById(id).then((res) => {
          setProject(res.data)
          setUpdatedProject(res.data)
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [id])

  const countMargin = () => {
    if (!updatedProject.total_revenue && !updatedProject.total_cogs) {
      return '0%'
    } else if (updatedProject.total_cogs > updatedProject.total_revenue) {
      return 'ERROR!!!'
    } else {
      return `${(100 - (updatedProject.total_cogs / updatedProject.total_revenue) * 100).toFixed(2)}%`
    }
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>
          <div className="mb-12">
            {user.role === 'ae' && (
              <div className="flex gap-2 mb-4">
                <Button
                  // href={`/projects/edit/${project.type === 'standard' || project.type === 'custom' ? 's' : 'f'}/${
                  href={`/projects/edit/s/${project.id}`}
                >
                  Edit Project
                </Button>
                <Button variant={'outlined'} href={`/sales-deal/${project.id}`}>
                  Go Sales Deal
                </Button>
              </div>
            )}
            <h1 className="font-semibold text-giant mb-4">Project Information</h1>
            <div className="border border-gray-border rounded-[5px] p-4 flex gap-16">
              <div className="min-w-[350px] max-w-[500px]">
                <p className="font-semibold mb-1">Project Name</p>
                <p className="mb-4">{updatedProject.name}</p>

                <p className="font-semibold mb-1">Description</p>
                <p className="mb-4">{updatedProject.description}</p>

                <div className="mb-4">
                  <p className="font-semibold mb-1">Client</p>
                  <p className="capitalize">{updatedProject.client.company_name}</p>
                </div>
                <div className="mb-4">
                  <p className="font-semibold mb-1">Project Type</p>
                  <p className="capitalize">{updatedProject.type}</p>
                </div>
                <div>
                  <p className="font-semibold mb-2">Project Status</p>
                  <StatusCell config={stateCellConfig} data={updatedProject.status} />
                </div>
                {/* <div className="mb-4">
                  <p className="font-semibold mb-1">Project Status</p>
                  <p className="capitalize">{updatedProject.status.replace('_', ' ')}</p>
                </div> */}
              </div>
              <div className="w-[200px]">
                <div className="mb-4">
                  <p className="font-semibold mb-1">Sales Deal Status</p>
                  <Status size={'small'} type={updatedProject.sales_deal.status} />
                </div>

                <div className="mb-4">
                  <p className="font-semibold mb-1">Start Date</p>
                  <p className="">{moment(updatedProject.sales_deal.created_at).format('D MMMM YYYY')}</p>
                </div>

                <div className="mb-4">
                  <p className="font-semibold mb-1">Topic</p>
                  <p className="">
                    {updatedProject.topic ? listTopic.find((topic) => topic.id == updatedProject.topic).topic : '-'}
                  </p>
                </div>
                {/* <div className="mb-4">
                  <p className="font-semibold mb-1">Expired Date</p>
                  <p className="">
                    {updatedProject.sales_deal.expired_date
                      ? moment(updatedProject.sales_deal.expired_date).format('D MMMM YYYY')
                      : '-'}
                  </p>
                </div> */}

                {/* <div className="mb-4">
                  <p className="font-semibold mb-1">Number Of Meetings</p>
                  <p>{updatedProject.sales_deal.number_of_meeting}</p>
                </div> */}

                <div className="mb-4">
                  <p className="font-semibold mb-1">Account Owner</p>
                  <div className="font-normal flex gap-1 items-center">
                    <span className="w-6 h-6 rounded-full bg-slate-100 overflow-hidden">
                      <img src={updatedProject.account.avatar} alt="avatar" className="w-full" />
                    </span>
                    <p>{updatedProject.account.name}</p>
                  </div>
                </div>

                <div className="mb-4">
                  <p className="font-semibold mb-1">Sales Deal</p>
                  <Link
                    to={`/sales-deal/${updatedProject.sales_deal_id}`}
                    className="hover:underline hover:underline-offset-1"
                  >
                    See Detail{' '}
                    <span className="w-4 h-4">
                      <ArrowForward sx={{ fontSize: 16 }} />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="lg:w-[400px] lg:max-w-[500px]">
                <div className="mb-4">
                  <h3 className="font-semibold mb-1">Project ID</h3>
                  <p className="">{updatedProject?.cc_project_id ? updatedProject.cc_project_id : '-'}</p>
                </div>
                <div className="mb-4">
                  {updatedProject.sm_review_note && (
                    <div className="mb-4">
                      <p className="mb-1 font-semibold">SM Review Note</p>
                      <div className="">
                        <article>{updatedProject.sm_review_note}</article>
                      </div>
                    </div>
                  )}
                  {updatedProject.vp_review_note && (
                    <div className="mb-4">
                      <p className="mb-1 font-semibold">VP Review Note</p>
                      <div className="">
                        <article>{updatedProject.vp_review_note}</article>
                      </div>
                    </div>
                  )}
                  {/* <p className="mb-4">{updatedProject.status}</p> */}
                </div>
              </div>
            </div>
          </div>
          <RevenueItemsTable project={updatedProject} setProject={setUpdatedProject} isEditable={false} />
          <COGSItemsTable project={updatedProject} setProject={setUpdatedProject} isEditable={false} />
          <div className="mb-12">
            <table></table>
            <h1 className="text-giant mb-4 font-semibold">Margin</h1>
            <div className="border border-gray-border rounded-[5px] p-4">
              {/* <div className="w-[360px] ">
                <div className="px-2.5 py-4 flex gap-8 border-b">
                  <p className="flex-1 font-semibold">Total Revenue</p>
                  <p className="flex-1 font-semibold text-right">Rp{formatThousands(updatedProject.total_revenue)}</p>
                </div>
                <div className="px-2.5 py-4 flex gap-8 border-b">
                  <p className="flex-1 font-semibold">Total COGS</p>
                  <p className="flex-1 font-semibold text-right">Rp{formatThousands(updatedProject.total_cogs)}</p>
                </div>
                <div className="px-2.5 py-4 flex gap-8 rounded-[5px] bg-main-500 text-white">
                  <p className="flex-1 font-semibold">Margin</p>
                  <p className="flex-1 font-semibold text-right">{countMargin()}</p>
                </div>
              </div> */}
              <Table
                tableType="inline"
                noIndex={true}
                cols={marginCols}
                data={[
                  {
                    id: 0,
                    row: 'Revenue',
                    totalAmount: updatedProject.total_revenue,
                    percentage:
                      updatedProject.total_revenue !== 0
                        ? 1 - updatedProject.total_cogs / updatedProject.total_revenue
                        : 0,
                  },
                  {
                    id: 1,
                    row: 'COGS',
                    totalAmount: updatedProject.total_cogs,
                    percentage: updatedProject.total_revenue
                      ? updatedProject.total_cogs / updatedProject.total_revenue
                      : 0,
                  },
                ]}
              />
            </div>
          </div>
          {/* {user.role === 'ae' ? null : (
            <div className="mb-8">
              <h1 className="text-giant mb-4 font-semibold">Review Result</h1>
              <div className="border border-gray-border rounded-[5px] p-4">
                <div className="flex gap-8">
                  <div className="">
                    <p className="font-semibold mb-2">Review Result</p>
                    <RadioButton
                      label={'Approved'}
                      checked={review.result === 'approved'}
                      onChange={() => setReview({ ...review, result: 'approved' })}
                    />
                    <RadioButton
                      label={'Rejected'}
                      checked={review.result === 'rejected'}
                      onChange={() => setReview({ ...review, result: 'approved' })}
                    />
                  </div>
                  <div className="w-[500px]">
                    <Textarea
                      rows={5}
                      label={'Notes'}
                      placeholder="Write your notes here..."
                      onChange={(e) => setReview({ ...review, notes: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </>
      )}
    </div>
  )
}
