import React, { useEffect, useState } from 'react'
import ClientAPI from '../../apis/ClientAPI'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Table from '../../components/table/Table'
import Loading from '../../components/Loading'
import BreadCrumbs from '../../components/BreadCrumbs'
import LogoCell from '../../components/table/cell/LogoCell'
import { useNavigate } from 'react-router-dom'
import { ChevronRight, Close, ChevronLeft } from '@mui/icons-material'
import usePermission from '../../hooks/usePermission'
import Pagination from '../../components/Pagination'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import toast from 'react-hot-toast'
import { industry } from '../../services/industry'
import DropdownInput from '../../components/DropdownInput'

export default function Clients() {
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [clients, setClients] = useState()
  const [cols, setCols] = useState([
    {
      id: 0,
      name: 'Company',
      classes: 'w-[400px]',
      selector: (row) => (
        <LogoCell
          img={row.logo != null ? row.logo : 'https://ui-avatars.com/api/?name=Kata+Data'}
          imgShape={'rounded'}
          text={row.company_name}
        />
      ),
    },
    // {
    //   id: 6,
    //   name: 'Agency',
    //   // classes: 'min-w-[200px]',
    //   selector: (row) => row.agency_name || '-',
    // },
    {
      id: 1,
      name: 'Company Code',
      // classes: 'min-w-[200px]',
      selector: (row) => row.company_code,
    },
    {
      id: 2,
      name: 'PIC Name',
      // classes: 'min-w-[200px]',
      selector: (row) => row.pic_name,
    },
    {
      id: 3,
      name: 'PIC Title',
      // classes: 'min-w-[250px]',
      selector: (row) => row.pic_title,
    },
    {
      id: 4,
      name: 'Email',
      // classes: 'min-w-[250px]',
      selector: (row) => row.pic_email,
    },
    {
      id: 5,
      name: 'Phone Number',
      // classes: 'min-w-[200px]',
      // selector: (row) => row.phone,
      selector: (row) => row.pic_phone_number,
    },
  ])
  const [isLoading, setLoading] = useState(true)
  const [querySearch, setQuerySearch] = useState('')
  // const [listAgency, setListAgency] = useState([])
  const [listIndustry, setListIndustry] = useState([{ id: '', industry: 'All Industry' }, ...industry])
  const [pagination, setPagination] = useState({ total_page: 1 })
  const [isOpenModal, setOpenModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const { isAdmin } = usePermission()

  useEffect(() => {
    // var promises = [
    //   new Promise((resolve, reject) => {
    //     ClientAPI.getListAgency()
    //       .then((res) => {
    //         setListAgency([{ id: '', name: 'All Agency' }, ...res.data])
    //         resolve()
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //   }),
    // ]
  }, [])

  useEffect(() => {
    ClientAPI.getAllClients(
      searchParams.q || undefined,
      searchParams.agency || undefined,
      searchParams.industry || undefined,
      searchParams.page
    )
      .then((res) => {
        setClients(res.data)
        setPagination(res.meta)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message)
        } else toast.error(err.statusText)
      })
  }, [searchParams.agency, searchParams.industry, searchParams.page])

  useEffect(() => {
    if (searchParams.q) {
      const timeoutId = setTimeout(() => {
        setLoading(true)
        ClientAPI.getAllClients(
          searchParams.q || undefined,
          searchParams.agency || undefined,
          searchParams.industry || undefined,
          searchParams.page
        )
          .then((res) => {
            setClients(res.data)
            setPagination(res.meta)
            setLoading(false)
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message)
            } else toast.error(err.statusText)
          })
      }, 250)
      return () => clearTimeout(timeoutId)
    }
  }, [searchParams.q])

  function handleDelete(id) {
    setDeleteId(id)
    setOpenModal(true)
  }

  return (
    <div className="py-4">
      <BreadCrumbs className="mb-4">
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item current>Clients</BreadCrumbs.Item>
      </BreadCrumbs>
      <>
        <div className="flex flex-col md:flex-row md:justify-between md:items-end gap-2.5 mb-2">
          <div className="flex items-center gap-2.5">
            <Input
              label={'Search Client'}
              placeholder="Client name"
              className="w-full md:w-[270px]"
              onChange={(e) => {
                setSearchParams({ ...searchParams, page: 1, q: e.target.value })
              }}
              value={searchParams.q}
            ></Input>
            {/* <div className="">
              <p className="font-semibold mb-1">Agency</p>
              <DropdownInput
                data={listAgency}
                placeholder="Select Agency"
                attributeShown={'name'}
                handleSelectedOption={(selected) => setSearchParams({ ...searchParams, page: 1, agency: selected.id })}
              />
            </div> */}
            <div className="">
              <p className="font-semibold mb-1">Industry</p>
              <DropdownInput
                data={listIndustry}
                placeholder="Select Industry"
                attributeShown={'industry'}
                handleSelectedOption={(selected) =>
                  setSearchParams({ ...searchParams, page: 1, industry: selected.id })
                }
              />
            </div>
          </div>
          {isAdmin && (
            <Button href="add" className={'w-full md:w-fit'}>
              Add New Client
            </Button>
          )}
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <Table
            cols={cols}
            data={clients}
            withActions
            editLink={(row) => {
              return `${row.id}/edit`
            }}
            pageIndex={searchParams.page}
            handleDelete={handleDelete}
          />
        )}
        <Pagination
          totalPage={pagination.total_page || 1}
          currentPage={parseInt(searchParams.page) || 1}
          onChange={(page) => setSearchParams({ ...searchParams, page: page })}
        />
        {isOpenModal && <CreateModal setOpenModal={setOpenModal} id={deleteId} />}
      </>
    </div>
  )
}

const CreateModal = ({ setOpenModal, id }) => {
  const navigate = useNavigate()

  function handleDelete() {
    console.log(id)

    ClientAPI.delete(id)
      .then((data) => {
        setOpenModal(false)
        navigate(0)
      })
      .catch((err) => {
        setOpenModal(false)
        console.log(err)
      })
  }

  return (
    <>
      <div
        className="bg-black bg-opacity-20 z-10 w-screen h-screen top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fixed"
        onClick={() => setOpenModal(false)}
      />
      <div className="fixed z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="w-[600px] rounded bg-white shadow-lg z-20">
          <div className="p-4 overflow-hidden rounded-t border-b flex justify-between items-center mb-2.5">
            <h5 className="text-big"> Are you sure to delete this client?</h5>
            <button
              className="w-6 h-6 flex items-center justify-center rounded text-gray-400 hover:bg-red-200 hover:text-red-500"
              onClick={() => setOpenModal(false)}
            >
              <Close sx={{ fontSize: 16 }} />
            </button>
          </div>
          <div className="flex justify-end gap-2.5 p-4 pt-0">
            <Button variant="outlined" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleDelete}>Delete</Button>
          </div>
        </div>
      </div>
    </>
  )
}
