import { Edit } from '@mui/icons-material'
import { Dialog, DialogContent } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import Button from './Button'
import getCroppedImg from '../cropImage'

export default function AvatarUpload({ src, className, onChange }) {
  const [currentAvatar, setCurrentAvatar] = useState(src)
  const [isCropping, setCropping] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [file, setFile] = useState({
    file: undefined,
    blob: '',
  })
  const [croppedImage, setCroppedImage] = useState('')
  const [croppedAreaPixels, setCroppedAreaPixels] = useState()

  useEffect(() => {
    if (croppedImage) {
      console.log(croppedImage)
      onChange(croppedImage)
    }
  }, [croppedImage])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleFileChange = (file) => {
    // console.log(file)
    setFile({
      file: file,
      blob: URL.createObjectURL(file, { type: 'image/png' }),
    })
    setCropping(true)
  }

  const getCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(file.blob, croppedAreaPixels)
      setCroppedImage(croppedImage)
      setCropping(false)
      setFile({ file: '', blob: '' })
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])

  return (
    <div className={`${className && className}`}>
      <Dialog open={isCropping} onClose={() => setCropping(false)} className="">
        <DialogContent className="w-[400px] min-h-[400px] relative">
          <Cropper
            image={file.blob}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            cropSize={{ width: 350, height: 350 }}
            onCropChange={setCrop}
            objectFit="horizontal-cover"
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </DialogContent>
        <div className="py-4 text-center">
          <Button className="" onClick={getCroppedImage}>
            Save
          </Button>
        </div>
      </Dialog>
      <span className="w-32 h-32 bg-slate-100 inline-block rounded-full overflow-hidden ring-2 ring-main-500 relative">
        <img src={croppedImage ? croppedImage : currentAvatar} className="object-cover w-full" />
        <label
          htmlFor="upload-file"
          className="cursor-pointer absolute top-0 left-0 w-full h-full flex items-center justify-center opacity-0 transition-all hover:bg-sky-100 hover:opacity-70 hover:text-main-500 "
        >
          <input
            type="file"
            className=""
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e.target.files[0])}
            id="upload-file"
          />
          <Edit />
        </label>
      </span>
    </div>
  )
}
