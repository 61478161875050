import {
  AccountBalance,
  AccountBalanceWallet,
  AttachFile,
  CalendarMonthRounded,
  Close,
  Description,
  DescriptionOutlined,
  Sell,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { dotsLimiter, formatThousands } from '../../helper'
import Status from '../Status'
import Button from '../Button'
import Loading from '../Loading'
import GroupButtons from '../GroupButtons'
import DropdownInput from '../DropdownInput'
import Input from '../Input'
import SalesPlanAPI from '../../apis/SalesPlanAPI'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import usePermission from '../../hooks/usePermission'

const SalesPlanModal = ({ setIsOpen, salesPlanId, setRefreshDOM }) => {
  const [salesPlan, setSalesPlan] = useState()
  const [isLoading, setLoading] = useState(true)
  const [isLoadingDelete, setLoadingDelete] = useState(false)
  const navigate = useNavigate()
  const { isAE, isAdmin } = usePermission()

  useEffect(() => {
    SalesPlanAPI.getSingleSalesPlan(salesPlanId).then((res) => {
      setSalesPlan(res.data)
      console.log(res.data)
      setLoading(false)
    })
  }, [])

  const handleDelete = () => {
    setLoadingDelete(true)
    SalesPlanAPI.deleteSalesPlan(salesPlanId).then((res) => {
      if (res.success) {
        setRefreshDOM(Math.random)
        setLoadingDelete(false)
      }
    })
  }

  return (
    <>
      <div
        className="bg-black bg-opacity-20 z-10 w-screen h-screen top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute"
        onClick={() => setIsOpen(false)}
      />
      <div className="fixed z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-2">
        <div className="w-full md:w-[600px] rounded bg-white shadow-lg z-20 mx-auto">
          {isLoading ? (
            <div className="h-[300px] py-8 flex justify-center">
              <Loading />
            </div>
          ) : (
            <>
              <div className="p-4 overflow-hidden rounded-t border-b flex justify-between items-center mb-2.5">
                <h5 className="text-big">Sales Plan - {moment(salesPlan.meeting_date).utc().format('D MMMM YYYY')}</h5>
                <button
                  className="w-6 h-6 flex items-center justify-center rounded text-gray-400 hover:bg-red-200 hover:text-red-500"
                  onClick={() => setIsOpen(false)}
                >
                  <Close sx={{ fontSize: 16 }} />
                </button>
              </div>
              <div className="p-4 grid grid-cols-2 gap-4 mb-2.5">
                <div>
                  <p className="font-semibold mb-0.5">Meeting Date</p>
                  <p className="font-normal">{moment(salesPlan.meeting_date).utc().format('dddd, D MMMM YYYY')}</p>
                </div>
                <div>
                  <p className="font-semibold mb-0.5">Value</p>
                  <p className="font-normal">Rp{formatThousands(salesPlan.project.value)}</p>
                </div>
                <div>
                  <p className="font-semibold mb-0.5">Client</p>
                  <p className="font-normal">{salesPlan.client.company_name}</p>
                </div>
                <div>
                  <p className="font-semibold mb-0.5">Start Date</p>
                  <p className="font-normal">{moment(salesPlan.sales_deal.created_at).utc().format('D MMMM YYYY')}</p>
                </div>
                <div>
                  <p className="font-semibold mb-0.5">Project Type</p>
                  <p className="font-normal capitalize">{salesPlan.project.type}</p>
                </div>
                <div>
                  <p className="font-semibold mb-0.5">Expired Date</p>
                  <p className="font-normal">
                    {salesPlan.sales_deal.expired_date
                      ? moment(salesPlan.sales_deal.expired_date).utc().format('D MMMM YYYY')
                      : '-'}
                  </p>
                </div>
                <div>
                  <p className="font-semibold mb-0.5">Project Name</p>
                  <p className="font-normal">{salesPlan.project.name}</p>
                </div>
                <div>
                  <p className="font-semibold mb-0.5">Number Of Meetings</p>
                  <p className="font-normal">{salesPlan.number_of_meetings}</p>
                </div>
                <div>
                  <p className="font-semibold mb-1">Sales Deal Status</p>
                  <div className="font-normal">{<Status size="small" type={salesPlan.sales_deal.status} />}</div>
                </div>
                <div>
                  <p className="font-semibold mb-1">Account Owner</p>
                  <div className="font-normal flex gap-1 items-center">
                    <span className="w-6 h-6 rounded-full bg-slate-100">
                      <img src={salesPlan.account_owner.avatar} alt="avatar" className="w-full" />
                    </span>
                    <p>{salesPlan.account_owner.name}</p>
                  </div>
                </div>
              </div>

              <div className="flex justify-between gap-2.5 p-4 pt-0">
                <div>
                  {(isAE || isAdmin) && (
                    <>
                      {salesPlan.mom_status === 'empty' ? (
                        <>
                          <Button href={`${salesPlan.id}/add-mom`} className={'mr-2'}>
                            Add MoM
                          </Button>
                          <Button variant="outlined" onClick={handleDelete} isLoading={isLoadingDelete}>
                            Delete
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button href={`${salesPlan.id}/edit-mom`} className={'mr-2'}>
                            Edit MoM
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <Button href={`/sales-deal/${salesPlan.sales_deal.id}`} variant="outlined">
                    Deal Details
                  </Button>
                </div>
              </div>
              {/* <div className="absolute bottom-1">
            <div className="flex justify-around items-center">
              <button className="px-4 py-2 bg-red-200 text-white" onClick={() => setIsOpen(false)}>
                Delete
              </button>
              <button className="px-4 py-2 bg-red-200 text-white" onClick={() => setIsOpen(false)}>
                Cancel
              </button>
            </div>
          </div> */}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default function Item({ plan, index, setRefreshDOM }) {
  const [isOpen, setIsOpen] = useState(false)
  const { isAE, isAdmin, isVP, isSM } = usePermission()

  useEffect(() => {
    // console.log(plan.sales_deal_status)
  }, [isOpen])

  return (
    <Draggable draggableId={`${plan.id}`} index={index}>
      {(provided) => (
        <div
          className="rounded p-4 border-2 border-gray-border shadow bg-white hover:bg-sky-50 transition-all"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {isOpen && <SalesPlanModal setIsOpen={setIsOpen} salesPlanId={plan.id} setRefreshDOM={setRefreshDOM} />}
          <div onClick={() => setIsOpen(true)}>
            <p className="text-big mb-2 font-bold">{plan.client.name}</p>
            <div className="flex gap-2 items-center text-gray-text mb-2">
              <Description sx={{ fontSize: 18 }} />
              <p className="">{dotsLimiter(plan.project.name, 30)}</p>
            </div>
            <div className="flex gap-2 items-center text-gray-text mb-2">
              <AccountBalanceWallet sx={{ fontSize: 18 }} />
              <p>Rp{formatThousands(plan.project_value)}</p>
            </div>
            {/* <div className="flex gap-2 items-center text-gray-text mb-2">
              <Sell sx={{ fontSize: 18 }} />
              {plan.tags.length > 0 ? (
                <p>
                  {plan.tags.slice(0, 3).toString().replace(',', ', ')}
                  {plan.tags.length > 3 && (
                    <>
                      &#44;&nbsp;<span className="p-0.5 px-1 rounded bg-sky-100">{plan.tags.length - 3} more</span>
                    </>
                  )}
                </p>
              ) : (
                <p>-</p>
              )}
            </div> */}
            <div className="flex items-center justify-between mt-4 z-0">
              <Status size={'small'} type={plan.sales_deal_status} />
              {(isAE || isAdmin) && (
                <>
                  {plan.mom_status === 'filled' ? (
                    <Button
                      variant="outlined"
                      size="small"
                      className={
                        'py-[4px] text-tiny bg-primary-green text-white ring-2 ring-primary-green border-0 font-normal hover:bg-primary-green hover:text-white'
                      }
                      // onClick={() => window.alert('click')}
                      icon={<AttachFile sx={{ fontSize: 12 }} />}
                      disabled
                    >
                      Mom Added
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      size="small"
                      className={'py-[4px] text-tiny'}
                      onClick={() => window.alert('click')}
                      icon={<AttachFile sx={{ fontSize: 12 }} />}
                      href={`${plan.id}/add-mom`}
                    >
                      Add MoM
                    </Button>
                  )}
                </>
              )}
              {(isSM || isVP) && (
                <>
                  {plan.mom_status === 'filled' ? (
                    <Button
                      variant="outlined"
                      size="small"
                      className={
                        'py-[4px] text-tiny bg-primary-green text-white ring-2 ring-primary-green border-0 font-normal hover:bg-primary-green hover:text-white'
                      }
                      // onClick={() => window.alert('click')}
                      icon={<AttachFile sx={{ fontSize: 12 }} />}
                      disabled
                    >
                      Mom Added
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      size="small"
                      className=""
                      // onClick={() => window.alert('click')}
                      icon={<AttachFile sx={{ fontSize: 12 }} />}
                    >
                      No MoM Yet
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}
