import React, { useEffect, useState } from 'react'
import ReimbursementAPI from '../../apis/ReimbursementAPI'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Table from '../../components/table/Table'
import Loading from '../../components/Loading'
import BreadCrumbs from '../../components/BreadCrumbs'
import LogoCell from '../../components/table/cell/LogoCell'
import { useNavigate } from 'react-router-dom'
import { Close, RateReview, RemoveRedEye, ChevronLeft, ChevronRight } from '@mui/icons-material'
import Basic from '../../components/table/cell/Basic'
import StatusCell from '../../components/table/cell/StatusCell'
import { formatThousands } from '../../helper'
import moment from 'moment'
import DropdownInput from '../../components/DropdownInput'
import AccountAPI from '../../apis/AccountAPI'
import GroupButtons from '../../components/GroupButtons'
import DropdownUser from '../../components/DropdownUser'
import usePermission from '../../hooks/usePermission'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import Pagination from '../../components/Pagination'
import toast from 'react-hot-toast'

export default function Reimbursement() {
  const [reimbursement, setReimbursement] = useState()
  const configStatusCell = [
    { status: 'default', text: 'Unknown Status', state: 'default' },
    { status: 'manager_review', text: 'Manager Review', state: 'warning' },
    { status: 'finance_review', text: 'Finance Review', state: 'warning' },
    { status: 'reimbursed', text: 'Reimbursed', state: 'success' },
    { status: 'rejected_by_manager', text: 'Rejected by Manager', state: 'danger' },
    { status: 'rejected_by_finance', text: 'Rejected by Finance', state: 'danger' },
  ]
  const [cols, setCols] = useState([
    {
      id: 0,
      name: 'Request Date',
      selector: (row) => <Basic data={moment(row.request_date).utc().format('D MMM YYYY, H:mm')} />,
    },
    {
      id: 1,
      name: 'Account Executive',
      // classes: 'min-w-[200px]',
      selector: (row) => <LogoCell img={row.account.avatar} imgShape={'circle'} text={row.account.name} />,
    },
    {
      id: 2,
      name: 'Client',
      // classes: 'min-w-[250px]',
      selector: (row) => <LogoCell img={row.client.logo} text={row.client.company_name} />,
    },
    {
      id: 3,
      name: 'Total Expenses',
      // classes: 'min-w-[250px]',
      selector: (row) => <Basic data={'Rp' + formatThousands(row.total_meeting_expenses)} />,
    },
    {
      id: 4,
      name: 'Status',
      // classes: 'min-w-[200px]',
      selector: (row) => <StatusCell data={row.reimbursement_status} config={configStatusCell} />,
    },
    {
      id: 5,
      name: 'Action',
      classes: 'w-16',
      selector: (row) => (
        <span className="flex gap-4 float-right">
          {row.reimbursement_status === 'manager_review' ? (
            <Button icon={<RateReview sx={{ fontSize: 16 }} />} href={row.id + '/review'}>
              Review
            </Button>
          ) : null}
          <Button icon={<RemoveRedEye sx={{ fontSize: 16 }} />} variant="outlined" href={row.id + ''}>
            Details
          </Button>
        </span>
      ),
    },
  ])
  const [isLoading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [isOpenModal, setOpenModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [listAE, setListAE] = useState([])
  const [reimbursementStatus, setReimbursementStatus] = useState([
    { id: '', value: 'All Status' },
    { id: 'manager_review', value: 'Manager Review' },
    { id: 'finance_review', value: 'Finance Review' },
    { id: 'reimbursed', value: 'Reimbursed' },
    { id: 'rejected_by_manager', value: 'Rejected by Manager' },
    { id: 'rejected_by_finance', value: 'Rejected by Finance' },
  ])
  const { isAE } = usePermission()
  useEffect(() => {
    AccountAPI.getList('ae').then((res) => {
      var data = [{ id: '', name: 'All AE' }, ...res.data]
      setListAE(data)
    })
  }, [])

  useEffect(() => {
    ReimbursementAPI.getList(searchParams.ae || undefined, searchParams.status || undefined, searchParams.page || 1)
      .then((res) => {
        setReimbursement(res.data)
        setPagination(res.meta)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response) toast.error(err.response.data.message)
        else toast.error(err.statusText)
      })
  }, [searchParams.ae, searchParams.status, searchParams.page])

  function handleDelete(id) {
    setDeleteId(id)
    setOpenModal(true)
  }

  return (
    <div className="py-4">
      <BreadCrumbs className="mb-4">
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item current>Reimbursement</BreadCrumbs.Item>
      </BreadCrumbs>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex gap-4 mb-2">
            <div>
              {/* <p className="text-slate-800 font-semibold mb-1">Account Executive</p> */}
              {!isAE && (
                <DropdownUser
                  data={listAE}
                  type="Account Executive"
                  value={listAE.find((ae) => ae.id == searchParams.ae)}
                  handleSelectedOption={(selected) => setSearchParams({ ...searchParams, page: 1, ae: selected.id })}
                  // handleSelectedOption={(selected) => {}}
                  placeholder="All"
                  noPic
                  attributeShown="name"
                />
              )}
            </div>
            <div>
              {/* <p className="text-slate-800 font-semibold mb-1">Status</p> */}
              <DropdownUser
                data={reimbursementStatus}
                type="Status"
                handleSelectedOption={(selected) => setSearchParams({ ...searchParams, page: 1, status: selected.id })}
                value={reimbursementStatus.find((status) => status.id == searchParams.status)}
                placeholder="All"
                noPic
                attributeShown="value"
              />
            </div>
          </div>
          <Table
            cols={cols}
            data={reimbursement}
            editLink={(row) => {
              return `${row.id}/review`
            }}
            pageIndex={searchParams.page}
            handleDelete={handleDelete}
          />
          <div className="w-full flex justify-end">
            <Pagination
              totalPage={pagination.total_page || 1}
              currentPage={parseInt(searchParams.page) || 1}
              onChange={(page) => setSearchParams({ ...searchParams, page: page })}
            />
          </div>
          {isOpenModal && <CreateModal setOpenModal={setOpenModal} id={deleteId} />}
        </>
      )}
    </div>
  )
}

const CreateModal = ({ setOpenModal, id }) => {
  const navigate = useNavigate()

  function handleDelete() {
    console.log(id)

    // ClientAPI.delete(id)
    //   .then(data => {
    //     setOpenModal(false)
    //     navigate(0)
    //   })
    //   .catch(err => {
    //     setOpenModal(false)
    //     console.log(err)
    //   })
  }

  return (
    <>
      <div
        className="bg-black bg-opacity-20 z-10 w-screen h-screen top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fixed"
        onClick={() => setOpenModal(false)}
      />
      <div className="fixed z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="w-[600px] rounded bg-white shadow-lg z-20">
          <div className="p-4 overflow-hidden rounded-t border-b flex justify-between items-center mb-2.5">
            <h5 className="text-big"> Are you sure to delete this client?</h5>
            <button
              className="w-6 h-6 flex items-center justify-center rounded text-gray-400 hover:bg-red-200 hover:text-red-500"
              onClick={() => setOpenModal(false)}
            >
              <Close sx={{ fontSize: 16 }} />
            </button>
          </div>
          <div className="flex justify-end gap-2.5 p-4 pt-0">
            <Button variant="outlined" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleDelete}>Delete</Button>
          </div>
        </div>
      </div>
    </>
  )
}
