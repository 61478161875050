import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Tabs from '../../components/Tabs'
import { ProductsAPI } from '../../apis/ProductsAPI'
import Loading from '../../components/Loading'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import { formatThousands } from '../../helper'

export default function Product() {  
  const [product, setProduct] = useState(1)
  const [activeProduct, setActiveProduct] = useState(1)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    let mounted = true
    ProductsAPI.getProductOnly().then(res => {
      if (mounted) {
        setLoading(false)
        setProduct(res.data)
      }
    })
    
    return () => { mounted = false }
  }, [])

  let path = useLocation().pathname
  var pathSplit = path.split('/')
  let lastPath = pathSplit[pathSplit.length-1]

  useEffect(() => {
    let mounted = true
    for(var i=0; i< product.length; i++) {
      if (product[i].link === lastPath) {
        if(mounted) {
          setActiveProduct(product[i].id)
        }
      }
    }
    return () => { mounted = false }
  }, [activeProduct, lastPath])

  const [colsProduct, setColsProduct] = useState([
    {
      id: 0,
      name: 'Sub Product',
      selector: (row) => <Basic data={row.sub_product_name} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 1,
      name: 'Product Variant',
      selector: (row) => <Basic data={row.product_variant_name} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 2,
      name: 'Type',
      selector: (row) => <Basic data={row.price_type.charAt(0).toUpperCase()  + row.price_type.substring(1)} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 3,
      name: 'Price',
      selector: (row) => <Basic data={row.price == 0 ? '-':'Rp'+formatThousands(row.price)} />,
      classes: 'min-w-[150px]',
    },
    {
      id: 4,
      name: 'COGS',
      selector: (row) => <Basic data={row.cogs * 100 + "%"} />,
      classes: 'min-w-[150px]',
    }
  ])

  const [productVariant, setProductVariant] = useState([])

  useEffect(() => {
    let mounted = true
    ProductsAPI.getProductVariantByProductId(activeProduct).then(res => {
      if (mounted) {
        setProductVariant(res.data)
      }
    })
    
    return () => { mounted = false }
  }, [activeProduct])

  return (
    <div className="py-4">
      <BreadCrumbs className="mb-4">
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item current>Products</BreadCrumbs.Item>
      </BreadCrumbs>

      {
        isLoading ? (<Loading />) : 
        (<div>
          <Tabs data={product} />
          
          <Table cols={colsProduct} data={productVariant} />
        </div>)
      }
    </div>
  )
}
