import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'

const initialState = {
  firebaseUser: null,
  userData: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveFirebaseLoginData: (state, action) => {
      state.firebaseUser = action.payload
    },
    saveDetailUserData: (state, action) => {
      state.userData = action.payload
    },
    updateToken: (state, action) => {
      state.firebaseUser = { ...state.firebaseUser, accessToken: action.payload }
    },
    setRefrehingToken: (state) => {
      state.firebaseUser = { ...state.firebaseUser, isRefreshing: true }
    },
    signOut: (state) => {
      state.firebaseUser = null
      state.userData = null
    },
  },
})

// Action creators are generated for each case reducer function
export const { saveDetailUserData, saveFirebaseLoginData, signOut, updateToken, setRefrehingToken } = authSlice.actions

export default authSlice.reducer
