import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import imageProduct from '../assets/images/data-image2/1-product.png'
import imageClient from '../assets/images/data-image2/2-client.png'
import imageCOGSItem from '../assets/images/data-image2/3-cogs-items.png'
import imageAccountRole from '../assets/images/data-image2/4-user-roles.png'
import imageTarget from '../assets/images/data-image2/5-target.png'
import imageReimbursement from '../assets/images/data-image2/6-reimbursement.png'
import imageSBUSettings from '../assets/images/data-image2/7-sbu-settings.png'
import imageInvoiceRequest from '../assets/images/data-image2/8-invoice-request.png'

export default function Data() {
  const [categories, setCategories] = useState([
    {
      id: 0,
      name: 'Products',
      link: 'products/research',
      icon: imageProduct,
    },
    {
      id: 1,
      name: 'Clients',
      link: 'clients',
      icon: imageClient,
    },
    {
      id: 2,
      name: 'COGS Items',
      link: 'cogs',
      icon: imageCOGSItem,
    },
    {
      id: 3,
      name: 'Account Role',
      link: 'account-role',
      icon: imageAccountRole,
    },
    {
      id: 4,
      name: 'Target',
      link: 'target',
      icon: imageTarget,
    },
    {
      id: 5,
      name: 'Reimbursement',
      link: 'reimbursement',
      icon: imageReimbursement,
    },
    {
      id: 7,
      name: 'Invoice Request',
      link: 'invoice-requests',
      icon: imageInvoiceRequest,
    },
    {
      id: 6,
      name: 'Other Data',
      link: 'other-data',
      icon: imageSBUSettings,
    },
  ])

  return (
    <>
      <div className="py-20 grid md:grid-cols-3 lg:grid-cols-4 w-fit mx-auto gap-10 ">
        {categories.map((category) => {
          return (
            <Link
              to={category.link}
              key={category.id}
              className="w-[200px] h-[200px] rounded-lg border-2 border-gray-border flex flex-col justify-center items-center hover:bg-sky-100 hover:border-main-500 transition-all"
            >
              <div className="mb-2 w-[120px] h-[120px] flex items-center justify-center overflow-hidden">
                <img src={category.icon} className="w-full" />
              </div>
              <p className="text-giant">{category.name}</p>
            </Link>
          )
        })}
      </div>
    </>
  )
}
