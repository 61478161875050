import { toast, ToastBar, Toaster } from 'react-hot-toast'
import { HiX } from 'react-icons/hi'

export default function DismissableToast() {
  return (
    <div>
      <Toaster
        reverseOrder={false}
        position="top-right"
        toastOptions={{
          // style: {
          //   borderRadius: '8px',
          //   background: '#AAA',
          //   color: '#fff',
          // },
          className: 'text-white',
          success: {
            style: {
              background: '#86efac',
              border: '2px solid #16a34a',
              color: '#16a34a',
            },
          },
          error: {
            style: {
              background: '#fca5a5',
              border: '2px solid #dc2626',
              color: '#dc2626',
            },
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {/* {t.type !== 'loading' && (
                  <button
                    className="rounded-lg p-1 ring-primary-400 transition hover:bg-[#444] focus:outline-none focus-visible:ring"
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <HiX />
                  </button>
                )} */}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  )
}
