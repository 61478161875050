import { ArrowDropDown, ArrowDropUp, ArrowUpward, Cancel, CheckCircle } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'

export default function Basic({ alignment, data, state, withIcon, withSecondLine, className, customView }) {
  // useEffect(() => {
  //   console.log(state)
  // }, [])

  if (withIcon) {
    if (state === 'positive') {
      return (
        <div
          className={`w-full flex items-center gap-1 text-primary-green ${
            alignment === 'center' ? 'justify-center' : alignment === 'right' ? 'justify-end' : 'justify-start'
          }`}
        >
          <p className={`${className ? className : ''}`}>{data}</p>
          <i className="">
            <CheckCircle sx={{ fontSize: 14 }} />
          </i>
        </div>
      )
    } else {
      return (
        <div
          className={`w-full flex items-center gap-2 text-primary-red ${
            alignment === 'center' ? 'justify-center' : alignment === 'right' ? 'justify-end' : 'justify-start'
          }`}
        >
          <p>{data}</p>
          <i className="">
            <Cancel sx={{ fontSize: 14 }} />
          </i>
        </div>
      )
    }
  } else if (withSecondLine) {
    if (state === 'positive') {
      return (
        <div
          className={`w-full text-primary-green ${
            alignment === 'center' ? 'text-center' : alignment === 'right' ? 'text-end' : 'text-start'
          }`}
        >
          <p className={`${className ? className : ''}`}>{data}</p>
          <div
            className={`flex gap-0 items-center ${
              alignment === 'center' ? 'justify-center' : alignment === 'right' ? 'justify-end' : 'justify-start'
            }`}
          >
            <i className="w-4 h-4 flex items-center justify-center">
              <ArrowDropUp sx={{ fontSize: 14 }} />
            </i>
            <p className="text-small mb-0">{withSecondLine}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={`w-full text-primary-red ${
            alignment === 'center' ? 'text-center' : alignment === 'right' ? 'text-end' : 'text-start'
          }`}
        >
          <p className={`${className ? className : ''}`}>{data}</p>
          <div
            className={`flex gap-0 items-center ${
              alignment === 'center' ? 'justify-center' : alignment === 'right' ? 'justify-end' : 'justify-start'
            }`}
          >
            <i className="w-4 h-4 flex items-center justify-center">
              <ArrowDropDown sx={{ fontSize: 14 }} />
            </i>
            <p className="text-small mb-0">{withSecondLine}</p>
          </div>
        </div>
      )
    }
  }

  return (
    <span
      className={`block ${
        alignment === 'center' ? 'text-center' : alignment === 'right' ? 'text-right' : 'text-left'
      } ${state === 'positive' ? 'text-primary-green' : state === 'negative' ? 'text-primary-red' : ''} ${
        className ? className : ''
      }`}
    >
      {customView ? customView : data}
    </span>
  )
}
