import React, { forwardRef, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import withClickOutside from '../withClickOutside'
import Input from './Input'
import { Filter, FilterList } from '@mui/icons-material'

const DropdownGroup = forwardRef(function DropdownGroup({ open, setOpen, props }, ref) {
  const [isOpen, setIsOpen] = useState(false)
  const { children } = { ...props }

  return (
    <div className="relative md:inline-block text-left" ref={ref}>
      <div className={`min-w-[150px]`}>
        <button
          type="button"
          className="w-full h-[56px] rounded-md px-4 py-2 font-medium text-gray-700 bg-sky-50 focus:outline-none focus:ring-0"
          onClick={() => setOpen(!open)}
        >
          <div className="flex items-center gap-2">
            <span className="w-10 h-10 rounded-full bg-blue-100 overflow-hidden flex items-center justify-center text-main-500">
              <FilterList sx={{ fontSize: 22 }} />
            </span>
            More Filter
          </div>
        </button>
      </div>
      {open && (
        <div className="absolute left-0 lg:left-auto lg:right-0 z-[40] mt-2 w-full md:w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="lg:p-4 grid xl:flex gap-4">{children}</div>
        </div>
      )}
    </div>
  )
})

export default withClickOutside(DropdownGroup)
