import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import { useNavigate, useParams, useRoutes } from 'react-router-dom'
import Input from '../../components/Input'
import Button from '../../components/Button'
import ClientAPI from '../../apis/ClientAPI'
import Alert from '../../components/Alert'

export default function EditAgency() {
  // const routes = useRoutes()
  const { id } = useParams()
  const [agencyName, setAgencyName] = useState('')
  const [isLoadingSave, setLoadingSave] = useState(false)
  const [error, setError] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      ClientAPI.getSingleAgency(id).then((res) => {
        setAgencyName(res.data.name)
      })
    }
  }, [id])

  const handleCreate = () => {
    setLoadingSave(true)
    if (agencyName) {
      ClientAPI.createNewAgency(agencyName)
        .then((res) => {
          if (res.success) {
            navigate('/data/other-data')
          }
        })
        .catch((err) => {
          if (err.response) setError(err.response.data.message)
          else setError(err.statusText)
          setLoadingSave(false)
        })
    } else {
      setLoadingSave(false)
      setError('Please fill agency name!')
    }
  }

  const handleEditAgency = () => {
    setLoadingSave(true)
    if (agencyName) {
      ClientAPI.updateAgency(id, agencyName)
        .then((res) => {
          if (res.success) {
            navigate('/data/other-data')
          }
        })
        .catch((err) => {
          if (err.response) setError(err.response.data.message)
          else setError(err.statusText)
          setLoadingSave(false)
        })
    } else {
      setLoadingSave(false)
      setError('Please fill agency name!')
    }
  }

  return (
    <div className="py-4">
      <BreadCrumbs className={'mb-4'}>
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item href={'/data/other-data'}>Other Data</BreadCrumbs.Item>
        <BreadCrumbs.Item current>{id ? 'Edit Agency' : 'Add New Agency'}</BreadCrumbs.Item>
      </BreadCrumbs>
      <div>
        {id ? (
          <>
            <h1 className="text-giant mb-4 font-bold">Edit Agency</h1>
            <div className="border-2 rounded-[5px] p-4 mb-4">
              <Input
                label={'Agency Name'}
                required
                placeholder="Company name"
                value={agencyName}
                onChange={(e) => setAgencyName(e.target.value)}
                className={'max-w-[500px]'}
              />
            </div>
            <div className="flex gap-2 items-center">
              <Button onClick={handleEditAgency} isLoading={isLoadingSave}>
                Save
              </Button>
              {error && <Alert type={'error'} message={error} />}
            </div>
          </>
        ) : (
          <>
            <h1 className="text-giant mb-4 font-bold">Add New Agency</h1>
            <div className="border-2 rounded-[5px] p-4 mb-4">
              <Input
                label={'Agency Name'}
                required
                placeholder="Company name"
                value={agencyName}
                onChange={(e) => setAgencyName(e.target.value)}
                className={'max-w-[500px]'}
              />
            </div>
            <div className="flex gap-2 items-center">
              <Button onClick={handleCreate} isLoading={isLoadingSave}>
                Save
              </Button>
              {error && <Alert type={'error'} message={error} />}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
