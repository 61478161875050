import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SalesPlanAPI from '../../apis/SalesPlanAPI'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
import { formatThousands } from '../../helper'
import Status from '../../components/Status'
import moment from 'moment'
import RadioButton from '../../components/RadioButton'
import Input from '../../components/Input'
import Alert from '../../components/Alert'
import DropdownInput from '../../components/DropdownInput'
import Textarea from '../../components/Textarea'
import DragAndDropFile from '../../components/DragAndDropFile'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import Button from '../../components/Button'
import ClientAPI from '../../apis/ClientAPI'
import StorageAPI from '../../apis/storageAPI'
import usePermission from '../../hooks/usePermission'
import ErrorPage from '../../ErrorPage'
import TagsDropdown from '../../components/TagsDropdown'

export default function AddMom() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(true)
  const [salesPlan, setSalesPlan] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const [client, setClient] = useState({
    pic_name: '',
    pic_title: '',
    pic_phone_number: '',
  })
  const [clientResponse, setClientResponse] = useState('')
  const [changeStatus, setChangeStatus] = useState()
  const [meetingNotes, setMeetingNotes] = useState()
  const [revenueEstimation, setRevenueEstimation] = useState('')
  const [itemName, setItemName] = useState()
  const [price, setPrice] = useState('')
  const [receipts, setReceipt] = useState([])
  const [currentReceipt, setCurrentReceipts] = useState(null)
  const [listExpenses, setListExpenses] = useState([])
  const [loadingItemAdd, setLoadingItemAdd] = useState(false)
  const [isLoadingSaveMomButton, setLoadingSaveMomButton] = useState(false)
  const [errorSaveMom, setErrorSaveMom] = useState()
  const [errorGetSalesPlan, setErrorGetSalesPlan] = useState(false)
  const { isVP, isSM } = usePermission()

  const [tableCols, setTableCols] = useState([
    {
      id: 0,
      name: 'Item Name',
      selector: (row) => <Basic data={row.item_name} />,
    },
    {
      id: 1,
      name: 'Price',
      classes: 'text-right',
      selector: (row) => <Basic data={`Rp${formatThousands(row.price)}`} alignment="right" />,
    },
    {
      id: 2,
      name: 'Receipt or Proof',
      // classes: 'min-w-[200px]',
      selector: (row) => (
        <Basic
          data={
            <a href={row.receipt} target="_blank" className="underline text-slate-700 underline-offset-1">
              See File
            </a>
          }
        />
      ),
    },
  ])

  const [tableColsMobile, setTableColsMobile] = useState([
    {
      id: 0,
      name: 'Item Name',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.item_name} />,
    },
    {
      id: 1,
      name: 'Price',
      classes: 'text-right min-w-[150px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.price)}`} alignment="right" />,
    },
    {
      id: 2,
      name: 'Receipt or Proof',
      // classes: 'min-min-w-[150px]',
      classes: 'min-w-[150px]',
      selector: (row) => (
        <Basic
          data={
            <a href={row.receipt} target="_blank" className="underline text-slate-700 underline-offset-1">
              See File
            </a>
          }
        />
      ),
    },
  ])

  useEffect(() => {
    SalesPlanAPI.getSingleSalesPlan(id).then((res) => {
      if (res.success) {
        setSalesPlan(res.data)
        setSelectedTags(res.data.tags)
        setLoading(false)
      } else {
        setErrorGetSalesPlan(true)
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    console.log(price)
  }, [price])

  const addCommas = (num) => {
    console.log(num)
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  const removeNonNumeric = (num) => {
    console.log(num)
    return num.toString().replace(/[^0-9]/g, '')
  }
  const handlePriceChange = (input) => {
    setPrice(addCommas(removeNonNumeric(input.value)))
  }

  const handleRevenueEstimation = (input) => {
    setRevenueEstimation(addCommas(removeNonNumeric(input.value)))
  }

  const handleAddExpensesItem = (e) => {
    var tempPrice = removeNonNumeric(price)
    console.log(tempPrice)
    e.preventDefault()
    setLoadingItemAdd(true)
    if (itemName && price && currentReceipt) {
      var temp = {
        id: listExpenses.length,
        item_name: itemName,
        price: price,
        receipt: currentReceipt.blob,
      }
      console.log(temp)
      setItemName('')
      setPrice('')
      setCurrentReceipts({})
      setListExpenses([...listExpenses, temp])
    }
    setLoadingItemAdd(false)
  }

  const handleSave = () => {
    setLoadingSaveMomButton(true)
    if (client.pic_name && client.pic_phone_number && client.pic_phone_number && clientResponse && meetingNotes) {
      // var tempPrice = removeNonNumeric(price)
      // console.log(
      //   listExpenses.map((item, index) => {
      //     // console.log(item.receipt)
      //     return { item_name: item.item_name, price: parseInt(removeNonNumeric(item.price)) }
      //   })
      // )
      var momFile = receipts.map((receipt) => {
        return new Promise((resolve, reject) => {
          StorageAPI.uploadFile(`sales-plan-documents/${id}/${receipt.file.name}`, receipt.file)
            .then((res) => {
              // resolve(`${res.metadata.bucket}/${res.metadata.fullPath}`)
              console.log(res)
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
      })

      Promise.all(momFile)
        .then((values) => {
          var temp = {
            sales_deal_activity_id: parseInt(id),
            project_id: salesPlan.project.id,
            client_response: clientResponse,
            change_status: changeStatus ? changeStatus : salesPlan.sales_deal.status,
            meeting_notes: meetingNotes ? meetingNotes : '',
            meeting_expenses: listExpenses.map((item, index) => {
              // console.log(item.receipt)
              return {
                item_name: item.item_name,
                price: parseInt(removeNonNumeric(item.price)),
                receipt: values[index],
              }
            }),
            revenue_estimation: parseInt(removeNonNumeric(revenueEstimation)),
            tags: selectedTags,
            ...client,
          }
          console.log(temp)
          SalesPlanAPI.saveMOM(temp)
            .then((res) => {
              if (res.success) {
                console.log(temp)
                navigate('/sales-plan')
                setLoadingSaveMomButton(false)
              } else {
                setErrorSaveMom('Failed saving Mom')
                setLoadingSaveMomButton(false)
              }
            })
            .catch((err) => {
              if (err.response.status === 403 && err.response.data.error_code === 'sales_deal_requirement_mismatch') {
                setErrorSaveMom(
                  `You can't update sales deal status to ${changeStatus.replace(
                    '_',
                    ' '
                  )}. Please complete all documents needed for ${changeStatus.replace('_', ' ')}`
                )
                setLoadingSaveMomButton(false)
              } else {
                setErrorSaveMom(err.response.data.message)
                setLoadingSaveMomButton(false)
              }
            })
        })
        .catch((err) => {
          setErrorSaveMom('Failed saving Mom')
          setLoadingSaveMomButton(false)
        })
    } else {
      setErrorSaveMom('Please fill all required field')
      setLoadingSaveMomButton(false)
    }
  }

  const handleFileUpload = (input) => {
    const objectUrl = URL.createObjectURL(input)
    setReceipt([...receipts, { file: input, blob: objectUrl }])
    setCurrentReceipts({ file: input, blob: objectUrl })
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!errorGetSalesPlan ? (
            <>
              <div className="">
                {/* <p>Sales Plan ID :{id}</p> */}
                <BreadCrumbs>
                  <BreadCrumbs.Item href="/sales-plan">Sales Plan</BreadCrumbs.Item>
                  <BreadCrumbs.Item current>{salesPlan.project.name}</BreadCrumbs.Item>
                </BreadCrumbs>
              </div>
              <div className="max-w-[850px]">
                <div className="mb-8">
                  <h1 className="text-giant mb-4 font-semibold">Add MoM</h1>
                  <div className="project-detail border border-gray-border rounded-[5px] p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="">
                      <p className="font-semibold mb-0.5">Value</p>
                      <p className="font-normal">Rp{formatThousands(salesPlan.project.value)}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Number of Meetings</p>
                      <p className="font-normal">{salesPlan.number_of_meetings}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-1">Sales Deal Status</p>
                      <div className="">
                        <Status size={'small'} type={salesPlan.sales_deal.status} />
                      </div>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Project Type</p>
                      <p className="font-normal">{salesPlan.project.type}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Start Date</p>
                      <p className="font-normal">
                        {moment(salesPlan.sales_deal.created_at).utc().format('D MMMM YYYY')}
                      </p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Total Meeting Expenses</p>
                      <p className="font-normal">{salesPlan.mom_status === 'empty' ? 'Belum ada meeting' : 'here'}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Account Owner</p>
                      <div className="font-normal flex gap-1 items-center">
                        <span className="w-6 h-6 rounded-full bg-slate-100 overflow-hidden">
                          <img src={salesPlan.account_owner.avatar} alt="avatar" className="w-full" />
                        </span>
                        <p>{salesPlan.account_owner.name}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Project Name</p>
                      <p className="font-normal">{salesPlan.project.name}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Expired Date</p>
                      <p className="font-normal">
                        {moment(salesPlan.sales_deal.expired_date).utc().format('D MMMM YYYY')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-red-500">
                    <strong>*</strong> means required to be filled
                  </p>
                </div>
                <div className="mb-8">
                  <h1 className="text-giant mb-4 font-semibold">PIC Client</h1>
                  <div className="border border-gray-border rounded-[5px] p-4">
                    <div className="w-full md:w-[400px]">
                      <p className="mb-4">Please enter the details of the PIC who attended the meeting.</p>
                      <Input
                        label={'PIC Name'}
                        placeholder="Name"
                        required
                        className={'mb-4'}
                        value={client.pic_name}
                        name={'pic_name'}
                        onChange={(e) => setClient({ ...client, pic_name: e.target.value })}
                      />
                      <Input
                        label={'PIC Position'}
                        placeholder="Position"
                        required
                        className={'mb-4'}
                        value={client.pic_title}
                        name={'pic_title'}
                        onChange={(e) => setClient({ ...client, pic_title: e.target.value })}
                      />
                      <Input
                        label={'PIC Phone Number'}
                        placeholder="Phone Number"
                        required
                        className={'mb-4'}
                        value={client.pic_phone_number}
                        name={'pic_phone_number'}
                        onChange={(e) => setClient({ ...client, pic_phone_number: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <div className="mb-4">
                    <h1 className="text-giant mb-1 font-semibold">MoM</h1>
                    <p className="font-semibold">{moment(salesPlan.meeting_date).utc().format('dddd, D MMMM YYYY')}</p>
                  </div>
                  <div className="border border-gray-border rounded-[5px] p-4 flex gap-8">
                    <div className="left w-full md:w-[400px]">
                      <p className="font-semibold mb-1">
                        Client Response<strong className="text-red-500">*</strong>
                      </p>
                      <div className="flex flex-col gap-2 mb-8">
                        <RadioButton
                          label="Interested"
                          name="interested"
                          id="interested"
                          value="interested"
                          onChange={(selected) => {
                            setClientResponse(selected.target.value)
                          }}
                          checked={clientResponse === 'interested'}
                        />
                        <RadioButton
                          label="Not Interested"
                          name="not-interested"
                          id="not-interested"
                          value="not_interested"
                          onChange={(selected) => {
                            setClientResponse(selected.target.value)
                          }}
                          checked={clientResponse === 'not_interested'}
                        />
                        <RadioButton
                          label="Need More Discussion"
                          name="need-more-discussion"
                          id="need-more-discussion"
                          value="need_more_discussion"
                          onChange={(selected) => {
                            setClientResponse(selected.target.value)
                          }}
                          checked={clientResponse === 'need_more_discussion'}
                        />
                      </div>
                      <div className="right flex-1 lg:hidden">
                        <Textarea
                          rows={10}
                          label={'Meeting Notes'}
                          required
                          value={meetingNotes}
                          onChange={(e) => setMeetingNotes(e.target.value)}
                          placeholder="Put meeting notes here..."
                        />
                      </div>
                      <Input
                        label="Revenue Estimation"
                        name="revenue_estimation"
                        placeholder="Revenue estimation"
                        value={revenueEstimation}
                        onChange={(e) => handleRevenueEstimation(e.target)}
                        className={'mb-4'}
                      />
                      <div className="mb-4">
                        <p className="font-semibold mb-1">Change Status</p>
                        <DropdownInput
                          data={[
                            { id: 0, text: 'Visit', value: 'visit' },
                            { id: 1, text: 'Leads', value: 'leads' },
                            { id: 2, text: 'Hot Prospect', value: 'hot_prospect' },
                            { id: 3, text: 'On Hands', value: 'on_hands' },
                            { id: 4, text: 'Revenue', value: 'revenue' },
                          ]}
                          placeholder="Change Status"
                          attributeShown="text"
                          defaultValue={salesPlan.sales_deal.status}
                          handleSelectedOption={(selected) => {
                            setChangeStatus(selected.value)
                          }}
                        />
                      </div>
                      {/* <div>
                        <TagsDropdown selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
                      </div> */}
                    </div>
                    <div className="right flex-1 hidden lg:block">
                      <Textarea
                        rows={10}
                        label={'Meeting Notes'}
                        required
                        value={meetingNotes}
                        onChange={(e) => setMeetingNotes(e.target.value)}
                        placeholder="Put meeting notes here..."
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mb-4">
                    <h1 className="text-giant mb-1 font-semibold">Meeting Expenses</h1>
                    <p className="font-semibold">{moment(salesPlan.meeting_date).utc().format('dddd, D MMMM YYYY')}</p>
                  </div>
                  <div className="border border-gray-border rounded-[5px] p-4 flex flex-col lg:flex-row gap-8">
                    {loadingItemAdd ? (
                      <Loading />
                    ) : (
                      <form className="left w-full md:w-[400px] flex flex-col gap-4 pr-4 lg:border-r">
                        <Input
                          type="text"
                          label="Item Name"
                          placeholder="Item Name"
                          onChange={(e) => setItemName(e.target.value)}
                          value={itemName}
                        />
                        <Input
                          label="Price"
                          placeholder="Price"
                          // type="number"
                          onChange={(e) => {
                            handlePriceChange(e.target)
                          }}
                          value={price}
                        />
                        <div className="">
                          <p className="font-semibold mb-1">Receipt or Proof</p>
                          <DragAndDropFile
                            className="text-small py-6"
                            onChange={(input) => {
                              handleFileUpload(input)
                            }}
                            value={currentReceipt ? currentReceipt.file : undefined}
                          />
                        </div>
                        <Button onClick={handleAddExpensesItem} className={'w-fit'} type="submit">
                          Add Item
                        </Button>
                        <Alert message="Meeting expenses are not recorded if Add Item button wasn't clicked" />
                      </form>
                    )}
                    <div className="right flex-1 hidden lg:block">
                      <Table cols={tableCols} data={listExpenses} />
                    </div>
                    <div className="right flex-1 lg:hidden">
                      <Table cols={tableColsMobile} data={listExpenses} tableType="inline" />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-8 gap-4">
                  {errorSaveMom && (
                    <div>
                      <Alert type={'error'} message={errorSaveMom} />
                    </div>
                  )}
                  <Button onClick={handleSave} isLoading={isLoadingSaveMomButton}>
                    Save MoM
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <ErrorPage type={404} />
          )}
        </>
      )}
    </div>
  )
}
