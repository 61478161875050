import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage, ref } from 'firebase/storage'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: 'AIzaSyDJ-ZMLad5-JtLIcVxFIeylRrQIBtVGLWc',
  authDomain: 'katadata-crm.firebaseapp.com',
  projectId: 'katadata-crm',
  storageBucket: 'katadata-crm.appspot.com',
  messagingSenderId: '970398600652',
  appId: '1:970398600652:web:4759cbc3e2bdf518936169',
  measurementId: 'G-4BW58Z28HE',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app)

const storage = getStorage(app)

const storageRef = (location) => ref(storage, location)

export { app, auth, storage, storageRef }
