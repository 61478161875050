import React, { useEffect, useState } from 'react'
import Item from './Item'
import { Droppable } from 'react-beautiful-dnd'
import {
  AccountBalance,
  AccountBalanceWallet,
  AddCircle,
  BusinessCenter,
  CalendarMonthRounded,
  Close,
  Money,
} from '@mui/icons-material'
import { ExitToApp } from '@mui/icons-material'
import GroupButtons from '../GroupButtons'
import DropdownInput from '../DropdownInput'
import Loading from '../Loading'
import Input from '../Input'
import Button from '../Button'
import Status from '../Status'
import ClientAPI from '../../apis/ClientAPI'
import { formatThousands } from '../../helper'
import Tooltip from '../Tooltip'

export default function Column({ col, statistics, loadMoreItem }) {
  const totalPage = Math.ceil(statistics.total_deal / 10)
  const [column, setColunm] = useState(col)
  const [isOpen, setIsOpen] = useState(false)
  const [isShowLoadButton, setShowLoadButton] = useState(totalPage > 1)
  const [currentPage, setCurrentPage] = useState(1)

  useState(() => {
    // console.log(statistics)
    // // const totalPage = Math.ceil(statistics.total_deal / 10)
    // console.log(totalPage)
  }, [])

  useState(() => {
    setColunm(col)
  }, [col])

  const handleLoadMore = () => {
    loadMoreItem(col.id.toLowerCase().replace(' ', '_'), currentPage + 1)
    // if (currentPage + 1 >= totalPage) setShowLoadButton(false)
    setCurrentPage(currentPage + 1)
  }

  return (
    <div className="h-full flex-1 flex flex-col min-w-[250px]">
      <div className="mb-4">
        <div className="py-2 px-4 mb-1 bg-sky-50 rounded border-b-[5px] border-main-500">
          <div className="mb-2 hidden lg:flex lg:gap-4 lg:items-center">
            <Status type={col.id.replace(' ', '_').toLowerCase()} />
            <Tooltip message={`${statistics.total_deal} projects`}>
              <span className="p-1 px-2.5 rounded-xl text-center bg-main-500 text-white font-semibold">
                {statistics.total_deal}
              </span>
            </Tooltip>
          </div>
          <div className="mb-2 lg:hidden">
            <Status type={col.id.replace(' ', '_').toLowerCase()} size={'small'} />
          </div>
          <div className="flex h-4 text-gray-text gap-4">
            {/* <div className="flex gap-2 items-center">
              <span className="w-4 h-4 flex items-center justify-center">
                <BusinessCenter sx={{ fontSize: 16 }} />
              </span>
              <p>{statistics.total_deal} projects</p>
            </div> */}
            <div className="flex gap-2 items-center">
              <span className="w-4 h-4 flex items-center justify-center">
                <AccountBalanceWallet sx={{ fontSize: 16 }} />
              </span>

              <p>Rp{formatThousands(statistics.total_revenue)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col gap-4 overflow-y-auto pr-1 scrollbar-thin scrollbar-track-sky-50 scrollbar-thumb-main-500 scrollbar-thumb-rounded scrollbar-track-rounded pb-20 lg:pb-0">
        <Droppable droppableId={col.id}>
          {(provided, snapshot) => {
            // console.log(snapshot)
            return (
              <div {...provided.droppableProps} ref={provided.innerRef} className="flex-1 flex flex-col gap-4">
                {col.list.map((salesDeal, index) => (
                  <Item key={index} salesDeal={salesDeal} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
        {isShowLoadButton && (
          <div>
            {currentPage < totalPage ? (
              <Button onClick={handleLoadMore} className={'w-full'}>
                Load More
              </Button>
            ) : (
              <Button className={'w-full'} disabled={true}>
                Load More
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
