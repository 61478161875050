import { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import { useParams, useNavigate } from 'react-router-dom'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import ErrorPage from '../../ErrorPage'
import usePermission from '../../hooks/usePermission'
import Alert from '../../components/Alert'

export default function EditCOGSItems() {
  let { id } = useParams()
  const [cogs, setCogs] = useState({
    id: null,
    name: '',
    description: '',
    cost: null,
  })
  const [isLoading, setLoading] = useState(true)
  const [title, setTitle] = useState('COGS Item')
  const [isLoadingSave, setLoadingSave] = useState(false)
  const [fieldsError, setFieldsError] = useState({
    name: false,
    description: false,
    cost: false,
  })
  const [errorSave, setErrorSave] = useState(false)
  const navigate = useNavigate()
  const { isAdmin } = usePermission()

  useEffect(() => {
    if (id != undefined) {
      setTitle('Edit ' + title)
      COGSitemAPI.getSingleCOGSitem(id).then((data) => {
        setCogs(data.data)
        setLoading(false)
      })
    } else {
      setTitle('Add New ' + title)
      setLoading(false)
    }
  }, [])

  function handleChange(e) {
    setCogs({
      ...cogs,
      [e.target.name]: e.target.value,
    })
  }

  function handleSave() {
    setLoadingSave(true)
    var allowSave = true
    var requiredKey = ['name', 'cost', 'description']

    requiredKey.forEach((key) => {
      if (!cogs[key]) {
        allowSave = false
        setFieldsError((fieldsError) => ({ ...fieldsError, [key]: true }))
      }
    })

    if (allowSave) {
      if (cogs.id != null) {
        COGSitemAPI.update(cogs)
          .then((data) => {
            navigate(-1)
          })
          .catch((err) => {
            if (err.response) {
              setErrorSave(err.response.data.message)
            } else {
              setErrorSave(err.statusText)
            }
            setLoadingSave(false)
          })
      } else {
        COGSitemAPI.create(cogs)
          .then((data) => {
            navigate(-1)
          })
          .catch((err) => {
            if (err.response) {
              setErrorSave(err.response.data.message)
            } else {
              setErrorSave(err.statusText)
            }
            setLoadingSave(false)
          })
      }
    } else {
      setErrorSave('Please fill the required fields')
      setLoadingSave(false)
    }
  }

  return (
    <div className="py-4">
      <BreadCrumbs className="mb-4">
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item href={'/data/cogs'}>COGS Items</BreadCrumbs.Item>
        <BreadCrumbs.Item current>{title}</BreadCrumbs.Item>
      </BreadCrumbs>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="">
            <h1 className="text-big font-semibold mb-2">{title}</h1>
            <div className="p-4 border rounded-md">
              <Input
                label={'Item Name'}
                value={cogs.name}
                name="name"
                error={fieldsError.name}
                placeholder="Item name"
                className="w-[270px] mb-4"
                onChange={handleChange}
              />
              <Input
                label={'Item Description'}
                value={cogs.description}
                name="description"
                error={fieldsError.description}
                placeholder="Item descrption"
                className="w-[270px] mb-4"
                onChange={handleChange}
              />
              <Input
                label={'Cost'}
                value={cogs.cost}
                name="cost"
                error={fieldsError.cost}
                placeholder="1000000"
                className="w-[270px] mb-4"
                onChange={handleChange}
              />
              <div className="flex gap-2">
                <Button onClick={handleSave} isLoading={isLoadingSave}>
                  Save
                </Button>
                {errorSave && <Alert type={'error'} message={errorSave} />}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
