import React, { forwardRef, useEffect, useState } from 'react'
import Column from './Column'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import SalesDealAPI from '../../apis/SalesDealAPI'
import { useNavigate } from 'react-router-dom'
import usePermission from '../../hooks/usePermission'

export default function DnD({ initialColumns, statistics, loadMoreItem, toastUpdateSalesDeal }) {
  const initialColumnsOld = {
    visit: {
      id: 'Visit',
      list: [
        {
          id: 57,
          created_at: '2023-05-07T22:16:55.000Z',
          last_updated: '2023-05-07T22:16:55.000Z',
          expired_date: null,
          created_by: 12,
          status: 'visit',
          sbu_id: 1,
          client_id: 7,
          total_revenue: 0,
          project_id: 57,
          client: {
            id: 7,
            name: 'Google Indonesia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-298.svg?alt=media&token=1dbdd33e-941b-4669-a849-a2917d592724',
          },
          project: {
            id: 57,
            name: 'Project Bersama Google',
            total_revenue: 0,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 56,
          created_at: '2023-05-07T22:08:39.000Z',
          last_updated: '2023-05-07T22:08:39.000Z',
          expired_date: null,
          created_by: 12,
          status: 'visit',
          sbu_id: 1,
          client_id: 7,
          total_revenue: 0,
          project_id: 56,
          client: {
            id: 7,
            name: 'Google Indonesia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-298.svg?alt=media&token=1dbdd33e-941b-4669-a849-a2917d592724',
          },
          project: {
            id: 56,
            name: 'Project Bersama Google',
            total_revenue: 0,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 55,
          created_at: '2023-05-07T22:08:06.000Z',
          last_updated: '2023-05-07T22:08:06.000Z',
          expired_date: null,
          created_by: 12,
          status: 'visit',
          sbu_id: 1,
          client_id: 7,
          total_revenue: 0,
          project_id: 55,
          client: {
            id: 7,
            name: 'Google Indonesia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-298.svg?alt=media&token=1dbdd33e-941b-4669-a849-a2917d592724',
          },
          project: {
            id: 55,
            name: 'test',
            total_revenue: 0,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 54,
          created_at: '2023-05-07T22:07:40.000Z',
          last_updated: '2023-05-07T22:07:40.000Z',
          expired_date: null,
          created_by: 12,
          status: 'visit',
          sbu_id: 1,
          client_id: 7,
          total_revenue: 0,
          project_id: 54,
          client: {
            id: 7,
            name: 'Google Indonesia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-298.svg?alt=media&token=1dbdd33e-941b-4669-a849-a2917d592724',
          },
          project: {
            id: 54,
            name: 'Project Bersama Google',
            total_revenue: 0,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 53,
          created_at: '2023-05-07T22:06:34.000Z',
          last_updated: '2023-05-07T22:06:34.000Z',
          expired_date: null,
          created_by: 12,
          status: 'visit',
          sbu_id: 1,
          client_id: 5,
          total_revenue: 0,
          project_id: 53,
          client: {
            id: 5,
            name: 'PT Antam',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-282.svg?alt=media&token=33f3ed18-e530-4386-b8e2-52ce09bbef57',
          },
          project: {
            id: 53,
            name: 'Project Bersama Google',
            total_revenue: 0,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 52,
          created_at: '2023-05-07T22:01:43.000Z',
          last_updated: '2023-05-07T22:01:43.000Z',
          expired_date: null,
          created_by: 12,
          status: 'visit',
          sbu_id: 1,
          client_id: 7,
          total_revenue: 0,
          project_id: 52,
          client: {
            id: 7,
            name: 'Google Indonesia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-298.svg?alt=media&token=1dbdd33e-941b-4669-a849-a2917d592724',
          },
          project: {
            id: 52,
            name: 'Project Bersama Google',
            total_revenue: 0,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 51,
          created_at: '2023-05-07T21:58:20.000Z',
          last_updated: '2023-05-07T21:58:20.000Z',
          expired_date: null,
          created_by: 12,
          status: 'visit',
          sbu_id: 1,
          client_id: 7,
          total_revenue: 0,
          project_id: 51,
          client: {
            id: 7,
            name: 'Google Indonesia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-298.svg?alt=media&token=1dbdd33e-941b-4669-a849-a2917d592724',
          },
          project: {
            id: 51,
            name: 'Project Bersama Google',
            total_revenue: 0,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 50,
          created_at: '2023-05-07T21:53:46.000Z',
          last_updated: '2023-05-07T21:53:46.000Z',
          expired_date: null,
          created_by: 5,
          status: 'visit',
          sbu_id: 1,
          client_id: 7,
          total_revenue: 0,
          project_id: 50,
          client: {
            id: 7,
            name: 'Google Indonesia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-298.svg?alt=media&token=1dbdd33e-941b-4669-a849-a2917d592724',
          },
          project: {
            id: 50,
            name: 'Project Bersama Google',
            total_revenue: 0,
          },
          account_owner: {
            id: 5,
            name: 'Gohan Parningotan',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 49,
          created_at: '2023-05-07T21:53:28.000Z',
          last_updated: '2023-05-07T21:53:28.000Z',
          expired_date: null,
          created_by: 5,
          status: 'visit',
          sbu_id: 1,
          client_id: 7,
          total_revenue: 0,
          project_id: 49,
          client: {
            id: 7,
            name: 'Google Indonesia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-298.svg?alt=media&token=1dbdd33e-941b-4669-a849-a2917d592724',
          },
          project: {
            id: 49,
            name: 'Project Bersama Google',
            total_revenue: 0,
          },
          account_owner: {
            id: 5,
            name: 'Gohan Parningotan',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 48,
          created_at: '2023-04-25T12:50:59.000Z',
          last_updated: '2023-04-25T12:50:59.000Z',
          expired_date: null,
          created_by: 5,
          status: 'visit',
          sbu_id: 1,
          client_id: 7,
          total_revenue: 0,
          project_id: 48,
          client: {
            id: 7,
            name: 'Google Indonesia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-298.svg?alt=media&token=1dbdd33e-941b-4669-a849-a2917d592724',
          },
          project: {
            id: 48,
            name: 'Project Bersama Google',
            total_revenue: 0,
          },
          account_owner: {
            id: 5,
            name: 'Gohan Parningotan',
            avatar: null,
          },
          number_of_meetings: 1,
        },
      ],
    },
    leads: {
      id: 'Leads',
      list: [
        {
          id: 1,
          created_at: '2023-04-08T15:54:29.000Z',
          last_updated: '2023-04-22T17:02:32.000Z',
          expired_date: '2023-12-30T00:00:00.000Z',
          created_by: 12,
          status: 'leads',
          sbu_id: 1,
          client_id: 1,
          total_revenue: 4550,
          project_id: 1,
          client: {
            id: 1,
            name: 'Dior',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-226.svg?alt=media&token=11a86d74-cc90-4534-b8b3-dcf960f4159a',
          },
          project: {
            id: 1,
            name: 'Promosi Tas Kolaborasi',
            total_revenue: 4550,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 2,
        },
        {
          id: 12,
          created_at: '2023-04-21T20:54:32.000Z',
          last_updated: '2023-04-21T20:57:18.000Z',
          expired_date: null,
          created_by: 12,
          status: 'leads',
          sbu_id: 1,
          client_id: 5,
          total_revenue: 0,
          project_id: 12,
          client: {
            id: 5,
            name: 'PT Antam',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-282.svg?alt=media&token=33f3ed18-e530-4386-b8e2-52ce09bbef57',
          },
          project: {
            id: 12,
            name: 'Project Tengah Tahun',
            total_revenue: 0,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 10,
          created_at: '2023-04-21T20:28:50.000Z',
          last_updated: '2023-04-21T20:34:35.000Z',
          expired_date: null,
          created_by: 12,
          status: 'leads',
          sbu_id: 1,
          client_id: 4,
          total_revenue: 0,
          project_id: 10,
          client: {
            id: 4,
            name: 'Tokopedia',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-250.svg?alt=media&token=add316e8-aa5a-41db-9f32-eaf1da122b7b',
          },
          project: {
            id: 10,
            name: 'Project Ramadan',
            total_revenue: 0,
          },
          account_owner: {
            id: 12,
            name: 'Ani Rahmani',
            avatar: null,
          },
          number_of_meetings: 1,
        },
        {
          id: 6,
          created_at: '2023-04-08T16:13:18.000Z',
          last_updated: '2023-04-08T16:14:46.000Z',
          expired_date: null,
          created_by: 13,
          status: 'leads',
          sbu_id: 2,
          client_id: 6,
          total_revenue: 4550,
          project_id: 6,
          client: {
            id: 6,
            name: 'PT Bukalapak',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-296.svg?alt=media&token=840350cb-20ce-46b2-830c-a4ad378a0580',
          },
          project: {
            id: 6,
            name: 'Riset metode pembayaran baru',
            total_revenue: 4550,
          },
          account_owner: {
            id: 13,
            name: 'Tuti Wijaya',
            avatar: null,
          },
          number_of_meetings: 1,
        },
      ],
    },
    hot_prospect: {
      id: 'Hot Prospect',
      list: [],
    },
    on_hands: {
      id: 'On Hands',
      list: [
        {
          id: 9,
          created_at: '2023-04-08T16:19:09.000Z',
          last_updated: '2023-04-18T21:38:35.000Z',
          expired_date: null,
          created_by: 14,
          status: 'on_hands',
          sbu_id: 3,
          client_id: 5,
          total_revenue: 4550,
          project_id: 9,
          client: {
            id: 5,
            name: 'PT Antam',
            logo: 'https://firebasestorage.googleapis.com/v0/b/katadata-crm.appspot.com/o/client-logo%2Flogoipsum-282.svg?alt=media&token=33f3ed18-e530-4386-b8e2-52ce09bbef57',
          },
          project: {
            id: 9,
            name: 'Campaign meningkatkan harga saham PT Antam',
            total_revenue: 4550,
          },
          account_owner: {
            id: 14,
            name: 'Budi Wahyudi',
            avatar: null,
          },
          number_of_meetings: 6,
        },
      ],
    },
    revenue: { id: 'Revenue', list: [] },
  }
  // const [displayedInitialColumns, setDisplayedInitialColumn] = useState(initialColumns)
  const [columns, setColumns] = useState(initialColumns)
  const navigate = useNavigate()
  const { isAdmin, isVP } = usePermission()

  useEffect(() => {
    setColumns(initialColumns)
    console.log(isAdmin || isVP)
    // console.log('here')
  }, [initialColumns])

  const onDragEnd = ({ source, destination }) => {
    // Make sure we have a valid destination
    console.log(source)
    if (destination === undefined || destination === null) return null

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index) return null

    // Set start and end variables
    const start = columns[source.droppableId.replace(' ', '_').toLowerCase()]
    const end = columns[destination.droppableId.replace(' ', '_').toLowerCase()]
    // If start is the same as end, we're in the same column
    if (isAdmin || isVP) {
      toastUpdateSalesDeal('error', 'Your Account don`t have permission to do update sales deal status.')
    } else if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter((item, index) => index !== source.index)

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index])

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      }
      // console.log(newCol.id.replace(' ', '_').toLowerCase())

      // Update the state
      setColumns((state) => ({ ...state, [newCol.id.replace(' ', '_').toLowerCase()]: newCol }))
      return null
    } else {
      var droppedSalesDeal = start.list[source.index]
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      SalesDealAPI.updateSalesDealStatus(droppedSalesDeal.id, destination.droppableId.replace(' ', '_').toLowerCase())
        .then((res) => {
          if (res.success) {
            const newStartList = start.list.filter((item, index) => index !== source.index)

            // Create a new start column
            const newStartCol = {
              id: start.id,
              list: newStartList,
            }

            // Make a new end list array
            const newEndList = end.list

            // Insert the item into the end list
            newEndList.splice(destination.index, 0, start.list[source.index])

            // Create a new end column
            const newEndCol = {
              id: end.id,
              list: newEndList,
            }

            // Update the state
            setColumns((state) => ({
              ...state,
              [newStartCol.id.replace(' ', '_').toLowerCase()]: newStartCol,
              [newEndCol.id.replace(' ', '_').toLowerCase()]: newEndCol,
            }))
            toastUpdateSalesDeal('success')
            return null
          } else {
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            toastUpdateSalesDeal('error')
          }
        })
        .finally(() => {
          // navigate(0)
        })
    }
  }
  useEffect(() => {
    // console.log(statistics)
  }, [])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex mx-auto w-full h-full gap-4 overflow-y-hidden overflow-x-scroll scrollbar-thin scrollbar-main-500">
        {Object.values(columns).map((col) => {
          return (
            <Column
              col={col}
              key={col.id}
              statistics={statistics[col.id.replace(' ', '_').toLowerCase()]}
              loadMoreItem={loadMoreItem}
            />
          )
        })}
      </div>
    </DragDropContext>
  )
}
