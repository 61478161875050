import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'
import { UserAuth } from './context/AuthContext'
import Navbar from './components/Navbar'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { signOut } from 'firebase/auth'
import { signOut as reduxSignout } from './redux/authSlice'
import { auth } from './apis/firebaseConfig'

const PrivateRoutes = ({ children, ...rest }) => {
  // const { user } = UserAuth()
  const firebaseUser = useSelector((state) => state.auth.firebaseUser)
  const userData = useSelector((state) => state.auth.userData)
  const dispatch = useDispatch()

  if (!firebaseUser || !userData) {
    signOut(auth)
    dispatch(reduxSignout())
    return <Navigate to={'/login'} />
  }

  return children
}

export default PrivateRoutes
