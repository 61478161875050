import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProjectDetail from './Projects/ProjectDetail'
import FlagshipDetail from './Projects/FlagshipDetail'

export default function ProjectRoutes() {
  return (
    <Routes>
      <Route path="s/:id" element={<ProjectDetail />} />
      <Route path="f/:id" element={<FlagshipDetail />} />
    </Routes>
  )
}
