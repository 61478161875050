import React, { forwardRef, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import withClickOutside from '../withClickOutside'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { AccountCircle, AccountCircleRounded, Logout, ManageAccounts } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const DropdownNavbar = forwardRef(function DropdownNavbar({ open, setOpen, props }, ref) {
  const [selected, setSelected] = useState()
  const { logout, children, className } = { ...props }

  // useEffect(() => {
  //   console.log(isOpen)
  // }, [isOpen])

  return (
    <div className={`relative text-left ${className}`} sref={ref}>
      <div>
        {/* <div onMouseOver={() => setOpen(!open)} onMouseOut={() => setOpen(!open)}> */}
        <button
          onClick={() => setOpen(!open)}
          className="text-whit flex items-center justify-center rounded hover:bg-main-400 hover:opacity-75 transition-all"
        >
          {children}
        </button>
      </div>
      {open && (
        <div className="absolute left-0 lg:left-auto lg:right-0 z-10 mt-2 w-[260px] max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-track-sky-50 scrollbar-thumb-main-500 scrollbar-thumb-rounded origin-top-left lg:origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1" role="none">
            <Link
              to="/profile"
              className="text-gray-700 min-w-[260px] px-4 py-2 text-sm flex items-center gap-2 hover:bg-main-50"
              onClick={() => {
                setOpen(false)
              }}
            >
              <span className="flex items-center justify-start w-6 h-6">
                <ManageAccounts sx={{ fontSize: 22 }} />
              </span>
              Profile
            </Link>
            <button
              className="text-gray-700 min-w-[260px] px-4 py-2 text-sm flex items-center gap-2 hover:bg-main-50"
              onClick={() => {
                setOpen(!open)
                logout()
              }}
            >
              <span className="flex items-center justify-start w-6 h-6">
                <Logout sx={{ fontSize: 22 }} />
              </span>
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  )
})

export default withClickOutside(DropdownNavbar)
