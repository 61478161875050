import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import Input from '../../components/Input'
import DropdownInput from '../../components/DropdownInput'
import InputDate from '../../components/InputDate'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Alert from '../../components/Alert'
import SBUAPI from '../../apis/SBUAPI'
import AccountAPI from '../../apis/AccountAPI'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export default function CreateAccount() {
  const [userData, setUserData] = useState({
    name: '',
    role: '',
    email: '',
    ae_seniority_level: '',
    sbu_id: '',
    password: '',
    dob: '',
    phone_number: '',
    avatar: '',
  })
  const [listRoles, setRoles] = useState([
    { id: 0, text: 'Account Executive', value: 'ae' },
    { id: 1, text: 'Sales Manager', value: 'sm' },
    { id: 2, text: 'Vice President', value: 'vp' },
    { id: 3, text: 'Admin', value: 'admin' },
  ])
  const [listLevel, setListLevel] = useState([
    {
      id: 0,
      text: 'Senior',
      value: 'senior',
    },
    {
      id: 2,
      text: 'Mid',
      value: 'mid',
    },
    {
      id: 1,
      text: 'Junior',
      value: 'junior',
    },
  ])
  const [listSbu, setListSBU] = useState([])
  const [error, setError] = useState()
  const [isShowPassword, setShowPassword] = useState(false)
  const [isLoadingSaveAccount, setLoadingSaveAccount] = useState(false)
  const [errorSaveLoading, setErrorSaveLoading] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    SBUAPI.getAllSBU()
      .then((res) => {
        setListSBU(res.data)
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response.data.message)
        } else {
          setError(err.statusText)
        }
      })
  }, [])

  useEffect(() => {
    console.log(userData)
  }, [userData])

  const handleChange = (input) => {
    if (input.name === 'name') {
      setUserData({
        ...userData,
        name: input.value,
        avatar: `https://ui-avatars.com/api/?name=${input.value.replace(' ', '+')}`,
      })
    } else {
      setUserData({ ...userData, [input.name]: input.value })
    }
  }
  const handleSave = (e) => {
    e.preventDefault()
    setLoadingSaveAccount(true)
    console.log(userData)
    var temp = { ...userData }
    let allowSave = true
    if (temp.role !== 'ae') delete temp.ae_seniority_level
    for (let key in temp) {
      if (temp[key].toString() === '') {
        allowSave = false
        break
      }
    }
    if (allowSave) {
      AccountAPI.createAccount(temp)
        .then((res) => {
          if (res.success) {
            navigate(-1)
          }
        })
        .catch((err) => {
          if (err.response) {
            setErrorSaveLoading(err.response.data.message)
          } else {
            setErrorSaveLoading(err.statusText)
          }
        })
    } else {
      setErrorSaveLoading('Lengkapi data user diatas!')
    }
    setLoadingSaveAccount(false)
  }

  return (
    <div className="py-4">
      <div className="mb-4">
        <BreadCrumbs>
          <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
          <BreadCrumbs.Item href={'/data/account-role'}>Account Roles</BreadCrumbs.Item>
          <BreadCrumbs.Item current>Create Account</BreadCrumbs.Item>
        </BreadCrumbs>
      </div>

      <div className="forms border rounded p-4 md:p-8">
        <form className="grid gap-4 max-w-[600px]" onSubmit={handleSave} autoComplete="off">
          <h1 className="text-giant font-bold mb-4">Tambah Akun Baru</h1>
          <Input name="name" onChange={(e) => handleChange(e.target)} label={'Nama'} />
          <Input name="email" onChange={(e) => handleChange(e.target)} label={'Email'} />
          <Input name="phone_number" onChange={(e) => handleChange(e.target)} label={'Phone Number'} />
          <InputDate
            handleSelectedOption={(date) => setUserData({ ...userData, dob: moment(date).format('YYYY-MM-DD') })}
            label="Birthday"
          />
          <div>
            <Input
              name="password"
              onChange={(e) => handleChange(e.target)}
              type={isShowPassword ? 'text' : 'password'}
              label={'Password'}
            />
            <Checkbox
              label={'Show password'}
              checked={isShowPassword}
              onChange={() => setShowPassword(!isShowPassword)}
            />
          </div>
          <div>
            <p className="font-semibold mb-1">Role</p>
            <DropdownInput
              data={listRoles}
              attributeShown="text"
              placeholder="Pilih Role"
              handleSelectedOption={(option) => {
                if (option.value !== 'ae') {
                  setUserData({ ...userData, role: option.value, ae_seniority_level: '' })
                } else {
                  setUserData({ ...userData, role: option.value })
                }
              }}
            />
          </div>
          {userData.role === 'ae' && (
            <div>
              <p className="font-semibold mb-1">Seniority Level</p>
              <DropdownInput
                data={listLevel}
                attributeShown="text"
                placeholder="Pilih Role"
                handleSelectedOption={(option) => setUserData({ ...userData, ae_seniority_level: option.value })}
              />
            </div>
          )}

          <div>
            <p className="font-semibold mb-1">SBU</p>
            <DropdownInput
              data={listSbu}
              attributeShown="sbu_name"
              placeholder="Pilih SBU"
              handleSelectedOption={(option) => setUserData({ ...userData, sbu_id: option.sbu_id })}
            />
          </div>
          <div className="flex gap-4 mt-4">
            <Button onClick={handleSave} type="submit" className={'w-fit '} isLoading={isLoadingSaveAccount}>
              Create Account
            </Button>
            {errorSaveLoading && <Alert type={'error'} message={errorSaveLoading} />}
          </div>
        </form>
      </div>
    </div>
  )
}
