import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import GroupButtons from '../../components/GroupButtons'
import DropdownInput from '../../components/DropdownInput'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import { ProductsAPI } from '../../apis/ProductsAPI'
import LogoCell from '../../components/table/cell/LogoCell'
import Basic from '../../components/table/cell/Basic'
import ActionCell from '../../components/table/cell/ActionCell'
import { formatThousands } from '../../helper'
import { COGSitemAPI } from '../../apis/COGSitemsAPI'
import { Delete } from '@mui/icons-material'
import CustomTextDropdownInput from '../../components/CustomTextDropdownInput'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import usePermission from '../../hooks/usePermission'
import ErrorPage from '../../ErrorPage'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'
import Alert from '../../components/Alert'

export default function CreateFlagship() {
  const [user, setUser] = useLocalStorage('user', {})
  const [project, setProject] = useState({
    name: '',
    status: '',
    description: '',
    review_note: null,
    total_revenue: 0,
    total_cogs: 0,
    type: 'flagship',
    revenue_items: [],
    cogs_items: [],
  })
  const [selectedProduct, setSelectedProduct] = useState()
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])
  const [isLoadingSaveProject, setLoadingSaveProject] = useState(false)
  const [errorSaveProject, setErrorSaveProject] = useState()
  const navigate = useNavigate()
  const { isAdmin } = usePermission()

  useEffect(() => {
    console.log(project)
  }, [project])

  const handleStatusChange = (selected) => {
    var temp = { ...project, status: selected.value }
    setProject(temp)
  }

  const handleChange = (input) => {
    setProject({ ...project, [input.name]: input.value })
  }

  const saveproject = () => {
    setLoadingSaveProject(true)
    if (project.revenue_items.length > 0) {
      var tempObject = {
        name: project.name,
        description: project.description,
        status: project.status,
        revenue_items: project.revenue_items.map((item) => {
          return {
            id: item.id,
            product_id: item.product_id,
            sub_product_id: item.sub_product_id,
            product_variant_id: item.product_variant_id,
            price: item.price,
            quantity: item.quantity,
            discount_type: item.discount_type,
            discount_percentage: item.discount_percentage,
            discount_absolute: item.discount_absolute,
          }
        }),
        cogs_items: project.cogs_items.map((item) => {
          return {
            revenue_items_id: item.project_revenue_item_id,
            cogs_id: item.cogs_id,
            cost: item.cost,
            quantity: item.quantity,
            custom_name: '',
          }
        }),
      }
      // console.log(tempObject)
      ProjectAPI.createNewFlagshipProject(tempObject)
        .then((res) => {
          console.log(res)
          if (res.success) navigate('/projects/flagship')
        })
        .catch((err) => {
          if (err.response) {
            setErrorSaveProject(err.response.data.message)
            setLoadingSaveProject(false)
          } else {
            setErrorSaveProject(err.message)

            setLoadingSaveProject(false)
          }
        })
    } else {
      setErrorSaveProject("Can't create flaghip project with no revenue item.")
      setLoadingSaveProject(false)
    }
  }

  if (!isAdmin) {
    return <ErrorPage type={403} />
  }

  return (
    <div className="py-4">
      <>
        <div className="mb-4">
          <BreadCrumbs>
            <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
            <BreadCrumbs.Item current>Create Flagship Project</BreadCrumbs.Item>
          </BreadCrumbs>
        </div>
        <div className="mb-12">
          <h1 className="font-semibold text-giant mb-4">Project Information</h1>
          <div className="border border-gray-border rounded-[5px] p-4">
            <div className="max-w-[500px]">
              <Input
                label={'Project Name'}
                onChange={(e) => handleChange(e.target)}
                name="name"
                className={'mb-4'}
                placeholder="Project Name"
                value={project.name}
              />
              <Textarea
                label={'Description'}
                onChange={(e) => handleChange(e.target)}
                name="description"
                className={'mb-4'}
                rows={5}
                value={project.description}
              />

              <p className="mb-1 font-medium">Status</p>
              <DropdownInput
                data={[
                  { id: 'active', text: 'Active', value: 'active' },
                  { id: 'inactive', text: 'Inctive', value: 'inactive' },
                ]}
                attributeShown="text"
                defaultValue={project.status}
                handleSelectedOption={(selected) => {
                  handleStatusChange(selected)
                }}
                placeholder={'Choose status'}
              />
            </div>
          </div>
        </div>
        <RevenueItemsTable
          project={project}
          setProject={setProject}
          projectTypeTemp={'custom'}
          isEditable={isAdmin}
          isFlagshipPages={isAdmin}
        />
        <COGSItemsTable project={project} setProject={setProject} projectTypeTemp={'custom'} isEditable={isAdmin} />
        <div className="mb-12">
          <h1 className="text-giant mb-4 font-semibold">Margin</h1>
          <div className="border border-gray-border rounded-[5px] p-4">
            {/* <div className="w-[360px] ">
                <div className="px-2.5 py-4 flex gap-8 border-b">
                  <p className="flex-1 font-semibold">Total Revenue</p>
                  <p className="flex-1 font-semibold text-right">Rp{formatThousands(project.total_revenue)}</p>
                </div>
                <div className="px-2.5 py-4 flex gap-8 border-b">
                  <p className="flex-1 font-semibold">Total COGS</p>
                  <p className="flex-1 font-semibold text-right">Rp{formatThousands(project.total_cogs)}</p>
                </div>
                <div className="px-2.5 py-4 flex gap-8 rounded-[5px] bg-main-500 text-white">
                  <p className="flex-1 font-semibold">Margin</p>
                  <p className="flex-1 font-semibold text-right">{countMargin()}</p>
                </div>
              </div> */}
            <Table
              tableType="inline"
              noIndex={true}
              cols={marginCols}
              data={[
                {
                  id: 0,
                  row: 'Revenue',
                  totalAmount: project.total_revenue,
                  percentage: project.total_revenue !== 0 ? 1 - project.total_cogs / project.total_revenue : 0,
                },
                {
                  id: 1,
                  row: 'COGS',
                  totalAmount: project.total_cogs,
                  percentage:
                    project.total_revenue !== 0
                      ? project.total_cogs / project.total_revenue
                      : project.total_cogs
                      ? 1
                      : 0,
                },
              ]}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4 mb-12">
          {errorSaveProject && <Alert type={'error'} message={errorSaveProject} />}
          <Button onClick={saveproject} isLoading={isLoadingSaveProject}>
            Save Project
          </Button>
        </div>
      </>
    </div>
  )
}
