import React from 'react'
import { Route, Router, Routes } from 'react-router-dom'
import Product from './Data/Product'
import Clients from './Data/Clients'
import EditClient from './Data/EditClient'
import COGSItems from './Data/COGSItems'
import EditCOGSItems from './Data/EditCOGSItems'
import Reimbursement from './Data/Reimbursement'
import ReimbursementReview from './Data/ReimbursementReview'
import ReimbursementDetails from './Data/ReimbursementDetails'
import usePermission from '../hooks/usePermission'
import ErrorPage from '../ErrorPage'
import AccountRoles from './Data/AccountRoles'
import EditSBU from './Data/EditSBU'
import EditSM from './Data/EditSM'
import EditAE from './Data/EditAE'
import Target from './Data/Target'
import CreateAccount from './Data/CreateAccount'
import InvoiceRequest from './Data/InvoiceRequest'
import InvoiceDetail from './Data/InvoiceDetail'
import OtherData from './Data/OtherData'
import SBU from './Data/SBU'
import EditAgency from './Data/EditAgency'
import Agency from './Data/Agency'
import EditOtherSBU from './Data/EditOtherSBU'
import EditTarget from './Data/EditTarget'

export default function DataRoutes() {
  const { isAdmin } = usePermission()
  return (
    <Routes>
      <Route path="/products/*" element={<Product />} />
      <Route path="/clients/add" element={isAdmin ? <EditClient /> : <ErrorPage type={403} />} />
      <Route path="/clients/:id/edit" element={<EditClient />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/cogs" element={<COGSItems />} />
      <Route path="/cogs/:id/edit" element={isAdmin ? <EditCOGSItems /> : <ErrorPage type={403} />} />
      <Route path="/cogs/add" element={isAdmin ? <EditCOGSItems /> : <ErrorPage type={403} />} />
      <Route path="/account-role/sbu/:id" element={isAdmin ? <EditSBU /> : <ErrorPage type={403} />} />
      <Route path="/account-role/sm/:id" element={isAdmin ? <EditSM /> : <ErrorPage type={403} />} />
      <Route path="/account-role/ae/:id" element={isAdmin ? <EditAE /> : <ErrorPage type={403} />} />
      <Route path="/account-role/create-account" element={isAdmin ? <CreateAccount /> : <ErrorPage type={403} />} />
      <Route path="/account-role/" element={<AccountRoles />} />
      <Route path="/target/edit/:year" element={isAdmin ? <EditTarget /> : <ErrorPage type={403} />} />
      <Route path="/target/add" element={isAdmin ? <EditTarget /> : <ErrorPage type={403} />} />
      <Route path="/target" element={<Target />} />
      <Route path="/reimbursement/" element={<Reimbursement />} />
      <Route path="/reimbursement/:id/review" element={<ReimbursementReview />} />
      <Route path="/reimbursement/:id/" element={<ReimbursementDetails />} />
      <Route path="/invoice-requests/:mode/:id" element={<InvoiceDetail />} />
      <Route path="/invoice-requests/" element={<InvoiceRequest />} />
      <Route path="/other-data/sbu/:id" element={isAdmin ? <EditOtherSBU /> : <ErrorPage type={403} />} />
      <Route path="/other-data/sbu" element={<SBU />} />
      <Route path="/other-data/agency/:id/edit" element={isAdmin ? <EditAgency /> : <ErrorPage type={403} />} />
      <Route path="/other-data/agency/add" element={isAdmin ? <EditAgency /> : <ErrorPage type={403} />} />
      <Route path="/other-data/" element={<Agency />} />
      <Route path="*" element={<ErrorPage type={404} />} />
    </Routes>
  )
}
