import React, { useEffect, useState } from 'react'

export default function GroupButtons({ data, defaultValue, className, size, icons }) {
  const [active, setActive] = useState()

  useEffect(() => {
    if (defaultValue) {
      setActive(defaultValue)
    } else setActive(data[0].id)
    // console.log(data.length)
  }, [])
  if (icons) {
    return (
      <div className={`border-2 border-main-500 rounded overflow-hidden bg-main-500 flex md:inline-block ${className}`}>
        {data.map((button, index) => {
          // console.log(index === 2)
          return (
            <button
              key={button.id}
              onClick={() => {
                setActive(button.id)
                button.onClick()
              }}
              className={`font-semibold transition-all ${
                size === 'small' ? 'h-8 w-10' : 'md:h-16 md:w-12 text-normal'
              } ${active === button.id ? 'bg-main-500 text-white ' : 'bg-white text-main-500'} ${
                index !== 0 && index !== data.length - 1 ? 'border-x-2 border-main-500' : null
              }`}
            >
              {button.text}
            </button>
          )
        })}
      </div>
    )
  }

  return (
    <div
      className={`w-full md:w-fit flex border-2 border-main-500 rounded md:inline-block overflow-hidden bg-main-500 ${className}`}
    >
      {data.map((button, index) => {
        // console.log(index === 2)
        return (
          <button
            key={button.id}
            onClick={() => {
              setActive(button.id)
              button.onClick()
            }}
            className={`font-semibold transition-all md:min-w-[100px] ${
              size === 'small' ? 'px-4 py-0.5 text-small' : 'flex-1 py-3 md:px-8 md:py-3 text-normal'
            } ${active === button.id ? 'bg-main-500 text-white ' : 'bg-white text-main-500'} ${
              index !== 0 && index !== data.length - 1 ? 'border-x-2 border-main-500' : null
            }`}
          >
            {button.text}
          </button>
        )
      })}
    </div>
  )
}
