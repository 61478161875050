import React, { useEffect, useState } from 'react'
import { UserAuth } from '../context/AuthContext'
import SalesPlanAPI from '../apis/SalesPlanAPI'
import Table from '../components/table/Table'
import Loading from '../components/Loading'
import Dnd from '../components/dnd_sales_plan/DnD'
import DropdownUser from '../components/DropdownUser'
import DropdownWeeks from '../components/DropdownWeeks'
import { addDays, getWeek, getYear, startOfWeek } from 'date-fns'
import moment from 'moment'
import SBUAPI from '../apis/SBUAPI'
import AccountAPI from '../apis/AccountAPI'
import ClientAPI from '../apis/ClientAPI'
import usePermission from '../hooks/usePermission'
import DropdownFilterTags from '../components/DropdownFilterTags'
import { useCustomSearchParams } from '../hooks/useCustomSearchParams'

const getListDateOfWeek = (date) => {
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  var res = {}
  var mondayOfTheWeek = startOfWeek(new Date(date), { weekStartsOn: 1 })
  for (let i = 0; i < days.length; i++) {
    res = { ...res, [days[i]]: addDays(new Date(mondayOfTheWeek), i) }
  }
  console.log(res)
}

const createDNDData = (data, date) => {
  console.log(data)
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  var mondayOfTheWeek = startOfWeek(new Date(date))
  var res = {}
  for (let i = 1; i < days.length + 1; i++) {
    var temp = data.filter((item, index) => {
      // console.log(
      //   moment(addDays(new Date(mondayOfTheWeek), i))
      //     .format('dddd')
      //     .toLowerCase()
      // )
      // console.log(moment(item.meeting_date).utc().format('MM-DD-YYYY'))
      // console.log(moment(addDays(new Date(mondayOfTheWeek), i)).format('MM-DD-YYYY'))
      if (
        item.day ===
        moment(addDays(new Date(mondayOfTheWeek), i))
          .format('dddd')
          .toLowerCase()
      ) {
        return item
      }
    })
    res = {
      ...res,
      [days[i - 1]]: {
        id: moment(addDays(new Date(mondayOfTheWeek), i)).format('D MMMM YYYY'),
        list: temp,
      },
    }
  }
  console.log(res)
  return res
}

export default function SalesPlan() {
  const { isAE } = usePermission()
  const [cols, setCols] = useState([
    {
      id: 0,
      name: 'Project',
      selector: (row) => row.project.name,
    },
    {
      id: 1,
      name: 'Mom Status',
      selector: (row) => row.mom_status,
    },
    {
      id: 2,
      name: 'Client Name',
      // classes: 'text-center',
      selector: (row) => row.client.name,
    },
    {
      id: 3,
      name: 'Project Value',
      selector: (row) => row.project_value,
    },
  ])
  const [clients, setClients] = useState()
  const [selectedClient, setSelectedClient] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const [salesPlan, setSalesPlan] = useState()
  const [listAE, setListAE] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [refreshDOM, setRefreshDOM] = useState()
  const [isLoadingFilterData, setLoadingFilterData] = useState(true)
  const [searchParams, setSearchParams] = useCustomSearchParams()
  // const [listDate, setListDate] = useState(getListDateOfWeek(new Date()))

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        AccountAPI.getListSimple('ae').then((res) => {
          setListAE([{ id: '', name: 'All AE' }, ...res.data])
          resolve()
        })
      }),
      new Promise((resolve, reject) => {
        ClientAPI.getClientSimple().then((res) => {
          setClients([{ id: '', company_name: 'All Clients' }, ...res.data])
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoadingFilterData(false)
    })
  }, [])

  useEffect(() => {
    if (!searchParams.date) setSearchParams({ ...searchParams, date: moment().format('MM-DD-YYYY') })
  }, [])

  useEffect(() => {
    setLoading(true)
    if (!isLoadingFilterData) {
      if (searchParams.date) {
        // setListDate(getListDateOfWeek(selectedWeek))
        SalesPlanAPI.getListOfSalesPlan(
          getYear(new Date(moment(searchParams.date).format())),
          getWeek(new Date(moment(searchParams.date).format())),
          searchParams.ae,
          // selectedTags.toString()
          searchParams.tags?.replaceAll(' ', ',') || ''
        ).then((res) => {
          setSalesPlan(createDNDData(res.data, searchParams.date))
          setLoading(false)
        })
      } else {
        SalesPlanAPI.getListOfSalesPlan(
          getYear(new Date()),
          getWeek(new Date()),
          searchParams.ae,
          // selectedTags.toString()
          searchParams.tags?.replaceAll(' ', ',') || ''
        ).then((res) => {
          // console.log(res.data)
          setSalesPlan(createDNDData(res.data, new Date()))
          setLoading(false)
        })
      }
    }
  }, [searchParams.date, refreshDOM, searchParams.ae, isLoadingFilterData, searchParams.tags])

  const handleDeleteItem = (id) => {
    SalesPlanAPI.deleteSalesPlan(id).then((res) => {
      console.log(res.data)
    })
  }
  return (
    <div className="h-[calc(100vh-56px)] overflow-hidden py-2 lg:px-8 lg:py-8">
      <div className="filter mb-4 flex gap-4">
        <DropdownWeeks
          handleSelectedOption={(selected) => {
            setSearchParams({ ...searchParams, date: moment(selected).format('MM-DD-YYYY') })
          }}
          value={moment(searchParams.date).format()}
        />
        {/* <DropdownUser
          data={clients}
          withSearch
          noPic
          type="Client"
          handleSelectedOption={(selected) => setSelectedClient(selected)}
          attributeShown="company_name"
        /> */}
        {!isAE && (
          <DropdownUser
            data={listAE}
            noPic
            withSearch
            type="Account Executive"
            value={listAE.find((ae) => ae.id == searchParams.ae)}
            handleSelectedOption={(selected) => setSearchParams({ ...searchParams, ae: selected.id })}
            attributeShown="name"
          />
        )}
        <DropdownFilterTags
          withSearch
          value={searchParams?.tags ? searchParams.tags.split(' ') : []}
          handleSelectedOption={(selected) =>
            setSearchParams({ ...searchParams, page: 1, tags: selected.map((tag) => tag).join(' ') })
          }
          attributeShown="value"
        />
      </div>
      <div className="h-[calc(100%-72px)]">
        {isLoading ? (
          <Loading />
        ) : salesPlan ? (
          <Dnd data="test" initialColumns={salesPlan} setRefreshDOM={setRefreshDOM} />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  )
}
