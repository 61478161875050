import moment from 'moment'
import { devAxios } from './axiosConfig'
import StorageAPI from './storageAPI'

const AccountAPI = {
  createAccount: async (data) => {
    var res = await devAxios.post('accounts', data)
    return res.data
  },
  getList: async (role = undefined) => {
    var query = ''

    if (role != undefined) {
      query = `?role=${role}`
    }
    const res = await devAxios.get(`/accounts${query}`)
    return res.data
  },
  getListSimple: async (role = undefined) => {
    var query = {
      role: role,
    }
    var res = await devAxios.get('/accounts/simple', { params: query })
    return res.data
  },

  getSingleAccount: async (id) => {
    var res = await devAxios.get(`accounts`, {
      params: {
        id: id,
      },
    })
    return res.data
  },

  getSingleAccountByUID: async (uid) => {
    var res = await devAxios.get(`accounts/${uid}`)
    return res.data
  },

  updateProfile: async (id, data, changeDp = false) => {
    console.log(data.avatar)
    var temp = { ...data }
    var temp2 = { ...temp, dob: moment(temp.dob).format('YYYY-MM-DD') }
    if (changeDp) {
      var blob = await fetch(temp.avatar).then((r) => r.blob())
      var upload = await StorageAPI.uploadFile(`avatar/${Math.random() * 10000000}.png`, blob).then((res) => {
        return res
      })
      temp2['avatar'] = upload
    }

    console.log(temp2)
    var res = await devAxios.put(`accounts/${id}`, temp2)
    return res.data
  },

  updateAEAccount: async (data) => {
    var res = await devAxios.put('accounts/role/update-ae', data)
    return res.data
  },

  updateSMAccount: async (data) => {
    var res = await devAxios.put('accounts/role/update-sm', data)
    return res.data
  },

  updateVPAccount: async (data) => {
    var res = await devAxios.put('accounts/role/update-vp', data)
    return res.data
  },
}

export default AccountAPI
