import { Info } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'

export default function Alert({ type, message, className }) {
  const [displayedMessage, setDisplayedMessage] = useState(message)

  useEffect(() => {
    setDisplayedMessage(message)
  }, [message])

  // useEffect(() => {
  //   console.log(displayedMessage)
  // }, [displayedMessage])

  if (type === 'error') {
    return (
      <div
        className={`p-2.5 rounded bg-red-100 border border-red-500 text-red-500 flex items-center gap-2.5 ${className}`}
      >
        <span className="flex items-center justify-center w-4 h-4">
          <Info sx={{ fontSize: 16 }} />
        </span>
        <p className="flex-1">{displayedMessage}</p>
      </div>
    )
  } else if (type === 'success') {
    return (
      <div
        className={`p-2.5 rounded bg-green-100 border border-green-500 text-green-500 flex items-center gap-2.5 ${className}`}
      >
        <span className="flex items-center justify-center w-4 h-4">
          <Info sx={{ fontSize: 16 }} />
        </span>
        <p className="flex-1">{displayedMessage}</p>
      </div>
    )
  } else if (type === 'info') {
    return (
      <div
        className={`p-2.5 rounded bg-blue-100 border border-blue-500 text-blue-500 flex items-center gap-2.5 ${className}`}
      >
        <span className="flex items-center justify-center w-4 h-4">
          <Info sx={{ fontSize: 16 }} />
        </span>
        <p className="flex-1">{displayedMessage}</p>
      </div>
    )
  }
  return (
    <div
      className={`p-2.5 rounded bg-yellow-100 border border-yellow-500 text-main-500 flex items-center gap-2.5 ${className}`}
    >
      <span className="flex items-center justify-center w-4 h-4">
        <Info sx={{ fontSize: 16 }} />
      </span>
      <p className="flex-1">{displayedMessage}</p>
    </div>
  )
}
