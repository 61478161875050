import {
  AccountBalance,
  AccountBalanceWallet,
  AttachFile,
  CalendarMonthRounded,
  Close,
  DescriptionOutlined,
  Group,
} from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { dotsLimiter, formatThousands } from '../../helper'
import Status from '../Status'
import Button from '../Button'
import Loading from '../Loading'
import GroupButtons from '../GroupButtons'
import DropdownInput from '../DropdownInput'
import Input from '../Input'
import SalesPlanAPI from '../../apis/SalesPlanAPI'
import moment from 'moment'
import { Link } from 'react-router-dom'

export default function Item({ salesDeal, index }) {
  return (
    <Draggable draggableId={`${salesDeal.id}`} index={index}>
      {(provided) => (
        <div
          className="rounded p-4 border-2 border-gray-border shadow bg-white hover:bg-sky-50 transition-all"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Link to={`${salesDeal.id}`} className="flex flex-col">
            <div className="flex-1">
              <p className="text-big mb-4 font-bold">{salesDeal.client.name}</p>
              <div className="mb-4">
                <div className="flex gap-2 items-center text-gray-text mb-2">
                  <DescriptionOutlined sx={{ fontSize: 18 }} />
                  <p className="">{dotsLimiter(salesDeal.project.name, 30)}</p>
                </div>
                <div className="flex gap-2 items-center text-gray-text mb-2">
                  <AccountBalanceWallet sx={{ fontSize: 18 }} />
                  <p>Rp{formatThousands(salesDeal.total_revenue)}</p>
                </div>
                <div className="flex gap-2 items-center text-gray-text mb-2">
                  <Group sx={{ fontSize: 18 }} />
                  <p>
                    {salesDeal.number_of_meetings} {salesDeal.number_of_meetings > 1 ? 'meetings' : 'meeting'}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between z-0 text-gray-text">
              <p className="text-small">{moment(salesDeal.last_updated).fromNow()}</p>
              <span className="w-6 h-6 rounded-full bg-gray-300 overflow-hidden">
                <img src={salesDeal.account_owner.avatar} />
              </span>
              {/* <Status size={'small'} type={salesDeal.status} /> */}
              {/* {salesDeal.mom_status === 'filled' ? (
                <Button
                  variant="outlined"
                  size="small"
                  className={
                    'py-[4px] text-tiny bg-primary-green text-white border-0 font-normal hover:bg-primary-green hover:text-white'
                  }
                  // onClick={() => window.alert('click')}
                  disabled
                  icon={<AttachFile sx={{ fontSize: 12 }} />}
                >
                  Add MoM
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  className={'py-[4px] text-tiny'}
                  onClick={() => window.alert('click')}
                  icon={<AttachFile sx={{ fontSize: 12 }} />}
                >
                  Add MoM
                </Button>
              )} */}
            </div>
          </Link>
        </div>
      )}
    </Draggable>
  )
}
