import React, { useEffect, useState } from 'react'
import usePermission from '../hooks/usePermission'
import CustomTextDropdownInput from './CustomTextDropdownInput'
import Button from './Button'
import Input from './Input'
import { formatThousands } from '../helper'
import { Delete } from '@mui/icons-material'
import { ProductsAPI } from '../apis/ProductsAPI'
import Basic from './table/cell/Basic'
import DiscountInput from './DiscountInput'

const reCountTotal = (revenue_items) => {
  var total = 0
  revenue_items.map((item) => {
    if (item.discount_type === 'absolute') {
      total += parseInt(item.price) * parseInt(item.quantity) - item.discount_absolute
    } else
      total += parseInt(item.price) * parseInt(item.quantity) * (1 - parseFloat(item.discount_percentage).toFixed(3))
  })
  console.log(total)
  return total
}

export default function RevenueItemsTable({ project, setProject, projectTypeTemp, isEditable, isFlagshipPages }) {
  const { isAE } = usePermission()
  const [listProducts, setListProduct] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const [listVariantProduct, setListVariantProduct] = useState([])
  const [selectedVariant, setSelectedVariant] = useState()
  const [isLoadingVariant, setLoadingVariant] = useState(false)
  const [totalRev, setTotalRev] = useState(reCountTotal(project.revenue_items))
  const [revenueCols, setRevenueCols] = useState([
    {
      id: 0,
      name: 'Product',
      classes: 'min-w-[200px]',
    },
    {
      id: 1,
      name: 'Variant',
      classes: 'w-[320px]',
    },
    {
      id: 2,
      name: 'Price',
      classes: 'text-right min-w-[200px]',
    },
    {
      id: 3,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
    },
    {
      id: 4,
      name: 'Discount',
      classes: 'text-right w-[250px]',
    },
    {
      id: 5,
      name: 'Total Price',
      classes: 'text-right min-w-[200px]',
    },
  ])

  useEffect(() => {
    if (isEditable || isFlagshipPages) {
      setRevenueCols([
        ...revenueCols,
        {
          id: 6,
          name: 'Action',
          classes: 'text-center w-20',
        },
      ])
    }
  }, [])

  useEffect(() => {
    // console.log('test')
    // console.log([
    //   {
    //     id: 0,
    //     name: 'Product',
    //     classes: 'min-w-[200px]',
    //   },
    //   {
    //     id: 1,
    //     name: 'Variant',
    //     classes: 'w-[320px]',
    //   },
    //   {
    //     id: 2,
    //     name: 'Price',
    //     classes: 'text-right min-w-[200px]',
    //   },
    //   {
    //     id: 3,
    //     name: 'Quantity',
    //     classes: 'text-right w-[100px]',
    //   },
    //   {
    //     id: 4,
    //     name: 'Discount',
    //     classes: 'text-right',
    //   },
    //   {
    //     id: 5,
    //     name: 'Total Price',
    //     classes: 'text-right min-w-[200px]',
    //   },
    //   isEditable && {
    //     id: 6,
    //     name: 'Action',
    //     classes: 'text-center w-20',
    //   },
    // ])
  }, [])

  useEffect(() => {
    if (project) {
      if (project.type === 'standard') {
        new Promise((resolve, reject) => {
          ProductsAPI.getProductOnly('standard').then((res) => {
            setListProduct(res.data)
            resolve()
          })
        })
      } else {
        new Promise((resolve, reject) => {
          ProductsAPI.getProductOnly().then((res) => {
            setListProduct(res.data)
            resolve()
          })
        })
      }
    } else {
      new Promise((resolve, reject) => {
        ProductsAPI.getProductOnly().then((res) => {
          setListProduct(res.data)
          resolve()
        })
      })
    }
  }, [projectTypeTemp])

  useEffect(() => {
    setLoadingVariant(true)
    if (selectedProduct) {
      if (projectTypeTemp === 'standard') {
        ProductsAPI.getVariantByProductId(selectedProduct.id, 'standard').then((res) => {
          setListVariantProduct(res.data)
        })
      } else {
        ProductsAPI.getVariantByProductId(selectedProduct.id).then((res) => {
          setListVariantProduct(res.data)
        })
      }
    }
  }, [selectedProduct])

  const handleAddRevenueItems = () => {
    var objectTemp = {
      id: isFlagshipPages ? Math.random() * 1000000 : null,
      product_id: selectedVariant.product_id,
      sub_product_id: selectedVariant.sub_product_id,
      product_variant_id: selectedVariant.product_variant_id,
      price: selectedVariant.price,
      quantity: 1,
      discount_type: 'percentage',
      discount_absolute: 0,
      discount_percentage: 0,
      product_name: selectedProduct.name,
      sub_product_name: selectedVariant.sub_product_name,
      sub_product_type: selectedVariant.sub_product_type,
      price_type: selectedVariant.price_type,
      cogs: selectedVariant.cogs,
      product_variant_name: selectedVariant.product_variant_name,
    }
    var temp = project.revenue_items
    const index = temp.findIndex((rev_item) => {
      return (
        rev_item.product_variant_id === objectTemp.product_variant_id &&
        rev_item.sub_product_id === objectTemp.sub_product_id &&
        rev_item.product_id === objectTemp.product_id
      )
    })
    if (index > -1) {
      temp[index].quantity += 1
      // console.log(temp[index].quantity)
    } else {
      temp.push(objectTemp)
    }
    // console.log({
    //   ...project,
    //   revenue_items: temp,
    //   total_revenue: project.total_revenue + objectTemp.price,
    // })
    setSelectedVariant()
    setSelectedProduct()
    setListVariantProduct([])
    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
    })
    setTotalRev(tempTotal)
  }

  const handleRevenueItemPriceChange = (price, item) => {
    var temp = project.revenue_items
    const index = temp.findIndex((rev_item) => {
      return (
        rev_item.product_variant_id === item.product_variant_id &&
        rev_item.sub_product_id === item.sub_product_id &&
        rev_item.product_id === item.product_id
      )
    })
    var objectTemp = { ...temp[index], price: price }
    temp.splice(index, 1, objectTemp)

    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
    })
    setTotalRev(tempTotal)
  }

  const handleRevenueItemQtyChange = (qty, item) => {
    var temp = project.revenue_items
    const index = temp.findIndex((rev_item) => {
      return (
        rev_item.product_variant_id === item.product_variant_id &&
        rev_item.sub_product_id === item.sub_product_id &&
        rev_item.product_id === item.product_id
      )
    })
    // console.log(index)
    var objectTemp = { ...temp[index], quantity: qty }
    temp.splice(index, 1, objectTemp)

    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
    })
    setTotalRev(tempTotal)
  }

  const handleRevenueDiscountTypeChange = (type, item) => {
    var temp = [...project.revenue_items]
    const index = temp.findIndex((rev_item) => {
      return (
        rev_item.product_variant_id === item.product_variant_id &&
        rev_item.sub_product_id === item.sub_product_id &&
        rev_item.product_id === item.product_id
      )
    })
    var objectTemp = { ...temp[index], discount_type: type }
    temp.splice(index, 1, objectTemp)
    var tempTotal = reCountTotal(temp)
    setProject({ ...project, revenue_items: temp, total_revenue: tempTotal })
    setTotalRev(tempTotal)
  }

  const handleRevenueItemDiscountChange = (dsc, item) => {
    var temp = project.revenue_items
    const index = temp.findIndex((rev_item) => {
      return (
        rev_item.product_variant_id === item.product_variant_id &&
        rev_item.sub_product_id === item.sub_product_id &&
        rev_item.product_id === item.product_id
      )
    })
    console.log(dsc)
    var objectTemp =
      item.discount_type === 'absolute'
        ? {
            ...temp[index],
            discount_absolute: dsc === '' ? 0 : dsc < item.price * item.quantity ? dsc | 0 : item.price * item.quantity,
          }
        : {
            ...temp[index],
            discount_percentage: dsc === '' ? 0 : parseFloat(dsc / 100) > 1.0 ? 1.0 : parseFloat(dsc / 100),
          }
    temp.splice(index, 1, objectTemp)
    // console.log(temp)
    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
    })
    setTotalRev(tempTotal)
  }

  const handleDeleteRevenueItem = (item) => {
    var temp = project.revenue_items
    var cogsTemp = project.cogs_items.filter((cogs_item) => cogs_item.project_revenue_item_id !== item.id)
    const index = temp.findIndex((rev_item) => {
      return (
        rev_item.product_variant_id === item.product_variant_id &&
        rev_item.sub_product_id === item.sub_product_id &&
        rev_item.product_id === item.product_id
      )
    })
    temp.splice(index, 1)
    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      revenue_items: temp,
      total_revenue: tempTotal,
      cogs_items: cogsTemp,
    })
    setTotalRev(tempTotal)
  }

  return (
    <div className="mb-12">
      <h1 className="font-semibold text-giant mb-4">Revenue</h1>
      <div className="border border-gray-border rounded-[5px] p-4">
        {isFlagshipPages ? (
          <div className="flex flex-col gap-4 md:flex-row md:items-end mb-4">
            <div className="md:w-[270px]">
              <p className="mb-1 font-medium">Product</p>
              <CustomTextDropdownInput
                className=""
                value={selectedProduct}
                data={listProducts}
                handleSelectedOption={(selected) => {
                  // console.log(selected)
                  setSelectedProduct(selected)
                }}
                placeholder="Choose Product"
                attributeShown="name"
                withSearch
              />
            </div>
            <div className="md:w-[500px]">
              <p className="mb-1 font-medium">Variant</p>
              <CustomTextDropdownInput
                className=""
                value={selectedVariant}
                data={listVariantProduct}
                handleSelectedOption={(selected) => {
                  // console.log(selected)
                  setSelectedVariant(selected)
                }}
                placeholder="Choose Variant"
                displayedText={(row) => `${row.sub_product_name} - ${row.product_variant_name}`}
                attributeShown="product_variant_name"
                withSearch
              />
            </div>
            <Button
              onClick={() => {
                handleAddRevenueItems()
              }}
            >
              Add
            </Button>
          </div>
        ) : (
          isEditable &&
          (project.status === 'pending' || project.status === 'need_revision' || project.status === 'rejected') && (
            <div className="flex flex-col gap-4 md:flex-row md:items-end mb-4">
              <div className="md:w-[270px]">
                <p className="mb-1 font-medium">Product</p>
                <CustomTextDropdownInput
                  className=""
                  value={selectedProduct}
                  data={listProducts}
                  handleSelectedOption={(selected) => {
                    // console.log(selected)
                    setSelectedProduct(selected)
                  }}
                  placeholder="Choose Product"
                  attributeShown="name"
                  withSearch
                />
              </div>
              <div className="md:w-[500px]">
                <p className="mb-1 font-medium">Variant</p>
                <CustomTextDropdownInput
                  className=""
                  value={selectedVariant}
                  data={listVariantProduct}
                  handleSelectedOption={(selected) => {
                    // console.log(selected)
                    setSelectedVariant(selected)
                  }}
                  placeholder="Choose Variant"
                  displayedText={(row) => `${row.sub_product_name} - ${row.product_variant_name}`}
                  attributeShown="product_variant_name"
                  withSearch
                />
              </div>
              <Button
                onClick={() => {
                  handleAddRevenueItems()
                }}
              >
                Add
              </Button>
            </div>
          )
        )}
        {/* <Table data={project.revenue_items} cols={revenueCols} /> */}
        <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-main-500 scrollbar-track-sky-50">
          <table className={'table-auto w-full'}>
            <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
              <tr>
                <th className="px-4 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
                {revenueCols.map((col, index) => {
                  // console.log(col)
                  if (index === revenueCols.length - 1) {
                    return (
                      <th
                        key={index}
                        className={`px-4 py-4 font-semibold rounded-r-[5px] ${col.classes && col.classes}`}
                      >
                        {col.name}
                      </th>
                    )
                  }
                  return (
                    <th key={index} className={`px-4 py-4 font-semibold ${col.classes && col.classes}`}>
                      {col.name}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {project.revenue_items.length === 0 ? (
                <tr className="text-center italic">
                  <td colSpan={revenueCols.length} className="text-gray-text font-semibold py-4">
                    No data found
                  </td>
                </tr>
              ) : (
                project.revenue_items.map((row, index) => {
                  return (
                    <tr key={index} className="border-b border-gray-border">
                      <td className="px-4 py-4 font-semibold w-8">{index + 1}.</td>
                      <td className={`px-4 py-4 font-semibold`}>{row.product_name}</td>
                      <td
                        className={`px-4 py-4 font-semibold`}
                      >{`${row.sub_product_name} - ${row.product_variant_name}`}</td>
                      <td className={`px-4 py-4 font-semibold text-right`}>
                        {(isEditable || isFlagshipPages) && row.price_type === 'custom' ? (
                          <Input
                            onChange={(e) => {
                              var temp
                              if (e.target.value < 0 || e.target.value === '') {
                                temp = 0
                              } else {
                                temp = e.target.value
                              }
                              handleRevenueItemPriceChange(parseInt(temp), row)
                            }}
                            value={row.price}
                          />
                        ) : (
                          `Rp${formatThousands(row.price)}`
                        )}
                      </td>
                      <td className={`px-4 py-4 font-semibold text-right`}>
                        {isEditable || isFlagshipPages ? (
                          <Input
                            onChange={(e) => {
                              var temp
                              if (e.target.value < 0 || e.target.value === '') {
                                temp = 0
                              } else {
                                temp = e.target.value
                              }
                              handleRevenueItemQtyChange(parseInt(temp), row)
                            }}
                            value={row.quantity}
                          />
                        ) : (
                          <Basic data={row.quantity} alignment={'right'} />
                        )}
                      </td>
                      <td className={`px-4 py-4 font-semibold text-right`}>
                        {isEditable || isFlagshipPages ? (
                          <DiscountInput
                            discountType={row.discount_type}
                            onChangeType={(type) => handleRevenueDiscountTypeChange(type, row)}
                            onChange={(e) => {
                              handleRevenueItemDiscountChange(e.target.value, row)
                            }}
                            min={'0'}
                            max={'100'}
                            type={'number'}
                            value={
                              row.discount_type === 'absolute'
                                ? row.discount_absolute
                                : parseFloat((parseFloat(row.discount_percentage) * 100).toFixed(3))
                            }
                          />
                        ) : (
                          <>
                            {row.discount_type === 'absolute' ? (
                              <Basic
                                data={row.discount_absolute > 0 ? `Rp${formatThousands(row.discount_absolute)}` : `0`}
                                alignment={'right'}
                              />
                            ) : (
                              <Basic
                                data={
                                  row.discount_percentage > 0
                                    ? `${parseFloat((parseFloat(row.discount_percentage) * 100).toFixed(3))}%`
                                    : `0`
                                }
                                alignment={'right'}
                              />
                            )}
                          </>
                        )}
                      </td>
                      <td className={`px-4 py-4 font-semibold text-right`}>
                        Rp
                        {row.discount_type === 'absolute'
                          ? formatThousands(row.price * row.quantity - row.discount_absolute)
                          : formatThousands(
                              row.price * row.quantity - row.price * row.quantity * row.discount_percentage
                            )}
                      </td>
                      {(isEditable || isFlagshipPages) && (
                        <td className={`px-4 py-4 font-semibold`}>
                          <div className="flex justify-center">
                            <button
                              className="w-8 h-8 rounded flex items-center justify-center text-gray-300 hover:bg-red-100 hover:text-red-500"
                              onClick={() => handleDeleteRevenueItem(row)}
                            >
                              <Delete sx={{ fontSize: 20 }} />
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  )
                })
              )}
              <tr className="border-b border-gray-border">
                <td className="px-4 py-4 font-semibold w-8"></td>
                <td className={`px-4 py-4 font-semibold`}></td>
                <td className={`px-4 py-4 font-semibold`}></td>
                <td className={`px-4 py-4 font-semibold text-right`}></td>
                <td className={`px-4 py-4 font-semibold text-right`}></td>
                <td className={`px-4 py-4 font-semibold text-right`}>Total Revenue</td>
                <td className={`px-4 py-4 font-semibold text-right`}>Rp{formatThousands(totalRev)}</td>
                <td className={`px-4 py-4 font-semibold`}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
