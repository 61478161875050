import { devAxios } from './axiosConfig'
const SalesPlanAPI = {
  createNewSalesPlan: async (data) => {
    var temp = {
      type: 'new',
      client_id: 7,
      project_type: 'standard',
      project_name: 'Project Bersama Google',
      project_id: null,
      flagship_project_id: null,
      meeting_date: '2023-04-21',
    }

    var res = await devAxios.post('sales-plan', data)
    return res.data
  },

  deleteSalesPlan: async (id) => {
    var res = devAxios.delete(`sales-plan/${id}`)
    return res.data
  },

  saveMOM: async (data) => {
    var temp = {
      sales_deal_activity_id: 5,
      project_id: 4,
      client_response: 'need_more_discussion',
      change_status: 'leads',
      meeting_notes: 'Tertarik tapi butuh diskusi',
      meeting_expenses: [
        { item_name: 'Starbucks', price: 70000, receipt: 'https://image.com/' },
        {
          item_name: 'Cetak proposal',
          price: 200000,
          receipt: 'https://image.com/',
        },
      ],
    }
    var res = await devAxios.post('sales-plan/save-mom', data)
    return res.data
  },

  editMom: async (data) => {
    var res = await devAxios.post('sales-plan/save-mom', data)
    return res.data
  },

  getSingleSalesPlan: async (id) => {
    var res = await devAxios.get(`sales-plan/${id}`)
    return res.data
  },

  getListOfSalesPlan: async (year, week, account_id = undefined, tags = undefined) => {
    console.log(tags)
    var res = await devAxios.get(`sales-plan`, {
      params: {
        week: week,
        year: year,
        account_id: account_id || undefined,
        tags: tags ? tags : undefined,
      },
    })
    return res.data
  },

  deleteSalesPlan: async (id) => {
    var res = await devAxios.delete(`sales-plan/${id}`)
    return res.data
  },

  getAllTags: async () => {
    var res = await devAxios.get('tag')
    return res.data
  },
}

export default SalesPlanAPI
