import { devAxios } from './axiosConfig'
import StorageAPI from './storageAPI'

const SBUAPI = {
  getAllSBU: async () => {
    var res = await devAxios.get('sbu')
    return res.data
  },
  getSingleSBU: async (id) => {
    var res = await devAxios.get(`sbu/${id}`)
    return res.data
  },

  updateSBU: async (id, sbu_name, cogs_guideline, logo, imageChanged = false) => {
    if (imageChanged) {
      console.log(imageChanged)
      var blob = await fetch(imageChanged).then((r) => r.blob())
      var upload = await StorageAPI.uploadFile(`avatar/${Math.random() * 10000000}.png`, blob).then((res) => {
        return res
      })
    }

    var res = await devAxios.put(`sbu/${id}`, {
      name: sbu_name,
      cogs_guideline: cogs_guideline,
      logo: imageChanged ? upload : logo ? logo : '',
    })
    return res.data
  },
}

export default SBUAPI
