import moment from 'moment'

const formatThousands = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

const formatSimplerNumber = (number) => {
  if (number > 999 && number < 1000000) {
    return (number / 1000).toFixed() + 'K' // convert to K for number from > 1000 < 1 million
  } else if (number > 1000000) {
    return (number / 1000000).toFixed(1) + 'M' // convert to M for number from > 1 million
  } else if (number < 900) {
    return number // if value < 1000, nothing to do
  }
}

const dotsLimiter = (text, maxLength) => {
  if (maxLength > text.length) return text
  else return text.slice(0, maxLength) + '...'
}

const getWeekOfYear = (date) => {
  var dateToCheck = new Date(date)
  var startDate = new Date(dateToCheck.getFullYear(), 0, 1)
  var days = Math.floor((dateToCheck - startDate) / (24 * 60 * 60 * 1000))

  var weekNumber = Math.ceil(days / 7)

  return weekNumber
}

const getWeekOfMonth = (date) => {
  date = new Date(moment(date).format('MM-DD-YYYY'))
  const startWeekDayIndex = 1 // 1 Monday 0 Sundays
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1)
  const firstDay = firstDate.getDay()

  let weekOfMonth = Math.ceil((date.getDate() + firstDay) / 7)
  if (startWeekDayIndex === 1) {
    if (date.getDay() === 0 && date.getDate() > 1) {
      weekOfMonth -= 1
    }

    if (firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1) {
      weekOfMonth += 1
    }
  }
  return weekOfMonth
}

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export { formatThousands, formatSimplerNumber, dotsLimiter, getWeekOfYear, getWeekOfMonth, sleep }
