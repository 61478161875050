import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import Table from '../../components/table/Table'
import SalesDealAPI from '../../apis/SalesDealAPI'
import Basic from '../../components/table/cell/Basic'
import LogoCell from '../../components/table/cell/LogoCell'
import Loading from '../../components/Loading'
import { formatThousands } from '../../helper'
import moment from 'moment/moment'
import DropdownUser from '../../components/DropdownUser'
import ClientAPI from '../../apis/ClientAPI'
import SBUAPI from '../../apis/SBUAPI'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import Pagination from '../../components/Pagination'

export default function SalesDealLoss() {
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [pagination, setPagination] = useState({ total_page: 1 })
  const [listSBU, setListSBU] = useState([])
  const [listClient, setListClient] = useState([])
  const [salesDealLoss, setSalesDealLoss] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [lossTableCols, setLossTableCols] = useState([
    {
      id: 0,
      name: 'Client',
      classes: 'w-[250px]',
      selector: (row) => (
        <LogoCell
          imgShape={'circle'}
          img={row.client_logo ? row.client_logo : `https://ui-avatars.com/api/?name=Kata+Data`}
          text={row.client.name}
        />
      ),
    },
    {
      id: 0,
      name: 'Project Name',
      classes: 'w-[250px]',
      selector: (row) => <Basic data={row.project.name} />,
    },
    {
      id: 0,
      name: 'Account Executive',
      selector: (row) => <LogoCell imgShape={'circle'} text={row.account_owner.name} img={row.account_owner.avatar} />,
    },
    {
      id: 0,
      name: 'Value',
      classes: 'text-right w-[100px]',
      selector: (row) => (
        <Basic
          data={`Rp${row.project.total_revenue ? formatThousands(row.project.total_revenue) : 0}`}
          alignment={'right'}
        />
      ),
    },
    {
      id: 0,
      name: '# Meetings',
      classes: 'text-right w-[120px]',
      selector: (row) => <Basic data={row.number_of_meetings} alignment={'right'} />,
    },
    {
      id: 0,
      name: 'Reason',
      classes: 'w-[300px]',
      selector: (row) => <Basic data={row.loss_reason} />,
    },
    {
      id: 0,
      name: 'Last Update',
      classes: '',
      selector: (row) => <Basic data={moment(row.last_updated).format('DD MMMM YYYY')} />,
    },
  ])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ClientAPI.getClientSimple().then((res) => {
          setListClient([{ id: '', company_name: 'All clients' }, ...res.data])
          resolve()
        })
      }),

      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU().then((res) => {
          setListSBU([{ id: '', sbu_name: 'All SBU' }, ...res.data])
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {})
  }, [])

  useEffect(() => {
    console.log(listClient)
    SalesDealAPI.getSalesDealByStatus('loss', searchParams.client, searchParams.sbu).then((res) => {
      setSalesDealLoss(res.data)
      setLoading(false)
    })
  }, [searchParams.sbu, searchParams.page, searchParams.client])

  return (
    <div className="py-4">
      <BreadCrumbs className={'mb-4'}>
        <BreadCrumbs.Item href={'/sales-deal'}>Sales Deal</BreadCrumbs.Item>
        <BreadCrumbs.Item current>Loss</BreadCrumbs.Item>
      </BreadCrumbs>

      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="flex flex-col md:items-center md:flex-row gap-4 mb-4">
              <div className="flex-1 w-full flex flex-col gap-2 md:flex-row">
                <DropdownUser
                  data={listSBU}
                  noPic
                  value={listSBU.find((sbu) => sbu.sbu_id == searchParams.sbu)}
                  type="Sales Business Unit"
                  handleSelectedOption={(selected) =>
                    setSearchParams({ ...searchParams, sbu: selected.sbu_id || '', page: 1 })
                  }
                  attributeShown="sbu_name"
                />
                <DropdownUser
                  data={listClient}
                  withSearch
                  noPic
                  value={listClient.find((client) => client.id == searchParams.client)}
                  type="Client"
                  handleSelectedOption={(selected) =>
                    setSearchParams({ ...searchParams, page: 1, client: selected.id || '' })
                  }
                  attributeShown="company_name"
                />
              </div>
              {/* <DropdownUser
              data={valueRange}
              noPic
              type="Value Range"
              handleSelectedOption={(selected) => setSelectedSBU(selected)}
            /> */}
            </div>
            <Table data={salesDealLoss} cols={lossTableCols} tableType="" viewLink={(row) => `/sales-deal/${row.id}`} />
            <Pagination
              totalPage={pagination.total_page || 1}
              currentPage={parseInt(searchParams.page) || 1}
              onChange={(page) => setSearchParams({ ...searchParams, page: page })}
            />
          </>
        )}
      </div>
    </div>
  )
}
