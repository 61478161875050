import React, { useEffect, useState } from 'react'
import { Pagination as MuiPagination, PaginationItem } from '@mui/material'
import Button from './Button'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

export default function Pagination({ totalPage, currentPage, onChange }) {
  const [currentPageTemp, setCurrentPageTemp] = useState(currentPage ? currentPage : 1)
  const [isClicked, setClicked] = useState(false)

  useEffect(() => {
    setCurrentPageTemp(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (isClicked) {
      onChange(currentPageTemp)
    }
  }, [currentPageTemp])

  const handlePageChange = (event, value) => {
    setClicked(true)
    setCurrentPageTemp(value)
  }

  const buttonClick = (page) => {
    setClicked(true)
    setCurrentPageTemp(page)
  }

  return (
    <div className="mt-4 flex justify-end">
      <MuiPagination
        count={totalPage}
        onChange={handlePageChange}
        page={currentPageTemp}
        shape="rounded"
        renderItem={(item) => {
          // console.log(item)
          if (item.type === 'previous') {
            return (
              <Button
                variant={'outlined'}
                size={'xs'}
                className={'mx-1'}
                disabled={currentPageTemp === 1}
                onClick={() => buttonClick(currentPageTemp - 1)}
              >
                <KeyboardArrowLeft sx={{ fontSize: 16 }} />
              </Button>
            )
          } else if (item.type === 'next') {
            // console.log('Current Page', currentPage)
            // console.log('Total Page', totalPage)
            return (
              <Button
                variant={'outlined'}
                size={'xs'}
                className={'mx-1'}
                disabled={currentPageTemp === totalPage}
                onClick={() => buttonClick(currentPageTemp + 1)}
              >
                <KeyboardArrowRight sx={{ fontSize: 16 }} />
              </Button>
            )
          } else {
            return <PaginationItem {...item} />
          }
        }}
      />
    </div>
  )
}
