import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { signOut as reduxSignOut } from '../redux/authSlice'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth } from '../apis/firebaseConfig'

export default function Logout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    signOut(auth)
    dispatch(reduxSignOut())

    navigate('/login')
  }, [])
  return null
}
