import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingSpinner from './LoadingSpinner'

export default function Button({
  className,
  children,
  onClick,
  variant,
  size,
  href,
  customColor,
  customFontColor,
  icon,
  disabled,
  isLoading,
}) {
  // useEffect(() => {
  //   console.log(disabled)
  // }, [])
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  useEffect(() => {
    setIsButtonLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    // console.log(isButtonLoading)
  }, [isButtonLoading])

  const sizeClass =
    size === 'small'
      ? 'text-small px-3 py-2 font-semibold'
      : size === 'xs'
      ? 'text-small px-2.5 py-1.5 font-semibold'
      : 'text-normal px-4 py-3 font-semibold'
  if (disabled) {
    return (
      <button
        disabled
        onClick={onClick}
        className={`rounded-[5px] ${className ? className : ''}  ${sizeClass ? sizeClass : ''} ${
          variant === 'outlined' ? 'bg-white ring-2 ring-gray-300 text-gray-300' : 'bg-gray-300 text-white'
        }  `}
      >
        {icon ? (
          <div className="flex gap-2 items-center justify-center -left-1.5 font-semibold">
            <span className="w-4 h-4 flex items-center justify-center ">{icon}</span>
            {children}
          </div>
        ) : (
          children
        )}
      </button>
    )
  } else if (href)
    return (
      <Link to={href}>
        <button
          className={` rounded-[5px]
          ${
            variant === 'outlined'
              ? 'text-main-500 border-2 border-main-500 hover:bg-sky-50 box-border'
              : 'text-white bg-main-500 border-2 border-main-500 box-border hover:bg-main-600 hover:border-main-600'
          }
          ${customColor ? customColor : ''} ${customFontColor ? customFontColor : ''} ${sizeClass ? sizeClass : ''} ${
            className ? className : ''
          }  transition-colors`}
        >
          <div className="flex gap-2 items-center justify-center -left-1.5 font-semibold">
            {icon ? (
              <div className="flex gap-1 items-center justify-center -left-1.5 font-semibold">
                <span className="w-4 h-4 flex items-center justify-center ">{icon}</span>
                {children}
              </div>
            ) : (
              children
            )}
          </div>
        </button>
      </Link>
    )
  else
    return (
      <button
        onClick={onClick}
        className={` rounded-[5px] ${customColor ? customColor : ''} ${sizeClass ? sizeClass : ''}  ${
          variant === 'outlined'
            ? `${customFontColor ? customFontColor : 'text-main-500'} ring-2 ring-main-500 hover:bg-sky-50 box-border`
            : `${
                customFontColor ? customFontColor : 'text-white'
              } ring-2 ring-main-500 bg-main-500 hover:bg-main-600 hover:border-main-600 box-border`
        } transition-colors ${className ? className : ''}`}
      >
        {icon ? (
          <div className="flex gap-2 items-center justify-center -left-1.5 font-semibold">
            <span className="w-4 h-4 flex items-center justify-center ">{icon}</span>
            {children}
          </div>
        ) : isButtonLoading ? (
          <LoadingSpinner button={true} />
        ) : (
          children
        )}
      </button>
    )
}
