import React from 'react'

export default function LogoCell({ img, imgShape, text }) {
  return (
    <div className="flex items-center">
      <span
        className={`w-8 h-8 mr-2 overflow-hidden flex item-center justify-center shrink-0 ${
          imgShape === 'circle' ? 'rounded-full' : ''
        }`}
      >
        <img className="w-full object-contain" src={img} alt="logo_img" />
      </span>
      {text && <p>{text}</p>}
    </div>
  )
}
