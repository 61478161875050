import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
import Button from '../../components/Button'
import Table from '../../components/table/Table'
import ProjectAPI from '../../apis/ProjectAPI'
import Basic from '../../components/table/cell/Basic'
import { formatThousands } from '../../helper'
import usePermission from '../../hooks/usePermission'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'
import StatusCell from '../../components/table/cell/StatusCell'

export default function FlagshipDetail() {
  const { id } = useParams()
  const [project, setProject] = useState()
  const [isLoading, setLoading] = useState(true)
  const { isAdmin } = usePermission()
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ProjectAPI.getFlagshipProjectById(id).then((res) => {
          setProject({ ...res.data, type: 'flagship' })
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [id])

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/projects'}>Project</BreadCrumbs.Item>
              <BreadCrumbs.Item href={'/projects/flagship'}>Flagship</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>
          {isAdmin && (
            <div className="flex gap-2 mb-4">
              <Button
                // href={`/projects/edit/${project.type === 'standard' || project.type === 'custom' ? 's' : 'f'}/${
                href={`/projects/edit/f/${id}`}
              >
                Edit Flagship Project
              </Button>
            </div>
          )}
          <div className="mb-12">
            <h1 className="font-semibold text-giant mb-4">Project Information</h1>
            <div className="border border-gray-border rounded-[5px] p-4">
              <div className="max-w-[500px]">
                <div className="mb-4">
                  <p className="font-semibold mb-1">Project Name</p>
                  <p className="">{project.name}</p>
                </div>
                <div className="mb-4">
                  <p className="font-semibold mb-1">Description</p>
                  <p className="">{project.description ? project.description : '-'}</p>
                </div>
                <div className="mb-4">
                  <p className="font-semibold mb-1.5">Status</p>
                  <StatusCell
                    data={project.status}
                    config={[
                      {
                        text: 'Active',
                        status: 'active',
                        state: 'success',
                      },
                      {
                        text: 'Inactive',
                        status: 'inactive',
                        state: 'danger',
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <RevenueItemsTable
            project={project}
            setProject={setProject}
            // isFlagshipPages={isAdmin}
            isEditable={false}
            projectTypeTemp={'custom'}
          />
          <COGSItemsTable project={project} setProject={setProject} isEditable={false} projectTypeTemp={'custom'} />
          <div className="mb-12">
            <h1 className="text-giant mb-4 font-semibold">Margin</h1>
            <div className="border border-gray-border rounded-[5px] p-4">
              {/* <div className="w-[360px] ">
                <div className="px-2.5 py-4 flex gap-8 border-b">
                  <p className="flex-1 font-semibold">Total Revenue</p>
                  <p className="flex-1 font-semibold text-right">Rp{formatThousands(project.total_revenue)}</p>
                </div>
                <div className="px-2.5 py-4 flex gap-8 border-b">
                  <p className="flex-1 font-semibold">Total COGS</p>
                  <p className="flex-1 font-semibold text-right">Rp{formatThousands(project.total_cogs)}</p>
                </div>
                <div className="px-2.5 py-4 flex gap-8 rounded-[5px] bg-main-500 text-white">
                  <p className="flex-1 font-semibold">Margin</p>
                  <p className="flex-1 font-semibold text-right">{countMargin()}</p>
                </div>
              </div> */}
              <Table
                tableType="inline"
                noIndex={true}
                cols={marginCols}
                data={[
                  {
                    id: 0,
                    row: 'Revenue',
                    totalAmount: project.total_revenue,
                    percentage: project.total_revenue !== 0 ? 1 - project.total_cogs / project.total_revenue : 0,
                  },
                  {
                    id: 1,
                    row: 'COGS',
                    totalAmount: project.total_cogs,
                    percentage:
                      project.total_revenue !== 0
                        ? project.total_cogs / project.total_revenue
                        : project.total_cogs === 0
                        ? 0
                        : 1,
                  },
                ]}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
