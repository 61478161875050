import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import Basic from '../../components/table/cell/Basic'
import Button from '../../components/Button'
import { Edit } from '@mui/icons-material'
import Table from '../../components/table/Table'
import TargetAPI from '../../apis/TargetAPI'
import moment from 'moment'
import { formatThousands } from '../../helper'
import Loading from '../../components/Loading'
import toast from 'react-hot-toast'
import Alert from '../../components/Alert'
import { Link } from 'react-router-dom'
import usePermission from '../../hooks/usePermission'

const listMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const formatTarget = (target) => {
  var temp = {
    account_executive_level: [],
    sbu: [],
    company: [],
  }

  let formatRow = (row) => {
    var tempFormatRow = {
      title: row[0].entity,
    }

    listMonth.forEach((month, index) => {
      tempFormatRow[month] = {
        client: {
          title: 'Client',
          amount: row.find((element) => element.month == index + 1 && element.type == 'client').amount,
        },
        revenue: {
          title: 'Revenue',
          amount: row.find((element) => element.month == index + 1 && element.type == 'revenue').amount,
        },
      }
    })
    // console.log(tempFormatRow)
    return tempFormatRow
  }

  var keys = Object.keys(target)
  keys.forEach((key) => {
    var temp_row = []
    var tableKeys = Object.keys(target[key])
    tableKeys.forEach((tableKey) => {
      temp_row.push(formatRow(target[key][tableKey]))
    })
    temp[key] = temp_row
  })
  console.log(temp)
  return temp
}

export default function Target() {
  const { isAdmin } = usePermission()
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [listTargetYears, setListTargetYear] = useState([])
  const [targetAE, setTargetAE] = useState([
    {
      id: 0,
      name: 'AE Level',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.title} className={'capitalize'} />,
    },
    ...listMonth.map((month, index) => ({
      id: index + 1,
      name: month,
      classes: 'min-w-[200px]',
      selector: (row) => (
        <Basic
          customView={
            <>
              <p className="mb-0.5">{`${row[month].client.title}: ${row[month].client.amount}`}</p>
              <p>{`${row[month].revenue.title}: Rp${formatThousands(row[month].revenue.amount)}`}</p>
            </>
          }
        />
      ),
    })),
  ])
  const [targetSBU, setTargetSBU] = useState([
    {
      id: 0,
      name: 'SBU',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.title} className={'capitalize'} />,
    },
    ...listMonth.map((month, index) => ({
      id: index + 1,
      name: month,
      classes: 'min-w-[200px]',
      selector: (row) => (
        <Basic
          customView={
            <>
              <p className="mb-0.5">{`${row[month].client.title}: ${row[month].client.amount}`}</p>
              <p>{`${row[month].revenue.title}: Rp${formatThousands(row[month].revenue.amount)}`}</p>
            </>
          }
        />
      ),
    })),
  ])
  const [targetCompany, setTargetCompany] = useState([
    {
      id: 0,
      name: 'Company',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.title} className={'capitalize'} />,
    },
    ...listMonth.map((month, index) => ({
      id: index + 1,
      name: month,
      classes: 'min-w-[200px]',
      selector: (row) => (
        <Basic
          customView={
            <>
              <p className="mb-0.5">{`${row[month].client.title}: ${row[month].client.amount}`}</p>
              <p>{`${row[month].revenue.title}: Rp${formatThousands(row[month].revenue.amount)}`}</p>
            </>
          }
        />
      ),
    })),
  ])

  const [maxYear, setMaxYear] = useState(0)

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        TargetAPI.getListTarget()
          .then((res) => {
            let max = 0
            console.log(res.data)
            setListTargetYear(
              res.data.map((year) => {
                max = Math.max(max, year.year)
                return {
                  year: year.year,
                  ...formatTarget({
                    account_executive_level: year.account_executive_level,
                    sbu: year.sbu,
                    company: year.company,
                  }),
                }
              })
            )
            setMaxYear(max)
            resolve()
          })
          .catch((err) => {
            if (err.response) {
              setError(err.response.data.message)
              toast.error(err.response.data.message)
            } else {
              setError(err.statusText)
              toast.error(err.statusText)
            }
            reject(err)
          })
      }),
    ]

    Promise.all(promises)
      .then((res) => {
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    console.log(listTargetYears)
  }, [listTargetYears])

  return (
    <div className="py-4">
      <BreadCrumbs className={'mb-4'}>
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item current>Target</BreadCrumbs.Item>
      </BreadCrumbs>
      <div className="flex items-center mb-8">
        <h1 className="font-semibold text-giant mb-4 flex-1">Target</h1>
        {isAdmin && (
          <Link to={`add?max=${maxYear}`}>
            <Button>Add Target</Button>
          </Link>
        )}
      </div>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <Alert type={'error'} message={error} />
      ) : (
        listTargetYears.map((targetYear, index) => {
          return (
            <div className="mb-8 pb-8 border-b-4 last:mb-0" key={index}>
              <div className="flex gap-4 items-center mb-6">
                <h1 className="font-semibold text-giant">{targetYear.year}</h1>
                {isAdmin && (
                  <Link to={`edit/${targetYear.year}`}>
                    <Button variant="outlined" icon={<Edit sx={{ fontSize: 18 }} />} size={'small'}>
                      Edit Target
                    </Button>
                  </Link>
                )}
              </div>
              <>
                <Table data={targetYear.account_executive_level} cols={targetAE} tableType="inline" />
                <Table data={targetYear.sbu} cols={targetSBU} tableType="inline" />
                <Table data={targetYear.company} cols={targetCompany} tableType="inline" />
              </>
            </div>
          )
        })
      )}
    </div>
  )
}
