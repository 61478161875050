import React, { useEffect, useState } from 'react'
import Tabs from '../components/Tabs'
import { Route, Routes } from 'react-router-dom'
import ProjectFeasibility from './Projects/ProjectFeasibility'
import ProjectFlagship from './Projects/ProjectFlagship'

export default function Project() {
  return (
    <div className="py-4 ">
      <Tabs
        data={[
          { id: 1, name: 'Project Feasibility', link: '' },
          { id: 2, name: 'Flagship Projects', link: 'flagship' },
        ]}
      />
      <Routes>
        <Route path="/" element={<ProjectFeasibility />} />
        <Route path="/flagship" element={<ProjectFlagship />} />
      </Routes>
    </div>
  )
}
