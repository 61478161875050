import React, { useEffect, useState } from 'react'
import Basic from '../../components/table/cell/Basic'
import Table from '../../components/table/Table'
import Button from '../../components/Button'
import { Dataset, Image } from '@mui/icons-material'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { Bar } from 'react-chartjs-2'
import { faker } from '@faker-js/faker'
import StatisticsAPI from '../../apis/Statistics'
import LoadingSpinner from '../../components/LoadingSpinner'
import { formatThousands } from '../../helper'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import moment from 'moment'
import exportFromJSON from 'export-from-json'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
)

const listMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const formatDataForDownload = (data) => {
  // console.log(data)
  var temp = data.map((row) => {
    var tempRow = {
      Type: row.id,
    }
    if (row.id.includes('target')) {
      listMonth.forEach((month, index) => {
        tempRow[month] = row.values[index]
      })
    } else {
      listMonth.forEach((month, index) => {
        tempRow[month] = row.values[index].value
      })
    }
    return tempRow
  })
  // console.log(temp)
  return temp
}

const formatSalesDealForDownload = (visit, leads, hot_prospect, on_hands, revenue) => {
  // console.log(visit)
  var temp = []
  var visitHeader = { Type: 'Visit' }
  var visitBody = visit.map((row) => {
    var tempRow = { Type: row.id }
    listMonth.forEach((month, index) => {
      tempRow[month] = row.values[index]
    })
    return tempRow
  })

  var leadsHeader = { Type: 'Leads' }
  var leadsBody = leads.map((row) => {
    var tempRow = { Type: row.id }
    listMonth.forEach((month, index) => {
      tempRow[month] = row.values[index]
    })
    return tempRow
  })
  var hotProspectHeader = { Type: 'Hot Prospect' }
  var hotProspectBody = hot_prospect.map((row) => {
    var tempRow = { Type: row.id }
    listMonth.forEach((month, index) => {
      tempRow[month] = row.values[index]
    })
    return tempRow
  })
  var onHandsHeader = { Type: 'On Hands' }
  var onHandsBody = on_hands.map((row) => {
    var tempRow = { Type: row.id }
    listMonth.forEach((month, index) => {
      tempRow[month] = row.values[index]
    })
    return tempRow
  })
  var revenueHeader = { Type: 'Revenue' }
  var revenueBody = revenue.map((row) => {
    var tempRow = { Type: row.id }
    listMonth.forEach((month, index) => {
      tempRow[month] = row.values[index]
    })
    return tempRow
  })

  var emptyRow = { Type: '' }
  listMonth.forEach((month, index) => {
    emptyRow[month] = ''
  })

  listMonth.forEach((month) => {
    visitHeader[month] =
      leadsHeader[month] =
      hotProspectHeader[month] =
      onHandsHeader[month] =
      revenueHeader[month] =
        ''
  })

  temp.push(
    visitHeader,
    ...visitBody,
    emptyRow,
    leadsHeader,
    ...leadsBody,
    emptyRow,
    hotProspectHeader,
    ...hotProspectBody,
    emptyRow,
    onHandsHeader,
    ...onHandsBody,
    emptyRow,
    revenueHeader,
    ...revenueBody
  )
  // console.log(temp)
  return temp
}

const AnalyticsTable = ({ monthlyPerformance, ytdPerformance, salesDealPerformance }) => {
  const monthlyPerformanceCols = [
    {
      id: 0,
      name: '',
      classes: 'min-w-[180px]',
      selector: (row) => <Basic data={row.id.replaceAll('_', ' ')} className="capitalize" />,
    },
    ...listMonth.map((month, index) => {
      return {
        id: index + 1,
        name: month,
        selector: (row) => {
          // console.log('iniii', row)
          if (row?.values[0]?.difference)
            return (
              <Basic
                data={
                  row.id === 'actual_revenue'
                    ? `Rp${formatThousands(row.values[index].value)}`
                    : row.values[index].value
                }
                withSecondLine={`${(row.values[index].difference * 100).toFixed(2)}%`}
                alignment={'right'}
                state={row.values[index].difference < 0 ? 'negative' : 'positive'}
              />
            )
          else {
            return (
              <Basic
                data={
                  row.id === 'target_revenue'
                    ? `Rp${formatThousands(row.values[index] ? row.values[index] : 0)}`
                    : row.values[index] || 0
                }
                alignment={'right'}
              />
            )
          }
        },
        classes: 'min-w-[170px] text-right',
      }
    }),
  ]
  const monthlyPerformanceFormatedData = formatDataForDownload(monthlyPerformance)

  const ytdPerformanceCols = [
    {
      id: 0,
      name: '',
      classes: 'min-w-[180px]',
      selector: (row) => <Basic data={row.id.replaceAll('_', ' ')} className="capitalize" />,
    },
    ...listMonth.map((month, index) => {
      return {
        id: index + 1,
        name: month,
        selector: (row) => {
          // console.log(row.values[index])
          if (row.values[0]?.difference)
            return (
              <Basic
                data={
                  row.id === 'actual_revenue'
                    ? `Rp${formatThousands(row.values[index].value)}`
                    : row.values[index].value
                }
                withSecondLine={`${(row.values[index].difference * 100).toFixed(2)}%`}
                state={row.values[index].difference < 0 ? 'negative' : 'positive'}
                alignment={'right'}
              />
            )
          else {
            // console.log('ini')
            return (
              <Basic
                data={
                  row.id === 'target_revenue'
                    ? `Rp${formatThousands(row.values[index] ? row.values[index] : 0)}`
                    : row.values[index]
                }
                alignment={'right'}
              />
            )
          }
        },
        classes: 'min-w-[170px] text-right',
      }
    }),
  ]

  const ytdPerformanceFormatedData = formatDataForDownload(ytdPerformance)

  let ytdPerformanceSettings = {
    fileName: 'ytd_performance_table', // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    // RTL: true, // Display the columns from right-to-left (the default value is false)
  }

  const visitCols = [
    {
      id: 0,
      name: 'Visit',
      classes: 'min-w-[180px]',
      selector: (row) => <Basic data={row.id.replaceAll('_', ' ')} className={'capitalize'} />,
    },
    ...listMonth.map((month, index) => {
      return {
        id: index + 1,
        name: month,
        selector: (row) => (
          <Basic
            data={row.id === 'value' ? `Rp${formatThousands(row.values[index])}` : row.values[index]}
            alignment={'right'}
          />
        ),
        classes: 'min-w-[170px] text-right',
      }
    }),
  ]

  const leadsCols = [
    {
      id: 0,
      name: 'Leads',
      classes: 'min-w-[180px]',
      selector: (row) => <Basic data={row.id.replaceAll('_', ' ')} className={'capitalize'} />,
    },
    ...listMonth.map((month, index) => {
      return {
        id: index + 1,
        name: '',
        selector: (row) => (
          <Basic
            data={row.id === 'value' ? `Rp${formatThousands(row.values[index])}` : row.values[index]}
            alignment={'right'}
          />
        ),
        classes: 'min-w-[170px] text-right',
      }
    }),
  ]
  const hotProspectCols = [
    {
      id: 0,
      name: 'Hot Prospect',
      classes: 'min-w-[180px]',
      selector: (row) => <Basic data={row.id.replaceAll('_', ' ')} className={'capitalize'} />,
    },
    ...listMonth.map((month, index) => {
      return {
        id: index + 1,
        name: '',
        selector: (row) => (
          <Basic
            data={row.id === 'value' ? `Rp${formatThousands(row.values[index])}` : row.values[index]}
            alignment={'right'}
          />
        ),
        classes: 'min-w-[170px] text-right',
      }
    }),
  ]

  const onHandsCols = [
    {
      id: 0,
      name: 'On Hands',
      classes: 'min-w-[180px]',
      selector: (row) => <Basic data={row.id.replaceAll('_', ' ')} className={'capitalize'} />,
    },
    ...listMonth.map((month, index) => {
      return {
        id: index + 1,
        name: '',
        selector: (row) => (
          <Basic
            data={row.id === 'value' ? `Rp${formatThousands(row.values[index])}` : row.values[index]}
            alignment={'right'}
          />
        ),
        classes: 'min-w-[170px] text-right',
      }
    }),
  ]

  const revenueCols = [
    {
      id: 0,
      name: 'Revenue',
      classes: 'min-w-[180px]',
      selector: (row) => <Basic data={row.id.replaceAll('_', ' ')} className={'capitalize'} />,
    },
    ...listMonth.map((month, index) => {
      return {
        id: index + 1,
        name: '',
        selector: (row) => (
          <Basic
            data={row.id === 'value' ? `Rp${formatThousands(row.values[index])}` : row.values[index]}
            alignment={'right'}
          />
        ),
        classes: 'min-w-[170px] text-right',
      }
    }),
  ]

  const salesDealPerformanceFormatedData = formatSalesDealForDownload(
    salesDealPerformance.visit,
    salesDealPerformance.leads,
    salesDealPerformance.hot_prospect,
    salesDealPerformance.on_hands,
    salesDealPerformance.revenue
  )

  useEffect(() => {
    // console.log(monthlyPerformance)
  }, [])

  const exportXls = (data, fileName) => {
    exportFromJSON({ data, fileName, exportType: 'xls' })
  }

  return (
    <div>
      <div className="monthly-performance mb-8">
        <h1 className="font-semibold text-giant mb-4">Monthly Performance</h1>
        <div className="mb-4">
          <Table data={monthlyPerformance} cols={monthlyPerformanceCols} tableType="inline" noIndex />
          {/* <Table data={[]} cols={monthlyPerformanceCols} tableType="inline" noIndex /> */}
        </div>
        <div>
          <Button
            variant={'outlined'}
            icon={<Dataset sx={{ fontSize: 18 }} />}
            onClick={() =>
              exportXls(monthlyPerformanceFormatedData, `monthly_performance_${moment().format('DDMMMYYYY')}`)
            }
          >
            .xls
          </Button>
        </div>
      </div>
      <div className="ytd-performance mb-8">
        <h1 className="font-semibold text-giant mb-4">YTD Performance</h1>
        <div className="mb-4">
          <Table data={ytdPerformance} cols={ytdPerformanceCols} tableType="inline" noIndex />
          {/* <Table data={[]} cols={ytdPerformanceCols} tableType="inline" noIndex /> */}
        </div>
        <div>
          <Button
            variant={'outlined'}
            icon={<Dataset sx={{ fontSize: 18 }} />}
            onClick={() => exportXls(ytdPerformanceFormatedData, `ytd_performance_${moment().format('DDMMMYYYY')}`)}
          >
            .xls
          </Button>
        </div>
      </div>

      <div className="sales-deal-performance mb-8">
        <h1 className="font-semibold text-giant mb-4">Sales Deal Performance</h1>
        <div className="mb-4 pb-1 w-full overflow-x-scroll scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-main-500 scrollbar-track-sky-50">
          <div className="w-fit">
            <Table data={salesDealPerformance.visit} cols={visitCols} noIndex />
            <Table data={salesDealPerformance.leads} cols={leadsCols} noIndex />
            <Table data={salesDealPerformance.hot_prospect} cols={hotProspectCols} noIndex />
            <Table data={salesDealPerformance.on_hands} cols={onHandsCols} noIndex />
            <Table data={salesDealPerformance.revenue} cols={revenueCols} noIndex />
          </div>
        </div>
        <div>
          <Button
            variant={'outlined'}
            icon={<Dataset sx={{ fontSize: 18 }} />}
            onClick={() =>
              exportXls(salesDealPerformanceFormatedData, `sales_deal_performance_${moment().format('DDMMMYYYY')}`)
            }
          >
            .xls
          </Button>
        </div>
      </div>
    </div>
  )
}

const AnalyticsGraph = ({ monthlyPerformance, ytdPerformance, salesDealPerformance }) => {
  const [labels, setLabels] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ])
  const [data, setData] = useState({
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Target Revenue',
        borderColor: '#034A73',
        borderWidth: 2,
        backgroundColor: '#034A73',
        pointStyle: 'circle',
        fill: false,
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Actual Revenue',
        backgroundColor: '#e0f2fe',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
    ],
  })

  const monthlyPerformanceRevenue = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Target Revenue',
        borderColor: '#034A73',
        borderWidth: 2,
        backgroundColor: '#034A73',
        pointStyle: 'circle',
        fill: false,
        data: monthlyPerformance.find((item) => item.id === 'target_revenue').values,
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Actual Revenue',
        backgroundColor: '#e0f2fe',
        data: monthlyPerformance
          .find((item) => item.id === 'actual_revenue')
          .values.map((month) => parseInt(month.value)),
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
    ],
  }

  const monthlyPerformanceClient = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Target Clients',
        borderColor: '#034A73',
        borderWidth: 2,
        backgroundColor: '#034A73',
        pointStyle: 'circle',
        fill: false,
        data: monthlyPerformance.find((item) => item.id === 'target_clients').values,
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Actual Clients',
        backgroundColor: '#e0f2fe',
        data: monthlyPerformance
          .find((item) => item.id === 'actual_clients')
          .values.map((month) => parseInt(month.value)),
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
    ],
  }

  const ytdPerformanceRevenue = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Target Revenue',
        borderColor: '#034A73',
        borderWidth: 2,
        backgroundColor: '#034A73',
        pointStyle: 'circle',
        fill: false,
        data: ytdPerformance.find((item) => item.id === 'target_revenue').values,
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Actual Revenue',
        backgroundColor: '#e0f2fe',
        data: ytdPerformance.find((item) => item.id === 'actual_revenue').values.map((month) => parseInt(month.value)),
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
    ],
  }

  const ytdPerformanceClient = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Target Clients',
        borderColor: '#034A73',
        borderWidth: 2,
        backgroundColor: '#034A73',
        pointStyle: 'circle',
        fill: false,
        data: ytdPerformance.find((item) => item.id === 'target_clients').values,
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Actual Clients',
        backgroundColor: '#e0f2fe',
        data: ytdPerformance.find((item) => item.id === 'actual_clients').values.map((month) => parseInt(month.value)),
        options: {
          plugins: {
            legend: {
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
              },
            },
          },
        },
      },
    ],
  }

  const stackedBarOptions = {
    aspectRatio: 1440 / 500,
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  const stackedBarData = {
    labels,
    datasets: [
      {
        label: 'Visit',
        data: salesDealPerformance.visit.value.map((item) => parseInt(item)),
        backgroundColor: '#0EA5E9',
      },
      {
        label: 'Leads',
        data: salesDealPerformance.leads.value.map((item) => parseInt(item)),
        backgroundColor: '#FCDF4A',
      },
      {
        label: 'Hot Prospect',
        data: salesDealPerformance.hot_prospect.value.map((item) => parseInt(item)),
        backgroundColor: '#FB923C',
      },
      {
        label: 'On Hands',
        data: salesDealPerformance.on_hands.value.map((item) => parseInt(item)),
        backgroundColor: '#16A34A',
      },
      {
        label: 'Revenue',
        data: salesDealPerformance.revenue.value.map((item) => parseInt(item)),
        backgroundColor: '#EC4899',
      },
      {
        label: 'Loss',
        data: salesDealPerformance.loss.value.map((item) => parseInt(item)),
        backgroundColor: '#475569',
      },
    ],
  }

  useEffect(() => {
    // console.log(salesDealPerformance)
  }, [])

  useEffect(() => {
    // if (monthlyPerformance) {
    //   console.log(
    //     monthlyPerformance
    //       .find((item) => item.id === 'actual_revenue')
    //       .values.map((month) => {
    //         return parseInt(month.value)
    //       })
    //   )
    // }
  }, [monthlyPerformance])

  const download = (chartId) => {
    const imageLink = document.createElement('a')
    const canvas = document.getElementById(chartId)
    imageLink.download = chartId + '.png'
    imageLink.href = canvas.toDataURL('image/png', 1)
    // document.write('<img src=" ' + imageLink + ' " />')
    imageLink.click()
    // console.log(imageLink)
  }

  // useEffect(() => {
  //   console.log(
  //     <Chart
  //       type="bar"
  //       id="monthly-performance"
  //       data={monthlyPerformanceRevenue}
  //       options={{
  //         aspectRatio: 1440 / 500,
  //         plugins: {
  //           legend: {
  //             labels: {
  //               pointStyle: 'circle',
  //             },
  //           },
  //         },
  //       }}
  //     />
  //   )
  // }, [])

  return (
    <div>
      <div className="monthly-performance mb-8">
        <h1 className="font-semibold text-giant mb-4">Monthly Performance</h1>
        <div className="mb-4">
          <div className="mb-4 h-[500px] w-full">
            <Chart
              type="bar"
              id="monthly-performance"
              data={monthlyPerformanceRevenue}
              options={{
                aspectRatio: 1440 / 500,
                plugins: {
                  legend: {
                    labels: {
                      pointStyle: 'circle',
                    },
                  },
                },
              }}
            />
          </div>
          <div>
            <Button
              variant={'outlined'}
              icon={<Image sx={{ fontSize: 18 }} />}
              onClick={() => download('monthly-performance')}
            >
              .png
            </Button>
          </div>
          <div className="mb-4 h-[500px] w-full">
            <Chart
              type="bar"
              id="monthly-performance"
              data={monthlyPerformanceClient}
              options={{
                aspectRatio: 1440 / 500,
                plugins: {
                  legend: {
                    labels: {
                      pointStyle: 'circle',
                    },
                  },
                },
              }}
            />
          </div>
          <div>
            <Button
              variant={'outlined'}
              icon={<Image sx={{ fontSize: 18 }} />}
              onClick={() => download('monthly-performance')}
            >
              .png
            </Button>
          </div>
        </div>
      </div>
      <div className="ytd-performance mb-8">
        <h1 className="font-semibold text-giant mb-4">YTD Performance</h1>
        <div className="mb-4">
          <div className="h-[500px] mb-4 w-full">
            <Chart
              type="bar"
              id="target-performance"
              data={ytdPerformanceRevenue}
              options={{
                aspectRatio: 1440 / 500,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    labels: {
                      pointStyle: 'circle',
                    },
                  },
                },
              }}
            />
          </div>
          <Button
            variant={'outlined'}
            icon={<Image sx={{ fontSize: 18 }} />}
            onClick={() => download('target-performance')}
          >
            .png
          </Button>
          <div className="h-[500px] mb-4 w-full">
            <Chart
              type="bar"
              data={ytdPerformanceClient}
              id="client-performance"
              options={{
                aspectRatio: 1440 / 500,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    labels: {
                      pointStyle: 'circle',
                    },
                  },
                },
              }}
            />
          </div>
          <Button
            variant={'outlined'}
            icon={<Image sx={{ fontSize: 18 }} />}
            onClick={() => download('client-performance')}
          >
            .png
          </Button>
        </div>
      </div>
      <div className="monthly-performance mb-8">
        <h1 className="font-semibold text-giant mb-4">Sales Deal Performance</h1>
        <div className="mb-4 h-[500px] w-full">
          <Bar options={stackedBarOptions} data={stackedBarData} id="sales-deal-performance" />
        </div>
        <div>
          <Button
            variant={'outlined'}
            icon={<Image sx={{ fontSize: 18 }} />}
            onClick={() => download('sales-deal-performance')}
          >
            .png
          </Button>
        </div>
      </div>
    </div>
  )
}

export default function Revenue({ analyticsType }) {
  const [isLoading, setLoading] = useState(true)
  const [monthlyPerformance, setMonthlyPerformance] = useState([])
  const [ytdPerformance, setYtdPerformance] = useState([])
  const [displayType, setDisplayType] = useState(analyticsType)
  const [salesDealPerformance, setSalesDealPerformance] = useState([])
  const [salesDealPerformanceGraphData, setSalesDealPerformanceGraphData] = useState()
  const [searchParams, setSearchParams] = useCustomSearchParams()

  const formatData = (data) => {
    var temp = []
    for (let key in data) {
      var tempRow = {
        id: key,
        values: data[key],
      }
      temp.push(tempRow)
    }
    // console.log(temp)
    return temp
  }

  // useEffect(() => {
  //   console.log('ini', monthlyPerformance)
  //   // if (monthlyPerformance) {
  //   //   console.log(monthlyPerformance.find((item) => item.id === 'target_revenue').values)
  //   // }
  // }, [monthlyPerformance])

  // useEffect(() => {
  //   console.log(selectedSBU, selectedSM, selectedAE, selectedYear)
  // }, [selectedSBU, selectedSM, selectedAE, selectedYear])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        StatisticsAPI.getMonthlyPerformance(
          searchParams.sbu,
          searchParams.sm,
          searchParams.ae,
          searchParams.year || moment().year()
        ).then((res) => {
          // if (res.success) {
          // console.log(res)
          setMonthlyPerformance(formatData(res.data))
          // console.log(res.data)
          resolve()
          // }
        })
      }),
      new Promise((resolve, reject) => {
        StatisticsAPI.getYTDPerformance(searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.year).then(
          (res) => {
            // console.log(res.data)
            setYtdPerformance(formatData(res.data))
            resolve()
          }
        )
      }),
      new Promise((resolve, reject) => {
        StatisticsAPI.getSalesDealPerformace(
          searchParams.sbu,
          searchParams.sm,
          searchParams.ae,
          searchParams.year
        ).then((res) => {
          if (res.success) {
            let salesDeal = {}
            for (let key in res.data) {
              salesDeal[key] = formatData(res.data[key])
            }
            setSalesDealPerformanceGraphData(res.data)
            setSalesDealPerformance(salesDeal)
            resolve()
          }
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.year])

  useEffect(() => {
    // console.log(salesDealPerformance)
  }, [salesDealPerformance])

  useEffect(() => {
    setDisplayType(analyticsType)
  }, [analyticsType])

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {analyticsType === 'table' ? (
            <AnalyticsTable
              monthlyPerformance={monthlyPerformance}
              ytdPerformance={ytdPerformance}
              salesDealPerformance={salesDealPerformance}
            />
          ) : (
            <AnalyticsGraph
              monthlyPerformance={monthlyPerformance}
              ytdPerformance={ytdPerformance}
              salesDealPerformance={salesDealPerformanceGraphData}
            />
          )}
        </>
      )}
    </>
  )
}
