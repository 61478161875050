import { forwardRef, useState } from 'react'
import withClickOutside from '../withClickOutside'
import { Tooltip } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

const DiscountTypeMenu = forwardRef(function DiscountTypeMenu({ open, setOpen, props }, ref) {
  const { discountType, onChangeType } = { ...props }
  const [listMenu, setListMenu] = useState([
    { id: 0, type: '$', value: 'absolute' },
    { id: 1, type: '%', value: 'percentage' },
  ])
  const [selectedType, setSelectedType] = useState(listMenu.find((item) => item.value === discountType))

  const handleSelect = (item) => {
    setSelectedType(item)
    onChangeType(item.value)
  }

  return (
    <div className="relative h-full" ref={ref}>
      <div className="bg-main-500/10 rounded-l-[5px] flex items-center">
        <Tooltip title={selectedType.value} placement="top">
          <p className="p-3 w-8">{selectedType.type}</p>
        </Tooltip>
        <i
          className="px-1.5 py-3 flex items-center justify-center cursor-pointer rounded transition-all hover:bg-main-100/50"
          onClick={() => setOpen((open) => !open)}
        >
          <KeyboardArrowDown sx={{ fontSize: 12 }} />
        </i>
      </div>
      {open && (
        <div className="absolute z-[120] mt-2 w-full max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-track-sky-50 scrollbar-thumb-main-500 scrollbar-thumb-rounded origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1" role="none">
            {listMenu.map((menu) => {
              return (
                <Tooltip key={menu.id} title={menu.value} placement="right">
                  <div
                    key={menu.id}
                    className="text-gray-700 w-full px-4 py-1 text-sm flex items-center cursor-pointer gap-2 hover:bg-main-50"
                    onClick={() => {
                      handleSelect(menu)
                      setOpen((open) => !open)
                    }}
                  >
                    {menu.type}
                  </div>
                </Tooltip>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
})

export default withClickOutside(DiscountTypeMenu)
