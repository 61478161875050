import { devAxios } from './axiosConfig'
const ReimbursementAPI = {
  getList: async (ae = undefined, status = undefined, page = 1) => {
    var query = `?page=${page}`

    if (ae != undefined) {
      query += `&ae_id=${ae}`
    }
    if (status != undefined) {
      query += `&status=${status}`
    }

    var res = await devAxios.get(`reimbursements${query}`)
    return res.data
  },

  getSingle: async (id) => {
    var res = await devAxios.get(`reimbursements/${id}`)
    return res.data
  },

  saveReview: async (review) => {
    var res = await devAxios.put(`reimbursements/review`, review)
    return res.data
  },

  delete: async (id) => {
    var res = await devAxios.delete(`clients`, { data: { id: id } })
    return res.data
  },
}

export default ReimbursementAPI
