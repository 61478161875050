import { devAxios } from './axiosConfig'

const ClientAPI = {
  getAllClients: async (search = undefined, agency = undefined, industry = undefined, page = 1) => {
    var res = await devAxios.get(`clients`, {
      params: {
        search: search,
        agency: agency,
        industry: industry,
        page: page,
      },
    })
    return res.data
  },

  getClientSimple: async () => {
    var res = await devAxios.get('clients/simple')
    return res.data
  },

  getClient: async (id) => {
    var res = await devAxios.get(`clients/${id}`)
    return res.data
  },

  create: async (client) => {
    var res = await devAxios.post(`clients`, client)
    return res.data
  },

  update: async (client) => {
    var res = await devAxios.put(`clients`, client)
    return res.data
  },

  delete: async (id) => {
    var res = await devAxios.delete(`clients`, { data: { id: id } })
    return res.data
  },

  getListAgency: async (tag = undefined) => {
    var res = await devAxios.get('agency', {
      params: {
        tag: tag,
      },
    })
    return res.data
  },

  getSingleAgency: async (id) => {
    var res = await devAxios.get(`agency/${id}`)
    return res.data
  },

  createNewAgency: async (agency_name) => {
    var res = await devAxios.post('agency', { name: agency_name })
    return res.data
  },

  updateAgency: async (id, agency_name) => {
    var res = await devAxios.put(`agency/${id}`, { name: agency_name })
    return res.data
  },

  deleteAgency: async (id) => {
    var res = await devAxios.delete(`agency/${id}`)
    return res.data
  },
}

export default ClientAPI
