import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SalesPlanAPI from '../../apis/SalesPlanAPI'
import BreadCrumbs from '../../components/BreadCrumbs'
import Loading from '../../components/Loading'
import { formatThousands } from '../../helper'
import Status from '../../components/Status'
import moment from 'moment'
import RadioButton from '../../components/RadioButton'
import Input from '../../components/Input'
import Alert from '../../components/Alert'
import DropdownInput from '../../components/DropdownInput'
import Textarea from '../../components/Textarea'
import DragAndDropFile from '../../components/DragAndDropFile'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import Button from '../../components/Button'
import ClientAPI from '../../apis/ClientAPI'
import StorageAPI from '../../apis/storageAPI'
import usePermission from '../../hooks/usePermission'
import ErrorPage from '../../ErrorPage'
import TagsDropdown from '../../components/TagsDropdown'

export default function EditMom() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(true)
  const [salesPlan, setSalesPlan] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const [client, setClient] = useState({
    pic_name: '',
    pic_title: '',
    pic_phone_number: '',
  })
  const [changeStatus, setChangeStatus] = useState()
  const [meetingNotes, setMeetingNotes] = useState()
  const [revenueEstimation, setRevenueEstimation] = useState('')
  const [price, setPrice] = useState('')
  const [isLoadingSaveMomButton, setLoadingSaveMomButton] = useState(false)
  const [errorSaveMom, setErrorSaveMom] = useState()
  const [errorGetSalesPlan, setErrorGetSalesPlan] = useState(false)
  const { isVP, isSM } = usePermission()

  const [tableCols, setTableCols] = useState([
    {
      id: 0,
      name: 'Item Name',
      selector: (row) => <Basic data={row.item_name} />,
    },
    {
      id: 1,
      name: 'Price',
      classes: 'text-right',
      selector: (row) => <Basic data={`Rp${formatThousands(row.price)}`} alignment="right" />,
    },
    {
      id: 2,
      name: 'Receipt or Proof',
      // classes: 'min-w-[200px]',
      selector: (row) => (
        <Basic
          data={
            <a href={row.receipt} target="_blank" className="underline text-slate-700 underline-offset-1">
              See File
            </a>
          }
        />
      ),
    },
  ])

  const [tableColsMobile, setTableColsMobile] = useState([
    {
      id: 0,
      name: 'Item Name',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.item_name} />,
    },
    {
      id: 1,
      name: 'Price',
      classes: 'text-right min-w-[150px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.price)}`} alignment="right" />,
    },
    {
      id: 2,
      name: 'Receipt or Proof',
      // classes: 'min-min-w-[150px]',
      classes: 'min-w-[150px]',
      selector: (row) => (
        <Basic
          data={
            <a href={row.receipt} target="_blank" className="underline text-slate-700 underline-offset-1">
              See File
            </a>
          }
        />
      ),
    },
  ])

  useEffect(() => {
    SalesPlanAPI.getSingleSalesPlan(id).then((res) => {
      if (res.success) {
        console.log(res.data)
        setSalesPlan(res.data)
        setClient(res.data.client)
        setSelectedTags(res.data.tags)
        setLoading(false)
      } else {
        setErrorGetSalesPlan(true)
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (salesPlan) {
      console.log(
        [
          { id: 0, text: 'Visit', value: 'visit' },
          { id: 1, text: 'Leads', value: 'leads' },
          { id: 2, text: 'Hot Prospect', value: 'hot_prospect' },
          { id: 3, text: 'On Hands', value: 'on_hands' },
          { id: 4, text: 'Revenue', value: 'revenue' },
        ].find((item) => item.value === salesPlan.change_status).id
      )
    }
    console.log(salesPlan)
  }, [salesPlan])

  const addCommas = (num) => {
    console.log(num)
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  const removeNonNumeric = (num) => {
    console.log(num)
    return num.toString().replace(/[^0-9]/g, '')
  }
  const handlePriceChange = (input) => {
    setPrice(addCommas(removeNonNumeric(input.value)))
  }

  const handleRevenueEstimation = (input) => {
    setSalesPlan({ ...salesPlan, revenue_estimation: addCommas(removeNonNumeric(input.value)) })
  }

  const handleSave = () => {
    setLoadingSaveMomButton(true)
    var temp = {
      sales_deal_activity_id: salesPlan.id,
      project_id: salesPlan.project.id,
      client_response: salesPlan.client_response,
      change_status: changeStatus ? changeStatus : salesPlan.change_status,
      meeting_notes: salesPlan.meeting_notes,
      revenue_estimation: parseInt(removeNonNumeric(salesPlan.revenue_estimation)),
      tags: selectedTags,
    }
    console.log(temp)
    SalesPlanAPI.editMom(temp)
      .then((res) => {
        console.log(res)
        if (res.success) {
          console.log(temp)
          navigate('/sales-plan')
          setLoadingSaveMomButton(false)
        } else {
          setErrorSaveMom('Failed saving Mom')
          setLoadingSaveMomButton(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (err.response.status === 403 && err.response.data.error_code === 'sales_deal_requirement_mismatch') {
          setErrorSaveMom(
            `You can't update sales deal status to ${changeStatus.replace(
              '_',
              ' '
            )}. Please complete all documents needed for ${changeStatus.replace('_', ' ')}.`
          )
          setLoadingSaveMomButton(false)
        } else if (err.response.status === 403) {
          setErrorSaveMom(err.response.data.message)
          setLoadingSaveMomButton(false)
        } else {
          setErrorSaveMom(err.message)
          setLoadingSaveMomButton(false)
        }
      })
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!errorGetSalesPlan ? (
            <>
              <div className="">
                {/* <p>Sales Plan ID :{id}</p> */}
                <BreadCrumbs>
                  <BreadCrumbs.Item href="/sales-plan">Sales Plan</BreadCrumbs.Item>
                  <BreadCrumbs.Item current>{salesPlan.project.name}</BreadCrumbs.Item>
                </BreadCrumbs>
              </div>
              <div className="max-w-[850px]">
                <div className="mb-8">
                  <h1 className="text-giant mb-4 font-semibold">Add MoM</h1>
                  <div className="project-detail border border-gray-border rounded-[5px] p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="">
                      <p className="font-semibold mb-0.5">Value</p>
                      <p className="font-normal">Rp{formatThousands(salesPlan.project.value)}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Number of Meetings</p>
                      <p className="font-normal">{salesPlan.number_of_meetings}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-1">Sales Deal Status</p>
                      <div className="">
                        <Status size={'small'} type={salesPlan.sales_deal.status} />
                      </div>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Project Type</p>
                      <p className="font-normal capitalize">{salesPlan.project.type}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Start Date</p>
                      <p className="font-normal">
                        {moment(salesPlan.sales_deal.created_at).utc().format('D MMMM YYYY')}
                      </p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Total Meeting Expenses</p>
                      <p className="font-normal">{salesPlan.mom_status === 'empty' ? 'Belum ada meeting' : '-'}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Account Owner</p>
                      <div className="font-normal flex gap-1 items-center">
                        <span className="w-6 h-6 rounded-full bg-slate-100 overflow-hidden">
                          <img src={salesPlan.account_owner.avatar} alt="avatar" className="w-full" />
                        </span>
                        <p>{salesPlan.account_owner.name}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Project Name</p>
                      <p className="font-normal">{salesPlan.project.name}</p>
                    </div>
                    <div className="">
                      <p className="font-semibold mb-0.5">Expired Date</p>
                      <p className="font-normal">
                        {salesPlan.sales_deal.expired_date
                          ? moment(salesPlan.sales_deal.expired_date).utc().format('D MMMM YYYY')
                          : '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-red-500">
                    <strong>*</strong> means required to be filled
                  </p>
                </div>
                <div className="mb-8">
                  <h1 className="text-giant mb-4 font-semibold">PIC Client</h1>
                  <div className="border border-gray-border rounded-[5px] p-4">
                    <div className="w-full md:w-[400px]">
                      {/* <p className="mb-4">Please enter the details of the PIC who attended the meeting.</p> */}
                      <div className="mb-4">
                        <p className="mb-2 font-semibold">Name</p>
                        <p>{salesPlan.pic_name}</p>
                      </div>
                      <div className="mb-4">
                        <p className="mb-2 font-semibold">Position</p>
                        <p>{salesPlan.pic_title}</p>
                      </div>
                      <div className="mb-4">
                        <p className="mb-2 font-semibold">Phone Number</p>
                        <p>{salesPlan.pic_phone_number}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <div className="mb-4">
                    <h1 className="text-giant mb-1 font-semibold">MoM</h1>
                    <p className="font-semibold">{moment(salesPlan.meeting_date).utc().format('dddd, D MMMM YYYY')}</p>
                  </div>
                  <div className="border border-gray-border rounded-[5px] p-4 flex gap-8">
                    <div className="left w-full md:w-[400px]">
                      <p className="font-semibold mb-1">
                        Client Response<strong className="text-red-500">*</strong>
                      </p>
                      <div className="flex flex-col gap-2 mb-8">
                        <RadioButton
                          label="Interested"
                          name="interested"
                          id="interested"
                          value="interested"
                          onChange={(selected) => {
                            setSalesPlan({ ...salesPlan, client_response: selected.target.value })
                          }}
                          checked={salesPlan.client_response === 'interested'}
                        />
                        <RadioButton
                          label="Not Interested"
                          name="not-interested"
                          id="not-interested"
                          value="not_interested"
                          onChange={(selected) => {
                            setSalesPlan({ ...salesPlan, client_response: selected.target.value })
                          }}
                          checked={salesPlan.client_response === 'not_interested'}
                        />
                        <RadioButton
                          label="Need More Discussion"
                          name="need-more-discussion"
                          id="need-more-discussion"
                          value="need_more_discussion"
                          onChange={(selected) => {
                            setSalesPlan({ ...salesPlan, client_response: selected.target.value })
                          }}
                          checked={salesPlan.client_response === 'need_more_discussion'}
                        />
                      </div>
                      <div className="right flex-1 lg:hidden">
                        <Textarea
                          rows={10}
                          label={'Meeting Notes'}
                          required
                          value={salesPlan.meeting_notes}
                          onChange={(e) => setSalesPlan({ ...salesPlan, meeting_notes: e.target.value })}
                          placeholder="Put meeting notes here..."
                        />
                      </div>
                      <Input
                        label="Revenue Estimation"
                        name="revenue_estimation"
                        placeholder="Revenue estimation"
                        value={salesPlan.revenue_estimation ? formatThousands(salesPlan.revenue_estimation) : 0}
                        onChange={(e) => handleRevenueEstimation(e.target)}
                        className={'mb-4'}
                      />
                      <div className="mb-4">
                        <p className="font-semibold mb-1">Change Status</p>
                        <DropdownInput
                          data={[
                            { id: 0, text: 'Visit', value: 'visit' },
                            { id: 1, text: 'Leads', value: 'leads' },
                            { id: 2, text: 'Hot Prospect', value: 'hot_prospect' },
                            { id: 3, text: 'On Hands', value: 'on_hands' },
                            { id: 4, text: 'Revenue', value: 'revenue' },
                          ]}
                          placeholder="Change Status"
                          attributeShown="text"
                          defaultValue={
                            [
                              { id: 0, text: 'Visit', value: 'visit' },
                              { id: 1, text: 'Leads', value: 'leads' },
                              { id: 2, text: 'Hot Prospect', value: 'hot_prospect' },
                              { id: 3, text: 'On Hands', value: 'on_hands' },
                              { id: 4, text: 'Revenue', value: 'revenue' },
                            ].find((item) => item.value === salesPlan.change_status).id
                          }
                          handleSelectedOption={(selected) => {
                            setChangeStatus(selected.value)
                          }}
                        />
                      </div>
                      {/* <div>
                        <TagsDropdown selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
                      </div> */}
                    </div>
                    <div className="right flex-1 hidden lg:block">
                      <Textarea
                        rows={10}
                        label={'Meeting Notes'}
                        required
                        value={salesPlan.meeting_notes}
                        onChange={(e) => setSalesPlan({ ...salesPlan, meeting_notes: e.target.value })}
                        placeholder="Put meeting notes here..."
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mb-4">
                    <h1 className="text-giant mb-1 font-semibold">Meeting Expenses</h1>
                    <p className="font-semibold">{moment(salesPlan.meeting_date).utc().format('dddd, D MMMM YYYY')}</p>
                  </div>
                  <div className="border border-gray-border rounded-[5px] p-4 flex flex-col lg:flex-row gap-8">
                    <div className="right flex-1 hidden lg:block">
                      <Table cols={tableCols} data={salesPlan.meeting_expenses} />
                    </div>
                    <div className="right flex-1 lg:hidden">
                      <Table cols={tableColsMobile} data={salesPlan.meeting_expenses} tableType="inline" />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-8 gap-4">
                  {errorSaveMom && (
                    <div>
                      <Alert type={'error'} message={errorSaveMom} />
                    </div>
                  )}
                  <Button onClick={handleSave} isLoading={isLoadingSaveMomButton}>
                    Save MoM
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <ErrorPage type={404} />
          )}
        </>
      )}
    </div>
  )
}
