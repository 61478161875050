import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import DropdownUser from '../../components/DropdownUser'
import SBUAPI from '../../apis/SBUAPI'
import AccountAPI from '../../apis/AccountAPI'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import StatusCell from '../../components/table/cell/StatusCell'
import ProjectAPI from '../../apis/ProjectAPI'
import { formatThousands } from '../../helper'
import LogoCell from '../../components/table/cell/LogoCell'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import moment from 'moment/moment'
import usePermission from '../../hooks/usePermission'
import { Link, createSearchParams, useNavigate } from 'react-router-dom'
import { Edit, NoteAdd } from '@mui/icons-material'
import Button from '../../components/Button'
import { useSelector } from 'react-redux'
import Pagination from '../../components/Pagination'
import Input from '../../components/Input'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'

const sortByDateUpdated = (arr) => {
  var temp = []
  let sortedArray = arr.sort(
    (a, b) => moment(b.updated_at).format('YYYYMMDDHHmmss') - moment(a.updated_at).format('YYYYMMDDHHmmss')
  )
  return sortedArray
}

export default function ProjectFeasibility() {
  const { isAdmin, isVP, isSM, isAE } = usePermission()
  const userData = useSelector((state) => state.auth.userData)
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState({ total_page: 1 })
  const [isLoading, setLoading] = useState(true)
  const [listSBU, setListSBU] = useState()
  const [listSM, setListSM] = useState()
  const [listAE, setListAe] = useState()
  const [status, setStatus] = useState([
    { id: 0, text: 'All Status', value: undefined },
    { id: 1, text: 'Approved', value: 'approved' },
    { id: 2, text: 'Pending', value: 'pending' },
    { id: 3, text: 'Rejected', value: 'rejected' },
    { id: 4, text: 'In Review SM', value: 'in_review_sm' },
    { id: 4, text: 'In Review VP', value: 'in_review_vp' },
    // { id: 5, text: 'Revenue', value: 'revenue' },
  ])
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'Pending',
      status: 'pending',
      state: 'warning',
    },
    {
      text: 'Need Revision',
      status: 'need_revision',
      state: 'warning-urgent',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
    {
      text: 'In Review - VP',
      state: 'default',
      status: 'in_review_vp',
    },

    {
      text: 'In Review - SM',
      state: 'default',
      status: 'in_review_sm',
    },
  ])
  const [projects, setProjects] = useState([])
  const [projectTableCols, setProjectTableCols] = useState([
    {
      id: 0,
      name: 'SBU',
      classes: 'min-w-[200px]',
      selector: (row) => <Basic data={row.sbu_name} />,
    },
    {
      id: 1,
      name: 'Client',
      classes: 'min-w-[200px]',
      selector: (row) => (
        <LogoCell
          img={row.client_logo ? row.client_logo : `https://ui-avatars.com/api/?name=Kata+Data`}
          imgShape={'circle'}
          text={row.client_name}
        />
      ),
    },
    {
      id: 2,
      name: 'Project Name',
      classes: 'min-w-[170px]',
      selector: (row) => <Basic data={row.project_name} />,
    },
    {
      id: 25,
      name: 'Project ID',
      classes: 'min-w-[250px]',
      selector: (row) => <Basic data={row?.cc_project_id || '-'} />,
    },
    {
      id: 3,
      name: 'Account Executive',
      classes: 'min-w-[200px]',
      selector: (row) => <LogoCell img={row.account_avatar} imgShape={'circle'} text={row.account_name} />,
    },
    {
      id: 4,
      name: 'Status',
      classes: 'min-w-[150px]',
      selector: (row) => <StatusCell data={row.project_status} config={stateCellConfig} />,
    },
    {
      id: 5,
      name: 'Total Revenue',
      classes: 'text-right min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`Rp${formatThousands(row.total_revenue)}`} />,
    },
    {
      id: 6,
      name: 'Total Cogs',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`Rp${formatThousands(row.total_cogs)}`} />,
    },
    {
      id: 7,
      name: 'Margin',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => (
        <Basic
          alignment="right"
          data={`${row.total_revenue ? ((1 - row.total_cogs / row.total_revenue) * 100).toFixed(1) : 0}%`}
        />
      ),
    },
    {
      id: 8,
      name: 'COGS',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => (
        <Basic
          alignment="right"
          data={`${row.total_revenue ? ((row.total_cogs / row.total_revenue) * 100).toFixed(1) : 0}%`}
        />
      ),
    },
  ])
  const [isLoadingProjects, setLoadingProject] = useState(true)
  const [isFilterChanged, setFilterChanged] = useState(false)

  // useEffect(() => {
  //   console.log(userData)
  // }, [])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU().then((res) => {
          setListSBU([{ sbu_name: 'All SBU', sbu_id: undefined }, ...res.data])
          resolve()
        })
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getList('sm').then((res) => {
          setListSM([{ name: 'All Sales Manager', id: undefined }, ...res.data])
          resolve()
        })
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getList('ae').then((res) => {
          setListAe([{ name: 'All Account Executive', id: undefined }, ...res.data])
          resolve()
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    ProjectAPI.getAllProjects(
      searchParams.sbu,
      searchParams.sm,
      searchParams.ae,
      searchParams.status,
      undefined,
      searchParams.page,
      searchParams.q
    )
      .then((res) => {
        setProjects(res.data)
        setLoadingProject(false)
        setPagination(res.meta)
      })
      .catch((err) => {
        setLoadingProject(false)
      })
  }, [searchParams.page, searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.status, searchParams.q])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      ProjectAPI.getAllProjects(
        searchParams.sbu,
        searchParams.sm,
        searchParams.ae,
        searchParams.status,
        undefined,
        searchParams.page,
        searchParams.q
      )
        .then((res) => {
          setProjects(res.data)
          setLoadingProject(false)
          setPagination(res.meta)
        })
        .catch((err) => {
          setLoadingProject(false)
        })
    }, 250)
    return () => clearTimeout(timeOutId)
  }, [searchParams.q])

  // useEffect(() => {
  //   const timeOutId = setTimeout(() => {
  //     ProjectAPI.getAllProjects(
  //       selectedSBU && selectedSBU.sbu_id,
  //       selectedSM && selectedSM.id,
  //       selectedAE && selectedAE.id,
  //       selectedStatus && selectedStatus.value,
  //       undefined,
  //       1,
  //       searchParams.q
  //     )
  //       .then((res) => {
  //         setFilterChanged(true)
  //         setSearchParams({ ...searchParams, page: 1 })
  //         setProjects(res.data)
  //         setLoadingProject(false)
  //         setPagination(res.meta)
  //       })
  //       .catch((err) => {
  //         setLoadingProject(false)
  //       })
  //   }, 200)
  //   return () => clearTimeout(timeOutId)
  // }, [searchParams])

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col gap-2 xl:flex-row lg:gap-4 mb-4">
            <div className="w-[250px] relative">
              <Input
                value={searchParams.q}
                onChange={(e) => setSearchParams({ ...searchParams, q: e.target.value, page: 1 })}
                label={'Search Project'}
                placeholder="Project name"
                className={''}
                containerClasses="relative -top-2"
              />
            </div>
            {(isVP || isAdmin) && (
              <>
                <DropdownUser
                  data={listSBU}
                  value={listSBU.find((sbu) => sbu.sbu_id == searchParams.sbu)}
                  noPic
                  type="Sales Business Unit"
                  handleSelectedOption={(selected) =>
                    setSearchParams({ ...searchParams, page: 1, sbu: selected.sbu_id || '' })
                  }
                  attributeShown="sbu_name"
                />
                <DropdownUser
                  data={listSM}
                  value={listSM.find((sm) => sm.id == searchParams.sm)}
                  noPic
                  type="Sales Manager"
                  handleSelectedOption={(selected) =>
                    setSearchParams({ ...searchParams, page: 1, sm: selected.id || '' })
                  }
                  attributeShown="name"
                />
              </>
            )}

            {(isVP || isSM || isAdmin) && (
              <DropdownUser
                data={listAE}
                value={listAE.find((ae) => ae.id == searchParams.ae)}
                noPic
                type="Account Executive"
                handleSelectedOption={(selected) =>
                  setSearchParams({ ...searchParams, page: 1, ae: selected.id || '' })
                }
                attributeShown="name"
              />
            )}
            <DropdownUser
              data={status}
              noPic
              type="Status"
              value={status.find((el) => el.value == searchParams.status)}
              handleSelectedOption={(selected) =>
                setSearchParams({ ...searchParams, page: 1, status: selected.value || '' })
              }
              attributeShown="text"
            />
          </div>
          <div>
            {isAE ? (
              <Table
                data={projects}
                cols={projectTableCols}
                viewLink={(row) => `view/${row.id}`}
                // withActions
                // // viewLink={(row) => (user.role === 'ae' ? `/projects/view/${row.id}` : `/projects/review/${row.id}`)}
                // editLink={(row) =>
                //   row.project_status === 'pending' || row.project_status === 'rejected'
                //     ? `/projects/edit/s/${row.id}`
                //     : `/projects/view/${row.id}`
                // }
                customAction={(row) => (
                  <>
                    {row.project_status === 'pending' ||
                    row.project_status === 'need_revision' ||
                    row.project_status === 'rejected' ? (
                      <Link
                        to={`/projects/edit/s/${row.id}`}
                        className="inline-flex w-8 h-8 items-center justify-center rounded text-main-100 hover:bg-sky-200 hover:text-main-500 transition-all"
                      >
                        <Edit sx={{ fontSize: 20 }} />
                      </Link>
                    ) : null}
                  </>
                )}
                tableType="inline"
                pageIndex={searchParams.page}
              />
            ) : isSM ? (
              <Table
                data={projects}
                cols={projectTableCols}
                viewLink={(row) => `view/${row.id}`}
                customAction={(row) => (
                  <>
                    {row.project_status === 'in_review_sm' && row.sales_manager_id === userData.id ? (
                      <Button icon={<NoteAdd sx={{ fontSize: 16 }} />} href={`/projects/review/${row.id}`}>
                        Review
                      </Button>
                    ) : null}
                  </>
                )}
                tableType="inline"
                pageIndex={searchParams.page}
              />
            ) : (
              <Table
                data={projects}
                cols={projectTableCols}
                viewLink={(row) => `view/${row.id}`}
                customAction={(row) => (
                  <>
                    {row.project_status === 'in_review_vp' && row.sbu_id === userData.sbu_id ? (
                      <Button icon={<NoteAdd sx={{ fontSize: 16 }} />} href={`/projects/review/${row.id}`}>
                        Review
                      </Button>
                    ) : null}
                  </>
                )}
                tableType="inline"
                pageIndex={searchParams.page}
              />
            )}
            <Pagination
              totalPage={pagination.total_page || 1}
              currentPage={parseInt(searchParams.page) || 1}
              onChange={(page) =>
                navigate({
                  pathname: '/projects',
                  search: `?${createSearchParams({ ...searchParams, page: page })}`,
                })
              }
            />
          </div>
        </>
      )}
    </div>
  )
}
