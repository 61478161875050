import { el } from 'date-fns/locale'
import { devAxios } from './axiosConfig'

const SalesDealAPI = {
  getAllSalesDeal: async (
    client_id = undefined,
    sbu_id = undefined,
    value_range_max = undefined,
    value_range_min = undefined,
    flagship_project_id = undefined,
    date_start = undefined,
    date_end = undefined,
    industry = undefined,
    topic = undefined,
    account_id = undefined
  ) => {
    var query = {
      client_id: client_id,
      sbu_id: sbu_id,
      value_range_max: value_range_max,
      value_range_min: value_range_min,
      flagship_project_id: flagship_project_id,
      date_start: date_start,
      date_end: date_end,
      industry: industry,
      topic: topic,
      account_id: account_id,
    }
    var res = await devAxios.get('sales-deal', { params: query })

    return res.data
  },

  getSalesDealByStatus: async (
    status,
    client_id = undefined,
    sbu_id = undefined,
    value_range_max = undefined,
    value_range_min = undefined,
    // flagship_project_id = undefined,
    page = 1
  ) => {
    var query = {
      // flagship_project_id: flagship_project_id,
    }
    var res = await devAxios.get(`sales-deal/status/${status}`, {
      params: {
        page: page || 1,
        client_id: client_id || undefined,
        sbu_id: sbu_id || undefined,
        value_range_max: value_range_max,
        value_range_min: value_range_min,
      },
    })
    return res.data
  },

  getSalesDealById: async (id) => {
    var res = await devAxios.get(`sales-deal/${id}`)
    return res.data
  },

  saveSalesDealDocument: async (data) => {
    var res = await devAxios.post('sales-deal/document', data)
    return res.data
  },

  saveCl: async (data) => {
    var res = await devAxios.post('documents/confirmation-letter', data)
    return res.data
  },

  updateSalesDealStatus: async (id, status) => {
    var res = await devAxios.put('sales-deal', { id: id, status: status })
    return res.data
  },

  updateSalesDealExpiredDate: async (sales_deal_id, expired_date) => {
    var res = await devAxios.put('sales-deal/expired-date', {
      sales_deal_id: sales_deal_id,
      expired_date: expired_date,
    })
    return res.data
  },

  updateSalesDealDocument: async (sales_deal_document_id, document_url) => {
    var res = await devAxios.put('sales-deal/document', {
      sales_deal_document_id: sales_deal_document_id,
      document_url: document_url,
    })
    return res.data
  },

  updateSalesDealLoss: async (id, reason) => {
    var res = await devAxios.put(`sales-deal/status-loss/`, {
      sales_deal_id: id,
      reason: reason,
    })
    return res.data
  },

  generateQuotation: async (id) => {
    var res = await devAxios.get(`documents/quotation/generate/${id}`)
    return res.data
  },

  getConfirmationLetter: async (id) => {
    var res = await devAxios.get(`documents/confirmation-letter/${id}`)
    return res.data
  },

  saveInvoiceData: async (data) => {
    var res = await devAxios.post('documents/invoice', data)
    return res.data
  },

  getInvoiceData: async (id) => {
    var res = await devAxios.get(`documents/invoice/${id}`)
    return res.data
  },
}

export default SalesDealAPI
