import React, { useEffect, useState } from 'react'
import Tabs from '../../components/Tabs'
import BreadCrumbs from '../../components/BreadCrumbs'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import SBUAPI from '../../apis/SBUAPI'
import toast, { Toaster } from 'react-hot-toast'
import LogoCell from '../../components/table/cell/LogoCell'

const nameShorten = (name) => {
  var temp = name.split(' ')
  var short = ''
  temp.forEach((element) => {
    short += element[0]
  })
  return short
}

export default function SBU() {
  const [isLoading, setLoading] = useState(true)
  const [sbuCols, setSBUCols] = useState([
    {
      id: 0,
      name: 'Sbu Name',
      classes: 'min-w-[250px]',
      selector: (row) => <Basic data={row.sbu_name} />,
    },
    {
      id: 1,
      name: 'Logo',
      classes: 'min-w-[150px]',
      selector: (row) => (
        <LogoCell
          img={
            row.sbu_image || `https://ui-avatars.com/api/?name=${nameShorten(row.sbu_name)}&background=888888&color=fff`
          }
        />
      ),
    },
    {
      id: 2,
      name: 'COGS Guideline',
      classes: 'min-w-[120px]',
      selector: (row) => <Basic data={`${(row.cogs_guideline * 100).toFixed(0)}%`} />,
    },
  ])
  const [sbu, setSbu] = useState([])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU()
          .then((res) => {
            setSbu(res.data)
            resolve()
          })
          .catch((err) => {
            if (err.response) toast.error(err.response.data.message)
            else toast.error(err.statusText)
            setLoading(false)
            reject()
          })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  return (
    <div className="py-4">
      <Toaster />
      <BreadCrumbs className={'mb-4'}>
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item href={'/data/other-data'}>Other Data</BreadCrumbs.Item>
        <BreadCrumbs.Item current>SBU</BreadCrumbs.Item>
      </BreadCrumbs>
      <Tabs
        className={'mb-4'}
        data={[
          { id: 1, name: 'Agency', link: '/data/other-data' },
          { id: 2, name: 'SBU', link: '/data/other-data/sbu' },
        ]}
      />
      <div className="">
        <Table
          data={sbu}
          cols={sbuCols}
          withActions={true}
          tableType="inline"
          editLink={(row) => `${row.sbu_id}`}
          withoutDelete
        />
      </div>
    </div>
  )
}
