import { storage, storageRef } from './firebaseConfig'
import { deleteObject, getDownloadURL, getMetadata, uploadBytes } from 'firebase/storage'

const StorageAPI = {
  uploadFile: async (path, file, metadata = {}) => {
    var res = await uploadBytes(storageRef(path), file, metadata).then(async (snapshot) => {
      var resURL = await getDownloadURL(snapshot.ref).then((url) => {
        // console.log(url)
        return url
      })
      return resURL
    })
    // console.log(res)
    return res
  },

  deleteFile: async (path) => {
    var res = await deleteObject(path)
    return res
  },

  // getDownloadUrl: (ref) => {
  //   var res = ref(storage, `gs://${bucket}/${fullPath}`)
  //   return res
  // },

  getMetadataFromURL: async (url) => {
    var res = await getMetadata(storageRef(url)).then(async (meta) => {
      return meta
    })
    return res
  },
}

export default StorageAPI
