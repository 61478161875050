import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import BarChartOutlined from '@mui/icons-material/BarChartOutlined'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import CardTravel from '@mui/icons-material/CardTravel'
import Ballot from '@mui/icons-material/Ballot'
import FolderOpenRounded from '@mui/icons-material/FolderOpenRounded'

import MenuIcon from '@mui/icons-material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from './Button'
import { UserAuth } from '../context/AuthContext'
import { signOut } from 'firebase/auth'
import { auth } from '../apis/firebaseConfig'
import CRMLogo from '../assets/icons/crm-logo.png'
import { useLocalStorage } from '../hooks/useLocalStorage'
import DropdownNavbar from './DropdownNavbar'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { signOut as reduxSignOut } from '../redux/authSlice'

export default function Navbar({ className }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  const user = useSelector((state) => state.auth.userData)
  const [isShowDropdown, setShowDropdown] = useState(false)
  const dispatch = useDispatch()
  // const [isActive]
  const logout = () =>
    signOut(auth).then((res) => {
      // setUser({})
      dispatch(reduxSignOut())
    })
  return (
    <>
      <nav
        className={`absolute lg:sticky top-0 z-30 flex flex-wrap items-center justify-between w-full min-h-[56px] bg-main-500 ${
          className ? className : ''
        }`}
      >
        <div className="container mx-auto flex flex-wrap items-center justify-between lg:justify-start max-w-[1440px] px-6">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:flex lg:justify-start">
            <Link
              className="font-bold leading-relaxed mr-4 py-2 whitespace-nowrap uppercase text-white flex items-center justify-center overflow-hidden"
              to="/"
            >
              <img src={CRMLogo} alt="crm-logo" className="w-[150px]" />
            </Link>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <span className="h-full w-6 text-center ">
                <MenuIcon sx={{ fontsize: 24 }} />
              </span>
            </button>
          </div>
          <div
            className={'lg:flex items-center' + (navbarOpen ? ' flex' : ' hidden') + ' w-full lg:w-auto lg:flex-1'}
            id="example-navbar-danger"
          >
            <ul className="flex flex-col items-start w-full lg:flex-row lg:items-center">
              <div className="flex-1 flex flex-col list-none w-full lg:w-fit lg:ml-auto lg:flex-row lg:items-center">
                <li className="nav-item shrink-0">
                  <NavLink to="/performance">
                    {({ isActive }) => {
                      return (
                        <span
                          onClick={() => setNavbarOpen(false)}
                          className={
                            isActive
                              ? 'px-3 py-2 flex items-center font-bold leading-snug text-primary-yellow rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                              : 'px-3 py-2 flex items-center font-bold leading-snug text-white rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                          }
                        >
                          <span className="h-full w-6 text-center">
                            <BarChartOutlined sx={{ fontsize: 24 }} />
                          </span>
                          <span className="ml-2 text-normal font-semibold">Performance</span>
                        </span>
                      )
                    }}
                  </NavLink>
                </li>
                <li className="nav-item shrink-0">
                  <NavLink to="/sales-plan" onClick={() => setNavbarOpen(false)}>
                    {({ isActive }) => {
                      return (
                        <span
                          onClick={() => setNavbarOpen(false)}
                          className={
                            isActive
                              ? 'px-3 py-2 flex items-center font-bold leading-snug text-primary-yellow rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                              : 'px-3 py-2 flex items-center font-bold leading-snug text-white rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                          }
                        >
                          <span className="h-full w-6 text-center">
                            <CalendarMonth sx={{ fontsize: 24 }} />
                          </span>
                          <span className="ml-2 text-normal font-semibold">Sales Plan</span>
                        </span>
                      )
                    }}
                  </NavLink>
                </li>
                <li className="nav-item shrink-0">
                  <NavLink to="/sales-deal" onClick={() => setNavbarOpen(false)}>
                    {({ isActive }) => {
                      return (
                        <span
                          onClick={() => setNavbarOpen(false)}
                          className={
                            isActive
                              ? 'px-3 py-2 flex items-center font-bold leading-snug text-primary-yellow rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                              : 'px-3 py-2 flex items-center font-bold leading-snug text-white rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                          }
                        >
                          <span className="h-full w-6 text-center">
                            <CardTravel sx={{ fontsize: 24 }} fill="on" />
                          </span>
                          <span className="ml-2 text-normal font-semibold">Sales Deal</span>
                        </span>
                      )
                    }}
                  </NavLink>
                </li>
                <li className="nav-item shrink-0">
                  <NavLink to="/projects" onClick={() => setNavbarOpen(false)}>
                    {({ isActive }) => {
                      return (
                        <span
                          onClick={() => setNavbarOpen(false)}
                          className={
                            isActive
                              ? 'px-3 py-2 flex items-center font-bold leading-snug text-primary-yellow rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                              : 'px-3 py-2 flex items-center font-bold leading-snug text-white rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                          }
                        >
                          <span className="h-full w-6 text-center">
                            <Ballot sx={{ fontsize: 24 }} />
                          </span>
                          <span className="ml-2 text-normal font-semibold">Projects</span>
                        </span>
                      )
                    }}
                  </NavLink>
                </li>
                <li className="nav-item shrink-0">
                  <NavLink to="/data" onClick={() => setNavbarOpen(false)}>
                    {({ isActive }) => {
                      return (
                        <span
                          onClick={() => setNavbarOpen(false)}
                          className={
                            isActive
                              ? 'px-3 py-2 flex items-center font-bold leading-snug text-primary-yellow rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                              : 'px-3 py-2 flex items-center font-bold leading-snug text-white rounded-lg hover:bg-main-400 hover:opacity-75 transition-all'
                          }
                        >
                          <span className="h-full w-6 text-center">
                            <FolderOpenRounded sx={{ fontsize: 24 }} fill="on" />
                          </span>
                          <span className="ml-2 text-normal font-semibold">Data</span>
                        </span>
                      )
                    }}
                  </NavLink>
                </li>
              </div>

              <li>
                {user ? (
                  <div className=" gap-4 lg:flex items-center ">
                    {/* <Button onClick={() => logout()}>Log out</Button> */}
                    {/* <Button href={'/login'}>Login</Button> */}

                    <div>
                      <DropdownNavbar logout={logout} className="mb-4 lg:mb-0">
                        <span className="px-2.5 py-1 rounded flex items-center gap-4">
                          <p className="text-white font-semibold">{user.name}</p>
                          <span className="w-[30px] h-[30px] rounded-full overflow-hidden flex items-center justify-center">
                            <img src={user.avatar} alt="user_avatar"></img>
                          </span>
                        </span>
                      </DropdownNavbar>
                    </div>
                  </div>
                ) : (
                  <div className=" gap-4 lg:flex items-center ">
                    <Button onClick={() => logout()}>Log out</Button>
                    <Button href={'/login'}>Login</Button>
                    <button className="text-white w-8 h-8 flex items-center justify-center rounded hover:bg-main-400 hover:opacity-75 transition-all">
                      <MoreVertIcon sx={{ fontsize: 24 }} />
                    </button>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
