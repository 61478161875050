import { ArrowForward } from '@mui/icons-material'
import React from 'react'
import { useState } from 'react'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import { formatThousands } from '../../helper'
import { Link } from 'react-router-dom'
import RevenueItemsTable from '../../components/RevenueItemsTable'
import COGSItemsTable from '../../components/COGSItemsTable'

export default function SalesDealProjectFeasibility({ salesDeal }) {
  const [project, setProject] = useState(salesDeal.project)
  const [marginCols, setMarginCols] = useState([
    { id: 0, name: 'Component', selector: (row) => <Basic data={row.row} />, classes: 'w-[180px] font-semibold' },
    {
      id: 1,
      name: 'Total Amount',
      selector: (row) => <Basic data={`Rp${formatThousands(row.totalAmount)}`} alignment={'right'} />,
      classes: 'w-[180px]  text-right',
    },
    {
      id: 2,
      name: 'Percentage',
      selector: (row) => <Basic data={`${(row.percentage * 100).toFixed(2)}%`} alignment={'right'} />,
      classes: 'w-[180px] text-right',
    },
  ])

  return (
    <div className="">
      <div className="mb-8">
        <p className="font-semibold">Project Feasibility Status</p>
        <Link to={`/projects/view/${salesDeal.project.id}`} className="flex items-center gap-2 mb">
          <p className="capitalize">{salesDeal.project.status.replace('_', ' ')}</p>
          <ArrowForward sx={{ fontSize: 16 }} />
        </Link>
      </div>
      <RevenueItemsTable project={project} setProject={setProject} isEditable={false} />
      <COGSItemsTable project={project} setProject={setProject} isEditable={false} />
      <div className="mb-8">
        <h1 className="text-giant mb-4 font-semibold">Margin</h1>
        <div className="border border-gray-border rounded-[5px] p-4">
          <Table
            tableType="inline"
            noIndex={true}
            cols={marginCols}
            data={[
              {
                id: 0,
                row: 'Revenue',
                totalAmount: project.total_revenue,
                percentage: project.total_revenue !== 0 ? 1 - project.total_cogs / project.total_revenue : 0,
              },
              {
                id: 1,
                row: 'COGS',
                totalAmount: project.total_cogs,
                percentage: project.total_revenue !== 0 ? project.total_cogs / project.total_revenue : 1,
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}
