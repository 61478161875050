import React, { useEffect, useState } from 'react'
import Status from '../../components/Status'
import { dotsLimiter, formatThousands } from '../../helper'
import moment from 'moment'
import DragAndDropFile from '../../components/DragAndDropFile'
import {
  ArrowForward,
  ArrowRight,
  BusinessCenter,
  Close,
  Description,
  DescriptionRounded,
  Edit,
  NoteAdd,
} from '@mui/icons-material'
import Button from '../../components/Button'
import StorageAPI from '../../apis/storageAPI'
import SalesDealAPI from '../../apis/SalesDealAPI'
import { Link, useNavigate } from 'react-router-dom'
import Alert from '../../components/Alert'
import { DatePicker } from '@mui/x-date-pickers'
import DropdownDatePicker from '../../components/DropdownDatePicker'
import { toast } from 'react-hot-toast'
import DismissableToast from '../../components/Toast'
import ProjectAPI from '../../apis/ProjectAPI'
import usePermission from '../../hooks/usePermission'
import Modal from '../../components/Modal'
import Textarea from '../../components/Textarea'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import StatusCell from '../../components/table/cell/StatusCell'
import InvoiceAPI from '../../apis/InvoiceAPI'
import { useSelector } from 'react-redux'

const DocumentView = ({ type, docs, label, handleUpdateDocs, handleAddDocs, project, className, salesDeal }) => {
  const [tempDocs, setTempDocs] = useState()
  const [showInput, setShowInput] = useState(docs !== undefined)
  const { isAE } = usePermission()

  useEffect(() => {
    // console.log(tempDocs?.type.split('/').pop())
  }, [tempDocs])

  useEffect(() => {
    // console.log(docs)
    if (tempDocs) {
      if (docs === undefined) {
        StorageAPI.uploadFile(
          `sales-deal-documents/${type}/${type}-${project.name.replaceAll(' ', '_')}.${tempDocs?.type
            .split('/')
            .pop()}`,
          tempDocs,
          {
            name: `${type}-${project.name.replaceAll(' ', '_')}.${tempDocs?.type.split('/').pop()}`,
          }
        ).then((res) => {
          // console.log(res)
          handleAddDocs(type, res)
        })
      } else {
        StorageAPI.uploadFile(
          `sales-deal-documents/${type}/${type}-${project.name.replaceAll(' ', '_')}.${tempDocs?.type
            .split('/')
            .pop()}`,
          tempDocs,
          {
            name: `${type}-${project.name.replaceAll(' ', '_')}.${tempDocs?.type.split('/').pop()}`,
          }
        ).then((res) => {
          // console.log(res)
          handleUpdateDocs(type, docs.id, res)
        })
      }
    }
  }, [tempDocs])

  if (!isAE) {
    return (
      <div className={`${className}`}>
        <p className="mb-2 font-semibold">{label}</p>
        {docs ? (
          <a
            href={docs.url}
            target="_blank"
            className="flex-1 flex gap-2 items-center py-2 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit"
          >
            <span className="w-3 h-3 flex items-center justify-center">
              <DescriptionRounded sx={{ fontSize: 20 }} />
            </span>
            {docs.metadata.name}
          </a>
        ) : (
          <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px] h-fit">
            {/* <i className="w-6 h-6 flex items-center justify-center"> */}
            <Description sx={{ fontSize: 16 }} />
            {/* </i> */}
            <p>No File Yet</p>
          </div>
        )}
      </div>
    )
  } else if (salesDeal.status === 'loss' && !showInput)
    return (
      <div className={`${className}`}>
        <p className="mb-2 font-semibold">{label}</p>
        <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px] h-fit">
          {/* <i className="w-6 h-6 flex items-center justify-center"> */}
          <Description sx={{ fontSize: 16 }} />
          {/* </i> */}
          <p>No File Yet</p>
        </div>
      </div>
    )

  if (showInput) {
    return (
      <div className={`${className}`}>
        <p className="mb-2 font-semibold">{label}</p>
        <div className="flex gap-2 items-center">
          <a
            href={docs.url}
            target="_blank"
            className="flex-1 flex gap-2 items-center py-2 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit"
          >
            <span className="w-3 h-3 flex items-center justify-center">
              <DescriptionRounded sx={{ fontSize: 20 }} />
            </span>
            {docs.metadata.name}
          </a>
          <button
            onClick={() => setShowInput(!showInput)}
            className="flex items-center justify-center w-6 h-6 rounded-full text-gray-600 hover:bg-red-200 hover:text-red-600 transition-all"
          >
            <Close sx={{ fontSize: 16 }} />
          </button>
        </div>
      </div>
    )
  }
  return (
    <DragAndDropFile
      label={label}
      className={`py-8 w-[270px] flex-1 ${className}`}
      value={tempDocs}
      onChange={(file) => setTempDocs(file)}
    />
  )
}

// const InvoiceView = ({ invoices, handleAddDocs, handleUpdateDocs, project }) => {
//   const [newFile, setNewFile] = useState()
//   useEffect(() => {}, [])

//   useEffect(() => {}, [])

//   useEffect(() => {
//     // console.log(docs)
//     if (newFile) {
//       StorageAPI.uploadFile(`sales-deal-documents/${'invoice'}/invoice-${project.name}`, newFile).then((res) => {
//         // console.log(res)
//         handleAddDocs('invoice', res)
//       })
//     }
//   }, [newFile])

//   return (
//     <div className="">
//       <p className="mb-2 font-semibold">Invoice</p>
//       <Button className={'w-fit'}>Request Invoice</Button>
//       <div className="flex flex-col gap-2 mb-4">
//         {invoices.length === 0
//           ? null
//           : invoices.map((invoice) => {
//               return <DocumentView docs={invoice} handleAddDocs={handleAddDocs} handleUpdateDocs={handleUpdateDocs} />
//             })}
//       </div>
//       <DragAndDropFile
//         label={'Add new invoice'}
//         project={project}
//         className={'py-8 w-[270px] mb-2'}
//         value={newFile}
//         onChange={(file) => setNewFile(file)}
//       />
//     </div>
//   )
// }

export default function SalesDealOverview({ salesDeal, setRefreshDOM }) {
  const user = useSelector((state) => state.auth.userData)
  const [updatedSalesDeal, setUpdatedSalesDeal] = useState(salesDeal)
  const [lossError, setLossError] = useState()
  const [isShowLossModal, setShowLossModal] = useState(false)
  const [reasonToLoss, setReasonToLoss] = useState('')
  const [generateQuotationError, setGenerateQuotationError] = useState()
  const [isLoadingGenerateQuotation, setLoadingGenerateQuotation] = useState(false)
  const [isLoadingRequestReview, setLoadingRequestReview] = useState(false)
  const [errorRequestReview, setErrorRequestReview] = useState()
  const [isLoadingStatusLoss, setLoadingStatusLoss] = useState()
  const [reasonToLossError, setReasonToLossError] = useState()
  const navigate = useNavigate()
  const { isAE } = usePermission()
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'In Review',
      status: 'in_review',
      state: 'warning',
    },
    {
      text: 'Pending',
      status: 'pending',
      // state: 'success',
    },
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
  ])
  const [invoiceCols, setInvoiceCols] = useState([
    {
      id: 0,
      name: 'Terms',
      classes: '!w-16',
      selector: (row) => <Basic data={row.term} />,
    },
    {
      id: 2,
      name: 'Amount',
      classes: 'text-right min-w-[150px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.amount)}`} alignment={'right'} />,
    },
    {
      id: 3,
      name: 'Invoice Date',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={moment(row.invoice_date).format('D MMMM YYYY')} />,
    },
    {
      id: 4,
      name: 'Invoice Due Date',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={moment(row.due_date).format('D MMMM YYYY')} />,
    },
    {
      id: 5,
      name: 'Invoice Number',
      classes: 'min-w-[150px]',
      selector: (row) => <Basic data={row.invoice_number ? row.invoice_number : '-'} />,
    },
    {
      id: 6,
      name: 'Invoice Status',
      classes: 'capitalize min-w-[150px]',
      selector: (row) => <StatusCell data={row.status} config={stateCellConfig} />,
    },
    {
      id: 6,
      name: 'Finance Note',
      classes: 'min-w-[250px] max-w-[350px]',
      selector: (row) => <Basic data={row.finance_review_note || '-'} />,
    },
    // isAE && {
    //   id: 7,
    //   name: 'Action',
    //   classes: 'capitalize',
    //   selector: (row) => <Button icon={<Description sx={{ fontSize: 20 }} />}>Send Request</Button>,
    // },
  ])

  useEffect(() => {
    setUpdatedSalesDeal(salesDeal)
  }, [salesDeal])

  // useState(() => {
  //   console.log(salesDeal)
  // }, [salesDeal, proposal])

  // useEffect(() => {
  //   console.log(updatedSalesDeal)
  // }, [updatedSalesDeal])

  // useEffect(() => {
  //   if (spk) {
  //     StorageAPI.uploadFile(`sales-deal-documents/spk/${spk.name}`, spk).then((res) => {
  // console.log(res)
  //       SalesDealAPI.saveSalesDealDocument({
  //         sales_deal_id: updatedSalesDeal.id,
  //         project_id: updatedSalesDeal.project.id,
  //         type: 'spk',
  //         document_url: res,
  //       }).then((res) => {
  //         navigate(0)
  //       })
  //     })
  //   }
  // }, [spk])

  // useEffect(() => {
  //   if (bast) {
  //     StorageAPI.uploadFile(`sales-deal-documents/bast/${bast.name}`, bast).then((res) => {
  // console.log(res)
  //       SalesDealAPI.saveSalesDealDocument({
  //         sales_deal_id: updatedSalesDeal.id,
  //         project_id: updatedSalesDeal.project.id,
  //         type: 'bast',
  //         document_url: res,
  //       }).then((res) => {
  //         navigate(0)
  //       })
  //     })
  //   }
  // }, [bast])

  const updateExpiredDate = (date) => {
    SalesDealAPI.updateSalesDealExpiredDate(updatedSalesDeal.id, date).then((res) => {
      if (res.success) navigate(0)
    })
  }

  const handleAddDocs = (type, url) => {
    // console.log(type, url)
    SalesDealAPI.saveSalesDealDocument({
      sales_deal_id: updatedSalesDeal.id,
      project_id: updatedSalesDeal.project.id,
      type: type,
      document_url: url,
    }).then((res) => {
      if (res.success) setRefreshDOM(Math.random())
      else {
        toast.error('Failed to upload your file')
      }
    })
  }

  const handleUpdateDocs = (type, id, url) => {
    // console.log(type, id, url)
    SalesDealAPI.updateSalesDealDocument(id, url).then((updateRes) => {
      console.log(updateRes)
      if (!updateRes.error) {
        setRefreshDOM(Math.random())
      } else {
        toast.error('Failed to upload your file')
      }
    })
  }

  // const handleRemoveDocument = (type) => {
  //   var temp = updatedSalesDeal.documents
  //   delete temp[type]
  //   setUpdatedSalesDeal({ ...updatedSalesDeal, documents: temp })
  // }

  const handleSetStatusLoss = () => {
    setLoadingStatusLoss(true)
    if (reasonToLoss) {
      SalesDealAPI.updateSalesDealLoss(salesDeal.id, reasonToLoss)
        .then((res) => {
          console.log(res.data)
          if (res.success) {
            navigate('/sales-deal')
            setLoadingStatusLoss(false)
          } else {
            setLossError(res.message)
            setLoadingStatusLoss(false)
          }
        })
        .catch((err) => {
          setLossError(err.response.data.message)
          setLoadingStatusLoss(false)
        })
    } else {
      setReasonToLossError('Please write your reason to setting project status to loss!')
      setLoadingStatusLoss(false)
    }
  }

  const handleGenerateQuotation = () => {
    setLoadingGenerateQuotation(true)
    SalesDealAPI.generateQuotation(updatedSalesDeal.id)
      .then((res) => {
        console.log(res.data)
        if (res.success) {
          setRefreshDOM(Math.random())
          setLoadingGenerateQuotation(false)
        } else {
          setGenerateQuotationError(res.message)
          setLoadingGenerateQuotation(false)
        }
      })
      .catch((err) => {
        setGenerateQuotationError(err.message)
        setLoadingGenerateQuotation(false)
      })
  }

  const saveToBeReviewed = () => {
    setLoadingRequestReview(true)

    var tempObject = {
      id: salesDeal.project.id,
      name: salesDeal.project.name,
      description: salesDeal.project.description,
      type: salesDeal.project.type,
      flagship_project_id: salesDeal.project.flagship_project_id,
      status: 'in_review_sm',
      topic: salesDeal.project.topic,
      revenue_items: salesDeal.project.revenue_items.map((item) => {
        return {
          product_id: item.product_id,
          sub_product_id: item.sub_product_id,
          product_variant_id: item.product_variant_id,
          price: item.price,
          quantity: item.quantity,
          discount_type: item.discount_type,
          discount_percentage: item.discount_percentage,
          discount_absolute: item.discount_absolute,
        }
      }),
      // cogs_items: salesDeal.project.cogs_items.map((item) => {
      //   return { cogs_id: item.cogs_id, cost: item.cost, quantity: item.quantity, custom_name: '' }
      // }),
    }
    // console.log(tempObject)
    ProjectAPI.updateProject(tempObject.id, tempObject)
      .then((res) => {
        // console.log(res)
        setRefreshDOM(Math.random())
        setLoadingRequestReview(false)
      })
      .catch((err) => {
        if (err.response) {
          setErrorRequestReview(err.response.data.message)
        } else {
          setErrorRequestReview(err.statusText)
        }
        setLoadingRequestReview(false)
      })
  }

  const sendRequestInvoice = (id) => {
    InvoiceAPI.sendRequestInvoice(id)
      .then((res) => {
        if (res.success) {
          toast.success(res.message)
          setRefreshDOM(Math.random())
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message)
        } else {
          toast.error(err.statusText)
        }
      })
  }

  return (
    <>
      <div className="mb-8">
        <DismissableToast />
        {isShowLossModal && (
          <Modal setIsOpen={setShowLossModal}>
            <Modal.Header setIsOpen={setShowLossModal}>
              <p className="font-semibold text-big">Change Status To Loss</p>
            </Modal.Header>
            <Modal.Body setIsOpen={setShowLossModal}>
              <div className="flex items-center gap-4 mb-6">
                <span className="w-4 h-4 flex items-center justify-center">
                  <BusinessCenter sx={{ fontSize: 20 }} />
                </span>
                <p className="font-semibold text-big">{salesDeal.project.name}</p>
              </div>
              <Textarea
                name="reason_to_loss"
                label={'Reason'}
                rows={4}
                onChange={(e) => setReasonToLoss(e.target.value)}
                value={reasonToLoss}
                placeholder={'Please write the reasons in detail!'}
              />
            </Modal.Body>
            <Modal.Footer>
              <div className="flex justify-end gap-4">
                {reasonToLossError && <Alert className={'flex-1'} type={'error'} message={reasonToLossError} />}
                <Button onClick={() => setShowLossModal(false)} variant={'outlined'}>
                  Cancel
                </Button>
                <Button isLoading={isLoadingStatusLoss} onClick={handleSetStatusLoss}>
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        <h1 className="font-semibold text-giant mb-4">Sales Deal</h1>
        <div className="rounded-[5px] border border-gray-border p-4 grid grid-cols-2 gap-4 w-full md:grid-cols-4 md:gap-16 md:w-fit ">
          <div className="min-w-[125px]">
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Client</h3>
              <p className="">{updatedSalesDeal.client.company_name}</p>
            </div>
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Project Type</h3>
              <p className="">{updatedSalesDeal.project.type}</p>
            </div>
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Project Name</h3>
              <p className="">{updatedSalesDeal.project.name}</p>
            </div>
          </div>
          <div className="min-w-[125px]">
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Value</h3>
              <p className="">
                {updatedSalesDeal.project.total_revenue
                  ? `Rp${formatThousands(updatedSalesDeal.project.total_revenue)}`
                  : '-'}
              </p>
            </div>
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Start Date</h3>
              <p className="">{moment(updatedSalesDeal.created_at).format('D MMMM YYYY')}</p>
            </div>
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Expired Date</h3>
              <div className="flex gap-2 items-center">
                <p className="">
                  {updatedSalesDeal.expired_date
                    ? moment(updatedSalesDeal.expired_date).utc().format('D MMMM YYYY')
                    : '-'}
                </p>
                {/* <DropdownDatePicker handleDatePicked={(date) => updateExpiredDate(date)}>
                  <button className="w-6 h-6 rounded flex items-center justify-center hover:bg-sky-100 hover:text-main-500">
                    <Edit sx={{ fontSize: 14 }} />
                  </button>
                </DropdownDatePicker> */}
              </div>
            </div>
          </div>
          <div className="min-w-[125px]">
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Number of Meetings</h3>
              <p className="">{updatedSalesDeal.number_of_meetings}</p>
            </div>
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Total Meeting Expenses</h3>
              <p className="">
                Rp
                {updatedSalesDeal.total_meeting_expenses ? formatThousands(updatedSalesDeal.total_meeting_expenses) : 0}
              </p>
            </div>
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Project ID</h3>
              <p className="">
                {updatedSalesDeal.project?.cc_project_id ? updatedSalesDeal.project.cc_project_id : '-'}
              </p>
            </div>
          </div>
          <div className="min-w-[125px]">
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Status</h3>
              <Status size="small" type={updatedSalesDeal.status} />
            </div>
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Account Owner</h3>
              <div className="font-normal flex gap-1 items-center">
                <span className="w-6 h-6 rounded-full bg-slate-100 overflow-hidden">
                  <img src={updatedSalesDeal.account.avatar} alt="avatar" className="w-full" />
                </span>
                <p>{updatedSalesDeal.account.name}</p>
              </div>
            </div>
            <div className="mb-8">
              <h3 className="font-semibold mb-1">Agency</h3>
              <p>{updatedSalesDeal.agency?.name || '-'} </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <h1 className="font-semibold text-giant mb-4">Documents</h1>
        <div className="rounded-[5px] border border-gray-border p-4 w-full">
          <div className="inline-flex flex-col gap-4 mb-4 pb-2 border-b-2 w-full md:flex-row md:items-center md:w-fit">
            <h3 className="text-big font-semibold">Document to be fullfiled for</h3>
            <Status type={'hot_prospect'} />
          </div>
          <div className="pb-8 border-b border-gray-border grid mb-8 grid-cols-1 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
            <div className="mb-4 md:mb-0">
              <p className="font-semibold mb-2">Project Feasibility Status</p>
              <Link
                className="flex gap-2 items-center rounded hover:underline hover:underline-offset-4 mb-4 capitalize"
                to={
                  salesDeal.created_by === user.id &&
                  (salesDeal.project.status === 'pending' || salesDeal.project.status === 'need_revision')
                    ? `/projects/edit/s/${updatedSalesDeal.project.id}`
                    : `/projects/view/${updatedSalesDeal.project.id}`
                }
              >
                {updatedSalesDeal.project.status === 'in_review_sm'
                  ? 'In Review SM'
                  : updatedSalesDeal.project.status === 'in_review_vp'
                  ? 'In Review VP'
                  : updatedSalesDeal.project.status.replaceAll('_', ' ')}
                <ArrowForward sx={{ fontSize: 14 }} />
              </Link>
              <div>
                <h4 className="font-semibold mb-1">Project Items</h4>
                <div className="mb-1">
                  <span className="w-24">Revenue Items : </span>
                  {updatedSalesDeal.project.revenue_items.length !== 0 ? (
                    <span className="text-primary-green font-bold"> Filled</span>
                  ) : (
                    <Link
                      to={`/projects/edit/s/${updatedSalesDeal.project.id}`}
                      className="hover:underline underline-offset-2"
                    >
                      <span className="text-primary-red font-bold">Need To Be Filled</span>
                    </Link>
                  )}
                </div>
                {/* <div className="mb-1">
                  <span className="w-24">COGS Items :</span>
                  {updatedSalesDeal.project.revenue_items.length !== 0 ? (
                    <span className="text-primary-red font-bold"> Missing</span>
                  )}
                </div> */}
              </div>
            </div>

            <DocumentView
              className={'mb-4 md:mb-0'}
              label="Proposal"
              docs={updatedSalesDeal.documents.proposal}
              salesDeal={salesDeal}
              project={updatedSalesDeal.project}
              type="proposal"
              handleAddDocs={(type, url) => handleAddDocs(type, url)}
              handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
            />
            <div className="mb-4 md:mb-0">
              <p className="mb-2 font-semibold">Quotation</p>
              {updatedSalesDeal.documents.quotation ? (
                <div>
                  <a href={updatedSalesDeal.documents.quotation.url} target="_blank">
                    <div className="mb-2 py-2.5 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit">
                      {dotsLimiter(updatedSalesDeal.documents.quotation.metadata.name, 40)}
                    </div>
                  </a>
                  {salesDeal.status !== 'loss' && isAE && (
                    <Button className="w-full" onClick={handleGenerateQuotation} isLoading={isLoadingGenerateQuotation}>
                      Generate Quotation
                    </Button>
                  )}
                </div>
              ) : (
                <>
                  <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px]">
                    {/* <i className="w-6 h-6 flex items-center justify-center"> */}
                    <Description sx={{ fontSize: 16 }} />
                    {/* </i> */}
                    <p>No File Yet</p>
                  </div>
                  {salesDeal.status !== 'loss' && isAE && (
                    <Button
                      className="w-full mb-2"
                      onClick={handleGenerateQuotation}
                      isLoading={isLoadingGenerateQuotation}
                    >
                      Generate Quotation
                    </Button>
                  )}
                </>
              )}
              {generateQuotationError && <Alert type={'error'} message={generateQuotationError} />}
            </div>

            {salesDeal.project.status === 'approved' || salesDeal.status !== 'hot_prospect' ? null : (
              <div className="md:cols-span-2 lg:col-span-3">
                {salesDeal.project.status === 'in_review_sm' || salesDeal.project.status === 'in_review_vp' ? (
                  <Alert type={'info'} message={'Project are in review'} />
                ) : salesDeal.project.status === 'pending' &&
                  salesDeal.project.revenue_items.length !== 0 &&
                  salesDeal.documents.proposal &&
                  salesDeal.documents.quotation ? (
                  <>
                    {isAE && (
                      <>
                        <Alert
                          message={
                            'Before a sales deal can be updated to on hands, you need to request SM to review the projects'
                          }
                          className={'mb-4'}
                        />
                        <Button onClick={saveToBeReviewed} isLoading={isLoadingRequestReview} className={'mb-4'}>
                          Request Project to be Reviewed
                        </Button>
                        {errorRequestReview && <Alert type={'error'} message={errorRequestReview} />}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {isAE && (
                      <>
                        <Alert
                          message={
                            'You need to add revenue items, upload proposal, and generate quotation before requesting SM to review the project!'
                          }
                          className={'mb-4'}
                        />
                        <Button disabled={true}>Request Project to be Reviewed</Button>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          <div className="inline-flex flex-col gap-4 mb-4 pb-2 border-b-2 w-full md:flex-row md:items-center md:w-fit">
            <h3 className="text-big font-semibold">Document to be fullfiled for</h3>
            <Status type={'on_hands'} />
          </div>
          <div className="pb-8 border-b border-gray-border grid mb-8 grid-cols-1 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
            <DocumentView
              label="Surat Perintah Kerja/PO"
              className={'mb-4 md:mb-0'}
              docs={updatedSalesDeal.documents.spk}
              salesDeal={salesDeal}
              project={updatedSalesDeal.project}
              type="spk"
              handleAddDocs={(type, url) => handleAddDocs(type, url)}
              handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
            />
            <div className="">
              <p className="mb-2 font-semibold">Confirmation Letter</p>

              {updatedSalesDeal.documents.confirmation_letter ? (
                <div>
                  <a href={updatedSalesDeal.documents.confirmation_letter.url} target="_blank">
                    <div className="mb-2 py-2.5 px-4 border-2 border-main-400 rounded-[5px] text-main-500 font-medium h-fit">
                      {dotsLimiter(updatedSalesDeal.documents.confirmation_letter.metadata.name, 40)}
                    </div>
                  </a>
                  {salesDeal.status !== 'loss' && isAE && (
                    <Button className="w-full" href={`generate-cl`}>
                      Generate Confirmation Letter
                    </Button>
                  )}
                </div>
              ) : (
                <>
                  <div className="py-2.5 px-4 mb-2 flex items-center gap-2 text-gray-text border border-gray-border rounded-[5px]">
                    {/* <i className="w-6 h-6 flex items-center justify-center"> */}
                    <Description sx={{ fontSize: 16 }} />
                    {/* </i> */}
                    <p>No File Yet</p>
                  </div>
                  {salesDeal.status !== 'loss' && isAE && (
                    <Button className="w-full" href={`generate-cl`}>
                      Generate Confirmation Letter
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="inline-flex flex-col gap-4 mb-4 pb-2 border-b-2 w-full md:flex-row md:items-center md:w-fit">
            <h3 className="text-big font-semibold">Document to be fullfiled for</h3>
            <Status type={'revenue'} />
          </div>
          <div className="pb-8 grid mb-8 grid-cols-1 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
            <div className="mb-4 md:mb-0">
              {/* <DocumentView
                type={'invoice'}
                label={'Invoice'}
                docs={updatedSalesDeal.documents.invoice}
                handleAddDocs={(type, url) => handleAddDocs(type, url)}
                handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
              /> */}
              {/* <InvoiceView
                invoices={updatedSalesDeal.documents.invoices}
                project={updatedSalesDeal.project}
                handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
                handleAddDocs={(type, url) => handleAddDocs(type, url)}
              /> */}
              <p className="mb-2 font-semibold">Invoice</p>
              {salesDeal.status !== 'loss' && (
                <>
                  {
                    <Button
                      className={'w-fit'}
                      href={'request-invoice'}
                      disabled={!isAE || !(salesDeal.status === 'on_hands' || salesDeal.status === 'revenue')}
                    >
                      Change Invoice
                    </Button>
                  }
                </>
              )}
            </div>
            <div>
              <DocumentView
                label="BAST"
                docs={updatedSalesDeal.documents.bast}
                salesDeal={salesDeal}
                project={updatedSalesDeal.project}
                type="bast"
                handleAddDocs={(type, url) => handleAddDocs(type, url)}
                handleUpdateDocs={(type, id, url) => handleUpdateDocs(type, id, url)}
              />
            </div>
            <div className="md:col-span-2 lg:col-span-3">
              <Table
                data={salesDeal.invoice_document}
                cols={invoiceCols}
                noIndex={true}
                customAction={(row) => (
                  <>
                    {row.status === 'pending' && isAE ? (
                      <Button icon={<Description sx={{ fontSize: 16 }} />} onClick={() => sendRequestInvoice(row.id)}>
                        Send Request
                      </Button>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {isAE && (
        <div className="mb-8">
          {salesDeal.status !== 'revenue' && salesDeal.status !== 'loss' && (
            <>
              <h1 className="font-semibold text-giant mb-4">Settings</h1>
              <div className="mb-4">
                <Button
                  // onClick={handleSetStatusLoss}
                  onClick={() => setShowLossModal(true)}
                  className="bg-red-500 text-white ring-red-500 hover:bg-red-600 hover:ring-red-600"
                >
                  Change Status to Loss
                </Button>
              </div>
            </>
          )}
          {lossError && <Alert type={'error'} message={lossError} />}
        </div>
      )}
    </>
  )
}
