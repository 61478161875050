import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedSBU: {},
  selectedSM: {},
  selectedAE: {},
  selectedYear: {},
}

export const performanceSlice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    setSelectedSBU: (state, action) => {
      state.selectedSBU = action.payload
    },
    setSelectedSM: (state, action) => {
      state.selectedSM = action.payload
    },
    setSelectedAE: (state, action) => {
      state.selectedAE = action.payload
    },
    setSelectedYear: (state, action) => {
      state.selectedYear = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSelectedAE, setSelectedSBU, setSelectedSM, setSelectedYear } = performanceSlice.actions

export const getFilter = (state) => state.performance

export default performanceSlice.reducer
