import { ArrowForward, AttachFileOutlined, CalendarMonth } from '@mui/icons-material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { formatThousands } from '../../helper'
import Button from '../../components/Button'

const sortByDateUpdated = (arr) => {
  var temp = []
  let sortedArray = arr.sort(
    (a, b) => moment(b.meeting_date).format('YYYYMMDDHHmmss') - moment(a.meeting_date).format('YYYYMMDDHHmmss')
  )
  return sortedArray
}

export default function SalesDealHistory({ salesDeal }) {
  const [listMom, setListMom] = useState(sortByDateUpdated(salesDeal.meeting_history))

  useEffect(() => {
    console.log(listMom)
  }, [listMom])
  return (
    <div className="rounded-[5px] border border-gray-border p-4 flex flex-col gap-4">
      {listMom.length === 0 ? (
        <p className="text-center">No meeting history found</p>
      ) : (
        listMom.map((meeting) => {
          if (meeting.type === 'drag_and_drop') {
            return (
              <div key={meeting.id} className="meeting-detail pb-4 border-b border-gray-border">
                <div className="flex gap-4 mb-4">
                  <div className="md:w-[200px]">
                    <div className="flex gap-2 mb-2 items-center text-big font-semibold">
                      <CalendarMonth sx={{ fontSize: 20 }} />
                      <p>Meeting Date</p>
                    </div>
                    <p>{moment(meeting.updated_at).format('D MMMM YYYY')}</p>
                  </div>
                </div>
                <div className="flex gap-10 mb-4">
                  <div>
                    <p className="font-semibold mb-1">Status</p>
                    <div className="flex gap-2 items-center capitalize">
                      <span>{meeting.sales_deal_status_before.replace('_', ' ')}</span>
                      <ArrowForward sx={{ fontSize: 16 }} />
                      <span>{meeting.sales_deal_status_after.replace('_', ' ')}</span>
                    </div>
                  </div>
                </div>
                <div className="notes">
                  <p className="font-semibold mb-1">Notes</p>
                  <article>{meeting.meeting_notes}</article>
                </div>
              </div>
            )
          } else if (meeting.mom_status === 'empty') {
            return (
              <div key={meeting.id} className="meeting-detail pb-4 border-b border-gray-border">
                <div className="flex gap-4 mb-8">
                  <div className="md:w-[200px]">
                    <div className="flex gap-2 mb-2 items-center text-big font-semibold">
                      <CalendarMonth sx={{ fontSize: 20 }} />
                      <p>Meeting Date</p>
                    </div>
                    <p>{moment(meeting.meeting_date).format('D MMMM YYYY')}</p>
                  </div>
                  <div className="w-[200px]">
                    <Button
                      size={'small'}
                      className={
                        'bg-primary-green border-primary-green text-white hover:bg-green-700 hover:border-green-700'
                      }
                      icon={<AttachFileOutlined sx={{ fontSize: 16 }} />}
                      href={`/sales-plan/${meeting.id}/add-mom`}
                    >
                      Add MoM
                    </Button>
                  </div>
                </div>
                <div className="flex gap-10 mb-4">
                  <div>
                    <p className="font-semibold mb-1">Response</p>
                    <p className="capitalize">-</p>
                  </div>
                  <div>
                    <p className="font-semibold mb-1">Status</p>
                    <div className="flex gap-2 items-center capitalize">-</div>
                  </div>
                  <div>
                    <p className="font-semibold mb-1">Meeting Expenses</p>
                    <p>-</p>
                  </div>
                </div>
                <div className="notes mb-4">
                  <p className="font-semibold mb-1">Notes</p>
                  <article>-</article>
                </div>
                {/* <div className="tags">
                  <p className="font-semibold mb-1">Tags</p>
                  <article>{meeting.tags.length > 0 ? meeting.tags.toString().replace(',', ', ') : '-'}</article>
                </div> */}
              </div>
            )
          }
          return (
            <div key={meeting.id} className="meeting-detail pb-4 border-b border-gray-border">
              <div className="flex flex-col gap-4 mb-8 md:flex-row">
                <div className="md:w-[200px]">
                  <div className="flex gap-2 mb-2 items-center text-big font-semibold">
                    <CalendarMonth sx={{ fontSize: 20 }} />
                    <p>Meeting Date</p>
                  </div>
                  <p>{moment(meeting.meeting_date).format('D MMMM YYYY')}</p>
                </div>
                <div className="md:w-[200px]">
                  <div className="flex gap-2 mb-2 items-center text-big font-semibold">
                    <CalendarMonth sx={{ fontSize: 20 }} />
                    <p>MoM Saved Date</p>
                  </div>
                  <p>{meeting.mom_saved_at ? moment(meeting.mom_saved_at).format('D MMMM YYYY') : '-'}</p>
                </div>
              </div>
              <div className="flex flex-col gap-4 mb-4 md:flex-row md:gap-10">
                <div>
                  <p className="font-semibold mb-1">Response</p>
                  <p className="capitalize">
                    {meeting.client_response ? meeting.client_response.replace('_', ' ') : '-'}
                  </p>
                </div>
                <div>
                  <p className="font-semibold mb-1">Status</p>
                  <div className="flex gap-2 items-center capitalize">
                    <span>{meeting.sales_deal_status_before.replace('_', ' ')}</span>
                    <ArrowForward sx={{ fontSize: 16 }} />
                    <span>{meeting.sales_deal_status_after.replace('_', ' ')}</span>
                  </div>
                </div>
                <div>
                  <p className="font-semibold mb-1">Meeting Expenses</p>
                  <p>Rp{meeting.total_meeting_expenses ? formatThousands(meeting.total_meeting_expenses) : 0}</p>
                </div>
                <div>
                  <p className="font-semibold mb-1">PIC Client</p>
                  <p className="capitalize">{meeting.pic_name}</p>
                </div>

                <div>
                  <p className="font-semibold mb-1">PIC Title</p>
                  <p className="capitalize">{meeting.pic_title}</p>
                </div>
                <div>
                  <p className="font-semibold mb-1">PIC Phone Number</p>
                  <p className="capitalize">{meeting.pic_phone_number}</p>
                </div>
              </div>
              <div className="notes mb-4">
                <p className="font-semibold mb-1">Notes</p>
                <article>{meeting.meeting_notes}</article>
              </div>
              {/* <div className="tags">
                <p className="font-semibold mb-1">Tags</p>
                <article>{meeting.tags.toString().replace(',', ', ')}</article>
              </div> */}
            </div>
          )
        })
      )}
    </div>
  )
}
