import { useEffect, useState } from 'react'

export default function Checkbox({ label, checked, id, onChange, ...props }) {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <label className="py-1.5 pr-2 flex items-center" htmlFor={id}>
      <input
        className={`check-input hidden`}
        id={id}
        checked={isChecked}
        type="checkbox"
        onChange={(e) => {
          setIsChecked((prev) => !prev)
          onChange(e)
        }}
        {...props}
      ></input>
      <span
        className={`mr-2 w-4 h-4 rounded inline-block border-2 border-slate-800 transition-all ${
          isChecked ? 'bg-main-500' : 'bg-white'
        }`}
      ></span>
      <span>{label}</span>
    </label>
  )
}
