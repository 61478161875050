import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SalesDealAPI from '../../apis/SalesDealAPI'
import Loading from '../../components/Loading'
import BreadCrumbs from '../../components/BreadCrumbs'
import { formatThousands } from '../../helper'
import moment from 'moment'
import Status from '../../components/Status'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { AttachMoney, Delete, Percent } from '@mui/icons-material'
import RadioButton from '../../components/RadioButton'
import Textarea from '../../components/Textarea'
import Checkbox from '../../components/Checkbox'
import usePermission from '../../hooks/usePermission'
import ErrorPage from '../../ErrorPage'
import Alert from '../../components/Alert'
import GroupButtons from '../../components/GroupButtons'

export default function GenerateConfirmationLetter() {
  const { id } = useParams()
  const [salesDeal, setSalesDeal] = useState()
  const [isLoading, setLoading] = useState(true)
  const { isAE, isAdmin } = usePermission()
  const [submitError, setSubmitError] = useState()
  const [isLoadingSubmit, setLoadingSubmit] = useState(false)
  const [isLoadingDraft, setLoadingDraft] = useState(false)
  const [paymentTerms, setPaymentTerms] = useState([
    {
      term: 1,
      amount_type: 'percentage',
      amount_percentage: 0,
      amount_absolute: null,
      amount: 0,
      description: 'Dari total nilai di akhir pengerjaan.',
    },
  ])
  const [legalPIC, setLegalPic] = useState({
    pic_name: '',
    pic_title: '',
    pic_email: '',
    pic_phone_number: '',
    partnership_type: '',
    vendor: null,
    list_documents: '',
  })
  const [billingDetail, setBillingDetail] = useState({
    pic_name: '',
    pic_title: '',
    pic_email: '',
    pic_phone_number: '',
    terms_of_payment: '',
    payment_documents: [],
    type_of_documents: [],
    email: '',
    contact_person: '',
    address: '',
    payment_due_date: '',
  })
  const [taxationDetail, setTaxationDetail] = useState({
    pic_name: '',
    pic_title: '',
    pic_email: '',
    pic_phone_number: '',
    have_npwp: 'yes',
    npwp_number: '',
    npwp_entity_name: '',
    npwp_address_in_npwp: '',
    nonpwp_type_of_business: '',
    nonpwp_skb_ppn: '',
    tax_cut: '',
  })

  const [selectedRadio, setSelectedRadio] = useState()
  const [paymentTermsType, setPaymentTermsType] = useState('percentage')
  const [haveDefaultPaymentTermsType, setHaveDefaultPaymentTermsType] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        SalesDealAPI.getSalesDealById(id)
          .then((res) => {
            setSalesDeal(res.data)
            resolve()
          })
          .catch((err) => {
            reject()
          })
      }),
      new Promise((resolve, reject) => {
        SalesDealAPI.getConfirmationLetter(id)
          .then((res) => {
            if (res.data.tax) setTaxationDetail(res.data.tax)
            if (res.data.legality) setLegalPic(res.data.legality)
            if (res.data.payment_terms.length !== 0) {
              setHaveDefaultPaymentTermsType(true)
              setPaymentTermsType(res.data.payment_terms[0].amount_type)
              setPaymentTerms(
                res.data.payment_terms.map((term) => ({ ...term, id: term.id || (Math.random() * 100000).toFixed(0) }))
              )
            }
            if (res.data.payment) {
              setBillingDetail({
                ...res.data.payment,
                payment_documents: res.data.payment.payment_documents.split(','),
                type_of_documents: res.data.payment.type_of_documents.split(','),
              })
            }
            resolve()
          })
          .catch((err) => {
            // reject()
            resolve()
          })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    // console.log(paymentTerms)
  }, [paymentTerms])

  useEffect(() => {
    // console.log(legalPIC)
  }, [legalPIC])

  useEffect(() => {
    // console.log(billingDetail)
  }, [billingDetail])

  useEffect(() => {
    // console.log(taxationDetail)
  }, [taxationDetail])

  const handleAddPaymentTerm = () => {
    var temp = [
      ...paymentTerms,
      {
        id: Math.random(),
        term: null,
        amount_type: paymentTermsType || 'percentage',
        amount_percentage: 0,
        amount_absolute: null,
        amount: 0,
        description: '',
      },
    ]
    setPaymentTerms(temp)
  }

  const handlePaymentTermTypeChange = (type) => {
    setPaymentTermsType(type)
    var temp
    if (paymentTermsType !== type) {
      temp = paymentTerms.map((term) => ({
        ...term,
        amount_type: type,
        amount_absolute: null,
        amount_percentage: null,
      }))
      // if (type === 'absolute') {
      //   temp = paymentTerms.map((term) => ({
      //     ...term,
      //     amount_type: type,
      //     amount_absolute: term.amount,
      //     amount_percentage: null,
      //   }))
      // } else {
      //   temp = paymentTerms.map((term) => ({
      //     ...term,
      //     amount_type: type,
      //     amount_absolute: null,
      //     amount_percentage: (term.amount / salesDeal.project.total_revenue).toFixed(2),
      //   }))
      // }
      console.log(temp)
      setPaymentTerms(temp)
    }
  }

  const handlePaymentTermChange = (input, id) => {
    var temp = paymentTerms.findIndex((item) => item.id === id)
    if (temp !== -1) {
      if (input.name === 'amount_percentage') {
        var tempTerm = {
          ...paymentTerms[temp],
          [input.name]: (parseInt(input.value) / 100).toFixed(2),
          amount: salesDeal.project.total_revenue * (parseInt(input.value) / 100).toFixed(2),
        }
        var paymentTermsTemp = [...paymentTerms]
        paymentTermsTemp.splice(temp, 1, tempTerm)
        setPaymentTerms(paymentTermsTemp)
      } else if (input.name === 'amount_absolute') {
        var tempTerm = { ...paymentTerms[temp], [input.name]: parseInt(input.value), amount: input.value }
        var paymentTermsTemp = [...paymentTerms]
        paymentTermsTemp.splice(temp, 1, tempTerm)
        setPaymentTerms(paymentTermsTemp)
      } else {
        var tempTerm = { ...paymentTerms[temp], [input.name]: input.value }
        var paymentTermsTemp = [...paymentTerms]
        paymentTermsTemp.splice(temp, 1, tempTerm)
        // console.log(paymentTermsTemp)
        setPaymentTerms(paymentTermsTemp)
      }
    } else {
      console.log('hee')
    }
  }

  const handleDeletePaymentTerm = (id) => {
    var temp = paymentTerms.filter((item) => item.id !== id)
    setPaymentTerms(temp)
  }

  // const handleChange = (input) => {
  //   console.log(input.value)
  // }

  // const handleRadioChange = (e) => {
  //   setSelectedRadio(e.target.value)
  // }

  const handleLegalPICChange = (input) => {
    setLegalPic({ ...legalPIC, [input.name]: input.value })
  }

  const handleBillingChange = (input) => {
    setBillingDetail({ ...billingDetail, [input.name]: input.value })
  }

  const handleDocumentsCheckbox = (input) => {
    var index = billingDetail.payment_documents.indexOf(input.name)
    if (index === -1) {
      setBillingDetail({ ...billingDetail, payment_documents: [...billingDetail.payment_documents, input.name] })
    } else {
      var temp = billingDetail.payment_documents
      temp.splice(index, 1)
      setBillingDetail({ ...billingDetail, payment_documents: temp })
    }
  }

  const handleDocumentTypeCheckbox = (input) => {
    var index = billingDetail.type_of_documents.indexOf(input.name)
    if (index === -1) {
      setBillingDetail({ ...billingDetail, type_of_documents: [...billingDetail.type_of_documents, input.name] })
    } else {
      var temp = billingDetail.type_of_documents
      temp.splice(index, 1)
      setBillingDetail({ ...billingDetail, type_of_documents: temp })
    }
  }
  const handleTaxationChange = (input) => {
    setTaxationDetail({ ...taxationDetail, [input.name]: input.value })
  }

  const handleSaveCL = (type) => {
    if (type === 'submitted') setLoadingSubmit(true)
    else setLoadingDraft(true)
    var paymentDocuments = ''
    billingDetail.payment_documents.map((item, index) => {
      if (index !== billingDetail.payment_documents.length - 1) paymentDocuments += item + ','
      else paymentDocuments += item
    })
    var typeDocuments = ''
    billingDetail.type_of_documents.map((item, index) => {
      if (index !== billingDetail.type_of_documents.length - 1) typeDocuments += item + ','
      else typeDocuments += item
    })

    var totalTerm = 0

    var temp = {
      sales_deal_id: salesDeal.id,
      status: type,
      legality: legalPIC,
      payment: { ...billingDetail, payment_documents: paymentDocuments, type_of_documents: typeDocuments },
      tax: taxationDetail,
      payment_terms: paymentTerms.map((item, index) => {
        if (paymentTermsType === 'percentage') {
          totalTerm += parseFloat(item.amount_percentage || 0)
          console.log(item.amount_percentage)
        } else {
          totalTerm += item.amount_absolute
        }
        return {
          term: item.term,
          amount_type: item.amount_type,
          amount_absolute: item.amount_absolute,
          amount_percentage: item.amount_percentage,
          amount: item.amount,
          description: item.description,
        }
      }),
    }

    console.log(totalTerm)

    if (paymentTermsType === 'percentage' && parseInt(totalTerm).toFixed(3) != 1) {
      setSubmitError('Make sure total invoice amount is correct!')
      setLoadingSubmit(false)
      setLoadingDraft(false)
      return
    }
    if (paymentTermsType === 'absolute' && totalTerm !== salesDeal.project.total_revenue) {
      setSubmitError('Make sure total invoice amount is correct!')
      setLoadingSubmit(false)
      setLoadingDraft(false)
      return
    }
    SalesDealAPI.saveCl(temp)
      .then((res) => {
        if (res.success) {
          navigate(-1)
        }
      })
      .catch((err) => {
        if (err.response) {
          setSubmitError(err.response.data.message)
          if (type === 'submit') setLoadingSubmit(true)
          else setLoadingDraft(true)
        } else {
          setSubmitError(err.message)
          if (type === 'submit') setLoadingSubmit(true)
          else setLoadingDraft(true)
        }
      })
  }

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/sales-deal'}>Sales Deal</BreadCrumbs.Item>
              <BreadCrumbs.Item href={`/sales-deal/${salesDeal.id}`}>{salesDeal.project.name}</BreadCrumbs.Item>
              <BreadCrumbs.Item current>Generate Confirmation Letter</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>
          <div className="mb-8 w-fit">
            <h1 className="font-semibold text-giant mb-4">Payment Items</h1>
            <div className="rounded-[5px] border border-gray-border p-4">
              <div className="min-w-[125px]">
                <div className="flex items-end justify-between mb-4">
                  <Button variant={'outlined'} className={''} onClick={handleAddPaymentTerm}>
                    Add Payments Terms
                  </Button>
                  {!haveDefaultPaymentTermsType && (
                    <GroupButtons
                      icons={true}
                      size="small"
                      defaultValue={paymentTermsType}
                      data={[
                        {
                          id: 'absolute',
                          text: <AttachMoney sx={{ fontSize: 18 }} />,
                          onClick: () => {
                            handlePaymentTermTypeChange('absolute')
                          },
                        },
                        {
                          id: 'percentage',
                          text: <Percent sx={{ fontSize: 18 }} />,
                          onClick: () => {
                            handlePaymentTermTypeChange('percentage')
                          },
                        },
                      ]}
                    />
                  )}
                </div>
                <table className={'table-auto text-left w-[800px]'}>
                  <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
                    <tr>
                      <th className="px-4 py-4 text-center grow-0 shrink-0 rounded-l-[5px] w-20">Term</th>
                      <th className="px-4 py-4 grow-0 shrink-0 w-[100px]">Amount</th>
                      <th className="px-4 py-4 grow-0 shrink-0 w-[400px]">Description (Amount and When to Pay)</th>
                      <th className="px-4 py-4 grow-0 shrink-0 w-[100px] rounded-r-[5px]">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentTerms.length === 0 ? (
                      <tr>
                        <td className="p-4 text-center italic text-gray-400" colSpan={6}>
                          No payment terms added
                        </td>
                      </tr>
                    ) : (
                      paymentTerms.map((term, index) => {
                        return (
                          <tr className="border-b" key={index}>
                            <td className="py-2 text-center">
                              <Input
                                placeholder="term"
                                type={'number'}
                                name="term"
                                onChange={(e) => handlePaymentTermChange(e.target, term.id)}
                                value={term.term}
                              />
                            </td>
                            <td className="py-2 px-4 w-max">
                              {paymentTermsType === 'percentage' ? (
                                <div
                                  className={`flex rounded-[5px] border ${'border-primary-gray'} w-full transition-all focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none focus:border-main-500`}
                                >
                                  <div className="bg-main-500/10 rounded-l-[5px] flex items-center p-3">
                                    <Percent sx={{ fontSize: 16 }} />
                                  </div>
                                  <input
                                    placeholder="%"
                                    type={'number'}
                                    name="amount_percentage"
                                    className={`p-3 outline-none rounded-[5px] flex-1 w-[200px]`}
                                    min="0"
                                    max="100"
                                    onChange={(e) => handlePaymentTermChange(e.target, term.id)}
                                    value={term.amount_percentage ? term.amount_percentage * 100 : 0}
                                  />
                                </div>
                              ) : (
                                <div
                                  className={`flex rounded-[5px] border ${'border-primary-gray'} w-full transition-all focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none focus:border-main-500`}
                                >
                                  <div className="bg-main-500/10 rounded-l-[5px] flex items-center p-3">
                                    <AttachMoney sx={{ fontSize: 16 }} />
                                  </div>
                                  <input
                                    placeholder="$"
                                    type={'number'}
                                    name="amount_absolute"
                                    className={`p-3 outline-none rounded-[5px] flex-1 w-[200px]`}
                                    onChange={(e) => handlePaymentTermChange(e.target, term.id)}
                                    value={term.amount_absolute ? term.amount_absolute : 0}
                                  />
                                </div>
                              )}
                            </td>
                            <td className="py-2 px-4">
                              <Input
                                placeholder="Description"
                                name="description"
                                onChange={(e) => handlePaymentTermChange(e.target, term.id)}
                                value={term.description}
                              />
                            </td>
                            <td className="p-2 px-4">
                              <button
                                className="w-8 h-8 rounded text-gray-400 hover:text-red-500 hover:bg-red-100"
                                onClick={() => {
                                  handleDeletePaymentTerm(term.id)
                                }}
                              >
                                <Delete sx={{ fontSize: 20 }} />
                              </button>
                            </td>
                          </tr>
                        )
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-big font-semibold mb-8">Ceklis Dokumen Pra Kerjasama PT Katadata Indonesia</h3>
            <div className="rounded-[5px] border border-gray-border p-4">
              <div className="pb-8 border-b border-gray-border mb-8">
                <p className="font-semibold text-big mb-8">A. Pertanyaan seputar Project & Legalitas</p>
                <div className="mb-8 max-w-[270px]">
                  <p className="mb-4 font-semibold">1. PIC</p>
                  <Input
                    label={'Nama'}
                    name="pic_name"
                    value={legalPIC.pic_name}
                    onChange={(e) => {
                      handleLegalPICChange(e.target)
                    }}
                    placeholder="Nama"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Jabatan'}
                    name="pic_title"
                    value={legalPIC.pic_title}
                    onChange={(e) => {
                      handleLegalPICChange(e.target)
                    }}
                    placeholder="Jabatan"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Email'}
                    name="pic_email"
                    value={legalPIC.pic_email}
                    onChange={(e) => {
                      handleLegalPICChange(e.target)
                    }}
                    placeholder="Email"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Nomor Handphone'}
                    name="pic_phone_number"
                    value={legalPIC.pic_phone_number}
                    onChange={(e) => {
                      handleLegalPICChange(e.target)
                    }}
                    placeholder="Nomor Handphone"
                    className={'mb-4'}
                  />
                </div>
                <div className="mb-8">
                  <p className="mb-2.5 font-semibold">
                    2. Mohon dicantumkan Akta perubahan terakhir perusahaan untuk menentukan pejabat yang berwenang
                    untuk tandatangan dokumen ke alamat email : ria.karina@katadata.co.id
                  </p>
                  <div>
                    <p className="mb-1 font-semibold">Apa jenis Kerjasama yang akan dilaksanakan ?</p>
                    <div className="flex flex-col gap-2">
                      <RadioButton
                        label="Direct"
                        name="partnership_type"
                        id="partnership_type_direct"
                        value="direct"
                        onChange={(e) => {
                          handleLegalPICChange(e.target)
                        }}
                        checked={legalPIC.partnership_type === 'direct'}
                      />
                      <RadioButton
                        label="Indirect / Agency"
                        name="partnership_type"
                        id="partnership_type_indirect"
                        value="indirect"
                        onChange={(e) => {
                          handleLegalPICChange(e.target)
                        }}
                        checked={legalPIC.partnership_type === 'indirect'}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <div>
                    <p className="mb-1 font-semibold">
                      3. Apakah dibutuhkan proses pendaftaran vendor dalam Kerjasama?
                    </p>
                    <div className="flex flex-col gap-2">
                      <RadioButton
                        label="Ya"
                        name="vendor"
                        id="vendor_yes"
                        value={'yes'}
                        onChange={(e) => {
                          handleLegalPICChange(e.target)
                        }}
                        checked={legalPIC.vendor === 'yes'}
                      />
                      <RadioButton
                        label="Tidak"
                        name="vendor"
                        id="vendor_no"
                        value={'no'}
                        onChange={(e) => {
                          handleLegalPICChange(e.target)
                        }}
                        checked={legalPIC.vendor === 'no'}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <div className="max-w-[570px]">
                    <p className="mb-1 font-semibold">
                      4. Jika dibutuhkan daftar vendor, apa saja dokumen project dan legalitas yang perlu kami submit?
                    </p>
                    <Textarea
                      placeholder="Dokumen"
                      value={legalPIC.list_documents}
                      onChange={(e) => {
                        handleLegalPICChange(e.target)
                      }}
                      name={'list_documents'}
                    />
                  </div>
                </div>
              </div>
              <div className="pb-8 border-b border-gray-border mb-8">
                <p className="font-semibold text-big mb-8">B. Pertanyaan seputar penagihan</p>
                <div className="mb-8 max-w-[270px]">
                  <p className="mb-4 font-semibold">1. PIC Penagihan</p>
                  <Input
                    label={'Nama'}
                    name="pic_name"
                    value={billingDetail.pic_name}
                    onChange={(e) => {
                      handleBillingChange(e.target)
                    }}
                    placeholder="Nama"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Jabatan'}
                    name="pic_title"
                    value={billingDetail.pic_title}
                    onChange={(e) => {
                      handleBillingChange(e.target)
                    }}
                    placeholder="Jabatan"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Email'}
                    name="pic_email"
                    value={billingDetail.pic_email}
                    onChange={(e) => {
                      handleBillingChange(e.target)
                    }}
                    placeholder="Email"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Nomor Handphone'}
                    name="pic_phone_number"
                    value={billingDetail.pic_phone_number}
                    onChange={(e) => {
                      handleBillingChange(e.target)
                    }}
                    placeholder="Nomor Handphone"
                    className={'mb-4'}
                  />
                </div>
                <div className="mb-8">
                  <div>
                    <p className="mb-1 font-semibold">2. Bagaimana term of payment dalam Kerjasama?</p>
                    <div className="flex flex-col gap-2">
                      <RadioButton
                        label="<30 hari sejak diterbitkan invoice"
                        name="terms_of_payment"
                        id="terms_of_payment_<30"
                        value="<30"
                        onChange={(e) => handleBillingChange(e.target)}
                        checked={billingDetail.terms_of_payment === '<30'}
                      />
                      <RadioButton
                        label="30 hari sejak diterbitkan invoice"
                        name="terms_of_payment"
                        id="terms_of_payment_30"
                        value="30"
                        onChange={(e) => handleBillingChange(e.target)}
                        checked={billingDetail.terms_of_payment === '30'}
                      />
                      <RadioButton
                        label="60 hari sejak diterbitkan invoice"
                        name="terms_of_payment"
                        id="terms_of_payment_60"
                        value="60"
                        onChange={(e) => handleBillingChange(e.target)}
                        checked={billingDetail.terms_of_payment === '60'}
                      />
                      <RadioButton
                        label="90 hari sejak diterbitkan invoice"
                        name="terms_of_payment"
                        id="terms_of_payment_90"
                        value="90"
                        onChange={(e) => handleBillingChange(e.target)}
                        checked={billingDetail.terms_of_payment === '90'}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <div>
                    <p className="mb-1 font-semibold">3. Dokumen apa saja yang dibutuhkan dalam penagihan ?</p>
                    <div className="flex flex-col gap-2">
                      <Checkbox
                        label="Confirmation Letter"
                        checked={billingDetail.payment_documents.includes('cl')}
                        name="cl"
                        onChange={(e) => {
                          console.log(e)
                          handleDocumentsCheckbox(e.target)
                        }}
                      />
                      <Checkbox
                        label="BAST"
                        name="bast"
                        checked={billingDetail.payment_documents.includes('bast')}
                        onChange={(e) => handleDocumentsCheckbox(e.target)}
                      />
                      <Checkbox
                        label="Invoice"
                        checked={billingDetail.payment_documents.includes('invoice')}
                        name="invoice"
                        onChange={(e) => handleDocumentsCheckbox(e.target)}
                      />
                      <Checkbox
                        label="Kwitansi"
                        checked={billingDetail.payment_documents.includes('kwitansi')}
                        name="kwitansi"
                        onChange={(e) => handleDocumentsCheckbox(e.target)}
                      />
                      <Checkbox
                        label="Faktur Pajak"
                        checked={billingDetail.payment_documents.includes('faktur_pajak')}
                        name="faktur_pajak"
                        onChange={(e) => handleDocumentsCheckbox(e.target)}
                      />
                      <Checkbox
                        label="Surat Permohonan Pembayaran"
                        checked={billingDetail.payment_documents.includes('surat_permohonan_pembayaran')}
                        name="surat_permohonan_pembayaran"
                        onChange={(e) => handleDocumentsCheckbox(e.target)}
                      />
                      <Checkbox
                        label="Lainnya"
                        name="lainnya"
                        checked={billingDetail.payment_documents.includes('lainnya')}
                        onChange={(e) => handleDocumentsCheckbox(e.target)}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <div>
                    <p className="mb-1 font-semibold">
                      4. Apa jenis dokumen yang perlu kami kirimkan dalam proses penagihan ?
                    </p>
                    <div className="flex flex-col gap-2">
                      <Checkbox
                        label="Softcopy"
                        name="softcopy"
                        checked={billingDetail.type_of_documents.includes('softcopy')}
                        onChange={(e) => handleDocumentTypeCheckbox(e.target)}
                      />
                      <Checkbox
                        label="Hardcopy"
                        name="hardcopy"
                        checked={billingDetail.type_of_documents.includes('hardcopy')}
                        onChange={(e) => handleDocumentTypeCheckbox(e.target)}
                      />
                    </div>
                  </div>
                </div>
                <div className="max-w-[270px]">
                  <p className="mb-1 font-semibold">5. Informasi Pengiriman (Softcopy dan Hardcopy)</p>
                  <Input
                    label={'Email'}
                    name="email"
                    value={billingDetail.email}
                    onChange={(e) => {
                      handleBillingChange(e.target)
                    }}
                    placeholder="Email"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Contact Person'}
                    name="contact_person"
                    value={billingDetail.contact_person}
                    onChange={(e) => {
                      handleBillingChange(e.target)
                    }}
                    placeholder="Nama Contact Person"
                    className={'mb-4'}
                  />
                  <Textarea
                    label={'Alamat'}
                    value={billingDetail.address}
                    className={'mb-4'}
                    onChange={(e) => handleBillingChange(e.target)}
                    name="address"
                    placeholder="Alamat"
                    rows={4}
                  />
                  <Input
                    label={'Batas Waktu Penerimaan Dokumen Tagihan'}
                    type={'date'}
                    value={billingDetail.payment_due_date}
                    onChange={(e) => handleBillingChange(e.target)}
                    name="payment_due_date"
                  />
                </div>
              </div>
              <div className="pb-8 ">
                <p className="font-semibold text-big mb-8">C. Pertanyaan seputar perpajakan</p>
                <div className="mb-8 max-w-[270px]">
                  <p className="mb-4 font-semibold">1. PIC Perpajakan</p>
                  <Input
                    label={'Nama'}
                    name="pic_name"
                    value={taxationDetail.pic_name ? taxationDetail.pic_name : null}
                    onChange={(e) => {
                      handleTaxationChange(e.target)
                    }}
                    placeholder="Nama"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Jabatan'}
                    name="pic_title"
                    value={taxationDetail.pic_title ? taxationDetail.pic_title : null}
                    onChange={(e) => {
                      handleTaxationChange(e.target)
                    }}
                    placeholder="Jabatan"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Email'}
                    name="pic_email"
                    value={taxationDetail.pic_email ? taxationDetail.pic_email : null}
                    onChange={(e) => {
                      handleTaxationChange(e.target)
                    }}
                    placeholder="Email"
                    className={'mb-4'}
                  />
                  <Input
                    label={'Nomor Handphone'}
                    name="pic_phone_number"
                    value={taxationDetail.pic_phone_number ? taxationDetail.pic_phone_number : null}
                    onChange={(e) => {
                      handleTaxationChange(e.target)
                    }}
                    placeholder="Nomor Handphone"
                    className={'mb-4'}
                  />
                </div>
                <div className="mb-8">
                  <div className="mb-4">
                    <p className="mb-1 font-semibold">2. Apakah client memiliki NPWP :</p>
                    <div className="flex flex-col gap-2">
                      <RadioButton
                        label="Ya"
                        name="npwp_yes"
                        id="yes"
                        value="yes"
                        onChange={(e) => setTaxationDetail({ ...taxationDetail, have_npwp: 'yes' })}
                        checked={taxationDetail.have_npwp === 'yes'}
                      />
                      <RadioButton
                        label="Tidak"
                        name="npwp_no"
                        id="no"
                        value="no"
                        onChange={(e) => setTaxationDetail({ ...taxationDetail, have_npwp: 'no' })}
                        checked={taxationDetail.have_npwp === 'no'}
                      />
                    </div>
                  </div>
                  {taxationDetail.have_npwp === 'yes' ? (
                    <div className="w-[500px] pl-20 mb-4">
                      <Input
                        label="NPWP"
                        placeholder="NPWP"
                        className={'mb-4'}
                        value={taxationDetail.npwp_number ? taxationDetail.npwp_number : null}
                        onChange={(e) => handleTaxationChange(e.target)}
                        name={'npwp_number'}
                      />
                      <Input
                        label="Nama Entitas"
                        placeholder="PT ..."
                        className={'mb-4'}
                        value={taxationDetail.npwp_entity_name ? taxationDetail.npwp_entity_name : null}
                        onChange={(e) => handleTaxationChange(e.target)}
                        name={'npwp_entity_name'}
                      />
                      <Textarea
                        rows={5}
                        onChange={(e) => {
                          handleTaxationChange(e.target)
                        }}
                        value={taxationDetail.npwp_address_in_npwp ? taxationDetail.npwp_address_in_npwp : null}
                        name="npwp_address_in_npwp"
                        label="Alamat yang tertera pada NPWP"
                      />
                    </div>
                  ) : (
                    <>
                      <div className="pl-20 mb-4">
                        <p className="mb-1 font-semibold">
                          2a. Jika client tidak memiliki NPWP, maka pilih salah satu jenis usaha di bawah ini :
                        </p>
                        <div className="flex flex-col gap-2">
                          <RadioButton
                            label="Orang pribadi tidak ber NPWP"
                            name="nonpwp_type_of_business"
                            id="non_npwp"
                            value="non_npwp"
                            onChange={() =>
                              setTaxationDetail({ ...taxationDetail, nonpwp_type_of_business: 'non_npwp' })
                            }
                            checked={
                              taxationDetail.nonpwp_type_of_business === 'non_npwp'
                                ? taxationDetail.nonpwp_type_of_business === 'non_npwp'
                                : null
                            }
                          />
                          <RadioButton
                            label="Badan usaha asing/lembaga internasional/NGO"
                            name="nonpwp_type_of_business"
                            id="foreign"
                            value="foreign"
                            onChange={() =>
                              setTaxationDetail({ ...taxationDetail, nonpwp_type_of_business: 'foreign' })
                            }
                            checked={
                              taxationDetail.nonpwp_type_of_business === 'foreign'
                                ? taxationDetail.nonpwp_type_of_business === 'foreign'
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="pl-20 mb-4">
                        <p className="mb-1 font-semibold">2b. Apakah anda memiliki SKB PPN ?</p>
                        <div className="flex flex-col gap-2">
                          <RadioButton
                            label="Ya, Kapan SKB tersebut kami terima ?"
                            name="nonpwp_skb_ppn"
                            id="nonpwp_skb_ppn_yes"
                            value="nonpwp_skb_ppn_yes"
                            onChange={() => setTaxationDetail({ ...taxationDetail, nonpwp_skb_ppn: 'yes' })}
                            checked={
                              taxationDetail.nonpwp_skb_ppn === 'yes' ? taxationDetail.nonpwp_skb_ppn === 'yes' : null
                            }
                          />
                          <RadioButton
                            label="Tidak, Kami akan mengikuti peraturan PPN yang berlaku"
                            name="nonpwp_skb_ppn"
                            id="nonpwp_skb_ppn_no"
                            value="nonpwp_skb_ppn_no"
                            onChange={() => setTaxationDetail({ ...taxationDetail, nonpwp_skb_ppn: 'no' })}
                            checked={
                              taxationDetail.nonpwp_skb_ppn === 'no' ? taxationDetail.nonpwp_skb_ppn === 'no' : null
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="">
                  <div className="mb-4">
                    <p className="mb-1 font-semibold">
                      3. Jika terdapat pemotongan pajak, maka bukti potong pajak akan diberikan kepada kami pada saat:
                    </p>
                    <div className="flex flex-col gap-2">
                      <RadioButton
                        label="Kurun waktu 20 hari setelah tanggal pembayaran"
                        name="tax_cut"
                        id="20"
                        value="20"
                        onChange={() => setTaxationDetail({ ...taxationDetail, tax_cut: '20_days' })}
                        checked={taxationDetail.tax_cut === '20_days' ? taxationDetail.tax_cut === '20_days' : null}
                      />
                      <RadioButton
                        label="1-2 bulan setelah bulan pembayaran, jika >2 bulan maka piutang dianggap belum lunas."
                        name="tax_cut"
                        id="1-2"
                        value="1-2"
                        onChange={() => setTaxationDetail({ ...taxationDetail, tax_cut: '1_month' })}
                        checked={taxationDetail.tax_cut === '1_month' ? taxationDetail.tax_cut === '1_month' : null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-4">
            {submitError && <Alert className={'flex-1'} type={'error'} message={submitError} />}
            <Button variant={'outlined'} onClick={() => handleSaveCL('draft')} isLoading={isLoadingDraft}>
              Save Draft
            </Button>
            <Button onClick={() => handleSaveCL('submitted')} isLoading={isLoadingSubmit}>
              Submit & Generate Document
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
