import React, { forwardRef, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import { format } from 'date-fns'
import { DateRange, DayPicker } from 'react-day-picker'
import moment from 'moment/moment'
import withClickOutside from '../withClickOutside'
import { CalendarIcon } from '@mui/x-date-pickers'

const InputDate = forwardRef(function InputDate({ open, setOpen, props }, ref) {
  const [selected, setSelected] = useState()
  const [range, setRange] = useState()
  const { handleSelectedOption, className, placeholder, label, value, toYear } = {
    ...props,
  }

  useEffect(() => {
    setSelected(value)
    // console.log(selected)
    // console.log(moment(new Date(selected)).format('DD MMMM YYYY'))
  }, [value])

  function handleClickDate(day) {
    // console.log(day)
    handleSelectedOption(moment(new Date(day)).format('YYYY-MM-DD'))
    setSelected(moment(new Date(day)).format('YYYY-MM-DD'))
    setOpen(!open)
  }
  return (
    <div className="relative" ref={ref}>
      {label && <p className="text-slate-800 font-semibold mb-1">{label}</p>}
      <div
        className={`rounded border border-primary-gray w-full block transition-all focus-within:ring-2 focus-within:ring-main-500 focus-within:outline-none ${className}`}
      >
        <div className="px-3 py-3 cursor-pointer" onClick={() => setOpen(!open)}>
          {selected ? (
            <div className="flex items-center justify-between">
              <p className="">{moment(new Date(selected)).format('D MMMM YYYY')}</p>
              <span className="w-4 h-4 items-center justify-center">
                <CalendarIcon sx={{ fontSize: 14 }} />
              </span>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <p className="text-gray-400">dd/mm/yyyy</p>
              <span className="w-4 h-4 items-center justify-center">
                <CalendarIcon sx={{ fontSize: 14 }} />
              </span>
            </div>
          )}
        </div>
        {open && (
          <div className="absolute top-14 left-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1" role="none">
              <DayPicker
                mode="single"
                selected={selected}
                onSelect={(day) => {
                  handleClickDate(day)
                }}
                captionLayout="dropdown-buttons"
                fromYear={1970}
                toYear={toYear ? toYear : new Date().getFullYear()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
})

export default withClickOutside(InputDate)
