import logo from './logo.svg'
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import 'react-day-picker/dist/style.css'
import Performance from './pages/Performance'
import SalesPlan from './pages/SalesPlan'
import Navbar from './components/Navbar'
import Project from './pages/Project'
import Data from './pages/Data'
import Components from './pages/Components'
import Login from './pages/Login'
import PrivateRoutes from './PrivateRoutes'
import DataRoutes from './pages/DataRoutes'
import { AuthContextProvider, UserAuth } from './context/AuthContext'
import SalesPlanRoutes from './pages/SalesPlanRoutes'
import SalesDeal from './pages/SalesDeal'
import SalesDealRoutes from './pages/SalesDealRoutes'
import ProjectRoutes from './pages/ProjectRoutes'
import ViewProject from './pages/Projects/ViewProject'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import ReviewProject from './pages/Projects/ReviewProjects'
import CreateFlagship from './pages/Projects/CreateFlagship'
import ViewProjectFlagship from './pages/Projects/ViewProjectFlagship'
import Profile from './pages/Profile/Profile'
import ErrorPage from './ErrorPage'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { useSelector } from 'react-redux'
import Logout from './pages/Logout'
function App() {
  let persistor = persistStore(store)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="text-normal h-screen overflow-y-auto">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <AuthContextProvider>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                  {/* <Route path="/components/*" element={<Components />} /> */}
                  <Route
                    path="/*"
                    element={
                      <PrivateRoutes>
                        <Navbar />
                        <div className="max-w-[1440px] mx-auto px-2 min-h-[calc(100%-56px)] mt-[56px] lg:mt-0 md:px-4 lg:px-6">
                          <Routes>
                            <Route path="profile/*" element={<Profile />} />
                            <Route path="data/*" element={<DataRoutes />} />
                            <Route path="data" element={<Data />} />
                            <Route path="projects/create-flagship" element={<CreateFlagship />} />
                            <Route path="projects/review/:id" element={<ReviewProject />} />
                            <Route path="projects/view/f/:id" element={<ViewProjectFlagship />} />
                            <Route path="projects/view/:id" element={<ViewProject />} />
                            <Route path="projects/edit/*" element={<ProjectRoutes />} />
                            <Route path="projects/*" element={<Project />} />
                            <Route path="sales-plan" element={<SalesPlan />} />
                            <Route path="sales-plan/*" element={<SalesPlanRoutes />} />
                            <Route path="sales-deal" element={<SalesDeal />} />
                            <Route path="sales-deal/*" element={<SalesDealRoutes />} />
                            <Route path="performance/*" element={<Performance />} />
                            <Route path="*" element={<ErrorPage type={404} />} />
                          </Routes>
                        </div>
                      </PrivateRoutes>
                    }
                  />
                  <Route exact path="/" element={<Navigate to={'/sales-plan'} />} />
                  <Route path="*" element={<ErrorPage type={404} />} />
                </Routes>
              </AuthContextProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </div>
      </PersistGate>
    </Provider>
  )
}

export default App
