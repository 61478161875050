import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import Input from '../../components/Input'
import DropdownInput from '../../components/DropdownInput'
import Table from '../../components/table/Table'
import LogoCell from '../../components/table/cell/LogoCell'
import Basic from '../../components/table/cell/Basic'
import InvoiceAPI from '../../apis/InvoiceAPI'
import LoadingSpinner from '../../components/LoadingSpinner'
import moment from 'moment'
import { formatThousands } from '../../helper'
import StatusCell from '../../components/table/cell/StatusCell'
import AccountAPI from '../../apis/AccountAPI'
import Pagination from '../../components/Pagination'
import { Description, RateReview, Visibility } from '@mui/icons-material'
import Button from '../../components/Button'
import usePermission from '../../hooks/usePermission'
import { Link } from 'react-router-dom'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'

export default function InvoiceRequest({}) {
  const { isAE, isFinance } = usePermission()
  const [isLoading, setLoading] = useState(true)
  const [listInvoiceRequest, setListInvoiceRequest] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [stopInput, setStopInput] = useState('')
  const [listAE, setListAE] = useState([])
  const [listStatus, setListStatus] = useState([
    {
      text: 'All Status',
      status: '',
    },
    {
      text: 'In Review',
      status: 'in_review',
      //
    },
    {
      text: 'Approved',
      status: 'approved',
    },
    {
      text: 'Rejected',
      status: 'rejected',
    },
  ])
  const [selectedStatus, setSelectedStatus] = useState(
    isFinance
      ? {
          text: 'In Review',
          status: 'in_review',
          //
        }
      : {
          text: 'All Status',
          status: '',
        }
  )
  const [statusCellConfig, setCellConfig] = useState([
    {
      text: 'In Review',
      status: 'in_review',
      state: 'warning',
    },
    {
      text: 'Approved',
      status: 'approved',
      state: 'success',
    },
    {
      text: 'Rejected',
      status: 'rejected',
      state: 'danger',
    },
  ])
  const [invoiceCols, setInvoiceCols] = useState([
    {
      id: 0,
      name: 'Request Date',
      classes: 'w-[180px]',
      selector: (row) => (
        <>
          <p>{moment(row.created_at).format('D MMMM YYYY')}</p>
          <p>{moment(row.created_at).format('hh:mm a')}</p>
        </>
      ),
    },
    {
      id: 1,
      name: 'Account Executive',
      classes: 'w-[220px]',
      selector: (row) => <LogoCell imgShape={'circle'} img={row.account_avatar} text={row.account_name} />,
    },
    {
      id: 2,
      name: 'Client',
      classes: 'w-[220px]',
      selector: (row) => (
        <LogoCell
          img={row.client_logo ? row.client_logo : 'https://ui-avatars.com/api/?name=Kata+Data'}
          text={row.client_name}
        />
      ),
    },
    {
      id: 3,
      name: 'Project',
      classes: 'w-[220px]',
      selector: (row) => <Basic data={row.project_name} />,
    },
    {
      id: 4,
      name: 'Invoice ID',
      classes: 'w-[120px]',
      selector: (row) => <Basic data={row.id} />,
    },
    {
      id: 5,
      name: 'Invoice Amount',
      classes: 'w-[150px] text-right',
      selector: (row) => <Basic className={'text-right'} data={`Rp${formatThousands(row.amount)}`} />,
    },
    {
      id: 6,
      name: 'Status',
      classes: 'w-[150px]',
      selector: (row) => <StatusCell config={statusCellConfig} data={row.status} />,
    },
  ])
  const [pagination, setPagination] = useState({
    total_page: 1,
  })
  const [searchParams, setSearchParams] = useCustomSearchParams()

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        AccountAPI.getListSimple('ae').then((res) => {
          setListAE([{ id: '', name: 'All AE' }, ...res.data])
          resolve()
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      //
    })
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true)
      var promises = [
        new Promise((resolve, reject) => {
          InvoiceAPI.getAllInvoice(
            searchParams.q || undefined,
            searchParams.ae || undefined,
            searchParams.status || undefined,
            searchParams.page || 1
          ).then((res) => {
            if (res.success) {
              setListInvoiceRequest(res.data)
              setPagination(res.meta)
            } else {
              setListInvoiceRequest([])
              setPagination({ total_page: 1 })
            }
            resolve()
          })
        }),
      ]

      Promise.all(promises).then((res) => {
        console.log(res)
        setLoading(false)
      })
    }, 250)
    return () => clearTimeout(timeoutId)
  }, [searchParams.q, searchParams.page, searchParams.status, searchParams.ae])

  useEffect(() => {}, [inputValue])

  return (
    <div className="py-4">
      <BreadCrumbs className="mb-4">
        <BreadCrumbs.Item href={'/data'}>Data</BreadCrumbs.Item>
        <BreadCrumbs.Item current>Invoice Request</BreadCrumbs.Item>
      </BreadCrumbs>
      <>
        <div className="mb-4">
          <div className="filter flex flex-col lg:flex-row gap-2.5 md:gap-4">
            <Input
              label={'Project'}
              value={searchParams.q}
              className={'w-full md:w-64'}
              onChange={(e) => setSearchParams({ ...searchParams, page: 1, q: e.target.value })}
              placeholder="Project Name"
            />
            {!isAE ? (
              <div>
                <p className="font-semibold mb-1">Account Executive</p>
                <DropdownInput
                  data={listAE}
                  value={listAE.find((ae) => ae.id == searchParams.ae)}
                  placeholder="Select AE"
                  attributeShown="name"
                  handleSelectedOption={(selected) => {
                    setSearchParams({ ...searchParams, page: 1, ae: selected.id })
                  }}
                />
              </div>
            ) : null}
            <div>
              <p className="font-semibold mb-1">Status</p>
              <DropdownInput
                data={listStatus}
                value={listStatus.find((status) => status.status === searchParams.status)}
                placeholder="Select AE"
                attributeShown="text"
                handleSelectedOption={(selected) => {
                  setSearchParams({ ...searchParams, page: 1, status: selected.status })
                }}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table
            data={listInvoiceRequest}
            cols={invoiceCols}
            tableType="w-full"
            // withActions={isAdmin}
            viewLink={(row) => `view/${row.id}`}
            editLink={(row) => `sbu/${row.sbu_id}`}
            customAction={(row) => (
              <>
                {row.status === 'in_review' && isFinance ? (
                  <>
                    <Button
                      icon={<Description sx={{ fontSize: 16 }} />}
                      href={`review/${row.id}`}
                      className={'hidden md:inline'}
                    >
                      Review
                    </Button>
                    <Link
                      className={'md:hidden border-2 border-main-500 text-main-500 mx-auto p-2 rounded-full'}
                      to={`review/${row.id}`}
                    >
                      <RateReview sx={{ fontSize: 16 }} />
                    </Link>
                  </>
                ) : (
                  <>
                    <Button
                      icon={<Visibility sx={{ fontSize: 16 }} />}
                      href={`view/${row.id}`}
                      variant={'outlined'}
                      className={'hidden md:inline'}
                    >
                      Details
                    </Button>
                    <Link
                      className={'md:hidden border-2 border-main-500 text-main-500 mx-auto p-2 rounded-full'}
                      to={`view/${row.id}`}
                    >
                      <RateReview sx={{ fontSize: 16 }} />
                    </Link>
                  </>
                )}
              </>
            )}
          />
        )}
        <Pagination
          totalPage={pagination.total_page || 1}
          currentPage={parseInt(searchParams.page) || 1}
          onChange={(page) => setSearchParams({ ...searchParams, page: page })}
        />
      </>
    </div>
  )
}
