import React, { forwardRef, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import withClickOutside from '../withClickOutside'
import Input from './Input'

const DropdownInput = forwardRef(function DropdownInput({ open, setOpen, props }, ref) {
  const [selected, setSelected] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const {
    data,
    handleSelectedOption,
    placeholder,
    attributeShown,
    className,
    defaultValue,
    error,
    value,
    position,
    withSearch,
    required,
  } = {
    ...props,
  }

  const [displayedData, setDisplayedData] = useState(data)
  const [searchText, setSearchText] = useState()

  useEffect(() => {
    if (defaultValue) {
      console.log(defaultValue)
      var temp = data.filter((item) => item.id === defaultValue)
      console.log(temp)
      setSelected(temp[0])
    }
  }, [defaultValue])

  useEffect(() => {
    setSelected(value)
  }, [value])

  useEffect(() => {
    setDisplayedData(data)
  }, [open])

  useEffect(() => {
    setDisplayedData(data)
  }, [data])

  // useState(() => {
  //   console.log(selected)
  // }, [selected])

  const handleOptionClick = (option) => {
    handleSelectedOption(option)
    setSelected(option)
    setOpen(!open)
  }

  const handleSearch = (input) => {
    if (input.value === '') setDisplayedData(data)
    var temp = data.filter((item) => item[attributeShown].toLowerCase().includes(input.value.toLowerCase()))
    console.log(temp)
    setDisplayedData(temp)
  }

  return (
    <div className="relative text-left" ref={ref}>
      <div className="min-w-[260px]">
        <div
          className={`px-3 py-3 rounded-[5px] border ${
            error ? 'border-red-500' : 'border-primary-gray'
          } w-full flex items-center justify-between transition-all
          focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none
          focus:border-main-500
           ${className}`}
          onClick={() => setOpen(!open)}
        >
          {selected ? <p>{selected[attributeShown]}</p> : <p className="text-gray-400">{placeholder}</p>}
          <span className="w-[12px] h-[12px] flex items-center justify-center ml-4">
            <KeyboardArrowDownIcon sx={{ fontSize: 16 }} className="" />
          </span>
        </div>
      </div>
      {open && (
        <div
          className={`absolute ${
            position === 'top' ? 'bottom-12' : 'left-0'
          } z-[900] mt-2 w-full max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-track-sky-50 scrollbar-thumb-main-500 scrollbar-thumb-rounded origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1" role="none">
            {withSearch && (
              <div className="py-1 px-2">
                <Input placeholder="Search data..." onChange={(e) => handleSearch(e.target)} />
              </div>
            )}
            {displayedData.map((option) => {
              return (
                <div
                  key={option.id}
                  className="text-gray-700 w-full px-4 py-2 text-sm flex items-center gap-2 hover:bg-main-50"
                  onClick={() => handleOptionClick(option)}
                >
                  {option[attributeShown]}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
})

export default withClickOutside(DropdownInput)
