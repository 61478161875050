export default function Tooltip({ message, children }) {
  return (
    <div className="group relative">
      {children}
      <p className="absolute top-6 scale-0 transition-all rounded bg-gray-800 p-2 text-small text-white group-hover:scale-100 z-10">
        {message}
      </p>
    </div>
  )
}
