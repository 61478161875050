export const industry = [
  { id: 'ngo', industry: 'NGO' },
  { id: 'telekomunikasi', industry: 'Telekomunikasi' },
  { id: 'energi', industry: 'Energi' },
  { id: 'bumn', industry: 'BUMN' },
  { id: 'kementerian', industry: 'Kementerian' },
  { id: 'bank_swasta', industry: 'Bank Swasta' },
  { id: 'bank_bumn', industry: 'Bank BUMN' },
  { id: 'fmcg', industry: 'FMCG' },
  { id: 'it_digital_technology', industry: 'IT/Digital Technology' },
  { id: 'ecommerce', industry: 'E-Commerce' },
  { id: 'financial', industry: 'Financial' },
  { id: 'otomotif', industry: 'Otomotif' },
  { id: 'asosiasi', industry: 'Asosiasi' },
  { id: 'property', industry: 'Property' },
  { id: 'elektronik', industry: 'Elektronik' },
  { id: 'asuransi', industry: 'Asuransi' },
  { id: 'bumd', industry: 'BUMD' },
  { id: 'rumah_sakit', industry: 'Rumah Sakit' },
  { id: 'pemerintah_daerah', industry: 'Pemerintah Daerah' },
  { id: 'logistik', industry: 'Logistik' },
  { id: 'airlines', industry: 'Airlines' },
  { id: 'horeka', industry: 'Horeka' },
  { id: 'industrial', industry: 'Industrial' },
  { id: 'lembaga_negara', industry: 'Lembaga Negara' },
  { id: 'farmacy', industry: 'Farmasi' },
  { id: 'transportasi', industry: 'Transportasi' },
  { id: 'pendidikan', industry: 'Pendidikan' },
]
