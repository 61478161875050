import React, { forwardRef, useEffect, useState } from 'react'
import Column from './Column'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import moment from 'moment'

export default function Dnd({ initialColumns, setRefreshDOM }) {
  const initialColumnsOld = {
    monday: {
      id: 'monday',
      list: [
        {
          id: 0,
          client: 'Unilever',
          project: 'Artikel Ramadhan',
          revenue: 125000000,
          status: 'visit',
        },
        {
          id: 1,
          client: 'Pertamina',
          project: 'Riset Impact GDP',
          revenue: 125000000,
          status: 'hot-prospect',
        },
      ],
    },
    tuesday: {
      id: 'tuesday',
      list: [
        {
          id: 2,
          client: 'Telkomsel',
          project: 'Promosi Paket Internet 5G',
          revenue: 22500000,
          status: 'leads',
        },
        {
          id: 3,
          client: 'Samsung',
          project: 'Promosi Rilis Samsung Galaxy Buds',
          revenue: 125000000,
          status: 'on-hands',
        },
        {
          id: 4,
          client: 'Kementrian Perhubungan',
          project: 'Sosialisasi Mudik x COVID',
          revenue: 125000000,
          status: 'revenue',
        },
      ],
    },
    wednesday: {
      id: 'wednesday',
      list: [
        {
          id: 5,
          client: 'Telkomsel',
          project: 'Promosi Paket Internet 5G',
          revenue: 22500000,
          status: 'leads',
        },
        {
          id: 6,
          client: 'Samsung',
          project: 'Promosi Rilis Samsung Galaxy Buds',
          revenue: 125000000,
          status: 'on-hands',
        },
        {
          id: 7,
          client: 'Kementrian Perhubungan',
          project: 'Sosialisasi Mudik x COVID',
          revenue: 125000000,
          status: 'revenue',
        },
      ],
    },
    thursday: {
      id: 'thursday',
      list: [
        {
          id: 8,
          client: 'Telkomsel',
          project: 'Promosi Paket Internet 5G',
          revenue: 22500000,
          status: 'leads',
        },
        {
          id: 9,
          client: 'Samsung',
          project: 'Promosi Rilis Samsung Galaxy Buds',
          revenue: 125000000,
          status: 'on-hands',
        },
        {
          id: 10,
          client: 'Kementrian Perhubungan',
          project: 'Sosialisasi Mudik x COVID',
          revenue: 125000000,
          status: 'revenue',
        },
      ],
    },
    friday: {
      id: 'friday',
      list: [
        {
          id: 11,
          client: 'Telkomsel',
          project: 'Promosi Paket Internet 5G',
          revenue: 22500000,
          status: 'leads',
        },
        {
          id: 12,
          client: 'Samsung',
          project: 'Promosi Rilis Samsung Galaxy Buds',
          revenue: 125000000,
          status: 'on-hands',
        },
        {
          id: 13,
          client: 'Kementrian Perhubungan',
          project: 'Sosialisasi Mudik x COVID',
          revenue: 125000000,
          status: 'revenue',
        },
      ],
    },
  }
  const [columns, setColumns] = useState(initialColumns)
  useEffect(() => {
    // console.log(initialColumns)
  }, [])
  const onDragEnd = ({ source, destination }) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index) return null

    // Set start and end variables
    console.log(moment(source.droppableId).format('dddd').toLowerCase())
    const start = columns[moment(source.droppableId).format('dddd').toLowerCase()]
    const end = columns[moment(destination.droppableId).format('dddd').toLowerCase()]

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter((item, index) => index !== source.index)

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index])

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      }

      // Update the state
      setColumns((state) => ({ ...state, [moment(newCol.id).format('dddd').toLowerCase()]: newCol }))
      return null
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter((item, index) => index !== source.index)

      // Create a new start column
      const newStartCol = {
        id: start.id,
        list: newStartList,
      }

      // Make a new end list array
      const newEndList = end.list

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index])

      // Create a new end column
      const newEndCol = {
        id: end.id,
        list: newEndList,
      }

      // Update the state
      setColumns((state) => ({
        ...state,
        [moment(newStartCol.id).format('dddd').toLowerCase()]: newStartCol,
        [moment(newEndCol.id).format('dddd').toLowerCase()]: newEndCol,
      }))
      return null
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex mx-auto w-full h-full gap-4 overflow-y-hidden overflow-x-scroll scrollbar-thin scrollbar-main-500">
        {Object.values(columns).map((col) => (
          <Column col={col} key={col.id} setRefreshDOM={setRefreshDOM} />
        ))}
      </div>
    </DragDropContext>
  )
}
