import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/BreadCrumbs'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { getBottomNavigationActionUtilityClass } from '@mui/material'
import SalesDealAPI from '../../apis/SalesDealAPI'
import Loading from '../../components/Loading'
import Tabs from '../../components/Tabs'
import SalesDealOverview from './SalesDealOverview'
import SalesDealHistory from './SalesDealHistory'
import SalesDealProjectFeasibility from './SalesDealProjectFeasibility'
import StorageAPI from '../../apis/storageAPI'
import DismissableToast from '../../components/Toast'
import toast from 'react-hot-toast'
import ErrorPage from '../../ErrorPage'

export default function SalesDealDetail() {
  const { id } = useParams()
  const [refreshDOM, setRefreshDOM] = useState()
  const [salesDeal, setSalesDeal] = useState()
  const [isLoading, setLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState('overview')
  const [errorRequest, setErrorRequest] = useState()
  const [errorCode, setErrorCode] = useState()

  useEffect(() => {
    SalesDealAPI.getSalesDealById(id)
      .then((salesDealRes) => {
        console.log(salesDealRes)
        var tempDocs = { invoices: [] }
        var promises = salesDealRes.data.documents?.map((doc) => {
          return new Promise((resolve, reject) => {
            if (doc.type === 'invoice') {
              StorageAPI.getMetadataFromURL(doc.url).then((res) => {
                var newInvoices = [...tempDocs.invoices, { ...doc, metadata: res }]
                tempDocs.invoices = newInvoices
                resolve('hehe')
              })
            } else {
              StorageAPI.getMetadataFromURL(doc.url).then((res) => {
                tempDocs[doc.type] = doc
                tempDocs[doc.type] = { ...tempDocs[doc.type], metadata: res }
                resolve('hehe')
              })
            }
          })
        })
        Promise.all(promises).then((res) => {
          setSalesDeal({ ...salesDealRes.data, documents: tempDocs })
          setLoading(false)
        })
      })
      .catch((err) => {
        console.log(err.response.data.error_code)
        if (err.response) {
          setErrorRequest(err.response.data.message)
          toast.error(err.response.data.message)
          setErrorCode(err.response.data.error_code)
        } else {
          setErrorRequest(err.statusText)
          toast.error(err.statusText)
        }
        setLoading(false)
      })
  }, [id, refreshDOM])

  // useEffect(() => {
  //   console.log(salesDeal)
  //   // if (salesDeal) StorageAPI.getMetadataFromURL(salesDeal.documents.propurl)
  // }, [salesDeal])

  // if (errorCode == 'forbidden_row_access') {
  //   return
  // }

  return (
    <div className="py-4">
      <DismissableToast />
      {isLoading ? (
        <Loading />
      ) : errorCode === 'forbidden_row_access' ? (
        <ErrorPage type={403} />
      ) : (
        <>
          <div className="mb-4">
            <BreadCrumbs>
              <BreadCrumbs.Item href={'/sales-deal'}>Sales Deal</BreadCrumbs.Item>
              <BreadCrumbs.Item current>{salesDeal.project.name}</BreadCrumbs.Item>
            </BreadCrumbs>
          </div>

          <Tabs
            data={[
              { id: 1, name: 'Overview', link: '' },
              { id: 2, name: 'History', link: 'history' },
              { id: 3, name: 'Project Feasibility', link: 'project-feasibility' },
            ]}
          />
          <Routes>
            <Route path="" element={<SalesDealOverview salesDeal={salesDeal} setRefreshDOM={setRefreshDOM} />}></Route>
            <Route path="history" element={<SalesDealHistory salesDeal={salesDeal} />}></Route>
            <Route path="project-feasibility" element={<SalesDealProjectFeasibility salesDeal={salesDeal} />}></Route>
            {/* <Route exact path="/" element={<Navigate to="overview" />}></Route> */}
          </Routes>
        </>
      )}
    </div>
  )
}
