import React, { forwardRef, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import withClickOutside from '../withClickOutside'
import Input from './Input'

const DropdownUser = forwardRef(function DropdownUser({ open, setOpen, props }, ref) {
  const [isOpen, setIsOpen] = useState(false)
  const {
    data,
    noPic,
    handleSelectedOption,
    type,
    placeholder,
    attributeShown,
    className,
    defaultValue,
    withSearch,
    value,
  } = { ...props }
  const [selected, setSelected] = useState()
  const [displayedData, setDisplayedData] = useState(data)

  useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    setSelected(value)
  }, [value])

  useEffect(() => {
    setDisplayedData(data)
  }, [open])

  useEffect(() => {
    setDisplayedData(data)
  }, [data])

  const handleOptionClick = (option) => {
    handleSelectedOption(option)
    setOpen(!open)
  }

  const handleSearch = (input) => {
    if (input.value === '') setDisplayedData(data)
    var temp = data.filter((item) => item[attributeShown].toLowerCase().includes(input.value.toLowerCase()))
    setDisplayedData(temp)
  }

  return (
    <div className="relative md:inline-block text-left" ref={ref}>
      <div className={`min-w-[260px] ${className}`}>
        <button
          type="button"
          className="w-full rounded-md bg-white px-4 py-2 font-medium text-gray-700 hover:bg-sky-50 focus:outline-none focus:ring-0"
          onClick={() => setOpen(!open)}
        >
          {selected ? (
            <div className="flex items-center gap-2">
              <span className="w-10 h-10 rounded-full bg-blue-100 overflow-hidden flex items-center justify-center text-main-500">
                <BusinessCenterIcon sx={{ fontSize: 22 }} />
              </span>
              <div className="flex-1 text-start pr-4">
                <p className="font-bold text-main-500">{type}</p>
                <div className="flex items-center justify-between">
                  <p className="">{selected[attributeShown]}</p>
                  <span className="flex items-center justify-center w-2 h-2">
                    <KeyboardArrowDownIcon sx={{ fontSize: 20 }} className="ml-4" />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <span className="w-10 h-10 rounded-full bg-blue-100 overflow-hidden flex items-center justify-center text-main-500">
                <BusinessCenterIcon sx={{ fontSize: 22 }} />
              </span>
              <div className="flex-1 text-start pr-4">
                <p className="font-bold text-main-500">{type}</p>
                <div className="flex items-center justify-between">
                  <span className="flex-1 text-normal">{placeholder ? placeholder : 'Select Option'}</span>
                  <span className="flex items-center justify-center w-2 h-2">
                    <KeyboardArrowDownIcon sx={{ fontSize: 20 }} className="ml-4" />
                  </span>
                </div>
              </div>
            </div>
          )}
        </button>
      </div>
      {open && (
        <div className="absolute left-0 z-50 mt-2 w-full max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="" role="none">
            {withSearch && (
              <div className="py-1 px-2">
                <Input placeholder="Search data..." onChange={(e) => handleSearch(e.target)} />
              </div>
            )}
            {displayedData.map((option, index) => {
              return (
                <button
                  key={index}
                  className="text-gray-700 w-full px-4 py-2 text-left text-sm flex items-center gap-2 hover:bg-main-50"
                  onClick={() => {
                    handleOptionClick(option)
                  }}
                >
                  <>
                    {noPic ? null : (
                      <span className="rounded-full bg-main-100 overflow-hidden">
                        <img className="w-8 h-8" src={option.image} alt="" />
                      </span>
                    )}
                    <>{option[attributeShown]}</>
                  </>
                </button>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
})

export default withClickOutside(DropdownUser)
