import { devAxios } from './axiosConfig'

const TargetAPI = {
  getListTarget: async () => {
    var res = await devAxios.get('target')
    return res.data
  },
  getTargetByYear: async (year) => {
    var res = await devAxios.get(`target/${year}`)
    return res.data
  },

  createTarget: async (data) => {
    var res = await devAxios.post('target', data)
    return res.data
  },

  updateTarget: async (year, data) => {
    var res = await devAxios.put(`target/${year}`, data)
    return res.data
  },
}

export default TargetAPI
