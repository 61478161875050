import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AddMom from './SalesPlan/AddMom'
import usePermission from '../hooks/usePermission'
import ErrorPage from '../ErrorPage'
import EditMom from './SalesPlan/EditMom'

export default function SalesPlanRoutes() {
  const { isAdmin } = usePermission()
  return (
    <Routes>
      <Route path="/:id/edit-mom" element={<EditMom />} />
      <Route path="/:id/add-mom" element={<AddMom />} />
      <Route path="*" element={<ErrorPage type={404} />} />
    </Routes>
  )
}
