import React, { useEffect, useState } from 'react'
import Table from '../../components/table/Table'
import Button from '../../components/Button'
import { Dataset } from '@mui/icons-material'
import Basic from '../../components/table/cell/Basic'
import { formatThousands } from '../../helper'
import StatisticsAPI from '../../apis/Statistics'
import Loading from '../../components/Loading'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'
import exportFromJSON from 'export-from-json'
import moment from 'moment'

const listMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const capitalizeEachWord = (str) => {
  var words = str.split(' ')

  words.forEach((word, index) => {
    words[index] = word[0].toUpperCase() + word.substring(1)
  })
  return words.join().replaceAll(',', ' ')
}

export default function Clients({}) {
  const [isLoading, setLoading] = useState(false)
  const [monthlyPerformance, setMonthlyPerformance] = useState([])
  const [monthlyPerformanceFormated, setMonthlyPerformanceFormated] = useState()
  const [ytdPerformance, setYtdPerformance] = useState([])
  const [ytdPerformanceFormated, setYtdPerformanceFormated] = useState()
  const [searchParams, setSearchParams] = useCustomSearchParams()

  const monthlyPerformanceCols = [
    { id: 0, name: '', classes: 'min-w-[180px]', selector: (row) => <Basic data={row.id} className={'capitalize'} /> },
    ...listMonth.map((month, index) => {
      return {
        id: index + 1,
        name: month,
        selector: (row) => <Basic data={`Rp${formatThousands(row.values[index])}`} alignment={'right'} />,
        classes: 'min-w-[170px] text-right',
      }
    }),
  ]

  const ytdPerformanceCols = [
    { id: 0, name: '', classes: 'min-w-[180px]', selector: (row) => <Basic data={row.id} className={'capitalize'} /> },
    ...listMonth.map((month, index) => {
      return {
        id: index + 1,
        name: month,
        selector: (row) => <Basic data={`Rp${formatThousands(row.values[index])}`} alignment={'right'} />,
        classes: 'min-w-[170px] text-right',
      }
    }),
  ]

  const formatData = (data) => {
    var temp = []
    for (let key in data) {
      var rowTemp = { id: key.replaceAll('_', ' '), values: data[key] }
      temp.push(rowTemp)
    }
    return temp
  }

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        StatisticsAPI.getClientMothlyPerformance(
          searchParams.sbu,
          searchParams.sm,
          searchParams.ae,
          searchParams.year
        ).then((res) => {
          var temp = formatData(res.data)
          setMonthlyPerformance(temp)
          var tempFormatDownload = temp.map((row) => {
            var tempRow = { Type: capitalizeEachWord(row.id.replaceAll('_', ' ')) }
            listMonth.forEach((month, index) => {
              tempRow[month] = row.values[index]
            })
            return tempRow
          })
          setMonthlyPerformanceFormated(tempFormatDownload)
          resolve()
        })
      }),
      new Promise((resolve, reject) => {
        StatisticsAPI.getClientYTDPerformance(
          searchParams.sbu,
          searchParams.sm,
          searchParams.ae,
          searchParams.year
        ).then((res) => {
          var temp = formatData(res.data)
          setYtdPerformance(temp)
          var tempFormatDownload = temp.map((row) => {
            var tempRow = { Type: capitalizeEachWord(row.id.replaceAll('_', ' ')) }
            listMonth.forEach((month, index) => {
              tempRow[month] = row.values[index]
            })
            return tempRow
          })
          setYtdPerformanceFormated(tempFormatDownload)
          resolve()
        })
      }),
    ]
    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [searchParams.sbu, searchParams.sm, searchParams.ae, searchParams.year])

  const exportXls = (data, fileName) => {
    exportFromJSON({ data, fileName, exportType: 'xls' })
  }

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="account-receivable-delay mb-8">
            <h1 className="font-semibold text-giant mb-4">Monthly Performance</h1>
            <div className="mb-4">
              <Table data={monthlyPerformance} cols={monthlyPerformanceCols} tableType="inline" noIndex />
              {/* <Table data={[]} cols={monthlyPerformanceCols} tableType="inline" noIndex /> */}
            </div>
            <div>
              <Button
                variant={'outlined'}
                icon={<Dataset sx={{ fontSize: 18 }} />}
                onClick={() =>
                  exportXls(monthlyPerformanceFormated, `monthly_performance_${moment().format('DDMMMYYYY')}`)
                }
              >
                .xls
              </Button>
            </div>
          </div>
          <div className="account-executive-ranking mb-8">
            <h1 className="font-semibold text-giant mb-4">YTD Performance</h1>
            <div className="mb-4">
              <Table data={ytdPerformance} cols={ytdPerformanceCols} tableType="inline" noIndex />
              {/* <Table data={[]} cols={ytdPerformanceCols} tableType="inline" noIndex /> */}
            </div>
            <div>
              <Button
                variant={'outlined'}
                icon={<Dataset sx={{ fontSize: 18 }} />}
                onClick={() => exportXls(ytdPerformanceFormated, `ytd_performance_${moment().format('DDMMMYYYY')}`)}
              >
                .xls
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
