import { devAxios } from './axiosConfig'

const InvoiceAPI = {
  getAllInvoice: async (project_name = undefined, ae_id = undefined, status = undefined, page = 1) => {
    var res = await devAxios.get('invoices', {
      params: {
        project_name: project_name,
        ae_id: ae_id,
        status: status,
        page: page,
      },
    })
    return res.data
  },

  getSingleInvoice: async (id) => {
    var res = await devAxios.get(`invoices/${id}`)
    return res.data
  },

  saveReviewInvoice: async (id, result, notes) => {
    var res = await devAxios.post(`invoices/review/${id}`, { result: result, notes: notes })
    return res.data
  },

  sendRequestInvoice: async (id) => {
    var res = await devAxios.post(`documents/invoice/send-request/${id}`)
    return res.data
  },
}

export default InvoiceAPI
