import React, { useState } from 'react'

export default function RadioButton({ name, id, value, onChange, checked, className, label }) {
  return (
    <label htmlFor={id} className={`flex items-center cursor-pointer py-0.5 ${className && className}`}>
      <input
        className="radio-input hidden"
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={(e) => {
          onChange(e)
        }}
        checked={checked}
      />
      <span
        className={`mr-2 w-4 h-4 rounded-full border-2 border-slate-800 inline-block transition-all ${
          checked ? 'bg-main-500' : 'bg-white'
        }`}
      />
      {label}
    </label>
  )
}
