import React, { useEffect, useState } from 'react'
import SBUAPI from '../apis/SBUAPI'
import AccountAPI from '../apis/AccountAPI'
import ProjectAPI from '../apis/ProjectAPI'
import Loading from '../components/Loading'
import DropdownUser from '../components/DropdownUser'
import { useLocalStorage } from '../hooks/useLocalStorage'
import Tabs from '../components/Tabs'
import GroupButtons from '../components/GroupButtons'
import { Dataset, Insights } from '@mui/icons-material'
import Table from '../components/table/Table'
import Basic from '../components/table/cell/Basic'
import Button from '../components/Button'
import { Route, Routes, createSearchParams, useLocation } from 'react-router-dom'
import Revenue from './Performance/Revenue'
import Sales from './Performance/Sales'
import Clients from './Performance/Clients'
import Products from './Performance/Products'
import usePermission from '../hooks/usePermission'
import { useDispatch } from 'react-redux'
import { useCustomSearchParams } from '../hooks/useCustomSearchParams'
import moment from 'moment'

const getYears = () => {
  var startYear = 2023
  var counter = 0
  var years = []
  while (startYear <= new Date().getFullYear()) {
    years.push({ id: counter, year: startYear })
    startYear += 1
    counter += 1
  }
  return years
}

export default function Performance() {
  const [listSBU, setListSBU] = useState([])
  const [listSM, setListSM] = useState([])
  const [listAE, setListAe] = useState([])
  const [listYear, setListYear] = useState(getYears())
  // const [filter, setFilter] = useState({
  //   sbu: undefined,
  //   sm: undefined,
  //   ae: undefined,
  //   year: undefined,
  // })
  const [isLoading, setLoading] = useState(true)
  const [analyticsType, setAnalyticsType] = useState('table')
  const location = useLocation()
  const { isAdmin, isVP, isSM, isAE } = usePermission()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useCustomSearchParams()

  useEffect(() => {
    if (!searchParams.year) setSearchParams({ ...searchParams, year: moment().year() })
  }, [])

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU().then((res) => {
          setListSBU([{ sbu_name: 'All SBU', sbu_id: '' }, ...res.data])
          resolve()
        })
      }),

      new Promise((resolve, reject) => {
        AccountAPI.getList('sm').then((res) => {
          setListSM([{ name: 'All Sales Manager', id: '' }, ...res.data])
        })
        resolve()
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getList('ae').then((res) => {
          setListAe([{ name: 'All Account Executive', id: '' }, ...res.data])
        })
        resolve()
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoading(false)
    })
  }, [])

  // useEffect(() => {
  //   ProjectAPI.getAllProjects(
  //     selectedSBU && selectedSBU.sbu_id,
  //     selectedSM && selectedSM.id,
  //     selectedAE && selectedAE.id,
  //     selectedStatus && selectedStatus.value
  //   ).then((res) => {
  //     setProjects(res.data)
  //     // console.log(res.data)
  //   })
  // }, [selectedSBU, selectedSM, selectedAE, selectedStatus])

  // useEffect(() => {
  //   console.log(filter)
  // }, [filter])

  return (
    <div className="py-4">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex gap-4 mb-4">
            {(isVP || isAdmin) && (
              <DropdownUser
                data={listSBU}
                noPic
                type="Sales Business Unit"
                handleSelectedOption={(selected) => {
                  setSearchParams({ ...searchParams, sbu: selected.sbu_id })
                }}
                attributeShown="sbu_name"
                value={listSBU.find((sbu) => sbu.sbu_id == searchParams.sbu)}
              />
            )}
            {(isAdmin || isVP) && (
              <DropdownUser
                data={listSM}
                noPic
                type="Sales Manager"
                handleSelectedOption={(selected) => {
                  setSearchParams({ ...searchParams, sm: selected.id })
                }}
                value={listSM.find((sm) => sm.id == searchParams.sm)}
                attributeShown="name"
              />
            )}
            {!isAE && (
              <DropdownUser
                data={listAE}
                noPic
                type="Account Executive"
                handleSelectedOption={(selected) => {
                  setSearchParams({ ...searchParams, ae: selected.id })
                }}
                value={listAE.find((ae) => ae.id == searchParams.ae)}
                attributeShown="name"
              />
            )}
            <DropdownUser
              data={listYear}
              noPic
              type="Year"
              handleSelectedOption={(selected) => {
                setSearchParams({ ...searchParams, year: selected.year })
              }}
              attributeShown="year"
              value={listYear.find((year) => year.year == searchParams.year)}
            />
          </div>
          <div className="flex gap-4 mb-4">
            <div className="flex-1">
              <Tabs
                className="mb-0"
                size={'full'}
                data={[
                  {
                    id: 1,
                    name: 'Revenue',
                    link: `/performance?${createSearchParams({ ...searchParams })}`,
                  },
                  { id: 2, name: 'Products', link: `products?${createSearchParams({ ...searchParams })}` },
                  {
                    id: 3,
                    name: 'Sales',
                    link: `sales?${createSearchParams({ ...searchParams })}`,
                    state: { ...searchParams },
                  },
                  {
                    id: 4,
                    name: 'Clients',
                    link: `clients?${createSearchParams({ ...searchParams })}`,
                    state: { ...searchParams },
                  },
                ]}
              />
            </div>
            <div className="w-[84px]">
              {location.pathname === '/performance' && (
                <GroupButtons
                  icons={true}
                  size="small"
                  defaultValue={analyticsType}
                  data={[
                    { id: 'table', text: <Dataset sx={{ fontSize: 18 }} />, onClick: () => setAnalyticsType('table') },
                    { id: 'graph', text: <Insights sx={{ fontSize: 18 }} />, onClick: () => setAnalyticsType('graph') },
                  ]}
                />
              )}
            </div>
          </div>
          <Routes>
            <Route path="/sales" element={<Sales />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/products" element={<Products />} />
            <Route path="/" element={<Revenue analyticsType={analyticsType} />} />
          </Routes>
        </>
      )}
    </div>
  )
}
