import React, { useEffect, useState } from 'react'
import Input from '../components/Input'
import Button from '../components/Button'
// import AuthAPI from '../apis/authAPI'
import { Navigate, useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import AccountAPI from '../apis/AccountAPI'
import Alert from '../components/Alert'
import { useDispatch } from 'react-redux'
import { saveDetailUserData, saveFirebaseLoginData } from '../redux/authSlice'
import { useSelector } from 'react-redux'
import moment from 'moment/moment'

export default function Login() {
  const firebaseData = useSelector((state) => state.auth.firebaseUser)
  const userData = useSelector((state) => state.auth.userData)
  const [inputUser, setInputUser] = useState()
  const { signIn } = UserAuth()
  const [error, setError] = useState('')
  const [isLoadingLogin, setLoadingLogin] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    console.log(error)
  }, [error])

  const handleChange = (input) => {
    setInputUser({ ...inputUser, [input.name]: input.value })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    setLoadingLogin(true)
    signIn(inputUser.email, inputUser.password)
      .then((res) => {
        dispatch(
          saveFirebaseLoginData({
            accessToken: res.user.accessToken,
            refreshToken: res.user.refreshToken,
            uid: res.user.uid,
            email: res.user.email,
            displayName: res.user.displayName,
            lastLoggedIn: moment().startOf('days').format(),
            lastRefreshToken: moment().format(),
            isRefreshing: false,
          })
        )
        // console.log(res.user)
        AccountAPI.getSingleAccountByUID(res.user.uid)
          .then((res) => {
            if (res.success) {
              // setAccountDetail(res.data)
              dispatch(
                saveDetailUserData({
                  id: res.data.id,
                  name: res.data.name,
                  role: res.data.role,
                  avatar: res.data.avatar,
                  email: res.data.email,
                  sbu_id: res.data.sbu_id,
                })
              )
              navigate('/sales-plan')
            }
          })
          .catch((err) => {
            console.log(err)
            setError(err.message)
            setLoadingLogin(false)
          })
      })
      .catch((err) => {
        console.log(err.code)
        if (err.code === 'auth/user-not-found') setError('Email was not found in database')
        else if (err.code === 'auth/wrong-password') setError('Wrong password.')
        else {
          setError(err.code)
        }

        setLoadingLogin(false)
        // setError(err.message)
      })
  }

  if (userData && firebaseData) return <Navigate to={'/sales-plan'} />

  return (
    <>
      <div className="w-full h-screen flex flex-col items-center justify-center bg-main-100">
        <div className="box w-[300px] rounded shadow-lg overflow-hidden mb-4">
          <div className="header h-[60px] bg-main-500 flex justify-center items-center text-lg font-bold leading-relaxed text-white">
            Katadata CRM
          </div>
          <form onSubmit={(e) => handleLogin(e)} className="body bg-white px-4 py-6 flex flex-col gap-4">
            <Input
              label={'Email'}
              onChange={(e) => handleChange(e.target)}
              name="email"
              type="email"
              placeholder="email@katadata.co.id"
            />
            <Input
              label={'Password'}
              onChange={(e) => handleChange(e.target)}
              name="password"
              type="password"
              placeholder=""
            />
            <Button className={'block'} type="submit" onClick={(e) => handleLogin(e)} isLoading={isLoadingLogin}>
              Login
            </Button>
          </form>
        </div>
        <div className="h-12 w-[300px]">{error && <Alert type="error" message={error} />}</div>
      </div>
    </>
  )
}
