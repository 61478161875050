import React, { useEffect, useState } from 'react'
import DropdownUser from '../components/DropdownUser'
import Loading from '../components/Loading'
import ClientAPI from '../apis/ClientAPI'
import SalesDealAPI from '../apis/SalesDealAPI'
import DnD from '../components/dnd_sales_deal/DnD'
import { addDays, startOfWeek } from 'date-fns'
import moment from 'moment'
import SBUAPI from '../apis/SBUAPI'
import StatisticsAPI from '../apis/Statistics'
import DismissableToast from '../components/Toast'
import { toast } from 'react-hot-toast'
import Button from '../components/Button'
import ProjectAPI from '../apis/ProjectAPI'
import { useCustomSearchParams } from '../hooks/useCustomSearchParams'
import DropdownGroup from '../components/DropdownGroup'
import AccountAPI from '../apis/AccountAPI'
import { industry } from '../services/industry'
import DropdownRangeDate from '../components/DropdownRangeDate'

const createDNDData = (data, date) => {
  const status = ['Visit', 'Leads', 'Hot Prospect', 'On Hands', 'Revenue']
  var res = {}
  for (let i = 0; i < status.length; i++) {
    var temp = data[status[i].replace(' ', '_').toLowerCase()]
    res = {
      ...res,
      [status[i].replace(' ', '_').toLowerCase()]: {
        id: status[i],
        list: temp,
      },
    }
  }
  // console.log(res)
  return res
}

export default function SalesDeal() {
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [isLoading, setLoading] = useState(true)
  const [listSBU, setListSBU] = useState([])
  const [listClient, setListClient] = useState([])
  const [listFlagshipProject, setListFlagshipProjects] = useState([])
  const [listAE, setListAE] = useState([])
  const [listTopic, setListTopic] = useState([
    { id: '', text: 'All Topic' },
    { id: 'green', text: 'Green' },
    { id: 'non_green', text: 'Non-green' },
  ])
  const [valueRange, setValueRange] = useState([
    { id: 12, text: 'All Value', value_range_max: '', value_range_min: '' },
    { id: 0, text: 'Kurang dari Rp50.000.000', value_range_max: 50000000, value_range_min: 0 },
    { id: 1, text: 'Rp50.000.000 - Rp100.000.000', value_range_max: 100000000, value_range_min: 50000000 },
    { id: 2, text: 'Rp100.000.000 - Rp200.000.000', value_range_max: 200000000, value_range_min: 100000000 },
    { id: 3, text: 'Rp200.000.000 - Rp300.000.000', value_range_max: 300000000, value_range_min: 200000000 },
    { id: 4, text: 'Lebih dari Rp300.000.000', value_range_max: 100000000000, value_range_min: 300000000 },
  ])
  const [listIndustry, setListIndustry] = useState([{ id: '', industry: 'All Industry' }, ...industry])
  const [statistics, setStatistics] = useState()
  const [salesDeal, setSalesDeal] = useState()
  const [isUpdatingStatus, setUpdatingStatus] = useState(false)
  const [isLoadingFilterData, setLoadingFilterData] = useState(true)

  useEffect(() => {
    var promises = [
      new Promise((resolve, reject) => {
        ClientAPI.getClientSimple()
          .then((res) => {
            setListClient([{ id: '', company_name: 'All clients' }, ...res.data])
          })
          .then((res) => resolve())
      }),
      new Promise((resolve, reject) => {
        SBUAPI.getAllSBU()
          .then((res) => {
            setListSBU([{ sbu_id: '', sbu_name: 'All SBU' }, ...res.data])
          })
          .then((res) => {
            resolve()
          })
      }),
      new Promise((resolve, reject) => {
        ProjectAPI.getAllFlagshipProject().then((res) => {
          if (res.success) {
            setListFlagshipProjects([{ id: '', name: 'All Projects' }, { id: -1, name: 'Non Flagship' }, ...res.data])
            resolve()
          }
        })
      }),
      new Promise((resolve, reject) => {
        AccountAPI.getListSimple('ae').then((res) => {
          setListAE([{ id: '', name: 'All AE' }, ...res.data])
          resolve()
        })
      }),
    ]

    Promise.all(promises).then((res) => {
      setLoadingFilterData(false)
    })
  }, [])

  useEffect(() => {
    // console.log(statistics)
  }, [statistics])

  useEffect(() => {
    if (!isLoadingFilterData) {
      var promises = [
        new Promise((resolve, reject) => {
          SalesDealAPI.getAllSalesDeal(
            searchParams.client || undefined,
            searchParams.sbu || undefined,
            searchParams.value_max || undefined,
            searchParams.value_min || undefined,
            searchParams.flagship || undefined,
            searchParams.start || undefined,
            searchParams.end || undefined,
            searchParams.industry || undefined,
            searchParams.topic || undefined,
            searchParams.ae || undefined
          ).then((res) => {
            setSalesDeal(createDNDData(res.data))
            resolve(createDNDData(res.data))
          })
        }),
        new Promise((resolve, reject) => {
          StatisticsAPI.getSalesDealStatistics(
            searchParams.client || undefined,
            searchParams.sbu || undefined,
            searchParams.value_max || undefined,
            searchParams.value_min || undefined,
            searchParams.flagship || undefined,
            searchParams.start || undefined,
            searchParams.end || undefined,
            searchParams.industry || undefined,
            searchParams.topic || undefined,
            searchParams.ae || undefined
          )
            .then((res) => {
              const status = ['visit', 'leads', 'hot_prospect', 'on-hands', 'revenue']
              var temp = {
                visit: { total_deal: 0, total_revenue: 0 },
                leads: { total_deal: 0, total_revenue: 0 },
                hot_prospect: { total_deal: 0, total_revenue: 0 },
                on_hands: { total_deal: 0, total_revenue: 0 },
                revenue: { total_deal: 0, total_revenue: 0 },
              }
              res.data.map((stats) => {
                temp[stats.status] = {
                  total_deal: stats.total_deal,
                  total_revenue: stats.total_revenue,
                }
              })
              setStatistics(temp)
            })
            .then((res) => {
              resolve()
            })
        }),
      ]
      Promise.all(promises).then((res) => {
        setLoading(false)
      })
    }
  }, [
    searchParams.client,
    searchParams.sbu,
    searchParams.value_max,
    searchParams.value_min,
    searchParams.flagship,
    searchParams.start,
    searchParams.end,
    searchParams.industry,
    searchParams.topic,
    searchParams.ae,
    isLoadingFilterData,
  ])

  const loadMoreItem = (status, page) => {
    SalesDealAPI.getSalesDealByStatus(
      status,
      searchParams.client || undefined,
      searchParams.sbu || undefined,
      searchParams.value_max || undefined,
      searchParams.value_min || undefined,
      page
    ).then((res) => {
      var tempSalesDeal = salesDeal
      var tempListOfTargetStatus = tempSalesDeal[status].list
      var newList = [...tempListOfTargetStatus, ...res.data]
      var newTargetStatusObject = { id: tempSalesDeal[status].id, list: newList }
      console.log(newTargetStatusObject)
      setSalesDeal({ ...tempSalesDeal, [status]: newTargetStatusObject })
    })
  }

  const toastUpdateSalesDeal = (type, message = undefined) => {
    if (type === 'success') toast.success('Update sales deal status success!')
    else {
      // console.log(message)
      if (message) {
        toast.error(message)
      } else {
        toast.error('Update sales deal failed. Please check all required documents')
      }
    }
  }

  return (
    <div className="h-[calc(100vh-72px-8px)] md:h-[calc(100vh-56px)] overflow-hidden py-2 pb-0 lg:px-8 lg:py-8">
      <DismissableToast />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col md:items-center md:flex-row gap-4 mb-4">
            <div className="flex-1 w-full flex flex-col gap-2 md:flex-row">
              <DropdownRangeDate
                handleSelectedOption={(selected) => {
                  if (selected?.from || selected?.to) {
                    setSearchParams({
                      ...searchParams,
                      start: moment(selected.from).format('YYYY-MM-DD'),
                      end: moment(selected.to).format('YYYY-MM-DD'),
                    })
                  } else {
                    setSearchParams({ ...searchParams, start: '', end: '' })
                  }
                }}
                value={{ from: moment(searchParams.start).format(), to: moment(searchParams.end).format() }}
              />
              <DropdownUser
                data={listAE}
                withSearch
                noPic
                type="Account Executive"
                value={listAE.find((ae) => ae.id == searchParams.ae)}
                handleSelectedOption={(selected) => setSearchParams({ ...searchParams, ae: selected.id })}
                attributeShown="name"
                className="hidden lg:block"
              />
              <DropdownUser
                data={listClient}
                withSearch
                noPic
                type="Client"
                value={listClient.find((client) => client.id == searchParams.client)}
                handleSelectedOption={(selected) => setSearchParams({ ...searchParams, client: selected.id })}
                attributeShown="company_name"
                className="hidden xl:block"
              />
              <DropdownUser
                data={listIndustry}
                withSearch
                noPic
                type="Industry"
                value={listIndustry.find((industry) => industry.id == searchParams.industry)}
                handleSelectedOption={(selected) => setSearchParams({ ...searchParams, industry: selected.id })}
                attributeShown="industry"
                className="!min-w-[200px] hidden xl:block"
              />
              <DropdownGroup>
                <DropdownUser
                  data={listAE}
                  withSearch
                  noPic
                  type="Account Executive"
                  value={listAE.find((ae) => ae.id == searchParams.ae)}
                  handleSelectedOption={(selected) => setSearchParams({ ...searchParams, ae: selected.id })}
                  attributeShown="name"
                  className="lg:hidden"
                />
                <DropdownUser
                  data={listClient}
                  withSearch
                  noPic
                  type="Client"
                  value={listClient.find((client) => client.id == searchParams.client)}
                  handleSelectedOption={(selected) => setSearchParams({ ...searchParams, client: selected.id })}
                  attributeShown="company_name"
                  className="xl:hidden"
                />
                <DropdownUser
                  data={listIndustry}
                  withSearch
                  noPic
                  type="Industry"
                  value={listIndustry.find((industry) => industry.id == searchParams.industry)}
                  handleSelectedOption={(selected) => setSearchParams({ ...searchParams, industry: selected.id })}
                  attributeShown="industry"
                  className="!min-w-[200px] xl:hidden"
                />
                <DropdownUser
                  data={listSBU}
                  noPic
                  type="Sales Business Unit"
                  value={listSBU.find((sbu) => sbu.sbu_id == searchParams.sbu)}
                  handleSelectedOption={(selected) => setSearchParams({ ...searchParams, sbu: selected.sbu_id })}
                  attributeShown="sbu_name"
                />
                <DropdownUser
                  data={listFlagshipProject}
                  noPic
                  type="Project Flagship"
                  value={listFlagshipProject.find((fsp) => fsp.id == searchParams.flagship)}
                  handleSelectedOption={(selected) => setSearchParams({ ...searchParams, flagship: selected.id })}
                  attributeShown="name"
                />
                <DropdownUser
                  data={valueRange}
                  noPic
                  type="Value Range"
                  value={valueRange.find(
                    (range) =>
                      range.value_range_min == searchParams.value_min && range.value_range_max == searchParams.value_max
                  )}
                  attributeShown="text"
                  handleSelectedOption={(selected) =>
                    setSearchParams({
                      ...searchParams,
                      value_min: selected.value_range_min,
                      value_max: selected.value_range_max,
                    })
                  }
                  className="min-w-[300px]"
                />
                <DropdownUser
                  data={listTopic}
                  noPic
                  type="Topic"
                  value={listTopic.find((topic) => topic.id == searchParams.topic)}
                  handleSelectedOption={(selected) => setSearchParams({ ...searchParams, topic: selected.id })}
                  attributeShown="text"
                  className="!min-w-[200px]"
                />
              </DropdownGroup>
            </div>
            <Button className={'h-fit w-full md:w-fit'} href={'loss'} variant={'outlined'}>
              Show Loss Deals
            </Button>
          </div>
          <div className="h-[calc(100%-72px)]">
            {salesDeal && (
              <DnD
                data="test"
                initialColumns={salesDeal}
                statistics={statistics}
                loadMoreItem={loadMoreItem}
                toastUpdateSalesDeal={toastUpdateSalesDeal}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}
