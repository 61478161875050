import React from 'react'

export default function Textarea({ label, className, ...props }) {
  return (
    <div>
      {label && (
        <p className="mb-1 font-medium">
          {label}
          {props.required && <strong className="text-red-500">*</strong>}
        </p>
      )}
      <textarea
        className={
          `rounded-[5px] border border-primary-gray 
                    w-full flex-1 p-2.5 transition-all ${props.className} 
                    focus-within:ring-4 focus-within:ring-secondary-blue focus-within:outline-none
                    focus:border-main-500 ` + className
        }
        onChange={(e) => props.onChange(e)}
        {...props}
      ></textarea>
    </div>
  )
}
