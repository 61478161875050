import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SalesDealDetail from './SalesDeal/SalesDealDetail'
import GenerateConfirmationLetter from './SalesDeal/GenerateConfirmationLetter'
import RequestInvoice from './SalesDeal/RequestInvoice'
import usePermission from '../hooks/usePermission'
import ErrorPage from '../ErrorPage'
import SalesDealLoss from './SalesDeal/SalesDealLoss'

export default function SalesDealRoutes() {
  const { isAE, isAdmin } = usePermission()
  return (
    <Routes>
      <Route path=":id/request-invoice" element={<RequestInvoice />} />
      <Route path="/loss" element={<SalesDealLoss />} />
      <Route
        path=":id/generate-cl"
        element={isAE || isAdmin ? <GenerateConfirmationLetter /> : <ErrorPage type={403} />}
      />
      <Route path=":id/*" element={<SalesDealDetail />} />
    </Routes>
  )
}
