import { devAxios } from './axiosConfig'
export const ProductsAPI = {
  getProductVariantByProductId: async (id) => {
    var res = await devAxios.get('products/' + id)
    return res.data
  },

  getProductOnly: async (price_type = undefined) => {
    var res = await devAxios.get('products', { params: { price_type: price_type } })
    return res.data
  },

  getVariantByProductId: async (product_id, price_type = undefined) => {
    var res = await devAxios.get(`products/${product_id}`, { params: { price_type: price_type } })
    return res.data
  },
}
