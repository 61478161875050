import { Close } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
// import JokeDetails from "./JokeDetails";

const Header = ({ children, setIsOpen }) => {
  return (
    <div className="px-4 py-2.5 flex gap-4 items-center border-b">
      <div className="flex-1">{children}</div>
      <button
        onClick={() => setIsOpen(false)}
        className="flex items-center justify-center w-6 h-6 rounded text-gray-500 hover:bg-red-100 hover:text-red-500 transition-all"
      >
        <Close sx={{ fontSize: 18 }} />
      </button>
    </div>
  )
}

const Body = ({ children }) => {
  return <div className="p-4">{children}</div>
}

const Footer = ({ children }) => {
  return <div className="px-4 py-4 border-t">{children}</div>
}

const Modal = ({ setIsOpen, children, className }) => {
  return (
    <>
      <div
        className="bg-black bg-opacity-20 z-[90] w-screen h-screen top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fixed"
        onClick={() => setIsOpen(false)}
      />
      <div className="fixed z-[100] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className={`rounded bg-white shadow-lg z-20 ${className ? className : 'w-[600px]'}`}>
          <div className="">{children}</div>
        </div>
      </div>
    </>
  )
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer

export default Modal
