import React, { useEffect, useState } from 'react'
import usePermission from '../hooks/usePermission'
import Input from './Input'
import Basic from './table/cell/Basic'
import { formatThousands } from '../helper'
import Button from './Button'
import CustomTextDropdownInput from './CustomTextDropdownInput'
import Checkbox from './Checkbox'
import { COGSitemAPI } from '../apis/COGSitemsAPI'
import { Delete } from '@mui/icons-material'
import DropdownInput from './DropdownInput'

const reCountTotal = (cogs_items) => {
  var total = 0
  cogs_items.map((item) => {
    total += item.cost * item.quantity
  })
  console.log(total)
  return total
}

export default function COGSItemsTable({ project, setProject, isEditable, projectTypeTemp }) {
  const { isSM } = usePermission()
  const [isCustomCOGS, setIsCustomCOGS] = useState(false)
  const [customCOGS, setCustomCOGS] = useState({ name: '', cost: '' })
  const [listCOGS, setListCOGS] = useState()
  const [selectedCOGS, setSelectedCOGS] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [isMissingCogsData, setMissingCustomCOGSData] = useState(false)
  const [totalCOGS, setTotalCOGS] = useState(reCountTotal(project.cogs_items))
  const [cogsCols, setCOGSCols] = useState([
    {
      id: 0,
      name: 'Product/Variant',
      classes: 'w-[300px]',
      selector: (row) => <Basic data={row.cogs_item_name} />,
    },
    {
      id: 0,
      name: 'Item',
      classes: 'w-[150px]',
      selector: (row) => <Basic data={row.cogs_item_name} />,
    },
    {
      id: 1,
      name: 'Cost',
      classes: 'text-right w-[200px]',
      selector: (row) => <Basic data={`Rp${formatThousands(row.cost)}`} alignment={'right'} />,
    },
    {
      id: 2,
      name: 'Quantity',
      classes: 'text-right w-[100px]',
      selector: (row) => <Basic alignment={'right'} data={row.quantity} />,
    },
    {
      id: 3,
      name: 'COGS',
      classes: 'text-right w-[200px]',
      selector: (row) => <Basic data={row.current_cost} alignment={'right'} />,
    },
    isEditable && {
      id: 5,
      name: 'Action',
      classes: 'text-center w-20',
      selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    },
    {
      id: 4,
      name: 'COGS per Product',
      classes: 'text-right w-[150px]',
      selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    },
    {
      id: 4,
      name: 'COGS%',
      classes: 'text-right w-[150px]',
      selector: (row) => <Basic data={`??????`} alignment={'right'} />,
    },
  ])

  const [displayedCOGS, setDisplayedCOGS] = useState([])

  useEffect(() => {
    var tempRevenueItems = project.revenue_items
    var tempCOGSItems = project.cogs_items
    console.log(tempCOGSItems)
    var temp = [...displayedCOGS]
    tempRevenueItems.forEach((rev_item) => {
      var tempCogs = []
      tempCOGSItems.forEach((cogs_item) => {
        if (cogs_item.project_revenue_item_id === rev_item.id) tempCogs.push(cogs_item)
      })
      temp.push({ ...rev_item, list_cogs: tempCogs })
    })
    setDisplayedCOGS(temp)
  }, [project])

  useEffect(() => {
    new Promise((resolve, reject) => {
      COGSitemAPI.getCOGSItemsSimple().then((res) => {
        setListCOGS(res.data)
        resolve()
      })
    })
  }, [])

  // useEffect(() => {
  //   console.log(selectedProduct)
  // }, [selectedProduct])

  useEffect(() => {
    setSelectedCOGS()
    setSelectedProduct()
    setCustomCOGS({ name: '', cost: 0 })
  }, [isCustomCOGS])

  const handleAddCOGSItem = () => {
    var tempObject = {
      id: Math.round(Math.random() * 10000000000),
      cogs_id: selectedCOGS.id,
      cost: selectedCOGS.cost,
      quantity: 1,
      cogs_item_name: selectedCOGS.name,
      cogs_item_description: selectedCOGS.description,
      current_cost: selectedCOGS.cost,
      project_revenue_item_id: selectedProduct.id,
      unique_code:
        (project.id ? project.id : Math.random() * 1000) * selectedProduct.id * selectedProduct.product_id +
        selectedProduct.product_variant_id,
    }
    var tempCogsItems = []
    var existFlag = false
    project.cogs_items.map((item, index) => {
      if (item.unique_code === tempObject.unique_code && item.cogs_id === selectedCOGS.id) {
        var tempItem = { ...item, quantity: item.quantity + 1 }
        tempCogsItems.push(tempItem)
        existFlag = true
      } else {
        tempCogsItems.push(item)
      }
    })
    if (!existFlag) {
      tempCogsItems.push(tempObject)
    }
    const tempTotal = reCountTotal(tempCogsItems)
    setProject({
      ...project,
      cogs_items: tempCogsItems,
      total_cogs: tempTotal,
    })
    setTotalCOGS(tempTotal)
    setSelectedCOGS()
    setSelectedProduct()
  }

  const handleCOGSItemQtyChange = (qty, item) => {
    var temp = [...project.cogs_items]
    const index = temp.findIndex((cogs_item) => {
      return item.unique_code === cogs_item.unique_code && cogs_item.cogs_id === item.cogs_id
    })
    console.log(index)
    var objectTemp = { ...temp[index], quantity: qty }
    var itemCOGSBefore = item.cost * item.quantity
    var itemCOGSAfter = item.cost * qty
    temp.splice(index, 1, objectTemp)
    // console.log(temp)
    const tempTotal = reCountTotal(temp)
    setProject({
      ...project,
      cogs_items: temp,
      total_cogs: tempTotal,
    })
    setTotalCOGS(tempTotal)
  }

  const handleDeleteCOGSItem = (item) => {
    var temp = project.cogs_items
    const index = temp.findIndex((cogs) => {
      return cogs.id === item.id && cogs.unique_code === item.unique_code
    })
    var itemCOGSBefore = item.cost * item.quantity
    temp.splice(index, 1)
    const tempTotal = reCountTotal(temp)
    setProject({ ...project, cogs_items: temp, total_cogs: tempTotal })
    setTotalCOGS(tempTotal)
  }

  const handleCustomCOGSChange = (input) => {
    setCustomCOGS({ ...customCOGS, [input.name]: input.value })
  }

  const handleAddCustomCOGS = () => {
    if (customCOGS.name && customCOGS.cost) {
      var tempObject = {
        id: Math.round(Math.random() * 10000000000),
        cogs_id: null,
        cost: parseInt(customCOGS.cost),
        quantity: 1,
        cogs_item_name: customCOGS.name,
        custom_name: customCOGS.name,
        cogs_item_description: '',
        current_cost: parseInt(customCOGS.cost),
        project_revenue_item_id: selectedProduct.id,
        unique_code: project.id * selectedProduct.id * selectedProduct.product_id + selectedProduct.product_variant_id,
      }
      var tempCogsItems = [...project.cogs_items, tempObject]

      const tempTotal = reCountTotal(tempCogsItems)
      setProject({
        ...project,
        cogs_items: tempCogsItems,
        total_cogs: tempTotal,
      })
      setTotalCOGS(tempTotal)
      setCustomCOGS({ name: '', cost: 0 })
      setMissingCustomCOGSData(false)
    } else {
      setMissingCustomCOGSData(true)
    }
  }
  return (
    <div className="mb-12">
      <h1 className="font-semibold text-giant mb-4">COGS</h1>
      <div className="border border-gray-border rounded-[5px] p-4">
        {isEditable && (
          <>
            {isCustomCOGS ? (
              <div className=" flex gap-4 items-end">
                <div className="md:w-[350px] ">
                  <p className="font-semibold mb-1">Select Product</p>
                  <CustomTextDropdownInput
                    data={project.revenue_items}
                    displayedText={(option) => `${option.product_name} - ${option.product_variant_name}`}
                    handleSelectedOption={setSelectedProduct}
                    value={selectedProduct}
                    placeholder="Choose Product"
                    // withSearch
                  />
                </div>
                <div className="w-[480px]">
                  <Input
                    label={'Item'}
                    onChange={(e) => handleCustomCOGSChange(e.target)}
                    name={'name'}
                    placeholder="Item name"
                    value={customCOGS.name}
                  />
                </div>
                <div className="w-[480px]">
                  <Input
                    label={'Cost'}
                    onChange={(e) => handleCustomCOGSChange(e.target)}
                    name={'cost'}
                    type={'number'}
                    placeholder="Cost"
                    value={customCOGS.cost}
                  />
                </div>
                <Button
                  onClick={() => {
                    handleAddCustomCOGS()
                  }}
                >
                  Add
                </Button>
              </div>
            ) : (
              <div className="mb-2 flex flex-col gap-4 md:mb-0 md:flex-row md:items-end">
                <div className="md:w-[350px] ">
                  <p className="font-semibold mb-1">Select Product</p>
                  <CustomTextDropdownInput
                    data={project.revenue_items}
                    displayedText={(option) => `${option.product_name} - ${option.product_variant_name}`}
                    handleSelectedOption={setSelectedProduct}
                    value={selectedProduct}
                    placeholder="Choose Product"
                    // withSearch
                  />
                </div>
                <div className="md:w-[350px] ">
                  <p className="font-semibold mb-1">Item</p>
                  <CustomTextDropdownInput
                    className=""
                    data={listCOGS}
                    value={selectedCOGS}
                    handleSelectedOption={(selected) => {
                      setSelectedCOGS(selected)
                    }}
                    placeholder="Choose COGS"
                    attributeShown="name"
                    displayedText={(row) =>
                      `${row.name}${row.description ? ' - ' + row.description : ''} - Rp${formatThousands(row.cost)}`
                    }
                    withSearch
                  />
                </div>
                <Button
                  onClick={() => {
                    handleAddCOGSItem()
                  }}
                >
                  Add
                </Button>
              </div>
            )}
          </>
        )}
        {isEditable && isSM && (
          <>
            {project.type === 'custom' && (
              <div className="">
                <Checkbox
                  onChange={() => setIsCustomCOGS(!isCustomCOGS)}
                  label={'Custom COGS Item?'}
                  value={isCustomCOGS}
                />
              </div>
            )}
          </>
        )}
        <div className="mb-2">
          {isMissingCogsData && <p className="text-small font-semibold text-red-500">Please complete all field</p>}
        </div>
        {/* <Table data={project.cogs_items} cols={cogsCols} /> */}
        <div className="pb-2 relative overflow-x-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-main-500 scrollbar-track-sky-50">
          <table className={'table-fixed w-full'}>
            <thead className="rounded-[5px] py-4 text-left font-bold text-white bg-main-500 overflow-hidden">
              <tr>
                <th className="px-4 w-8 text-center grow-0 shrink-0 rounded-l-[5px]">#</th>
                {cogsCols.map((col, index) => {
                  if (index === cogsCols.length - 1) {
                    return (
                      <th key={index} className={`px-4 py-4 font-semibold rounded-r-[5px] ${col.classes}`}>
                        {col.name}
                      </th>
                    )
                  }
                  return (
                    <th key={index} className={`px-4 py-4 font-semibold ${col.classes}`}>
                      {col.name}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {project.revenue_items.length === 0 ? (
                <tr className="text-center italic">
                  <td colSpan={cogsCols.length} className="text-gray-text font-semibold py-4">
                    No data found
                  </td>
                </tr>
              ) : (
                project.revenue_items.map((row, rev_index) => {
                  var tempItemTotalCOGS = 0
                  return (
                    <tr key={rev_index} className="border-b border-gray-border">
                      <td className="px-4 py-4 font-semibold w-8">{rev_index + 1}.</td>
                      <td className="px-4 py-4 font-semibold w-8">
                        <Basic data={`${row.product_name} - ${row.product_variant_name}`} />
                      </td>
                      <td colSpan={5}>
                        {project.cogs_items.map((cogs_row, cogs_index) => {
                          if (cogs_row.project_revenue_item_id === row.id) {
                            tempItemTotalCOGS += cogs_row.cost * cogs_row.quantity
                            return (
                              <tr
                                key={cogs_index}
                                className={`${
                                  cogs_index === project.cogs_items.length - 1 ? '' : `border-b border-gray-border`
                                }`}
                              >
                                <td className={`px-4 py-4 font-semibold w-[150px]`}>
                                  {cogs_row.cogs_id === null
                                    ? cogs_row.custom_name
                                    : `${cogs_row.cogs_item_name}${
                                        cogs_row.cogs_item_description !== ''
                                          ? ' - ' + cogs_row.cogs_item_description
                                          : ''
                                      }`}
                                </td>
                                <td className={`px-4 py-4 font-semibold text-right w-[200px]`}>
                                  Rp{formatThousands(cogs_row.cost)}
                                </td>
                                <td className={`px-4 py-4 font-semibold text-right w-[100px]`}>
                                  {isEditable ? (
                                    <Input
                                      onChange={(e) => {
                                        var temp
                                        if (e.target.value < 0 || e.target.value === '') {
                                          temp = 0
                                        } else {
                                          temp = e.target.value
                                        }
                                        handleCOGSItemQtyChange(parseInt(temp), cogs_row)
                                      }}
                                      value={cogs_row.quantity}
                                    />
                                  ) : (
                                    <Basic data={cogs_row.quantity} alignment={'right'} />
                                  )}
                                </td>
                                <td className={`px-4 py-4 font-semibold text-right w-[200px]`}>
                                  Rp{formatThousands(cogs_row.cost * cogs_row.quantity)}
                                </td>
                                {isEditable && (
                                  <td className={`px-4 py-4 font-semibold `}>
                                    <div className="flex justify-center">
                                      <button
                                        className="w-8 h-8 rounded flex items-center justify-center text-gray-300 hover:bg-red-100 hover:text-red-500"
                                        onClick={() => handleDeleteCOGSItem(cogs_row)}
                                      >
                                        <Delete sx={{ fontSize: 20 }} />
                                      </button>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            )
                          }
                        })}
                      </td>
                      <td className="px-4 py-4 font-semibold w-8">
                        <Basic data={`Rp${formatThousands(tempItemTotalCOGS)}`} alignment={'right'} />
                      </td>
                      <td className="px-4 py-4 font-semibold w-8">
                        <Basic
                          data={
                            row.discount_type === 'absolute'
                              ? `${parseFloat(
                                  (tempItemTotalCOGS / (row.quantity * row.price - row.discount_absolute)) * 100
                                ).toFixed(0)}% / ${(parseFloat(row.cogs) * 100).toFixed(0)}%`
                              : `${parseFloat(
                                  (tempItemTotalCOGS /
                                    (row.quantity * row.price * (1 - parseFloat(row.discount_percentage)))) *
                                    100
                                ).toFixed(0)}% / ${(parseFloat(row.cogs) * 100).toFixed(0)}%`
                          }
                          alignment={'right'}
                          state={
                            row.discount_type === 'absolute'
                              ? `${
                                  (tempItemTotalCOGS / (row.quantity * row.price - row.discount_absolute)) * 100 <=
                                  parseFloat(row.cogs) * 100
                                    ? 'positive'
                                    : 'negative'
                                }`
                              : `${
                                  (tempItemTotalCOGS /
                                    (row.quantity * row.price * (1 - parseFloat(row.discount_percentage)))) *
                                    100 <=
                                  parseFloat(row.cogs) * 100
                                    ? 'positive'
                                    : 'negative'
                                }`
                          }
                          withIcon
                        />
                      </td>
                    </tr>
                  )
                })
              )}
              <tr className="border-b border-gray-border">
                <td colSpan={6}></td>
                <td className={`px-4 py-4 font-semibold text-right w-32 `}>Total COGS</td>
                <td className={`px-4 py-4 font-semibold text-right`}>Rp{formatThousands(totalCOGS)}</td>
                {/* <td className={`px-4 py-4 font-semibold text-right`}>{'????'}%</td> */}
                <td className={`px-4 py-4 font-semibold `}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
