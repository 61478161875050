import React, { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import DropdownUser from '../../components/DropdownUser'
import SBUAPI from '../../apis/SBUAPI'
import AccountAPI from '../../apis/AccountAPI'
import Table from '../../components/table/Table'
import Basic from '../../components/table/cell/Basic'
import StatusCell from '../../components/table/cell/StatusCell'
import ProjectAPI from '../../apis/ProjectAPI'
import { formatThousands } from '../../helper'
import LogoCell from '../../components/table/cell/LogoCell'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import usePermission from '../../hooks/usePermission'
import Alert from '../../components/Alert'
import Pagination from '../../components/Pagination'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'

export default function ProjectFlagship() {
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const [isLoading, setLoading] = useState(true)
  const [status, setStatus] = useState([
    { id: 0, text: 'All Status', value: '' },
    { id: 1, text: 'Active', value: 'active' },
    { id: 2, text: 'Inactive', value: 'inactive' },
  ])
  const [selectedStatus, setSelectedStatus] = useState()
  const [listClient, setListClient] = useState()
  const [selectedClient, setSelectedClient] = useState()
  const [stateCellConfig, setCellConfig] = useState([
    {
      text: 'Inactive',
      status: 'inactive',
      state: 'danger',
    },
    {
      text: 'Active',
      status: 'active',
      state: 'success',
    },
  ])
  const [projects, setProjects] = useState([])
  const [pagination, setPagination] = useState({ total_page: 1 })
  const [projectTableCols, setProjectTableCols] = useState([
    {
      id: 1,
      name: 'Project Name',
      classes: 'min-w-[200px]',
      selector: (row) => <Basic data={row.name} />,
    },
    {
      id: 5,
      name: 'Total Revenue',
      classes: 'text-right min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`Rp${formatThousands(row.total_revenue)}`} />,
    },
    {
      id: 6,
      name: 'Total Cogs',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => <Basic alignment="right" data={`Rp${formatThousands(row.total_cogs)}`} />,
    },
    {
      id: 7,
      name: 'Margin',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => (
        <Basic
          alignment="right"
          data={`${row.total_revenue ? ((1 - row.total_cogs / row.total_revenue) * 100).toFixed(2) : 0}%`}
        />
      ),
    },
    {
      id: 8,
      name: 'COGS',
      classes: 'text-right  min-w-[150px]',
      selector: (row) => (
        <Basic
          alignment="right"
          data={`${
            row.total_revenue ? ((row.total_cogs / row.total_revenue) * 100).toFixed(2) : row.total_cogs ? 100 : 0
          }%`}
        />
      ),
    },
    {
      id: 9,
      name: 'Status',
      classes: 'min-w-[150px]',
      selector: (row) => <StatusCell config={stateCellConfig} data={row.status} />,
    },
  ])
  const { isAdmin } = usePermission()
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedDelete, setSelectedDelete] = useState()
  const [isLoadingDelete, setLoadingDelete] = useState(false)
  const [refreshDOM, setRefreshDOM] = useState(0)
  const [errorDelete, setErrorDelete] = useState()

  useEffect(() => {
    setLoading(true)
    ProjectAPI.getAllFlagshipProject(searchParams.status || undefined).then((res) => {
      setProjects(res.data)
      // console.log(res.data)
      setLoading(false)
    })
  }, [searchParams.status, refreshDOM])

  useEffect(() => {
    console.log(selectedDelete)
  }, [selectedDelete])

  const handleCloseModal = () => {
    setModalOpen(!isModalOpen)
    setSelectedDelete()
  }

  const handleDelete = () => {
    setLoadingDelete(true)
    ProjectAPI.deleteFlagshipProject(selectedDelete)
      .then((res) => {
        setRefreshDOM(Math.random() * 1000000)
        setModalOpen(false)
        setSelectedDelete()
        setLoadingDelete(false)
      })
      .catch((err) => {
        if (err.response) {
          setErrorDelete(err.response.data.message)
        } else {
          setErrorDelete(err.statusText)
        }
      })
  }

  return (
    <div>
      {isModalOpen && (
        <Modal
          setIsOpen={() => {
            handleCloseModal()
          }}
        >
          <Modal.Header
            setIsOpen={() => {
              handleCloseModal()
            }}
          >
            Delete Flagship Project
          </Modal.Header>
          <Modal.Body
            setIsOpen={() => {
              handleCloseModal()
            }}
          >
            <div className="px-2 py-1.5 rounded">Are you sure to delete this flagship project?</div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-end items-center gap-4">
              <div className="flex-1">{errorDelete && <Alert type={'error'} message={errorDelete} />}</div>
              <Button>Cancel</Button>
              <Button
                isLoading={isLoadingDelete}
                onClick={handleDelete}
                className="!bg-red-100 !text-red-500 ring-red-100 hover:!bg-red-500 hover:ring-red-500 hover:!text-white"
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <div className="flex flex-col md:flex-row gap-4 mb-4">
        <div className="md:flex-1">
          <DropdownUser
            data={status}
            value={status.find((stat) => stat.value === searchParams.status)}
            noPic
            type="Status"
            handleSelectedOption={(selected) =>
              setSearchParams({ ...searchParams, page: 1, status: selected.value || '' })
            }
            attributeShown="text"
          />
        </div>
        {isAdmin && (
          <Button href={'/projects/create-flagship'} className={'w-full md:w-fit'}>
            Create Project Flagship
          </Button>
        )}
      </div>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <Table
            data={projects}
            cols={projectTableCols}
            withActions={isAdmin}
            viewLink={(row) => `/projects/view/f/${row.id}`}
            editLink={(row) => (isAdmin ? `/projects/edit/f/${row.id}/` : '/403')}
            handleDelete={(row) => {
              setModalOpen(true)
              setSelectedDelete(row)
            }}
            // tableType="inline"
          />
        )}
        <Pagination
          totalPage={pagination.total_page || 1}
          currentPage={parseInt(searchParams.page) || 1}
          onChange={(page) => setSearchParams({ ...searchParams, page: page })}
        />
      </div>
    </div>
  )
}
