import React, { forwardRef, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import withClickOutside from '../withClickOutside'
import Input from './Input'
import SalesPlanAPI from '../apis/SalesPlanAPI'
import { Check, CheckCircle } from '@mui/icons-material'

const DropdownFilterTags = forwardRef(function DropdownFilterTags({ open, setOpen, props }, ref) {
  const [isOpen, setIsOpen] = useState(false)
  const { handleSelectedOption, className, withSearch, value } = { ...props }
  const [selectedTags, setSelectedTags] = useState(value)
  const [data, setData] = useState([])
  const [displayedData, setDisplayedData] = useState([])
  const [refreshOption, setRefreshOption] = useState(0)

  useEffect(() => {
    SalesPlanAPI.getAllTags().then((res) => {
      setData([{ id: undefined, value: 'All tags' }, ...res.data])
      setDisplayedData([{ id: undefined, value: 'All tags' }, ...res.data])
    })
  }, [])

  useEffect(() => {
    setSelectedTags(value)
  }, [value])

  // useEffect(() => {
  //   // console.log(selectedTags.map((tag) => tag.id))
  //   // setSelectedTags(data.filter((tag) => value.includes(tag.id)))
  //   // setSelectedTags(value)
  //   // console.log(value)
  // }, [value])

  const handleOptionClick = (option) => {
    setRefreshOption((refreshOption) => refreshOption + 1)
    var index = selectedTags.findIndex((item) => item.id === option.id)
    // console.log(index)

    if (option.value === 'All tags') {
      console.log('all')
      setSelectedTags([])
    } else {
      if (index === -1) {
        handleSelectedOption([...selectedTags, option.id])
        setSelectedTags([...selectedTags, option.id])
      } else {
        var temp = [...selectedTags]
        temp.splice(index, 1)
        handleSelectedOption(temp.map((tag) => tag.id))
        setSelectedTags(temp.map((tag) => tag.id))
      }
    }
  }

  const handleSearch = (input) => {
    if (input.value === '') setDisplayedData(data)
    var temp = data.filter((item) => item.value.toLowerCase().includes(input.value.toLowerCase()))
    setDisplayedData(temp)
  }

  return (
    <div className="relative md:inline-block text-left" ref={ref}>
      <div className={`min-w-[260px] ${className}`}>
        <button
          type="button"
          className="w-full rounded-md bg-white px-4 py-2 font-medium text-gray-700 hover:bg-sky-50 focus:outline-none focus:ring-0"
          onClick={() => setOpen(!open)}
        >
          {selectedTags.length > 0 ? (
            <div className="flex items-center gap-2">
              <span className="w-10 h-10 rounded-full bg-blue-100 overflow-hidden flex items-center justify-center text-main-500">
                <BusinessCenterIcon sx={{ fontSize: 22 }} />
              </span>
              <div className="flex-1 text-start pr-4">
                <p className="font-bold text-main-500">Tags</p>
                <div className="flex items-center justify-between">
                  <p>{selectedTags.length} tags selected</p>
                  <span className="flex items-center justify-center w-2 h-2">
                    <KeyboardArrowDownIcon sx={{ fontSize: 20 }} className="ml-4" />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <span className="w-10 h-10 rounded-full bg-blue-100 overflow-hidden flex items-center justify-center text-main-500">
                <BusinessCenterIcon sx={{ fontSize: 22 }} />
              </span>
              <div className="flex-1 text-start pr-4">
                <p className="font-bold text-main-500">Tags</p>
                <div className="flex items-center justify-between">
                  <span className="flex-1 text-normal">Select Tags</span>
                  <span className="flex items-center justify-center w-2 h-2">
                    <KeyboardArrowDownIcon sx={{ fontSize: 20 }} className="ml-4" />
                  </span>
                </div>
              </div>
            </div>
          )}
        </button>
      </div>
      {open && (
        <div className="absolute left-0 z-50 mt-2 w-full max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="" role="none">
            {withSearch && (
              <div className="py-1 px-2">
                <Input placeholder="Search data..." onChange={(e) => handleSearch(e.target)} />
              </div>
            )}
            {displayedData.map((option, index) => {
              return (
                <button
                  key={index}
                  className="text-gray-700 w-full px-4 py-2 text-left text-sm flex items-center gap-2 hover:bg-main-50"
                  onClick={() => {
                    handleOptionClick(option)
                  }}
                >
                  <div className="flex items-center gap-2">
                    <span
                      className={`w-6 h-6 ${
                        selectedTags.length === 0 && option.value === 'All tags'
                          ? 'text-main-500'
                          : selectedTags.findIndex((item) => item == option.id) === -1
                          ? 'text-gray-200'
                          : 'text-main-500'
                      }`}
                    >
                      <CheckCircle sx={{ fontSize: 22 }} />
                    </span>
                    <p>{option.value}</p>
                  </div>
                </button>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
})

export default withClickOutside(DropdownFilterTags)
